import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import AutoComleteField from "../components/common/AutoComleteField";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import { userLang } from "../store/reducers/user";
import { useSelector } from "react-redux";
import useHospitalsCountriesData from "../hooks/useHospitalsCountriesData";
export default function Hospitals() {
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [hospital, setHospital] = useState("");
  const [hospital_EN, setHospital_EN] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalId, setHospitalId] = useState();
  const [hospitalIdDelete, setHospitalIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const lang = useSelector(userLang);
  const { data: hosiptalsCountries } = useHospitalsCountriesData();

  const view = useHaveUserPermission("hospital", "view");
  const add = useHaveUserPermission("hospital", "add");
  const change = useHaveUserPermission("hospital", "change");
  const deletePerm = useHaveUserPermission("hospital", "delete");
  const createHospitals = async () => {
    setDisableBtnCreate(true);
    const result = await api.createHospitals({
      country: country.value,
      city: city.value,
      hospital: hospital,
      hospital_EN: hospital_EN,
      active,
    });
    if (result.ok) {
      // const newHospitalList = [...hospitalList];
      // newHospitalList.unshift({
      //   country: result.data.country,
      //   city: result.data.city,
      //   active: result.data.active,
      //   id: result.data.id,
      //   date: result.data.date,
      //   hospital: result.data.hospital,
      //   hospital_EN: result.data.hospital_EN,
      // });
      // setHospitalList(newHospitalList);
      getHospital();
      setHospitalId();
      setHospital("");
      setHospital_EN("");
      setActive(false);
      toast.success("تم إضافة المستشفى بنجاح");
    }
    setDisableBtnCreate(false);
  };
  const getCities = async () => {
    const result = await api.getHospitalsCities(country.value);
    if (result.ok) {
      setCitiesList(result.data.reverse());
    }
  };
  const getHospital = async () => {
    if (view) {
      const result = await api.getHospitals(country.value);
      if (result.ok) {
        setHospitalList(result.data.reverse());
      }
    }
  };
  const onClick = (item) => {
    setActive(item.active);
    setHospital(item.hospital);
    setHospital_EN(item.hospital_EN);
    setCity({
      label: lang == "AR" ? item.city.city : item.city.city_EN,
      value: item.city.id,
    });

    setHospitalId(item.id);
  };
  const deleteHospital = async (id) => {
    const result = await api.deleteHospitals(id);
    if (result.ok) {
      const newHospitalList = hospitalList.filter((i) => i.id != id);
      setHospitalList(newHospitalList);
      setHospitalId();
      setHospital("");
      setHospital_EN("");
      setActive(false);

      setShowModal(false);
      toast.success("تم حذف المستشفى بنجاح");
    } else {
      toast.error(strings2.error_delete[lang]);
    }
  };
  const updateHospital = async () => {
    setDisableBtnUpdate(true);

    const result = await api.updateHospitals(hospitalId, {
      country: country.value,
      city: city.value,
      hospital: hospital,
      hospital_EN: hospital_EN,
      active,
    });
    if (result.ok) {
      getHospital();
      setHospitalId();
      setHospital("");
      setHospital_EN("");
      setActive(false);
      toast.success("تم تعديل المستشفى بنجاح");
    }
    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = strings[lang].data.Hospitals;
    if (country) {
      setHospitalList([]);
      getHospital();
      setCity(null);
      getCities();
      setHospitalId();
      setHospital("");
      setHospital_EN("");
      setActive(false);
    } else {
      setHospitalList([]);
      setHospitalId();
      setHospital("");
      setHospital_EN("");
      setCity(null);
      setActive(false);
    }
  }, [country]);
  // useEffect(() => {
  //   if (city) {
  //     setHospitalId();
  //     setHospital("");
  //     setHospital_EN("");
  //     setActive(false);
  //   } else {
  //     setHospitalList([]);
  //     setHospitalId();
  //     setHospital("");
  //     setHospital_EN("");
  //     setActive(false);
  //   }
  // }, [city]);
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.Hospitals}
        </p>
      </div>
      <div className=" d-flex    flex-column ">
        <div className="w-50 mt-4 align-self-center">
          <AutoComleteField
            value={country}
            setValue={setCountry}
            placeholder={strings[lang].data.country}
            data={hosiptalsCountries.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
          />
          <AutoComleteField
            value={city}
            setValue={setCity}
            placeholder={strings[lang].data.city}
            data={citiesList.map((item) => ({
              label: lang == "AR" ? item.city : item.city_EN,
              value: item.id,
            }))}
          />
          <div className="mx-2">
            <input
              value={hospital}
              onChange={(e) => setHospital(e.target.value)}
              className="form-control  p-2  my-3 "
              placeholder="المستشفى"
            />
            <input
              value={hospital_EN}
              onChange={(e) => setHospital_EN(e.target.value)}
              className="form-control  p-2  my-3 "
              placeholder="Hospital"
            />
            <div className="form-check ">
              <input
                onChange={(e) => setActive(e.target.checked)}
                className="form-check-input float-none ms-0"
                type="checkbox"
                checked={active}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label mx-2"
                htmlFor="flexCheckDefault"
              >
                {strings[lang].data.active}
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex align-self-center">
          <button
            onClick={updateHospital}
            className={`btn btn-success  ${
              disableBtnUpdate || !hospitalId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createHospitals}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !hospital || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {hospitalList.length > 0 && (
          <div style={{}} className="table-responsive">
            <table className="table table-hover mt-4  table-bordered text-center align-middle  ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{strings[lang].data.country}</th>
                  <th scope="col">{strings[lang].data.city}</th>
                  <th scope="col">المستشفى</th>
                  <th scope="col">hospital</th>
                  <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {hospitalList.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row" onClick={() => onClick(item)}>
                      {hospitalList.length - index}
                    </th>
                    <td onClick={() => onClick(item)}>
                      {lang == "AR"
                        ? item.country.country
                        : item.country.country_EN}
                    </td>
                    <td onClick={() => onClick(item)}>
                      {lang == "AR" ? item.city.city : item.city.city_EN}
                    </td>
                    <td onClick={() => onClick(item)}>{item.hospital}</td>
                    <td onClick={() => onClick(item)}>{item.hospital_EN}</td>
                    <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>

                    <td>
                      <p
                        className={`mb-0 btn btn-outline-danger  ${
                          !deletePerm ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setHospitalIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={hospitalIdDelete}
        deleteMethod={deleteHospital}
      />
    </div>
  );
}
