export const showLoading = () => {
  document.getElementById("loading").style.display = "block";

  const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
  document.body.style.overflow = "hidden";
  document.body.style.paddingRight = `${scrollBarCompensation}px`;
};
export const hideLoading = () => {
  document.getElementById("loading").style.display = "none";

  document.body.style.overflow = "";
  document.body.style.paddingRight = `0`;
};
