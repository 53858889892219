import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../components/api/api";

export const sponsorshipServices = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "sponsorshipServices",
  tagTypes: ["sponsorships"],
  endpoints: (builder) => ({
    getSponsorships: builder.query({
      query: (sponsorId) => ({
        url: `data/sponsorship/?sponsor=${sponsorId}`,
      }),
    }),
  }),
});

export const { useLazyGetSponsorshipsQuery, useGetSponsorshipsQuery } =
  sponsorshipServices;
