import React, { useState } from "react";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import api from "../../components/api/api";
import Datetime from "react-datetime";
import moment from "moment";
import { writeFile, utils, write } from "xlsx";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import ColorTabel from "../../components/reports/HospitalDateStats/ColorTabel";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function FollowUpReceipt() {
  const [year, setYear] = useState("");
  const [reports, setReports] = useState([]);
  const lang = useSelector(userLang);

  const getReport = async () => {
    const { data, ok } = await api.getHospitalsDateReport(year ? year : "");
    if (ok) {
      setReports(data);
    } else {
      setReports([]);
    }
  };

  const createExcel = () => {
    const table = document.getElementById("hospitals-reports");

    const wb = utils.table_to_book(table, {
      raw: true,
    });
    writeFile(wb, "reports.xlsx");
  };
  const tableToImg = async () => {
    const tabel = document.getElementById("hospitals-reports");
    return await htmlToImage.toPng(tabel);
  };
  const downloadPhoto = async () => {
    const img = await tableToImg();
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  const createPDF = async () => {
    const imgURL = await tableToImg();
    const doc = new jsPDF("p", "mm", "a4");
    var width = doc.internal.pageSize.getWidth();

    doc.addImage(imgURL, "JPEG", 0, 0, width, 0);
    doc.save("reports.pdf");
  };
  return (
    <>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div className="col-lg-4 col-12 " dir={lang == "AR" ? "rtl" : "ltr"}>
          <Datetime
            className="p-2"
            closeOnSelect
            dateFormat="YYYY"
            onChange={(v) => {
              if (v) {
                setYear(moment(v).format("YYYY"));
              } else {
                setYear("");
              }
            }}
            timeFormat={false}
            inputProps={{
              placeholder: strings2.year[lang],
              value: year,
            }}
          />

          <div className="row justify-content-around mb-3">
            <button
              onClick={getReport}
              className={`btn btn-primary col-4 ${!year ? "disabled" : ""}`}
            >
              {strings[lang].reports.search}
            </button>
            <div style={{ width: "35%" }} className="dropdown-center ">
              <button
                style={{ width: "100%" }}
                type="button"
                className={`btn btn-warning dropdown-toggle `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {strings[lang].reports.download}
              </button>
              <ul className="dropdown-menu">
                <li style={{ cursor: "pointer" }} onClick={downloadPhoto}>
                  <span className="dropdown-item text-end">
                    {strings[lang].reports.photo}
                  </span>
                </li>
                <li style={{ cursor: "pointer" }} onClick={createExcel}>
                  <span className="dropdown-item text-end">{"Excel"}</span>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li onClick={createPDF} style={{ cursor: "pointer" }}>
                  <span className="dropdown-item text-end">PDF</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 flex-column jusify-content-center align-items-center">
        <div className="table-responsive col-12 ">
          {reports.length > 0 && (
            <table
              id="hospitals-reports"
              className="table table-striped  table-bordered text-center align-middle "
              dir={lang == "EN" ? "ltr" : "rtl"}
            >
              <thead className="header-tabel">
                <tr>
                  <td>#</td>
                  <td>{strings2.hospital[lang]}</td>
                  <td>{strings2.country[lang]}</td>
                  <td>{strings2.city[lang]}</td>
                  {reports[0].months.map((i) => (
                    <td key={i.month}>{i.month}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reports.map((item, index) => (
                  <tr key={index}>
                    <td className="fw-bold">{index + 1}</td>
                    <td>{lang == "AR" ? item.name : item.name_EN}</td>
                    <td>{lang == "AR" ? item.country : item.country_EN}</td>
                    <td>{lang == "AR" ? item.city : item.city_EN}</td>
                    {item.months.map((i) => {
                      const m = i.date
                        ? parseInt(moment(i.date).format("DD"))
                        : "";
                      // const m = 20;
                      let bg = "";
                      if (i.date) {
                        if (m <= 3) bg = "bg-primary text-white";
                        else if (m > 3 && m <= 7) bg = "bg-success text-white";
                        else if (m > 7 && m <= 12) bg = "bg-warning ";
                        else if (m > 12 && m <= 20)
                          bg = "bg-danger-subtle text-white";
                        else bg = "bg-danger text-white";
                      }
                      return (
                        <td key={i.month} className={bg}>
                          {m}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {reports.length > 0 && (
          <div className="col-12 col-lg-4">
            <ColorTabel />
          </div>
        )}
      </div>
    </>
  );
}
