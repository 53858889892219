import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../components/api/api";
import { toast } from "react-toastify";
import strings2 from "../../components/common/strings2.json";
import { userLang } from "../reducers/user";

export const hospitalsCitiesServices = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "hospitalsCitiesServices",
  tagTypes: ["cities"],
  endpoints: (builder) => ({
    getHospitalsCities: builder.query({
      query: (countryId) => ({
        url: `data/HospitalsCities/?country__in=${countryId}`,
      }),
    }),
    createHospitalsCity: builder.mutation({
      query: (data) => ({
        url: "data/HospitalsCities/",
        method: "POST",
        data,
      }),
      async onQueryStarted({ country }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          hospitalsCitiesServices.util.updateQueryData(
            "getHospitalsCities",
            country,
            (draft) => {
              draft.unshift(data);
            }
          )
        );
        toast.success("تم إضافة المدينة بنجاح");
      },
    }),
    updateHospitalsCity: builder.mutation({
      query: ({ cityId, data }) => ({
        url: `data/HospitalsCities/${cityId}/`,
        method: "PUT",
        data,
      }),
      async onQueryStarted(
        { cityId, data: { country } },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          hospitalsCitiesServices.util.updateQueryData(
            "getHospitalsCities",
            country,
            (draft) => {
              const index = draft.findIndex((c) => c.id === cityId);
              draft[index] = data;
            }
          )
        );
        toast.success("تم تعديل المدينة بنجاح");
      },
    }),
    deleteHospitalsCity: builder.mutation({
      query: ({ cityId }) => ({
        url: `data/HospitalsCities/${cityId}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { countryId, cityId },
        { dispatch, queryFulfilled, getState }
      ) {
        const lang = userLang(getState());
        try {
          const data = await queryFulfilled;
          dispatch(
            hospitalsCitiesServices.util.updateQueryData(
              "getHospitalsCities",
              countryId,
              (draft) => {
                return draft.filter((cities) => cities.id !== cityId);
              }
            )
          );
          toast.success("تم حذف المدينة بنجاح");
        } catch (error) {
          toast.error(strings2.error_delete[lang]);
        }
      },
    }),
  }),
});

export const {
  useLazyGetHospitalsCitiesQuery,
  useCreateHospitalsCityMutation,
  useDeleteHospitalsCityMutation,
  useUpdateHospitalsCityMutation,
} = hospitalsCitiesServices;
