import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import strings from "../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function DeleteModal({ show, setShow, deleteMethod, id }) {
  const [disabled, setDisabled] = useState(false);
  const lang = useSelector(userLang);

  const deletePlan = async () => {
    setDisabled(true);
    await deleteMethod(id);
    setDisabled(false);
    setShow(false);
  };
  return (
    <Modal
      dir={lang == "EN" ? "ltr" : "rtl"}
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{strings[lang].common.confirmation}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          {strings[lang].common.close}
        </Button>
        <Button disabled={disabled} onClick={deletePlan} variant="danger">
          {strings[lang].common.delete}
          {disabled && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
