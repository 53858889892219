import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  token: "",
  profile: null,
  lang: "AR",
  userPermissions: {
    annualplan: [],
    camp: [],
    cities: [],
    planstatus: [],
    responsible: [],
    contenttype: [],
    countries: [],
    financialfollowup: [],
    financialfollowupfile: [],
    financialsectorfollowup: [],
    financialsectorfollowupfile: [],
    group: [],
    historicalannualplan: [],
    historicalcountries: [],
    historicalfinancialfollowup: [],
    historicalmediafollowup: [],
    historicalmedicationfollowup: [],
    historicalsponsorsfollowup: [],
    historicalpreparatoryvisitfollowup: [],
    historicalfinancialsectorfollowup: [],
    logentry: [],
    mediafollowup: [],
    mediafollowupfile: [],
    medicationfollowup: [],
    medicationfollowupfile: [],
    preparatoryvisitfollowup: [],
    preparatoryvisitfollowupfile: [],
    permission: [],
    session: [],
    sponsor: [],
    sponsorsfollowup: [],
    sponsorsfollowupfile: [],
    preparingtheteamfollowup: [],
    preparingtheteamfollowupfile: [],
    sponsorship: [],
    stats: [],
    team: [],
    token: [],
    tokenproxy: [],
    users: [],
    reports: [],
    secondfollowup: [],
    firstfollowup: [],
    hospital: [],
    hospitalstats: [],
    hospitalsstatsfile: [],
    hospitalscountries: [],
    hospitalscities: [],
    contactperson: [],
    hospitalsmonthlyreport: [],
    hospitalsfollowupreceiptreport: [],
    hospitalsanalyticalreport: [],
    hospitalscollectivereport: [],
    collectivereports: [],
    followupreports: [],
    teamchronologicalreport: [],
  },
};

export const getUserPermissions = createAsyncThunk(
  "user/getUserPermissions",
  async () => {
    return await apiServices.getUserPermissions();
  }
);
export const getUser = createAsyncThunk("user/getUser", async () => {
  return await apiServices.getUser();
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        const allPermissions = { ...initialState.userPermissions };
        action.payload.data.forEach((item) => {
          const per = item.split(".")[1];
          const perType = per.split("_");
          if (!allPermissions[perType[1]]) {
            allPermissions[perType[1]] = [perType[0]];
          } else {
            allPermissions[perType[1]] = [
              ...allPermissions[perType[1]],
              perType[0],
            ];
          }
        });
        state.userPermissions = allPermissions;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.profile = action.payload.data;
      });
  },
});

export const { setToken, setProfile, setUserPermissions, setLang } =
  userSlice.actions;

export const userToken = (state) => state.user.token;
export const userLang = (state) => state.user.lang;
export const userProfile = (state) => state.user.profile;
export const userPermissions = (state) => state.user.userPermissions;

export default userSlice.reducer;
