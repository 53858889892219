import React, { useState, useEffect, memo } from "react";
import {
  useLocation,
  NavLink,
  Link,
  useNavigate,
  redirect,
} from "react-router-dom";

import Logo from "../../asset/logo.png";
import strings2 from "../common/strings2.json";
import Menu from "./Menu";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
function NavBar({}) {
  const lang = useSelector(userLang);

  const location = useLocation();
  const navigation = useNavigate();
  const annualplanView = useHaveUserPermission("annualplan", "view");
  const viewHospitalstats = useHaveUserPermission("hospitalstats", "view");
  const viewStats = useHaveUserPermission("stats", "view");
  const viewSecondfollowup = useHaveUserPermission("secondfollowup", "view");
  const viewFirstfollowup = useHaveUserPermission("firstfollowup", "view");
  const viewFinancialfollowup = useHaveUserPermission(
    "financialfollowup",
    "view"
  );
  const viewFinancialsectorfollowup = useHaveUserPermission(
    "financialsectorfollowup",
    "view"
  );
  const viewMediafollowup = useHaveUserPermission("mediafollowup", "view");
  const viewMedicationfollowup = useHaveUserPermission(
    "medicationfollowup",
    "view"
  );
  const viewPreparatoryvisitfollowup = useHaveUserPermission(
    "preparatoryvisitfollowup",
    "view"
  );
  const viewSponsorsfollowup = useHaveUserPermission(
    "sponsorsfollowup",
    "view"
  );
  const viewCampsCities = useHaveUserPermission("cities", "view");
  const viewCampsCountries = useHaveUserPermission("countries", "view");
  const viewHospitalsCities = useHaveUserPermission("hospitalscities", "view");
  const viewHospitalsCountries = useHaveUserPermission(
    "hospitalscountries",
    "view"
  );
  const viewSponsor = useHaveUserPermission("sponsor", "view");
  const viewSponsorship = useHaveUserPermission("sponsorship", "view");
  const viewTeam = useHaveUserPermission("team", "view");
  const viewPlanstatus = useHaveUserPermission("planstatus", "view");
  const viewResponsible = useHaveUserPermission("responsible", "view");
  const viewHospital = useHaveUserPermission("hospital", "view");
  const viewUsers = useHaveUserPermission("users", "view");
  const viewGroup = useHaveUserPermission("group", "view");
  const viewReports = useHaveUserPermission("reports", "view");
  const viewHospitalsMonthlyReport = useHaveUserPermission(
    "hospitalsmonthlyreport",
    "view"
  );
  const viewHospitalsFollowupReceiptReport = useHaveUserPermission(
    "hospitalsfollowupreceiptreport",
    "view"
  );
  const viewHospitalsAnalyticalReport = useHaveUserPermission(
    "hospitalsanalyticalreport",
    "view"
  );
  const viewHospitalsCollectiveReport = useHaveUserPermission(
    "hospitalscollectivereport",
    "view"
  );
  const viewCampsCollectiveReports = useHaveUserPermission(
    "collectivereports",
    "view"
  );
  const viewFollowupReports = useHaveUserPermission("followupreports", "view");
  const vieTeamChronologicalReport = useHaveUserPermission(
    "teamchronologicalreport",
    "view"
  );

  // HospitalDateReport
  const hospitalMenu = [
    { label: "user", perm: true },
    { label: strings2.home[lang], to: "/hospitals", perm: true },

    {
      label: strings2.hospital_stats[lang],
      to: "/hospitals/hospitalStats",
      perm: viewHospitalstats,
    },

    {
      label: strings2.reports[lang],
      dropmenu: [
        {
          label: strings2.monthly_report[lang],
          to: "/hospitals/monthly_report",
          perm: viewHospitalsMonthlyReport,
        },
        {
          label: strings2.follow_up_receipt[lang],
          to: "/hospitals/follow_up_receipt",
          perm: viewHospitalsFollowupReceiptReport,
        },
        {
          label: strings2.analytical_report[lang],
          to: "/hospitals/analytical_report",
          perm: viewHospitalsAnalyticalReport,
        },
        {
          label: strings2.collective_report[lang],
          to: "/hospitals/collectiveReports",
          perm: viewHospitalsCollectiveReport,
        },
      ],
      perm:
        viewHospitalsMonthlyReport ||
        viewHospitalsFollowupReceiptReport ||
        viewReports,
    },
    {
      label: strings2.data[lang],
      dropmenu: [
        {
          label: strings2.Hospitals[lang],
          to: "/hospitals/hospital",
          perm: viewHospital,
        },
        {
          label: strings2.countries_nav[lang],
          to: "/hospitals/countries",
          perm: viewHospitalsCountries,
        },
        {
          label: strings2.cities_nav[lang],
          to: "/hospitals/cities",
          perm: viewHospitalsCities,
        },
      ],
      perm: viewHospital || viewHospitalsCountries || viewHospitalsCities,
    },

    {
      label: strings2.system[lang],
      dropmenu: [
        {
          label: strings2.Hospitals[lang],
          to: "/hospitals",
          perm: true,
        },
        {
          label: strings2.camps[lang],
          to: "/camps",
          perm: true,
        },
      ],
      perm: true,
    },
    {
      label: strings2.lang[lang],
      perm: true,
    },
  ];

  const campsMenu = [
    { label: "user", perm: true },
    { label: strings2.home[lang], to: "/camps", perm: true },
    {
      label: strings2.plan[lang],
      to: "/camps/plan",
      perm: annualplanView,
    },
    {
      label: strings2.follow_ups[lang],
      to: "/camps/followUp",
      perm:
        viewFinancialfollowup ||
        viewFinancialsectorfollowup ||
        viewMediafollowup ||
        viewMedicationfollowup ||
        viewPreparatoryvisitfollowup ||
        viewSponsorsfollowup,
    },
    {
      label: strings2.stats[lang],
      dropmenu: [
        {
          label: strings2.camps_stats[lang],
          to: "/camps/stats",
          perm: viewStats,
        },
        {
          label: strings2.first_follow_up[lang],
          to: "/camps/firstFollowUp",
          perm: viewFirstfollowup,
        },
        {
          label: strings2.second_follow_up[lang],
          to: "/camps/secondFollowUp",
          perm: viewSecondfollowup,
        },
      ],
      perm: viewStats || viewFirstfollowup || viewSecondfollowup,
    },
    {
      label: strings2.reports[lang],
      dropmenu: [
        {
          label: strings2.follow_ups_reports[lang],
          to: "/camps/reportsFollowUp",
          perm: viewFollowupReports,
        },
        {
          label: strings2.collective_report[lang],
          to: "/camps/collectiveReports",
          perm: viewCampsCollectiveReports,
        },
        {
          label: strings2.chronological_report[lang],
          to: "/camps/ChronologicalReport",
          perm: vieTeamChronologicalReport,
        },

        // { label: "المتابعة الثانية", to: "/secondFollowUp" },
      ],
      perm:
        viewCampsCollectiveReports ||
        viewFollowupReports ||
        vieTeamChronologicalReport,
    },
    {
      label: strings2.data[lang],
      dropmenu: [
        {
          label: strings2.countries_nav[lang],
          to: "/camps/countries",
          perm: viewCampsCountries,
        },
        {
          label: strings2.cities_nav[lang],
          to: "/camps/cities",
          perm: viewCampsCities,
        },
        {
          label: strings2.teams[lang],
          to: "/camps/teams",
          perm: viewTeam,
        },
        {
          label: strings2.status[lang],
          to: "/camps/status",
          perm: viewPlanstatus,
        },
        {
          label: strings2.Responsible[lang],
          to: "/camps/Responsible",
          perm: viewResponsible,
        },

        { label: "" },
        {
          label: strings2.donors[lang],
          to: "/camps/sponsor",
          perm: viewSponsor,
        },

        {
          label: strings2.sub_donors[lang],
          to: "/camps/sponsorship",
          perm: viewSponsorship,
        },
      ],
      perm:
        viewCampsCountries ||
        viewCampsCities ||
        viewTeam ||
        viewPlanstatus ||
        viewResponsible ||
        viewSponsor ||
        viewSponsorship,
    },
    {
      label: strings2.system[lang],
      dropmenu: [
        {
          label: strings2.Hospitals[lang],
          to: "/hospitals",
          perm: true,
        },
        {
          label: strings2.camps[lang],
          to: "/camps",
          perm: true,
        },
      ],
      perm: true,
    },
    {
      label: strings2.lang[lang],
      perm: true,
    },
  ];

  useEffect(() => {
    // const filterLink = campsMenu
    //   .reverse()
    //   .filter(
    //     (i) => i.perm && i.label != strings2.lang[lang] && i.label != "user"
    //   );
    // if (filterLink.length && location.pathname == "/") {
    //   if (filterLink[0].dropmenu) {
    //     navigation(filterLink[0].dropmenu.filter((i) => i.perm)[0].to, {
    //       replace: true,
    //     });
    //   } else {
    //     navigation(filterLink[0]?.to, { replace: true });
    //   }
    // }
    // navigation(filterLink[0]?.to);
  }, []);
  // useEffect(() => {
  //   if (!annualplanView && !viewHospital) {
  //     navigation("/camps", { replace: true });
  //   } else if (annualplanView || viewHospital) {
  //     if (location.pathname.includes("camps") && !annualplanView) {
  //       navigation("/hospitals", { replace: true });
  //     }
  //     if (location.pathname.includes("hospitals") && !viewHospital) {
  //       navigation("/camps", { replace: true });
  //     }
  //   }
  //   if (location.pathname.includes("camps")) {
  //     setMenu("camps");
  //   }
  //   if (location.pathname.includes("hospital")) {
  //     setMenu("hospital");
  //   }
  // }, [location]);
  return (
    <nav className="navbar  navbar-expand-lg bg-light">
      <div
        className={`container-fluid   ${
          lang == "EN" ? "flex-lg-row-reverse" : "flex-lg-row"
        }`}
      >
        <NavLink className="navbar-brand " to="/">
          <img
            src={Logo}
            alt="Logo"
            width={200}
            className="d-inline-block align-text-top"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse  !visible"
          id="navbarSupportedContent"
        >
          <ul
            className={`navbar-nav w-100 mb-2 mb-lg-0 flex-wrap flex-column-reverse flex-lg-row  align-items-md-end align-items-lg-center ${
              lang == "EN" ? "!justify-start" : "!justify-end"
            }`}
          >
            <Menu
              menuData={
                location.pathname.includes("camps") ? campsMenu : hospitalMenu
              }
              viewGroup={viewGroup}
              viewUsers={viewUsers}
            />
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default memo(NavBar);
