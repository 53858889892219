import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import strings from "../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function Dropzone({
  setFiles,
  files,
  maxFiles,
  disabled = false,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    maxFiles: maxFiles ? maxFiles : 100,
  });
  const lang = useSelector(userLang);

  useEffect(() => {
    setFiles(
      acceptedFiles.map((file, index) => ({
        file,
        note: "",

        id: index + 1,
      }))
    );
  }, [acceptedFiles]);
  return (
    <>
      <section
        onMouseEnter={(e) => {
          e.target.style.borderColor = "#0d6efd";
        }}
        onMouseLeave={(e) => {
          e.target.style.borderColor = "#0009";
        }}
        className="m-5"
      >
        <div
          style={{ height: 300, border: "2px dashed #0009", cursor: "pointer" }}
          {...getRootProps({
            className:
              "dropzone d-flex align-items-center justify-content-center  p-0 cursor-pointer",
          })}
        >
          <input {...getInputProps()} />
          <p className="p-1 m-0 text-center text-secondary fs-5">
            {strings[lang].common.drag}
          </p>
        </div>
      </section>
      {files.length > 0 && (
        <table className="table mb-5 text-center align-middle">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{strings[lang].common.file}</th>
              <th scope="col">{strings[lang].common.remark}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={file.id}>
                <th scope="row">{index + 1}</th>
                <td>{file.file.path}</td>
                <td>
                  <input
                    onChange={(e) => {
                      const newFiles = [...files];
                      const index = newFiles.findIndex(
                        (i) => i.file.path == file.file.path
                      );
                      newFiles[index].note = e.target.value;
                      setFiles(newFiles);
                    }}
                    className="form-control  p-2 text-center "
                    placeholder={strings[lang].common.remark}
                  />
                </td>
                <td>
                  <p
                    className="mb-0 btn btn-outline-danger"
                    onClick={() => {
                      const newFiles = files.filter(
                        (i) => i.file.path != file.file.path
                      );
                      setFiles([...newFiles]);
                    }}
                  >
                    {strings[lang].common.delete}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
