import React from "react";
import moment from "moment";
import Datetime from "react-datetime";

import strings2 from "./strings2.json";
import { useController } from "react-hook-form";
import Autocomlete from "./AutoComlete";
import { useGetCitiesQuery } from "../../store/services/planCities";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useGetSponsorshipsQuery } from "../../store/services/sponsorship";
import useCountriesData from "../../hooks/useCountriesData";
import useTeamsData from "../../hooks/useTeamsData";
import useSponsorsData from "../../hooks/useSponsorsData";
import useResponsibleData from "../../hooks/useResponsibleData";
import useStatusData from "../../hooks/useStatusData";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import strings from "./strings.json";

export default function PlanFields({
  formDate: { register, watch, control, handleSubmit, setValue },
  planId,
  isFetchingPlans,
  setShowUpdateModal,
  setSearchData,
  deleteFields,
  setShowCreateModal,
  setShowHistoriesModal,
  isLoadingUpdatePlan,
  isLoadingCreatePlan,
}) {
  const citiesView = useHaveUserPermission("cities", "view");
  const sponsorshipView = useHaveUserPermission("sponsorship", "view");
  const annualplanView = useHaveUserPermission("annualplan", "view");
  const historicalannualplanView = useHaveUserPermission(
    "historicalannualplan",
    "view"
  );

  const add = useHaveUserPermission("annualplan", "add");
  const change = useHaveUserPermission("annualplan", "change");
  const lang = useSelector(userLang);

  const { field: yearField } = useController({
    name: "year",
    control,
    rules: { required: true },
  });
  const { field: dateFrom } = useController({
    name: "dateFrom",
    control,
    rules: { required: true },
  });
  const { field: dateTo } = useController({
    name: "dateTo",
    control,
    rules: { required: true },
  });

  const { data: cities } = useGetCitiesQuery(watch("country")?.value, {
    skip: !watch("country") && citiesView,
    selectFromResult: (data) => {
      return watch("country")?.value ? data : [];
    },
  });

  const { data: sponsorships } = useGetSponsorshipsQuery(
    watch("sponsor")?.value,
    {
      skip: !watch("sponsor") && sponsorshipView,
      selectFromResult: (data) => {
        return watch("sponsor")?.value ? data : [];
      },
    }
  );
  const { data: countries } = useCountriesData();
  const { data: teamList } = useTeamsData();
  const { data: sponsors } = useSponsorsData();
  const { data: responsibles } = useResponsibleData();
  const { data: statusData } = useStatusData();
  const handleCreatePlan = () => {
    setShowCreateModal(true);
  };
  const handleUpdatePlan = () => {
    setShowUpdateModal(true);
  };

  return (
    <div className="counatiner-fluid    ">
      <form className="row mx-0 mt-3  ">
        <div className="col-sm-12 col-lg-6  ">
          <div className="d-lg-flex d-block w-100 ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <label className="form-label">
                {strings2.year_of_the_plan[lang]}
              </label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY"
                timeFormat={false}
                onChange={(v) => yearField.onChange(moment(v).format("YYYY"))}
                inputProps={{
                  value: yearField.value,
                  placeholder: strings2.select[lang],
                  ref: yearField.ref,
                  onBlur: yearField.onBlur,
                  name: yearField.name,
                }}
              />
            </div>
            <div className="col">
              <Autocomlete
                data={responsibles.map((item) => ({
                  label: lang == "AR" ? item.responsible : item.responsible_EN,
                  value: item.id,
                }))}
                title={strings2.responsible[lang]}
                control={control}
                name={"responsible"}
              />
            </div>
          </div>
          <Autocomlete
            data={countries.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
            title={strings2.country[lang]}
            control={control}
            name={"country"}
            onChangeData={() => setValue("city", null)}
            rules={{
              required: true,
            }}
          />
          <Autocomlete
            data={teamList.map((item) => ({
              label: lang == "AR" ? item.team : item.team_EN,
              value: item.id,
            }))}
            title={strings2.team[lang]}
            control={control}
            name={"team"}
          />
          <Autocomlete
            data={statusData.map((item) => ({
              label: lang == "AR" ? item.status : item.status_EN,
              value: item.id,
            }))}
            title={strings2.camp_condition[lang]}
            control={control}
            name={"status"}
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col ">
          <div className="d-lg-flex d-block w-100  ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <div className=" ">
                <label className="form-label">
                  {strings2.reference_number[lang]}
                </label>

                <input
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                  {...register("reference_number")}
                ></input>
              </div>
            </div>
            <div className=" p-2   w-100">
              <div className=" ">
                <label className="form-label">
                  {strings2.project_number[lang]}
                </label>

                <input
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                  {...register("project_number")}
                ></input>
              </div>
            </div>
          </div>
          <Autocomlete
            data={
              cities
                ? cities.map((item) => ({
                    label: lang == "AR" ? item.city : item.city_EN,
                    value: item.id,
                  }))
                : []
            }
            title={strings2.city[lang]}
            control={control}
            name={"city"}
          />

          <div className="d-lg-flex d-block w-100  ">
            <Autocomlete
              data={sponsors.map((item) => ({
                label: lang == "AR" ? item.sponsor : item.sponsor_EN,
                value: item.id,
              }))}
              title={strings2.donor[lang]}
              control={control}
              name={"sponsor"}
              onChangeData={() => setValue("sponsorship", null)}
            />
            <Autocomlete
              isMulti
              closeMenuOnSelect={false}
              data={
                sponsorships
                  ? sponsorships.map((item) => ({
                      label:
                        lang == "AR" ? item.sponsorship : item.sponsorship_EN,
                      value: item.id,
                    }))
                  : []
              }
              title={strings2.sub_donor[lang]}
              control={control}
              name={"sponsorship"}
            />
          </div>
          <div className="d-lg-flex d-block w-100  ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <label className="form-label">{strings2.camp_start[lang]}</label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(v) =>
                  dateFrom.onChange(moment(v).format("YYYY-MM-DD"))
                }
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  ref: dateFrom.ref,
                  onBlur: dateFrom.onBlur,
                  name: dateFrom.name,
                  value: dateFrom.value,
                }}
              />
            </div>
            <div className=" p-2  mb-2 w-100">
              <label className="form-label">{strings2.camp_end[lang]}</label>

              <Datetime
                isValidDate={(current) => {
                  const yesterday = moment(watch("dateFrom")).subtract(
                    1,
                    "day"
                  );
                  return current.isAfter(yesterday);
                }}
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(v) =>
                  dateTo.onChange(moment(v).format("YYYY-MM-DD"))
                }
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  ref: dateTo.ref,
                  onBlur: dateTo.onBlur,
                  name: dateTo.name,
                  value: dateTo.value,
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mx-0 d-flex flex-column-reverse flex-lg-row  ">
          <div className="col-sm-12 col-lg-6    px-4 justify-content-between ">
            <div className="row w-100 ">
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className="form-check-input float-none ms-0"
                  type="checkbox"
                  id="visit"
                  {...register("visitChecked")}
                />
                <label className="form-check-label mx-2" htmlFor="visit">
                  {strings2.preparatory_visit[lang]}
                </label>
              </div>
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className="form-check-input float-none ms-0"
                  type="checkbox"
                  id="financialChecked"
                  {...register("financialChecked")}
                />
                <label
                  className="form-check-label mx-2"
                  htmlFor="financialChecked"
                >
                  {strings2.financial_follow_up[lang]}
                </label>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className="form-check-input float-none ms-0"
                  type="checkbox"
                  id="financialsectorChecked"
                  {...register("financialsectorChecked")}
                />
                <label
                  className="form-check-label mx-2"
                  htmlFor="financialsectorChecked"
                >
                  {strings2.financial_sector_follow_up[lang]}
                </label>
              </div>
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className="form-check-input float-none ms-0"
                  type="checkbox"
                  id="medicationChecked"
                  {...register("medicationChecked")}
                />
                <label
                  className="form-check-label mx-2"
                  htmlFor="medicationChecked"
                >
                  {strings2.medication_follow_up[lang]}
                </label>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className="form-check-input float-none ms-0"
                  type="checkbox"
                  id="sponsorChecked"
                  {...register("sponsorChecked")}
                />
                <label
                  className="form-check-label mx-2"
                  htmlFor="sponsorChecked"
                >
                  {strings2.sponsors_follow_up[lang]}
                </label>
              </div>
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className=" form-check-input float-none ms-0"
                  type="checkbox"
                  id="mediaChecked"
                  {...register("mediaChecked")}
                />
                <label className="form-check-label mx-2" htmlFor="mediaChecked">
                  {strings2.media_follow_up[lang]}
                </label>
              </div>
              <div className="col-12 col-lg-6 form-check ps-2">
                <input
                  className=" form-check-input float-none ms-0"
                  type="checkbox"
                  id="preparingtheteamChecked"
                  {...register("preparingtheteamChecked")}
                />
                <label
                  className="form-check-label mx-2"
                  htmlFor="preparingtheteamChecked"
                >
                  {strings[lang].follow_up.preparing_the_team_follow_up}
                </label>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="mx-2 ">
              <label className="form-label">{strings2.remark[lang]}</label>

              <textarea
                {...register("remark")}
                style={{ resize: "none" }}
                rows={2}
                className="form-control  p-2  "
                placeholder={strings2.write_here[lang]}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row mb-2 mt-3  mx-5  ">
          <div className="col-sm-12 col-lg-6  d-flex  flex-lg-row flex-column justify-content-center">
            <button
              onClick={handleSubmit(handleUpdatePlan)}
              className={`btn btn-success mx-lg-2 my-2 my-lg-0   px-2 col-lg-3 ${
                !isLoadingUpdatePlan && planId && change ? "" : "disabled"
              }`}
            >
              {strings2.edit[lang]}
              {isLoadingUpdatePlan && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            <button
              type="button"
              className={`btn btn-success  mx-lg-2 my-2 my-lg-0   px-2 col-lg-3
      ${!isFetchingPlans && annualplanView ? "" : "disabled"}`}
              onClick={setSearchData}
            >
              {strings2.search[lang]}
              {isFetchingPlans && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            <button
              type="button"
              onClick={deleteFields}
              className={`btn btn-success mx-lg-2 my-2 my-lg-0   px-2  col-lg-3`}
            >
              {strings2.Delete_fields[lang]}
            </button>
          </div>
          <div className="col d-flex flex-lg-row flex-column justify-content-center  mx-2">
            <button
              onClick={handleSubmit(handleCreatePlan)}
              className={`btn btn-primary mx-lg-2 my-2 my-lg-0   px-2 ${
                !isLoadingCreatePlan && add ? "" : "disabled"
              }`}
            >
              {strings2.create_plan[lang]}
              {isLoadingCreatePlan && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            <button
              type="button"
              onClick={() => setShowHistoriesModal(true)}
              className={`btn btn-warning mx-lg-2 my-2 my-lg-0   px-2 ${
                planId && historicalannualplanView ? "" : "disabled"
              }`}
            >
              {strings2.history[lang]}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
