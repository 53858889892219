import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getGroupsPermissions = createAsyncThunk(
  "groupsPermissions/getGroupsPermissions",
  async () => {
    return await apiServices.getGroupsPermissions();
  }
);

export const crateGroupsPermissions = createAsyncThunk(
  "groupsPermissions/crateGroupsPermissions",
  async (data) => {
    return await apiServices.crateGroupsPermissions(data);
  }
);

export const updateGroupsPermissions = createAsyncThunk(
  "groupsPermissions/updateGroupsPermissions",
  async ({ id, data }) => {
    return await apiServices.updateGroupsPermissions(id, data);
  }
);
export const deleteGroupsPermissions = createAsyncThunk(
  "groupsPermissions/deleteGroupsPermissions",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteGroupsPermissions(id);
    if (ok) return id;

    return rejectWithValue();
  }
);

const groupsPermissionsSlice = createSlice({
  name: "groupsPermissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupsPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupsPermissions.fulfilled, (state, action) => {
        state.data = action.payload.data.map((item) => ({
          value: item.id,
          label: `${item.name || ""} ${item.name_EN || ""}`,
          name: item.name || "",
          name_EN: item.name_EN || "",
          permissions: item.permissions,
        }));
      })
      .addCase(getGroupsPermissions.rejected, (state) => {
        state.error = true;
      })
      .addCase(crateGroupsPermissions.fulfilled, (state, action) => {
        const { id, name, name_EN, permissions } = action.payload.data;

        state.data.push({
          value: id,
          label: `${name || ""} ${name_EN || ""}`,
          name: name || "",
          name_EN: name_EN || "",
          permissions: permissions,
        });
      })
      .addCase(updateGroupsPermissions.fulfilled, (state, action) => {
        const { id, name, name_EN, permissions } = action.payload.data;
        const index = state.data.findIndex((p) => p.value === id);
        state.data[index] = {
          value: id,
          label: `${name || ""} ${name_EN || ""}`,
          name: name || "",
          name_EN: name_EN || "",
          permissions: permissions,
        };
      })
      .addCase(deleteGroupsPermissions.fulfilled, (state, action) => {
        state.data = state.data.filter((p) => p.value !== action.payload);
      });
  },
});

export const {} = groupsPermissionsSlice.actions;

export const groupsPermissionsData = (state) => state.groupsPermissions;

export default groupsPermissionsSlice.reducer;
