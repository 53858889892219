export const OPD_FIELDS = [
  {
    action: "New",
    value: "new",
  },
  {
    action: "Old",
    value: "old",
  },
  {
    action: "Working Days",
    value: "Working_Days_OPD",
  },
  {
    action: "Average Day",
    value: "Average_Day_OPD",
  },
];
export const Surgeries_FIELDS = [
  {
    action: "Phaco Foldable IOL",
    value: "Phaco_IOL",
  },
  {
    action: "ECCE + PCIOL",
    value: "ECCE_PCIOL",
  },
  {
    action: "Phaco Foldable IOL + Avastin ",
    value: "Phaco_IOL_Avastin",
  },
  {
    action: "Evisearation ",
    value: "Evisearation",
  },
  {
    action: "Enucleation ",
    value: "Enucleation",
  },
  {
    action: "Phaco + Trabe ",
    value: "Phaco_Trabe",
  },
  {
    action: "AMG ",
    value: "AMG",
  },
  {
    action: "Secondary PC/AC IOL ",
    value: "Secondary_PC_AC_IOL",
  },
  {
    action: "SICS + PC IOL",
    value: "SICS_PC_IOL",
  },
  {
    action: "ECCE / ICCE",
    value: "ECCE_ICCE",
  },
  {
    action: "Trabeculectomy/ MMC ",
    value: "Trabeculectomy_MMC",
  },
  {
    action: "D.C.R./ DCR Laser ",
    value: "DCR_Laser",
  },
  {
    action: "Squint Surgery ",
    value: "Squint_Surgery",
  },
  {
    action: "Entropion",
    value: "Entropion",
  },
  {
    action: "E.U.A.",
    value: "E_U_A",
  },
  {
    action: "Exentration",
    value: "Exentration",
  },
  {
    action: "Artificial Eye",
    value: "Artificial_Eye",
  },
  {
    action: "Lensectomy+ Foldable IOL (In Children)",
    value: "Lensectomy_Foldable_IOL",
  },
  {
    action: "(Blepheroplasty + Orbital reconstruction + skin graft)",
    value: "Blepheroplasty_Orbital_reconstruction_skin_graft",
  },
  {
    action: "Surgical  Capsuloctomy",
    value: "Surgical_Capsuloctomy",
  },
  {
    action: "Ptosis Correction",
    value: "Ptosis_Correction",
  },
  {
    action: "Cyclocryopoxy",
    value: "Cyclocryopoxy",
  },
  {
    action: "Syringing and Probing",
    value: "Syringing_Probing",
  },
  {
    action: "Corneal Repair/Scleral Rep",
    value: "Corneal_Repair_Scleral_Rep",
  },
  {
    action: "Corneal Repair + PCIOL",
    value: "Corneal_Repair_PCIOL",
  },
  {
    action: "Corneal Cross Linking (CXL)",
    value: "Corneal_Cross_Linking",
  },
  {
    action: "PKP + PCIOL/Scleralfix",
    value: "PKP_PCIOL_Scleralfix",
  },
  {
    action: "PKP",
    value: "PKP",
  },
  {
    action: "Dermiod Cyst  Excision",
    value: "Dermiod_Cyst_Excision",
  },
  {
    action: "Tarsoraphy",
    value: "Tarsoraphy",
  },
  {
    action: "Removed of Silicon OIL  + IOL",
    value: "Removed_of_Silicon_OIL_IOL",
  },
  {
    action: "Removed of Silicon OIL",
    value: "Removed_of_Silicon_OIL",
  },
  {
    action: "Removal of IOL/ Exchange IOL",
    value: "Removal_of_IOL_Exchange_IOL",
  },
  {
    action: "PHACO + P P V",
    value: "PHACO_PPV",
  },
  {
    action: "DALK / DSAEK",
    value: "DALK_DSAEK",
  },
  {
    action: "PPV",
    value: "PPV",
  },
  {
    action: "Silicon Oil Inject",
    value: "Silicon_Oil_Inject",
  },
  {
    action: "PPV + PPL+PC/AC IOL ",
    value: "PPV_PPL_PC_AC_IOL",
  },
  {
    action: "Orbital Implant ",
    value: "Orbital_Implant",
  },
  {
    action: "Anterior VIT+ A/C wash ",
    value: "Anterior_VIT_A_C_wash",
  },
  {
    action: "Resuturing / Globe Rupture ",
    value: "Resuturing_Globe_Rupture",
  },
  {
    action: "Reposting of IOL + Ant.Vit ",
    value: "Reposting_of_IOL_Ant_Vit",
  },
  {
    action: "Tumor Excision ",
    value: "Tumor_Excision",
  },
  {
    action: "Biopcy ",
    value: "Biopcy",
  },
  {
    action: "Ahmed Valve Implant ",
    value: "Ahmed_Valve_Implant",
  },
  {
    action: "Keratoprothesis",
    value: "Keratoprothesis",
  },
  {
    action: "Scleral Graft",
    value: "Scleral_Graft",
  },
  {
    action: "Lid repair",
    value: "Lid_repair",
  },
  {
    action: "PI (Iridectomy)",
    value: "PI_Iridectomy",
  },
  {
    action: "Preposition of Iris Prolapse / Pupilo  Plasty",
    value: "Preposition_of_Iris_Prolapse_Pupilo_Plasty",
  },
  {
    action: "AC/Wash",
    value: "AC_Wash",
  },
  {
    action: "Minor",
    value: "Minor",
  },
  {
    action: "Total Days of Operations",
    value: "Total_Days_of_Operations_Surgeries",
  },
  {
    action: "Average Per Day",
    value: "Average_Per_Day_Surgeries",
  },
  {
    action: "Percentage of Surg. from OPD %",
    value: "Percentage_of_Surg_from_OPD_Surgeries",
  },
];
export const Lasers_FIELDS = [
  {
    action: "Yag Laser Capsulotomy",
    value: "Yag_Laser_Capsulotomy",
  },
  {
    action: "Yag Laser + P.I",
    value: "Yag_Laser_P_I",
  },
  {
    action: "Argon Laser (PRP)",
    value: "Argon_Laser_PRP",
  },
  {
    action: "F.Photo",
    value: "F_Photo",
  },
  {
    action: "FFA",
    value: "FFA",
  },
  {
    action: "Perimetery",
    value: "Perimetery",
  },
  {
    action: "OCT",
    value: "OCT",
  },
  {
    action: "B-Scan",
    value: "B_Scan",
  },
  {
    action: "Others",
    value: "Others",
  },
];
export const Injection_FIELDS = [
  {
    action: "Avastin Injection",
    value: "Avastin_Injection",
  },
  {
    action: "IVTA (subtenon)",
    value: "IVTA_subtenon",
  },
  {
    action: "Kinacort Injection",
    value: "Kinacort_Injection",
  },
  {
    action: "Vancomycine Injectin",
    value: "Vancomycine_Injectin",
  },
  {
    action: "Inj Avastin + IVTA",
    value: "Inj_Avastin_IVTA",
  },
];
