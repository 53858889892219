import React, { useEffect, useState } from "react";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useStatusData from "../hooks/useStatusData";
import { useDispatch, useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import {
  createStatusService,
  deleteStatusService,
  updateStatusService,
} from "../store/reducers/status";
export default function Status() {
  const [status, setStatus] = useState("");
  const [status_EN, setStatus_EN] = useState("");
  const [color, setColor] = useState("");
  const [statusId, setStatusId] = useState();
  const [statusIdDelete, setstatusIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const { data: statusData } = useStatusData();
  const lang = useSelector(userLang);
  const dispatch = useDispatch();

  const add = useHaveUserPermission("planstatus", "add");
  const change = useHaveUserPermission("planstatus", "change");
  const deletePerm = useHaveUserPermission("planstatus", "delete");
  const createStatus = async () => {
    setDisableBtnCreate(true);
    dispatch(
      createStatusService({
        status: status,
        status_EN: status_EN,
        color,
      })
    );

    toast.success("تم إضافة الحالة بنجاح");
    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setStatus(item.status);
    setStatus_EN(item.status_EN);
    setStatusId(item.id);
    setColor(item.color);
  };
  const deleteStatus = async (id) => {
    const resultAction = await dispatch(deleteStatusService(id));
    if (deleteStatusService.rejected.match(resultAction)) {
      toast.error(strings2.error_delete[lang]);
    }
    if (deleteStatusService.fulfilled.match(resultAction)) {
      toast.success("تم حذف الحالة بنجاح");
    }
    setShowModal(false);
  };
  const updateStatus = async () => {
    setDisableBtnUpdate(true);
    dispatch(
      updateStatusService({
        id: statusId,
        data: {
          status: status,
          active,
          status_EN: status_EN,
          color,
        },
      })
    );
    toast.success("تم تعديل الحالة بنجاح");

    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "الحالات";
  }, []);
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.Status}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50">
          <input
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="الحالة"
          />
          <input
            value={status_EN}
            onChange={(e) => setStatus_EN(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="status"
          />
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="form-control p-2 my-3"
            placeholder="color"
          />
        </div>
        <div className="d-flex">
          <button
            onClick={updateStatus}
            className={`btn btn-success  ${
              disableBtnUpdate || !statusId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createStatus}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !status || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {statusData.length > 0 && (
          <div className="table-responsive" style={{ margin: "0 20%" }}>
            <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">status</th>
                  <th scope="col">{strings2.color[lang]}</th>
                  {/* <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col">{strings[lang].data.active}</th> */}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {statusData.map((item, index) => (
                  <tr
                    className={statusId == item.id ? "row-selected" : ""}
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    <th scope="row" onClick={() => onClick(item)}>
                      {statusData.length - index}
                    </th>
                    <td onClick={() => onClick(item)}>{item.status}</td>
                    <td onClick={() => onClick(item)}>{item.status_EN}</td>
                    <td onClick={() => onClick(item)}>
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <div
                          style={{
                            height: 15,
                            width: 15,
                            borderRadius: "50%",
                            backgroundColor: item.color,
                          }}
                        ></div>
                      </div>
                    </td>
                    {/* <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>
                    <td onClick={() => onClick(item)}>
                      <i
                        className={`fa ${
                          item.active
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td> */}
                    <td>
                      <p
                        className={`mb-0 btn 
                        ${
                          statusId == item.id
                            ? "btn-danger"
                            : "btn-outline-danger"
                        }
                        ${!deletePerm ? "disabled" : ""}`}
                        onClick={() => {
                          setstatusIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={statusIdDelete}
        deleteMethod={deleteStatus}
      />
    </div>
  );
}
