import React from "react";

import strings from "../../common/strings.json";
import strings2 from "../../common/strings2.json";
import { useSelector } from "react-redux";
import { userLang } from "../../../store/reducers/user";

export default function ColorTabel() {
  const lang = useSelector(userLang);

  return (
    <div className="">
      <table
        className="table table-striped  table-bordered text-center align-middle "
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <tbody>
          <tr>
            <td>{strings2.tabel_excellent[lang]}</td>

            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-primary`}
              ></div>
            </td>
            <td>{strings2.excellent[lang]}</td>
          </tr>
          <tr>
            <td>{strings2.tabel_good[lang]}</td>

            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-success`}
              ></div>
            </td>
            <td>{strings2.good[lang]}</td>
          </tr>
          <tr>
            <td>{strings2.tabel_little_late[lang]}</td>

            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-warning`}
              ></div>
            </td>
            <td>{strings2.little_late[lang]}</td>
          </tr>

          <tr>
            <td>{strings2.tabel_late[lang]}</td>

            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-danger-subtle`}
              ></div>
            </td>
            <td>{strings2.late[lang]}</td>
          </tr>
          <tr>
            <td>{strings2.tabel_too_late[lang]}</td>

            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-danger`}
              ></div>
            </td>
            <td>{strings2.too_late[lang]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
