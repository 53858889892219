import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../components/api/api";
import DeleteModal from "../../components/common/DeleteModal";
import FollowUpTabel from "../../components/stats/FollowUpTabel";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import SearchModal from "../../components/common/SearchModal";
import NormalSearch from "../../components/common/NormalSearch";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function FirstFollowUp() {
  const [archives, setArchives] = useState(false);
  const [planID, setPlanID] = useState();
  const [plan, setPlan] = useState();
  const [followUpID, setFollowUpID] = useState("");
  const [statsID, setStatsID] = useState("");
  const [campManager, setCampManager] = useState("");
  const [sponsorStat, setSponsorStat] = useState("");
  const [sponsorLocal, setSponsorLocal] = useState("");
  const [surgeonManager, setSurgeonManager] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [campLocation, setCampLocation] = useState("");
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [startDate, setStartDate] = useState("");
  const [sponsor, setSponsor] = useState();
  const [normalSearch, setNormalSearch] = useState(false);
  const [remark, setRemark] = useState("");
  const [participatingTeam, setParticipatingTeam] = useState("");

  const [inputsValue, setInputsValue] = useState({
    va_6_18_or_better: { male: "", female: "" },
    va_6_18: { male: "", female: "" },
    va_6_60: { male: "", female: "" },
    followUpRemark: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const lang = useSelector(userLang);

  const view = useHaveUserPermission("firstfollowup", "view");
  const add = useHaveUserPermission("firstfollowup", "add");
  const change = useHaveUserPermission("firstfollowup", "change");
  const deletePerm = useHaveUserPermission("firstfollowup", "delete");
  const updateFollowUp = async () => {
    setDisabledSave(true);
    const { va_6_18, va_6_18_or_better, va_6_60, followUpRemark } = inputsValue;

    const data = {
      va_6_18_or_better_male: va_6_18_or_better.male || 0,
      va_6_18_or_better_female: va_6_18_or_better.female || 0,
      va_6_60_male: va_6_60.male || 0,
      va_6_60_female: va_6_60.female || 0,
      va_6_18_male: va_6_18.male || 0,
      va_6_18_female: va_6_18.female || 0,
      stats: planID,
      followUpRemark,
      remark,
      participatingTeam,
    };
    const result = await api.updateFirstFollowUp(planID, data);
    if (result.ok) {
      toast.success("تم تعديل المتابعة بنجاح");
      setFollowUpID(planID);
    }
    setDisabledSave(false);
  };
  const createFollowUp = async () => {
    setDisabledSave(true);
    const { va_6_18, va_6_18_or_better, va_6_60, followUpRemark } = inputsValue;

    const data = {
      va_6_18_or_better_male: va_6_18_or_better.male || 0,
      va_6_18_or_better_female: va_6_18_or_better.female || 0,
      va_6_60_male: va_6_60.male || 0,
      va_6_60_female: va_6_60.female || 0,
      va_6_18_male: va_6_18.male || 0,
      va_6_18_female: va_6_18.female || 0,
      stats: planID,
      followUpRemark,
      remark,
      participatingTeam,
    };
    const result = await api.createFirstFollowUp(data);
    if (result.ok) {
      toast.success("تم إنشاء المتابعة بنجاح");
      setFollowUpID(planID);
    }
    setDisabledSave(false);
  };

  const getStats = async () => {
    if (view) {
      const result = await api.getStats(planID);
      if (result.ok) {
        const {
          camp_manager,
          sponsor,
          sponsor_local,
          surgeon_manager,
          camp_location,
        } = result.data;
        setStatsID(planID);
        setCampManager(camp_manager);
        setSponsorStat(sponsor);
        setSponsorLocal(sponsor_local);
        setSurgeonManager(surgeon_manager);
        setCampLocation(camp_location);

        const { ok, data } = await api.getFirstFollowUp(planID);
        if (ok) {
          setFollowUpID(planID);
          setInputsValue({
            va_6_18_or_better: {
              male: data.va_6_18_or_better_male,
              female: data.va_6_18_or_better_female,
            },
            va_6_18: { male: data.va_6_18_male, female: data.va_6_18_female },
            va_6_60: { male: data.va_6_60_male, female: data.va_6_60_female },
            followUpRemark: data.followUpRemark,
          });
          setRemark(data.remark);
          setParticipatingTeam(data.participatingTeam);
        }
      } else {
        setFollowUpID("");

        setCampManager("");
        setSponsorStat("");
        setSponsorLocal("");
        setSurgeonManager("");
        setCampLocation("");
        setRemark("");
        setParticipatingTeam("");
        setInputsValue({
          va_6_18_or_better: { male: "", female: "" },
          va_6_18: { male: "", female: "" },
          va_6_60: { male: "", female: "" },
          followUpRemark: "",
        });
      }
    }
  };
  const deleteFollowUp = async () => {
    const result = await api.deleteFirstFollowUp(planID);
    if (result.ok) {
      toast.success("تم حذف المتابعة بنجاح");
      setFollowUpID("");
      setInputsValue({
        va_6_18_or_better: { male: "", female: "" },
        va_6_18: { male: "", female: "" },
        va_6_60: { male: "", female: "" },
        followUpRemark: "",
      });
      setFollowUpID("");

      setCampManager("");
      setSponsorStat("");
      setSponsorLocal("");
      setSurgeonManager("");
      setRemark("");
      setParticipatingTeam("");
      setCampLocation("");
    } else {
      toast.error(strings2.error_delete[lang]);
    }
    setShowModal(false);
  };
  useEffect(() => {
    document.title = "المتابعة الأولى";
    if (planID) {
      getStats();
    } else {
      setFollowUpID("");

      setCampManager("");
      setSponsorStat("");
      setSponsorLocal("");
      setSurgeonManager("");
      setCampLocation("");
      setRemark("");
      setParticipatingTeam("");
      setInputsValue({
        va_6_18_or_better: { male: "", female: "" },
        va_6_18: { male: "", female: "" },
        va_6_60: { male: "", female: "" },
        followUpRemark: "",
      });
    }
  }, [planID]);
  return (
    <>
      <div
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container   mt-3 p-0 bg-light shadow pb-1  "
      >
        <div className="bg-primary d-flex p-5 ">
          <p className="fs-3 fw-bold  m-auto text-white text-center">
            {strings[lang].stats.first_follow_up}
          </p>
        </div>
        <NormalSearch
          country={country}
          setCountry={setCountry}
          city={city}
          setCity={setCity}
          sponsor={sponsor}
          setSponsor={setSponsor}
          startDate={startDate}
          setStartDate={setStartDate}
          setNormalSearch={setNormalSearch}
          setShow={setShowSearch}
        />
        {plan && (
          <div className="container px-5 mx-0">
            <div className="row  m-0 mt-3">
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control  input-bg"
                  placeholder={strings2.country[lang]}
                  value={
                    lang == "AR"
                      ? plan?.country?.country
                      : plan?.country?.country_EN
                  }
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.city[lang]}
                  value={lang == "AR" ? plan?.city?.city : plan?.city?.city_EN}
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.donor[lang]}
                  value={
                    lang == "AR"
                      ? plan.sponsor.sponsor
                      : plan.sponsor.sponsor_EN
                  }
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.camp_start[lang]}
                  value={plan.camp_start_date}
                />
              </div>
            </div>
          </div>
        )}
        <div className="counatiner-fluid    ">
          <div className="row mx-0  mt-3  px-5 ">
            <div className="col-sm-12 col-lg-6  ">
              <div className="mx-2 ">
                <label className="form-label">
                  {strings[lang].stats.camp_manager}
                </label>

                <input
                  readOnly
                  value={campManager}
                  // onChange={(e) => setSponsor(e.target.value)}
                  className="form-control  p-2  "
                  // placeholder="مدير المخيم "
                />
              </div>
              <div className="mx-2 mt-2">
                <label className="form-label">
                  {strings[lang].stats.donor}
                </label>

                <input
                  readOnly
                  value={lang == "AR" ? sponsorStat.AR : sponsorStat.EN}
                  // onChange={(e) => setSponsor(e.target.value)}
                  className="form-control  p-2  "
                  // placeholder="الجهة الداعمة "
                />
              </div>
              <div className="mx-2 mt-2">
                <label className="form-label">
                  {strings[lang].common.remark}
                </label>

                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                ></textarea>
              </div>
              <div className="mx-2 mt-2">
                <label className="form-label">
                  {strings2.participatingTeam[lang]}
                </label>

                <textarea
                  value={participatingTeam}
                  onChange={(e) => setParticipatingTeam(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                ></textarea>
              </div>
            </div>
            <div className="col mt-0 mt-lg-2">
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.chief_surgeon}
                </label>

                <input
                  readOnly
                  value={surgeonManager}
                  // onChange={(e) => setSurgeonManager(e.target.value)}
                  className="form-control  p-2  "
                  // placeholder="رئيس الجراحين"
                />
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.camp_site}
                </label>

                <input
                  readOnly
                  value={campLocation}
                  // onChange={(e) => setCampLocation(e.target.value)}
                  className="form-control  p-2  "
                  // placeholder="مكان المخيم"
                />
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.local_collaborator}
                </label>

                <input
                  readOnly
                  value={sponsorLocal}
                  // onChange={(e) => setSponsorLocal(e.target.value)}
                  className="form-control  p-2  "
                  // placeholder="الجهة المتعاونة محليا"
                />
              </div>
            </div>
          </div>

          <div className="row mb-2 mt-3  mx-5  align-items-center">
            <div className="col-sm-12 col-lg-6  d-flex  justify-content-center align-items-center">
              <button
                onClick={followUpID ? updateFollowUp : createFollowUp}
                className={`btn btn-success m-lg-0 m-3   w-25 ${
                  !statsID || disabledSave
                    ? "disabled"
                    : change || add
                    ? followUpID && !change
                      ? "disabled"
                      : ""
                    : "disabled"
                }`}
              >
                {followUpID ? strings2.change[lang] : strings2.create[lang]}
                {disabledSave && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>

            <div className="col-sm-12 col-lg-6 d-flex justify-content-center align-items-center ">
              <button
                onClick={() => setShowModal(true)}
                className={`btn btn-danger m-lg-0 m-3  w-25  ${
                  !followUpID || !deletePerm ? "disabled" : ""
                }`}
              >
                {strings[lang].stats.delete}
              </button>
            </div>
          </div>
        </div>
      </div>
      <FollowUpTabel
        inputsValue={inputsValue}
        setInputsValue={setInputsValue}
        fields={[
          {
            action_AR: "__",
            action_EN: "VA 6/18 or better",
            valueAdults: "va_6_18_or_better",
          },
          {
            action_AR: "__",
            action_EN: "VA < 6/18-6/60",
            valueAdults: "va_6_18",
          },
          {
            action_AR: "__",
            action_EN: "VA <6/60",
            valueAdults: "va_6_60",
          },
        ]}
      />

      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        id={followUpID}
        deleteMethod={deleteFollowUp}
      />
      <SearchModal
        show={showSearch}
        setShow={setShowSearch}
        setPlanID={setPlanID}
        setPlan={setPlan}
        plan={plan}
        searchFields={{
          stats_filter: true,
          status: 2,
          first_followup: normalSearch ? false : archives,
        }}
        archives={archives}
        setArchives={setArchives}
        normalSearch={normalSearch}
        dataNormalSearch={{
          country: country?.value || "",
          city: city?.value || "",
          sponsor: sponsor?.value || "",
          startDate: startDate || "",
        }}
      />
    </>
  );
}
