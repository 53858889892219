import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import strings2 from "../common/strings2.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function UpdateModal({ show, setShow, updateMethod }) {
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const lang = useSelector(userLang);

  const Update = async () => {
    setDisabled(true);
    await updateMethod(reason);
    setDisabled(false);
    setShow(false);
  };
  return (
    <Modal
      dir={lang == "EN" ? "ltr" : "rtl"}
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{strings2.confirmation_Update[lang]}</Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100 gap-3">
          <input
            className="form-control  p-2  w-100"
            placeholder={strings2.write_here[lang]}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></input>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {strings2.close[lang]}
          </Button>
          <Button disabled={disabled} onClick={Update} variant="success">
            {strings2.edit[lang]}
            {disabled && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
