import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import {
  getHospitalsCountries,
  hospitalsCountriesData,
} from "../store/reducers/hospitalsCountries";

const useHospitalsCountriesData = () => {
  const { data, loading, error } = useSelector(hospitalsCountriesData);

  const view = useHaveUserPermission("hospitalscountries", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getHospitalsCountries());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useHospitalsCountriesData;
