import React, { useState, useEffect, useMemo } from "react";
import api from "../components/api/api";
import { toast } from "react-toastify";
import DeleteModal from "../components/common/DeleteModal";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import { useDispatch, useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import usePermissionsData from "../hooks/usePermissionsData";
import useGroupsPermissionsData from "../hooks/useGroupsPermissionsData";
import {
  crateGroupsPermissions,
  deleteGroupsPermissions,
  updateGroupsPermissions,
} from "../store/reducers/groupsPermissions";

const followUps = [
  "financialfollowup",
  "financialfollowupfile",
  "historicalfinancialfollowup",
  "financialsectorfollowup",
  "financialsectorfollowupfile",
  "historicalfinancialsectorfollowup",
  "mediafollowup",
  "mediafollowupfile",
  "historicalmediafollowup",
  "medicationfollowup",
  "medicationfollowupfile",
  "historicalmedicationfollowup",
  "preparatoryvisitfollowup",
  "preparatoryvisitfollowupfile",
  "historicalpreparatoryvisitfollowup",
  "preparingtheteamfollowup",
  "preparingtheteamfollowupfile",
  "historicalpreparingtheteamfollowup",
  "sponsorsfollowup",
  "sponsorsfollowupfile",
  "historicalsponsorsfollowup",
];
const campaigns = [
  "annualplan",
  "historicalannualplan",
  "planstatus",
  "cities",
  "contactperson",
  "countries",
  "team",
  "sponsor",
  "sponsorship",
  "responsible",
];
const users = ["group", "permission", "users"];
const campaignsStats = ["stats", "firstfollowup", "secondfollowup"];
const reports = [
  "campshomereports",
  "hospitalshomereports",
  "hospitalsmonthlyreport",
  "hospitalsfollowupreceiptreport",
  "hospitalsanalyticalreport",
  "hospitalscollectivereport",
  "collectivereports",
  "followupreports",
  "teamchronologicalreport",
];
const hospitals = ["hospital", "hospitalscities", "hospitalscountries"];
const hospitalsStats = [
  "hospitalstats",
  "hospitalsstatsfile",
  "historicalhospitalstats",
];

export default function GroupsPermissions() {
  const [permissions, setPermissions] = useState([]);
  const [permissionsTapFilters, setPermissionsTapFilters] = useState(campaigns);
  const [groupName, setGroupName] = useState("");
  const [groupName_EN, setGroupName_EN] = useState("");
  const [groupID, setGroupID] = useState("");
  const [activeTap, setActiveTap] = useState(0);
  const [disableCreate, setDisableCreate] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [groupIdDelete, setGroupIdDelete] = useState();
  const [showModal, setShowModal] = useState(false);

  const { data: permissionsData } = usePermissionsData();
  const { data: groupsPermissions } = useGroupsPermissionsData();
  const lang = useSelector(userLang);
  const dispatch = useDispatch();

  const view = useHaveUserPermission("group", "view");
  const add = useHaveUserPermission("group", "add");
  const change = useHaveUserPermission("group", "change");
  const deletePerm = useHaveUserPermission("group", "delete");
  const onClickRow = (item) => {
    setGroupName(item.name);
    setGroupName_EN(item.name_EN);
    setGroupID(item.value);

    setPermissions(item.permissions);
  };
  const createGroup = async () => {
    setDisableCreate(true);
    const data = {
      name: groupName,
      name_EN: groupName_EN,
      permissions: permissions,
    };
    dispatch(crateGroupsPermissions(data));
    toast.success("تم إنشاء المجموعة بنجاح");

    setDisableCreate(false);
  };
  const updateGroup = async () => {
    setDisableUpdate(true);
    const data = {
      name: groupName,
      name_EN: groupName_EN,

      permissions: permissions,
    };
    dispatch(updateGroupsPermissions({ id: groupID, data }));

    toast.success("تم تعديل المجموعة بنجاح");

    setDisableUpdate(false);
  };
  const deleteGroup = async (id) => {
    dispatch(deleteGroupsPermissions(id));
    setShowModal(false);
  };
  useEffect(() => {
    document.title = "مجموعة الصلاحيات";
  }, []);
  const groupedPermition = () => {
    const data = {};

    permissionsData.forEach((element) => {
      data[element?.name_EN?.replaceAll(" ", "_")] = {
        name_EN: element.name_EN,
        name: element.name,
      };
    });
    const newData = {};
    Object.entries(data).forEach(([key, value]) => {
      newData[key] = {
        name: { name: value.name, name_EN: value.name_EN },

        add: permissionsData.filter(
          (e) => e.name_EN == value.name_EN && e.type_EN == "add"
        )[0],
        view: permissionsData.filter(
          (e) => e.name_EN == value.name_EN && e.type_EN == "view"
        )[0],
        delete: permissionsData.filter(
          (e) => e.name_EN == value.name_EN && e.type_EN == "delete"
        )[0],
        change: permissionsData.filter(
          (e) => e.name_EN == value.name_EN && e.type_EN == "change"
        )[0],
      };
    });
    return newData;
  };
  const tabs = useMemo(() => {
    return [
      {
        label: strings2.campaigns[lang],
        perm: campaigns,
      },
      {
        label: strings2.campaignsStats[lang],
        perm: campaignsStats,
      },
      {
        label: strings2.followUps[lang],
        perm: followUps,
      },
      {
        label: strings2.hospitals[lang],
        perm: hospitals,
      },
      {
        label: strings2.hospitalsStats[lang],
        perm: hospitalsStats,
      },
      {
        label: strings2.reports[lang],
        perm: reports,
      },
      {
        label: strings2.users[lang],
        perm: users,
      },
    ];
  }, []);
  return (
    <div
      style={{ position: "relative" }}
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-1  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.groups}
        </p>
      </div>
      <div className="counatiner-fluid   mt-3 ">
        <form className="d-flex justify-content-center align-items-center  flex-column  ">
          <div className="col-sm-12 col-lg-6  ">
            <div className="mx-2 mb-2 ">
              <input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings["AR"].data.group_name}
              />
            </div>
            <div className="mx-2 mb-2 ">
              <input
                value={groupName_EN}
                onChange={(e) => setGroupName_EN(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings["EN"].data.group_name}
              />
            </div>
            {/* <AutoComleteField
              value={permissions}
              setValue={setPermissions}
              isMulti
              data={permissionsList}
              placeholder={strings[lang].data.permissions}
              closeMenuOnSelect={false}
            /> */}
          </div>
        </form>
      </div>
      <div className="d-flex align-items-center justify-content-center my-2">
        <button
          onClick={createGroup}
          className={`btn btn-success ${
            !add || !groupName || !permissions || disableCreate
              ? "disabled"
              : ""
          }`}
        >
          {strings[lang].data.create}
          {disableCreate && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <button
          onClick={updateGroup}
          className={`btn btn-success mx-2 ${
            !groupID || !change || disableUpdate ? "disabled" : ""
          }`}
        >
          {strings[lang].data.save}
          {disableUpdate && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
      </div>
      <ul
        className=" nav nav-tabs   p-0 px-lg-5  mt-2 counatiner-fluid"
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        {tabs.map((item, index) => (
          <li key={index} className="nav-item">
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPermissionsTapFilters(item.perm);
                setActiveTap(index);
              }}
              className={`nav-link ${activeTap === index ? "active" : ""}`}
            >
              {item.label}
            </p>
          </li>
        ))}
      </ul>
      <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
        <thead
          className="header-tabel"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000000,
          }}
        >
          <tr>
            <td>{strings2.permision[lang]}</td>
            <td>{strings2.add[lang]}</td>
            <td>{strings2.view[lang]}</td>
            <td>{strings2.change[lang]}</td>
            <td>{strings2.delete[lang]}</td>
          </tr>
        </thead>
        <tbody>
          {!!permissionsData.length &&
            permissionsTapFilters.map((key, index) => {
              const value = groupedPermition()[key];

              const perms = permissionsData.filter(
                (p) => p.codename.split("_")[1] === key
              );
              const add = perms.find((p) => p.codename.split("_")[0] === "add");
              const deletePerm = perms.find(
                (p) => p.codename.split("_")[0] === "delete"
              );
              const view = perms.find(
                (p) => p.codename.split("_")[0] === "view"
              );
              const change = perms.find(
                (p) => p.codename.split("_")[0] === "change"
              );
              return (
                <tr key={index}>
                  <td>{lang == "AR" ? perms[0]?.name : perms[0]?.name_EN}</td>
                  <td style={{ position: "relative" }}>
                    {add && (
                      <>
                        <label
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                          htmlFor={add.id}
                        ></label>
                        <input
                          id={add.id}
                          checked={permissions.includes(add.id) ? true : false}
                          style={{ zIndex: "0 !important" }}
                          type="checkbox"
                          name="add"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPermissions((prev) => [...prev, add.id]);
                            } else {
                              setPermissions((prev) =>
                                prev.filter((e) => e != add.id)
                              );
                            }
                          }}
                        />
                      </>
                    )}
                  </td>
                  <td style={{ position: "relative" }}>
                    {view && (
                      <>
                        <label
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                          htmlFor={view.id}
                        ></label>
                        <input
                          id={view.id}
                          checked={permissions.includes(view.id) ? true : false}
                          type="checkbox"
                          name="view"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPermissions((prev) => [...prev, view.id]);
                            } else {
                              setPermissions((prev) =>
                                prev.filter((e) => e != view.id)
                              );
                            }
                          }}
                        />
                      </>
                    )}
                  </td>
                  <td style={{ position: "relative" }}>
                    {change && (
                      <>
                        <label
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                          htmlFor={change.id}
                        ></label>
                        <input
                          id={change.id}
                          checked={
                            permissions.includes(change.id) ? true : false
                          }
                          type="checkbox"
                          name="change"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPermissions((prev) => [...prev, change.id]);
                            } else {
                              setPermissions((prev) =>
                                prev.filter((e) => e != change.id)
                              );
                            }
                          }}
                        />
                      </>
                    )}
                  </td>
                  <td style={{ position: "relative" }}>
                    {deletePerm && (
                      <>
                        <label
                          style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                          htmlFor={deletePerm.id}
                        ></label>
                        <input
                          id={deletePerm.id}
                          checked={
                            permissions.includes(deletePerm.id) ? true : false
                          }
                          type="checkbox"
                          name="delete"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPermissions((prev) => [
                                ...prev,
                                deletePerm.id,
                              ]);
                            } else {
                              setPermissions((prev) =>
                                prev.filter((e) => e != deletePerm.id)
                              );
                            }
                          }}
                        />
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div style={{ margin: "0 20%" }}>
        <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{strings["AR"].data.group_name}</th>
              <th scope="col">{strings["EN"].data.group_name}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {groupsPermissions.map((item, index) => (
              <tr
                className={groupID == item.value ? "row-selected" : ""}
                key={index}
                style={{ cursor: "pointer" }}
              >
                <th onClick={() => onClickRow(item)} scope="row">
                  {index + 1}
                </th>
                <td onClick={() => onClickRow(item)}>{item.name}</td>
                <td onClick={() => onClickRow(item)}>{item.name_EN}</td>
                <td>
                  <p
                    className={`mb-0 btn 
                    ${
                      groupID == item.value
                        ? "btn-danger"
                        : "btn-outline-danger"
                    }
                    ${!deletePerm ? "disabled" : ""}`}
                    onClick={() => {
                      setGroupIdDelete(item.value);
                      setShowModal(true);
                    }}
                  >
                    {strings[lang].data.delete}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={groupIdDelete}
        deleteMethod={deleteGroup}
      />
    </div>
  );
}
