import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getResponsibles = createAsyncThunk(
  "responsibles/getResponsibles",
  async () => {
    return await apiServices.getResponsible();
  }
);
export const createResponsibleService = createAsyncThunk(
  "responsibles/createResponsible",
  async (data) => {
    return await apiServices.createResponsible(data);
  }
);
export const deleteResponsibleService = createAsyncThunk(
  "responsibles/deleteResponsibleService",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteResponsible(id);
    if (ok) return id;
    return rejectWithValue();
  }
);
export const updateResponsibleService = createAsyncThunk(
  "responsibles/updateResponsibleService",
  async ({ id, data }) => {
    return await apiServices.updateResponsible(id, data);
  }
);
const responsiblesSlice = createSlice({
  name: "responsibles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResponsibles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResponsibles.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getResponsibles.rejected, (state) => {
        state.error = true;
      })
      .addCase(createResponsibleService.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
      })
      .addCase(deleteResponsibleService.fulfilled, (state, action) => {
        state.data = state.data.filter((r) => r.id !== action.payload);
      })
      .addCase(updateResponsibleService.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (r) => r.id === action.payload.data.id
        );
        state.data[index] = action.payload.data;
      });
  },
});

export const {} = responsiblesSlice.actions;

export const responsiblesData = (state) => state.responsibles;

export default responsiblesSlice.reducer;
