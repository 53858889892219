import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import hospitalImg from "../asset/noun-hospital-262229.png";
import campsImg from "../asset/noun-camp-2207543.png";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
export default function Root() {
  const annualplanView = useHaveUserPermission("annualplan", "view");
  const viewHospital = useHaveUserPermission("hospital", "view");
  const navigation = useNavigate();

  useEffect(() => {
    if (!annualplanView || !viewHospital) {
      navigation(annualplanView ? "/camps" : "/hospitals", {
        replace: true,
      });
    }
  }, []);

  return (
    <>
      <div className="h-100 d-flex justify-content-center align-items-center flex-column flex-lg-row ">
        {annualplanView && viewHospital && (
          <>
            <div className="card-container d-flex justify-content-center align-items-center flex-column m-5">
              <img
                src={campsImg}
                alt=""
                className=""
                width={150}
                height={150}
              />
              <Link className=" btn btn-dark mt-3" to={"/camps"}>
                الحملات الطبية
              </Link>
            </div>
            <div className="card-container d-flex justify-content-center align-items-center flex-column m-5">
              <img
                src={hospitalImg}
                alt=""
                className=""
                width={150}
                height={150}
              />
              <Link className=" btn btn-dark mt-3" to={"/hospitals"}>
                المستشفيات
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}
