import React from "react";
import "./App.css";
import NavBar from "./components/navbar/NavBar";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Plan from "./pages/Plan";
import FollowUpPage from "./pages/followUps/FollowUpPage";
import FinancialFollowUps from "./pages/followUps/FinancialFollowUps";
import MediaFollowUps from "./pages/followUps/MediaFollowUps";
import MedicationFollowUps from "./pages/followUps/MedicationFollowUps";
import SponsorsFollowUps from "./pages/followUps/SponsorsFollowUps";
import Stats from "./pages/campStats/Stats";
import Login from "./pages/Login";
import Countries from "./pages/Countries";
import Cities from "./pages/Cities";
import Teams from "./pages/Teams";
import Sponsors from "./pages/Sponsors";
import Sponsorships from "./pages/Sponsorships";
import Reports from "./pages/reports/Reports";
import UsersManagement from "./pages/UsersManagement";
import { useLocation } from "react-router-dom";
import GroupsPermissions from "./pages/GroupsPermissions";
import ResetPassword from "./pages/ResetPassword";
import PreparatoryVisitFollowUp from "./pages/followUps/PreparatoryVisitFollowUp";
import FinancialSectorFollowUps from "./pages/followUps/FinancialSectorFollowUps";
import FirstFollowUp from "./pages/campStats/FirstFollowUp";
import SecondFollowUp from "./pages/campStats/SecondFollowUp";
import CampsCollectiveReport from "./pages/reports/CampsCollectiveReport";
import HomeCamps from "./pages/Home";
import { ChronologicalReport } from "./pages/reports/ChronologicalReport";
import Status from "./pages/Status";
import Responsible from "./pages/Responsible";
import Hospitals from "./pages/Hospitals";
import HospitalStats from "./pages/HospitalsStats";
import Root from "./pages/Root";
import FollowUpReceipt from "./pages/reports/HosptalDateReport ";
import HosptalAnalyticalReport from "./pages/reports/HosptalAnalyticalReport";
import PreparingTheTeamFollowUps from "./pages/followUps/PreparingTheTeamFollowUps";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import useHaveUserPermission from "./hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userToken } from "./store/reducers/user";
import HospitalsCountries from "./pages/HospitalsCountries";
import HospitalsCities from "./pages/HospitalsCities";
import HomeHospitals from "./pages/HomeHospitals";
import HosptalMonthlyReport from "./pages/reports/HosptalMonthlyReport";
import HospitalsCollectiveReport from "./pages/reports/HospitalsCollectiveReport";

function App() {
  const token = useSelector(userToken);

  const annualplanView = useHaveUserPermission("annualplan", "view");
  const viewHospitalstats = useHaveUserPermission("hospitalstats", "view");
  const viewStats = useHaveUserPermission("stats", "view");
  const viewSecondfollowup = useHaveUserPermission("secondfollowup", "view");
  const viewFirstfollowup = useHaveUserPermission("firstfollowup", "view");
  const viewFinancialfollowup = useHaveUserPermission(
    "financialfollowup",
    "view"
  );
  const viewFinancialsectorfollowup = useHaveUserPermission(
    "financialsectorfollowup",
    "view"
  );
  const viewMediafollowup = useHaveUserPermission("mediafollowup", "view");
  const viewMedicationfollowup = useHaveUserPermission(
    "medicationfollowup",
    "view"
  );
  const viewPreparatoryvisitfollowup = useHaveUserPermission(
    "preparatoryvisitfollowup",
    "view"
  );
  const viewPreparingTheTeamfollowup = useHaveUserPermission(
    "preparingtheteamfollowup",
    "view"
  );
  const viewSponsorsfollowup = useHaveUserPermission(
    "sponsorsfollowup",
    "view"
  );
  const viewCities = useHaveUserPermission("cities", "view");
  const viewCountries = useHaveUserPermission("countries", "view");
  const viewCitiesHospitals = useHaveUserPermission("hospitalscities", "view");
  const viewCountriesHospitals = useHaveUserPermission(
    "hospitalscountries",
    "view"
  );
  const viewSponsor = useHaveUserPermission("sponsor", "view");
  const viewSponsorship = useHaveUserPermission("sponsorship", "view");
  const viewTeam = useHaveUserPermission("team", "view");
  const viewPlanstatus = useHaveUserPermission("planstatus", "view");
  const viewResponsible = useHaveUserPermission("responsible", "view");
  const viewHospital = useHaveUserPermission("hospital", "view");
  const viewUsers = useHaveUserPermission("users", "view");
  const viewGroup = useHaveUserPermission("group", "view");

  const viewHospitalsMonthlyReport = useHaveUserPermission(
    "hospitalsmonthlyreport",
    "view"
  );
  const viewHospitalsFollowupReceiptReport = useHaveUserPermission(
    "hospitalsfollowupreceiptreport",
    "view"
  );
  const viewHospitalsAnalyticalReport = useHaveUserPermission(
    "hospitalsanalyticalreport",
    "view"
  );
  const viewHospitalsCollectiveReport = useHaveUserPermission(
    "hospitalscollectivereport",
    "view"
  );
  const viewCampsCollectiveReports = useHaveUserPermission(
    "collectivereports",
    "view"
  );
  const viewFollowupReports = useHaveUserPermission("followupreports", "view");
  const vieTeamChronologicalReport = useHaveUserPermission(
    "teamchronologicalreport",
    "view"
  );
  const location = useLocation();
  return (
    <>
      <div
        id="loading"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "#0005",
          zIndex: 1000000000,
          display: "none",
        }}
      >
        <div className="w-100 h-100 d-flex">
          <div
            className="spinner-border text-light m-auto "
            role="status"
            style={{ height: "4rem", width: "4rem" }}
          ></div>
        </div>
      </div>
      <div className="d-flex flex-column h-100">
        {token && location.pathname != "/" && <NavBar />}
        <div className="flex-fill d-flex flex-column">
          <Routes>
            <Route element={<AuthenticatedRoutes />}>
              <Route path="*" element={<Root />} />
              <Route path="/" element={<Root />} />
              <Route path="resetPassword" element={<ResetPassword />} />
              {viewUsers && (
                <Route path="users" element={<UsersManagement />} />
              )}
              {viewGroup && (
                <Route path="groups" element={<GroupsPermissions />} />
              )}
              <Route path="camps" element={<HomeCamps />} />
              <Route path="hospitals" element={<HomeHospitals />} />
              {annualplanView && <Route path="camps/plan" element={<Plan />} />}
              {viewCountries && (
                <Route path="camps/countries" element={<Countries />} />
              )}
              {viewCountriesHospitals && (
                <Route
                  path="hospitals/countries"
                  element={<HospitalsCountries />}
                />
              )}
              {viewCities && <Route path="camps/cities" element={<Cities />} />}
              {viewCitiesHospitals && (
                <Route path="hospitals/cities" element={<HospitalsCities />} />
              )}
              {viewTeam && <Route path="camps/teams" element={<Teams />} />}
              {viewPlanstatus && (
                <Route path="camps/status" element={<Status />} />
              )}
              {viewResponsible && (
                <Route path="camps/Responsible" element={<Responsible />} />
              )}
              {viewSponsor && (
                <Route path="camps/sponsor" element={<Sponsors />} />
              )}
              {viewSponsorship && (
                <Route path="camps/sponsorship" element={<Sponsorships />} />
              )}

              {viewFollowupReports && (
                <Route path="camps/reportsFollowUp" element={<Reports />} />
              )}
              {viewCampsCollectiveReports && (
                <Route
                  path="camps/collectiveReports"
                  element={<CampsCollectiveReport />}
                />
              )}
              {vieTeamChronologicalReport && (
                <Route
                  path="camps/ChronologicalReport"
                  element={<ChronologicalReport />}
                />
              )}
              {viewFirstfollowup && (
                <Route path="camps/firstFollowUp" element={<FirstFollowUp />} />
              )}
              {viewSecondfollowup && (
                <Route
                  path="camps/secondFollowUp"
                  element={<SecondFollowUp />}
                />
              )}
              {viewStats && <Route path="camps/stats" element={<Stats />} />}
              {(viewFinancialfollowup ||
                viewFinancialsectorfollowup ||
                viewMediafollowup ||
                viewMedicationfollowup ||
                viewSponsorsfollowup ||
                viewPreparatoryvisitfollowup) && (
                <Route path="camps/followUp" element={<FollowUpPage />}>
                  {viewFinancialfollowup && (
                    <Route
                      path="financialFollowUps"
                      element={<FinancialFollowUps />}
                    />
                  )}
                  {viewPreparingTheTeamfollowup && (
                    <Route
                      path="preparingTheTeamFollowUps"
                      element={<PreparingTheTeamFollowUps />}
                    />
                  )}
                  {viewFinancialsectorfollowup && (
                    <Route
                      path="financialSectorFollowUps"
                      element={<FinancialSectorFollowUps />}
                    />
                  )}
                  {viewMediafollowup && (
                    <Route path="mediaFollowUps" element={<MediaFollowUps />} />
                  )}
                  {viewMedicationfollowup && (
                    <Route
                      path="medicationFollowUps"
                      element={<MedicationFollowUps />}
                    />
                  )}
                  {viewSponsorsfollowup && (
                    <Route
                      path="sponsorsFollowUps"
                      element={<SponsorsFollowUps />}
                    />
                  )}
                  {viewPreparatoryvisitfollowup && (
                    <Route
                      path="preparatoryVisitFollowUps"
                      element={<PreparatoryVisitFollowUp />}
                    />
                  )}
                </Route>
              )}
              {viewHospitalstats && (
                <Route
                  path="/hospitals/hospitalStats"
                  element={<HospitalStats />}
                />
              )}
              {viewHospitalsMonthlyReport && (
                <Route
                  path="/hospitals/monthly_report"
                  element={<HosptalMonthlyReport />}
                />
              )}
              {viewHospitalsAnalyticalReport && (
                <Route
                  path="/hospitals/analytical_report"
                  element={<HosptalAnalyticalReport />}
                />
              )}
              {viewHospitalsFollowupReceiptReport && (
                <Route
                  path="/hospitals/follow_up_receipt"
                  element={<FollowUpReceipt />}
                />
              )}
              {viewHospital && (
                <Route path="/hospitals/hospital" element={<Hospitals />} />
              )}
              {viewHospitalsCollectiveReport && (
                <Route
                  path="/hospitals/collectiveReports"
                  element={<HospitalsCollectiveReport />}
                />
              )}
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
        </div>
      </div>

      <ToastContainer theme="light" rtl />
    </>
  );
}

export default App;
