import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getHospitalsCountries = createAsyncThunk(
  "hospitalsCountries/getHospitalsCountries",
  async () => {
    return await apiServices.getHospitalsCountries();
  }
);
export const createCountry = createAsyncThunk(
  "hospitalsCountries/createCountry",
  async (data) => {
    return await apiServices.createHospitalsCountry(data);
  }
);
export const updateCountries = createAsyncThunk(
  "hospitalsCountries/updateCountries",
  async ({ id, data }) => {
    return await apiServices.updateHospitalsCountries(id, data);
  }
);

export const deleteCountry = createAsyncThunk(
  "hospitalsCountries/deleteCountry",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteHospitalsCountries(id);
    if (ok) return id;

    return rejectWithValue();
  }
);

const hospitalsCountriesSlice = createSlice({
  name: "hospitalsCountries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHospitalsCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHospitalsCountries.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getHospitalsCountries.rejected, (state) => {
        state.error = true;
      })
      .addCase(createCountry.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
      })
      .addCase(updateCountries.fulfilled, (state, action) => {
        const countryIndex = state.data.findIndex(
          (c) => c.id === action.payload.data.id
        );
        state.data[countryIndex] = action.payload.data;
      })
      .addCase(deleteCountry.fulfilled, (state, action) => {
        state.data = state.data.filter((c) => c.id !== action.payload);
      });
  },
});

export const {} = hospitalsCountriesSlice.actions;

export const hospitalsCountriesData = (state) => state.hospitalsCountries;

export default hospitalsCountriesSlice.reducer;
