import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import AutocomleteField from "../components/common/AutoComleteField";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useSponsorsData from "../hooks/useSponsorsData";
import { userLang } from "../store/reducers/user";
import { useSelector } from "react-redux";
import { useLazyGetSponsorshipsQuery } from "../store/services/sponsorship";
export default function Sponsorships() {
  const [sponsorship, setSponsorship] = useState("");
  const [sponsorship_EN, setSponsorship_EN] = useState("");
  const [sponsor, setSponsor] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sponsorshipId, setSponsorshipId] = useState();
  const [sponsorshipIdDelete, setSponsorshipIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const { data: sponsors } = useSponsorsData();
  const lang = useSelector(userLang);
  const [triggerSponsorships, { data: sponsorships }] =
    useLazyGetSponsorshipsQuery({
      selectFromResult: (data) => {
        return sponsor?.value ? data : [];
      },
    });
  const view = useHaveUserPermission("sponsorship", "view");
  const add = useHaveUserPermission("sponsorship", "add");
  const change = useHaveUserPermission("sponsorship", "change");
  const deletePerm = useHaveUserPermission("sponsorship", "delete");
  const getSponsorships = async () => {
    if (view) {
      triggerSponsorships(sponsor.value, !!sponsorships);
    }
  };
  const createSponsorship = async () => {
    setDisableBtnCreate(true);
    const result = await api.createSponsorships({
      sponsorship,
      sponsorship_EN,
      active,
      email,
      phone,
      sponsor: sponsor.value,
    });
    if (result.ok) {
      const newSponsorships = [...sponsorships];
      newSponsorships.unshift({
        sponsor: result.data.sponsor,
        sponsorship: result.data.sponsorship,
        sponsorship_EN: result.data.sponsorship_EN,
        active: result.data.active,
        id: result.data.id,
        date: result.data.date,
        email: result.data.email,
        phone: result.data.phone,
      });
      setSponsorshipId();
      setEmail("");
      setPhone("");
      setSponsorship("");
      setSponsorship_EN("");
      setActive(false);
      toast.success("تم إضافة الراعي بنجاح");
    }
    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setActive(item.active);
    setEmail(item.email ? item.email : "");
    setPhone(item.phone ? item.phone : "");
    setSponsorship(item.sponsorship);
    setSponsorship_EN(item.sponsorship_EN);
    setSponsorshipId(item.id);
  };
  const deleteSponsor = async (id) => {
    const result = await api.deleteSponsorships(id);
    if (result.ok) {
      const newSponsor = sponsorships.filter((i) => i.id != id);
      setSponsorshipId();

      setShowModal(false);
      toast.success("تم حذف الراعي بنجاح");
    } else {
      toast.error(strings2.error_delete[lang]);
    }
  };
  const updateSponsor = async () => {
    setDisableBtnUpdate(true);

    const result = await api.updateSponsorships(sponsorshipId, {
      sponsorship,
      sponsorship_EN,
      active,
      phone,
      email,
      sponsor: sponsor.value,
    });
    if (result.ok) {
      const newSponsorships = [...sponsorships];
      const index = newSponsorships.findIndex((i) => i.id == sponsorshipId);
      newSponsorships[index].sponsorship = sponsorship;
      newSponsorships[index].sponsorship_EN = sponsorship_EN;
      newSponsorships[index].active = active;
      newSponsorships[index].phone = phone;
      newSponsorships[index].email = email;
      setSponsorshipId();
      setSponsorship("");
      setSponsorship_EN("");
      setEmail("");
      setPhone("");
      setActive(false);
      toast.success("تم تعديل الراعي بنجاح");
    }
    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "أسماء المتبرعين";

    if (sponsor) {
      getSponsorships();
      setSponsorshipId();
      setSponsorship("");
      setSponsorship_EN("");
      setEmail("");
      setPhone("");

      setActive(false);
    }
  }, [sponsor]);

  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.sub_donors}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50 mt-3">
          <AutocomleteField
            placeholder="الراعي"
            value={sponsor}
            setValue={setSponsor}
            data={sponsors.map((i) => ({
              label: lang == "AR" ? i.sponsor : i.sponsor_EN,
              value: i.id,
            }))}
          />
          <div className="mx-2">
            <input
              value={sponsorship}
              onChange={(e) => setSponsorship(e.target.value)}
              className="form-control  p-2  mb-3 mt-2"
              placeholder="الراعي الفرعي"
            />
            <input
              value={sponsorship_EN}
              onChange={(e) => setSponsorship_EN(e.target.value)}
              className="form-control  p-2  mb-3 mt-2"
              placeholder="Sub Donor"
            />
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={strings[lang].data.email}
            />
            <input
              onWheel={(event) => event.currentTarget.blur()}
              type={"number"}
              value={phone}
              onChange={(e) => setPhone(e.target.value.toString())}
              className="form-control  p-2  my-3"
              placeholder={strings[lang].data.phone}
            />
            <div className="form-check ">
              <input
                onChange={(e) => setActive(e.target.checked)}
                className="form-check-input float-none ms-0"
                type="checkbox"
                checked={active}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label mx-2"
                htmlFor="flexCheckDefault"
              >
                {strings[lang].data.active}
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={updateSponsor}
            className={`btn btn-success  ${
              disableBtnUpdate || !sponsorshipId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createSponsorship}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !sponsorship || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
      </div>
      {sponsorships?.length > 0 && (
        <div className="table-responsive">
          <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{strings[lang].data.donors}</th>
                <th scope="col">الراعي الفرعي</th>
                <th scope="col">Sub Donor</th>
                <th scope="col">{strings[lang].data.email}</th>
                <th scope="col">{strings[lang].data.phone}</th>
                <th scope="col">{strings[lang].data.date_of_registration}</th>
                <th scope="col">{strings[lang].data.active}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {sponsorships.map((item, index) => (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <th scope="row" onClick={() => onClick(item)}>
                    {sponsorships.length - index}
                  </th>
                  <td onClick={() => onClick(item)}>
                    {lang == "AR"
                      ? sponsors.find((i) => i.id == item.sponsor).sponsor
                      : sponsors.find((i) => i.id == item.sponsor).sponsor_EN}
                  </td>
                  <td onClick={() => onClick(item)}>{item.sponsorship}</td>
                  <td onClick={() => onClick(item)}>{item.sponsorship_EN}</td>
                  <td onClick={() => onClick(item)}>{item.email}</td>
                  <td onClick={() => onClick(item)}>{item.phone}</td>
                  <td onClick={() => onClick(item)} dir="ltr">
                    <span>{moment(item.date).format("YYYY-M-D")}</span>
                    <span> {moment(item.date).format("LT")}</span>
                  </td>
                  <td onClick={() => onClick(item)}>
                    <i
                      className={`fa ${
                        item.active
                          ? "fa-check-circle text-success"
                          : "fa-times-circle text-danger"
                      } `}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <p
                      className={`mb-0 btn btn-outline-danger  ${
                        !deletePerm ? "disabled" : ""
                      }`}
                      onClick={() => {
                        setSponsorshipIdDelete(item.id);
                        setShowModal(true);
                      }}
                    >
                      {strings[lang].data.delete}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={sponsorshipIdDelete}
        deleteMethod={deleteSponsor}
      />
    </div>
  );
}
