import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getPermissions = createAsyncThunk(
  "permissions/getPermissions",
  async () => {
    return await apiServices.getPermissions();
  }
);

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.data = action.payload.data;
        // state.data = action.payload.data.map((item) => ({
        //   value: item.id,
        //   label: `${item.name} ${item.type}`,
        //   name: item.name,
        //   type: item.type,
        //   type_EN: item.type_EN,
        //   name_EN: item.name_EN,
        // }));
      })
      .addCase(getPermissions.rejected, (state) => {
        state.error = true;
      });
  },
});

export const {} = permissionsSlice.actions;

export const permissionsData = (state) => state.permissions;

export default permissionsSlice.reducer;
