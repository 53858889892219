import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./reducers/user";

import countriesReducer from "./reducers/countries";
import groupsPermissionsReducer from "./reducers/groupsPermissions";
import hospitalsCountriesReducer from "./reducers/hospitalsCountries";
import permissionsReducer from "./reducers/permissions";
import responsiblesReducer from "./reducers/responsibles";
import sponsorsReducer from "./reducers/sponsors";
import statusReducer from "./reducers/status";
import teamsReducer from "./reducers/teams";

import { planApi } from "./services/plan";
import { planCitiesServices } from "./services/planCities";
import { sponsorshipServices } from "./services/sponsorship";
import { hospitalsCitiesServices } from "./services/hospitalsCities";

const rootReducer = combineReducers({
  user: userReducer,
  permissions: permissionsReducer,
  countries: countriesReducer,
  sponsors: sponsorsReducer,
  groupsPermissions: groupsPermissionsReducer,
  hospitalsCountries: hospitalsCountriesReducer,
  responsibles: responsiblesReducer,
  status: statusReducer,
  teams: teamsReducer,
  [planApi.reducerPath]: planApi.reducer,
  [planCitiesServices.reducerPath]: planCitiesServices.reducer,
  [sponsorshipServices.reducerPath]: sponsorshipServices.reducer,
  [hospitalsCitiesServices.reducerPath]: hospitalsCitiesServices.reducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["user"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(planApi.middleware)
      .concat(sponsorshipServices.middleware)
      .concat(planCitiesServices.middleware)
      .concat(hospitalsCitiesServices.middleware),
});
export const persistor = persistStore(store);
