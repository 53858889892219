import React from "react";
import strings from "../common/strings.json";
import strings2 from "../common/strings2.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function Tabel({ setInputsValue, inputsValue }) {
  const lang = useSelector(userLang);

  const generalStatistics = [
    {
      action_AR: "عدد المرضى الذي أجرى لهم فحص",
      action_EN: "Total no. of patients attended OPD",
      valueAdults: "totalPatients",
      valueChildren: "totalPatientsChildren",
    },
    {
      action_AR: "إجمالي عدد النظارات",
      action_EN: "Glasses",
      valueAdults: "glasses",
      valueChildren: "glassesChildren",
    },
    {
      action_AR: "عدد المرضى الذين قررت لهم عمليات",
      action_EN: "Total no. of patients admitted for surger",
      valueAdults: "patientsSurger",
      valueChildren: "patientsSurgerChildren",
    },
  ];
  const SurgerTableList = [
    {
      action_AR: "عمليات ماء أبيض مع زرع العدسة (باستخدام تقنية الفاكو)",
      action_EN: "PHACO + P/C IOL",
      valueAdults: "phaco",
      valueChildren: "phacoChildren",
    },
    {
      action_AR: "عمليات ماء أبيض مع زرع العدسة",
      action_EN: "ECCE with PC IOL implant",
      valueAdults: "iolImplant",
      valueChildren: "iolImplantChildren",
    },
    {
      action_AR: "__",
      action_EN: "IOL (AC IOL)",
      valueAdults: "acIol",
      valueChildren: "acIolChildren",
    },
    {
      action_AR: "عمليات الماء الأبيض بدون رزاعة عدسة",
      action_EN: "ICCE",
      valueAdults: "ICCE",
      valueChildren: "ICCEChildren",
    },
    {
      action_AR: "عملية ازالة لحمية العين الظفرة",
      action_EN: "PTERYGIUM",
      valueAdults: "PTERYGIUM",
      valueChildren: "PTERYGIUMChildren",
    },
    {
      action_AR: "إزالة كيس دهني",
      action_EN: "CHALAZION",
      valueAdults: "CHALAZION",
      valueChildren: "CHALAZIONChildren",
    },
    {
      action_AR: "أخرى",
      action_EN: "OTHERS",
      valueAdults: "OTHERS",
      valueChildren: "OTHERSChildren",
    },
  ];
  const beforeSurgery = [
    {
      action_AR: "__",
      action_EN: "VA ≥ 6/60",
      valueAdults: "vaLarger",
      valueChildren: "vaLargerChildren",
    },
    {
      action_AR: "__",
      action_EN: "VA < 6/60",
      valueAdults: "vaLess",
      valueChildren: "vaLessChildren",
    },
  ];
  const afterSurgery = [
    {
      action_AR: "__",
      action_EN: "Surgery ICCE plain",
      valueAdults: "icce_plan",
    },
    {
      action_AR: "__",
      action_EN: "Surgery ICCE + AC IOL",
      valueAdults: "icce_aciol",
    },
    {
      action_AR: "__",
      action_EN: "Surgery ECCE plain",
      valueAdults: "ecce",
    },
    {
      action_AR: "__",
      action_EN: "Surgery ECCE + PC IOL",
      valueAdults: "ecce_pciol",
    },
    {
      action_AR: "__",
      action_EN: "Surgery  SICS/Phaco + PC- IOL",
      valueAdults: "phaco_pciol",
    },
    {
      action_AR: "__",
      action_EN: "Surgery SICS/Phaco + AC- IOL",
      valueAdults: "phaco_aciol",
    },
    {
      action_AR: "__",
      action_EN: "Surgery  SICS/Phaco no IOL",
      valueAdults: "phaco_noiol",
    },
  ];
  return (
    <>
      <div
        className="container table-responsive p-0"
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <table className="table table-hover table-striped  table-bordered text-center align-middle">
          <thead className="header-tabel">
            <tr>
              <th scope="col" colSpan={6}>
                {strings2.generalStatistics[lang]}
              </th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.children}
              </th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.adults}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <thead className="header-tabel">
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">{strings[lang].stats.procedure}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.total}</th>
            </tr>
          </thead>
          <tbody>
            {generalStatistics.map((item, index) => {
              const value = { ...inputsValue };

              return (
                <tr key={index}>
                  {/* <th scope="row">{index + 1}</th> */}
                  <td>
                    <p>{item.action_AR}</p>

                    <p>{item.action_EN}</p>
                  </td>
                  <td>
                    {/* ----------------male Children-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female Children------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].female = Number(
                          e.target.value
                        );
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* ----------------male Adults-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female Adults------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].female = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {Number(inputsValue[item.valueAdults].female) +
                      Number(inputsValue[item.valueAdults].male) +
                      Number(inputsValue[item.valueChildren].female) +
                      Number(inputsValue[item.valueChildren].male) || 0}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="container table-responsive p-0"
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <table className="table table-hover table-striped  table-bordered text-center align-middle">
          <thead className="header-tabel">
            <tr>
              <th scope="col" colSpan={6}>
                {strings2.operationsStatistics[lang]}
              </th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.children}
              </th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.adults}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <thead className="header-tabel">
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">{strings[lang].stats.procedure}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.total}</th>
            </tr>
          </thead>
          <tbody>
            {SurgerTableList.map((item, index) => {
              const value = { ...inputsValue };

              return (
                <tr key={index}>
                  {/* <th scope="row">{index + 1}</th> */}
                  <td>
                    <p>{item.action_AR}</p>

                    <p>{item.action_EN}</p>
                  </td>
                  <td>
                    {/* ----------------male Children-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female Children------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].female = Number(
                          e.target.value
                        );
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* ----------------male Adults-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female Adults------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].female = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {Number(inputsValue[item.valueAdults].female) +
                      Number(inputsValue[item.valueAdults].male) +
                      Number(inputsValue[item.valueChildren].female) +
                      Number(inputsValue[item.valueChildren].male) || 0}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={6}>
                <span>{strings[lang].stats.total}</span>
                <span className="badge text-bg-primary mx-2 fs-6">
                  {SurgerTableList.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      Number(inputsValue[currentValue.valueAdults].female) +
                      Number(inputsValue[currentValue.valueAdults].male) +
                      Number(inputsValue[currentValue.valueChildren].female) +
                      Number(inputsValue[currentValue.valueChildren].male),
                    0
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive">
        <table
          dir={lang == "EN" ? "ltr" : "rtl"}
          className="container table table-hover table-striped  table-bordered text-center align-middle"
        >
          <thead className="header-tabel">
            <tr>
              <th scope="col" colSpan={6}>
                {strings[lang].stats.before_surgery}
              </th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.children}
              </th>
              <th scope="col" colSpan="2">
                {strings[lang].stats.adults}
              </th>
              <th scope="col"></th>
            </tr>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">{strings[lang].stats.procedure}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.male}</th>
              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.total}</th>
            </tr>
          </thead>
          <tbody>
            {beforeSurgery.map((item, index) => {
              const value = { ...inputsValue };
              return (
                <tr key={index}>
                  {/* <th scope="row">{index + 1}</th> */}
                  <td>
                    <p>{item.action_AR}</p>
                    <p>{item.action_EN}</p>
                  </td>
                  <td>
                    {/* ----------------male children-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female children------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueChildren]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueChildren].female = Number(
                          e.target.value
                        );
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* ----------------male Adults-------------- */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.male}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].male = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {/* -----------female Adults------------ */}
                    <input
                      style={{ minWidth: 75 }}
                      value={value[item.valueAdults]?.female}
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        value[item.valueAdults].female = Number(e.target.value);
                        setInputsValue(value);
                      }}
                      className="form-control  p-2 text-center "
                      placeholder={strings[lang].stats.number}
                      type={"number"}
                      min={0}
                    />
                  </td>
                  <td>
                    {Number(inputsValue[item.valueAdults]?.female) +
                      Number(inputsValue[item.valueAdults]?.male) +
                      Number(inputsValue[item.valueChildren]?.female) +
                      Number(inputsValue[item.valueChildren]?.male) || 0}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={6}>
                <span>{strings[lang].stats.total}</span>
                <span className="badge text-bg-primary mx-2 fs-6">
                  {Number(inputsValue.vaLess.male) +
                    Number(inputsValue.vaLess.female) +
                    Number(inputsValue.vaLessChildren?.male) +
                    Number(inputsValue.vaLessChildren?.female) +
                    Number(inputsValue.vaLarger.male) +
                    Number(inputsValue.vaLarger.female) +
                    Number(inputsValue.vaLargerChildren?.male) +
                    Number(inputsValue.vaLargerChildren?.female) || 0}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container table table-hover table-striped  table-bordered text-center align-middle d-none "
      >
        <thead className="header-tabel">
          <tr>
            <th scope="col" colSpan={6}>
              {strings[lang].stats.after_surgery}
            </th>
          </tr>
          <tr>
            <th scope="col">{strings[lang].stats.procedure}</th>
            <th scope="col">{strings[lang].stats.male}</th>

            <th scope="col">{strings[lang].stats.female}</th>
            <th scope="col">{strings[lang].stats.total}</th>
          </tr>
        </thead>
        <tbody>
          {afterSurgery.map((item, index) => {
            const value = { ...inputsValue };

            return (
              <tr key={index}>
                {/* <th scope="row">{index + 1}</th> */}
                <td>
                  <p>{item.action_AR}</p>

                  <p>{item.action_EN}</p>
                </td>

                <td>
                  {/* ----------------male Adults-------------- */}
                  <input
                    style={{ minWidth: 75 }}
                    value={value[item.valueAdults]?.male}
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                      value[item.valueAdults].male = Number(e.target.value);
                      setInputsValue(value);
                    }}
                    className="form-control  p-2 text-center "
                    placeholder={strings[lang].stats.number}
                    type={"number"}
                    min={0}
                  />
                </td>
                <td>
                  {/* -----------female Adults------------ */}
                  <input
                    style={{ minWidth: 75 }}
                    value={value[item.valueAdults]?.female}
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                      value[item.valueAdults].female = Number(e.target.value);
                      setInputsValue(value);
                    }}
                    className="form-control  p-2 text-center "
                    placeholder={strings[lang].stats.number}
                    type={"number"}
                    min={0}
                  />
                </td>
                <td>
                  {Number(inputsValue[item.valueAdults]?.female) +
                    Number(inputsValue[item.valueAdults]?.male) || 0}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4}>
              <span>{strings[lang].stats.total}</span>
              <span className="badge text-bg-primary mx-2 fs-6">
                {Number(inputsValue.icce_plan.male) +
                  Number(inputsValue.icce_plan.female) +
                  Number(inputsValue.icce_aciol.male) +
                  Number(inputsValue.icce_aciol.female) +
                  Number(inputsValue.ecce.male) +
                  Number(inputsValue.ecce.female) +
                  Number(inputsValue.ecce_pciol.male) +
                  Number(inputsValue.ecce_pciol.female) +
                  Number(inputsValue.phaco_pciol.male) +
                  Number(inputsValue.phaco_pciol.female) +
                  Number(inputsValue.phaco_aciol.male) +
                  Number(inputsValue.phaco_aciol.female) +
                  Number(inputsValue.phaco_noiol.male) +
                  Number(inputsValue.phaco_noiol.female) || 0}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
