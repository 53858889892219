import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import strings from "../../components/common/strings.json";
import SearchModal from "../../components/common/SearchModal";
import NormalSearch from "../../components/common/NormalSearch";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { api } from "../../components/api/api";
export default function FollowUpPage() {
  const [show, setShow] = useState(false);
  const [planID, setPlanID] = useState();
  const [plan, setPlan] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [startDate, setStartDate] = useState("");
  const [sponsor, setSponsor] = useState();
  const [archives, setArchives] = useState(false);
  const [normalSearch, setNormalSearch] = useState(false);
  const [followUpsData, setFollowUpsData] = useState({});

  const lang = useSelector(userLang);
  const navigate = useNavigate();
  const location = useLocation();

  const viewFinancialfollowup = useHaveUserPermission(
    "financialfollowup",
    "view"
  );
  const viewFinancialsectorfollowup = useHaveUserPermission(
    "financialsectorfollowup",
    "view"
  );
  const viewMediafollowup = useHaveUserPermission("mediafollowup", "view");
  const viewMedicationfollowup = useHaveUserPermission(
    "medicationfollowup",
    "view"
  );
  const viewPreparatoryvisitfollowup = useHaveUserPermission(
    "preparatoryvisitfollowup",
    "view"
  );
  const viewPreparingTheTeamfollowup = useHaveUserPermission(
    "preparingtheteamfollowup",
    "view"
  );

  const viewSponsorsfollowup = useHaveUserPermission(
    "sponsorsfollowup",
    "view"
  );
  const tabs = useMemo(() => {
    let data = [
      {
        label: strings[lang].follow_up.financial_follow_up,
        to: "financialFollowUps",
        perm: viewFinancialfollowup,
      },
      {
        label: strings[lang].follow_up.financial_sector_follow_up,
        to: "financialSectorFollowUps",
        perm: viewFinancialsectorfollowup,
      },
      {
        label: strings[lang].follow_up.sponsors_follow_up,
        to: "sponsorsFollowUps",
        perm: viewSponsorsfollowup,
      },
      {
        label: strings[lang].follow_up.media_follow_up,
        to: "mediaFollowUps",
        perm: viewMediafollowup,
      },
      {
        label: strings[lang].follow_up.medication_follow_up,
        to: "medicationFollowUps",
        perm: viewMedicationfollowup,
      },
      {
        label: strings[lang].follow_up.preparatory_visit,
        to: "preparatoryVisitFollowUps",
        perm: viewPreparatoryvisitfollowup,
      },
      {
        label: strings[lang].follow_up.preparing_the_team_follow_up,
        to: "preparingTheTeamFollowUps",
        perm: viewPreparingTheTeamfollowup,
      },
    ];
    if (planID && plan) {
      const filteredData = [];
      const followUps = Object.entries(followUpsData);
      followUps.forEach(([key, value]) => {
        filteredData.push(key);
      });

      data = data.filter((i) => filteredData.includes(i.to));
    }
    return data;
  }, [plan, followUpsData, archives]);

  const getPlanFollowUps = async () => {
    const { data } = await api.get(`follow_ups/planFollowUps/${planID}`);
    setFollowUpsData(data);
  };

  useEffect(() => {
    document.title = "المتابعات";
    if (location.pathname == "/camps/followUp") {
      navigate("financialFollowUps");
    }
  }, [location]);

  useEffect(() => {
    if (planID) getPlanFollowUps();
  }, [planID]);

  useEffect(() => {
    if (tabs[0]) navigate(tabs[0].to);
  }, [tabs]);
  return (
    <>
      <NormalSearch
        country={country}
        setCountry={setCountry}
        city={city}
        setCity={setCity}
        sponsor={sponsor}
        setSponsor={setSponsor}
        startDate={startDate}
        setStartDate={setStartDate}
        setNormalSearch={setNormalSearch}
        setShow={setShow}
      />

      <div className="container">
        <ul
          className=" nav nav-tabs   p-0 px-lg-5  mt-2"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          {tabs
            .filter((i) => i.perm)
            .map((item, index) => (
              <li key={index} className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname.includes(item.to) ? "active" : ""
                  }`}
                  aria-current="to"
                  to={item.to}
                >
                  {item.label}
                </Link>
              </li>
            ))}
        </ul>
        <Outlet context={{ planID, plan, followUpsData }} />
      </div>
      <SearchModal
        show={show}
        setShow={setShow}
        setPlanID={setPlanID}
        followUp
        archives={archives}
        setArchives={setArchives}
        setPlan={setPlan}
        plan={plan}
        searchFields={{ followUpArchive: normalSearch ? false : archives }}
        normalSearch={normalSearch}
        dataNormalSearch={{
          country: country?.value || "",
          city: city?.value || "",
          sponsor: sponsor?.value || "",
          startDate: startDate || "",
        }}
      />
    </>
  );
}
