import React, { useEffect, useState } from "react";
import api from "../../components/api/api";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { writeFile, utils, write } from "xlsx";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import { useTable, useSortBy } from "react-table";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useController, useForm } from "react-hook-form";
import Autocomlete from "../../components/common/AutoComlete";
import useHospitalsCountriesData from "../../hooks/useHospitalsCountriesData";
import moment from "moment";
import Datetime from "react-datetime";

export default function HospitalsCollectiveReport() {
  const [reports, setReports] = useState([]);
  const lang = useSelector(userLang);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      country: null,
      dateFrom: "",
      dateTo: "",
    },
  });

  const getReports = async (searchData) => {
    const { data, ok } = await api.getHospitalsCollectiveReport(searchData);
    if (ok) {
      setReports(data);
    }
  };
  useEffect(() => {
    getReports();
  }, []);
  const downloadPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [
        [
          "#",
          strings2.hospital["EN"],
          strings2.country["EN"],
          strings2.city["EN"],
          strings2.first_report["EN"],
          strings2.last_report["EN"],
          strings2.hospitalAge["EN"],
          strings2.clinics["EN"],
          strings2.surgeries["EN"],
          "Phaco",
        ],
      ],
      body: [
        ...reports.map((item, index) => [
          index + 1,
          item.name_EN,
          item.country_EN,
          item.city_EN,
          moment(item.first_date).format('YYYY-MM'),
          moment(item.last_date).format('YYYY-MM'),
          item.hospital_age,
          item.clinics || 0,
          item.surgery || 0,
          item.phaco || 0,
        ]),
      ],

      foot: [
        [
          {
            content: strings["EN"].reports.total,
            colSpan: 7,
          },
          reports.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.clinics,
            0
          ),
          reports.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.surgery,
            0
          ),
          reports.reduce(
            (accumulator, currentValue) => accumulator + currentValue?.phaco,
            0
          ),
        ],
      ],
      styles: { valign: "middle", halign: "center", fontStyle: "Amiri" },
      showFoot: "lastPage",
      margin: { horizontal: 5, vertical: 5 },
    });

    doc.save("table.pdf");
  };
  const createExcel = () => {
    const table = document.getElementById("tabel-data");
    const newTable = table.cloneNode(true);

    newTable.childNodes[0].childNodes[0].childNodes[0].childNodes[0].remove();

    const wb = utils.table_to_book(newTable, { raw: true });
    writeFile(wb, "reports.xlsx");
  };

  const createImg = async () => {
    const table = document.getElementById("tabel-data");
    setTimeout(async () => {
      const img = await htmlToImage.toPng(table);
      Axios.get(img, {
        responseType: "blob",
      }).then((res) => {
        fileDownload(res.data, "reports" + ".png");
      });
    }, 1500);
  };
  const columns = React.useMemo(() => {
    let dataT;
    dataT = [
      {
        Header: strings2.hospital[lang],
        accessor: lang == "AR" ? "name" : "name_EN",
      },
      {
        Header: strings2.country[lang],
        accessor: lang == "AR" ? "country" : "country_EN",
      },
      {
        Header: strings2.city[lang],
        accessor: lang == "AR" ? "city" : "city_EN",
      },
      {
        Header: strings2.first_report[lang],
        accessor: row => moment(row.first_date).format('YYYY-MM'),
      },
      {
        Header: strings2.last_report[lang],
        accessor: row => moment(row.last_date).format('YYYY-MM'),
      },
      {
        Header: strings2.hospitalAge[lang],
        accessor: (row) => <span dir="ltr">{row.hospital_age}</span>,
      },
      {
        Header: strings[lang].reports.clinics,
        accessor: (d) => d.clinics.toLocaleString(),
      },
      {
        Header: strings[lang].reports.surgeries,
        accessor: (d) => d.surgery.toLocaleString(),
      },
      {
        Header: "Phaco",
        accessor: (d) => d.phaco.toLocaleString(),
      },
    ];

    return dataT;
  }, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: reports,
      },
      useSortBy
    );

  const { field: dateFrom } = useController({
    name: "dateFrom",
    control,
  });
  const { field: dateTo } = useController({
    name: "dateTo",
    control,
  });
  const { data: hosiptalsCountries } = useHospitalsCountriesData();

  return (
    <>
      <form onSubmit={handleSubmit(getReports)}>
        <div className="flex items-center [&>.p-2]:!p-0 gap-3 sm:w-72 md:w-[500px] w-full mx-auto my-4 flex-row-reverse">
          <Autocomlete
            isMulti
            data={hosiptalsCountries.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
            // title={strings2.responsible[lang]}
            control={control}
            name={"country"}
          />
          <Datetime
            closeOnSelect
            dateFormat="YYYY-MM"
            timeFormat={false}
            onChange={(v) =>
              dateFrom.onChange(v ? moment(v).format("YYYY-MM") : "")
            }
            inputProps={{
              value: dateFrom.value,
              placeholder: strings2.from[lang],
              ref: dateFrom.ref,
              onBlur: dateFrom.onBlur,
              name: dateFrom.name,
              autoComplete: "off",
            }}
          />
          <Datetime
            closeOnSelect
            dateFormat="YYYY-MM"
            timeFormat={false}
            onChange={(v) =>
              dateTo.onChange(v ? moment(v).format("YYYY-MM") : "")
            }
            inputProps={{
              value: dateTo.value,
              placeholder: strings2.to[lang],
              ref: dateTo.ref,
              onBlur: dateTo.onBlur,
              name: dateTo.name,
              autoComplete: "off",
            }}
          />
        </div>

        <div className="row justify-content-around sm:w-72 md:w-96 w-full mx-auto mb-4">
          <button
            type="button"
            onClick={() => reset({ country: null, dateFrom: "", dateTo: "" })}
            className={`btn btn-success col-4`}
          >
            {strings2.Delete_fields[lang]}
          </button>
          <button
            type="submit"
            className={`btn btn-primary col-4 ${false ? "disabled" : ""}`}
          >
            {strings[lang].reports.search}
          </button>
        </div>
      </form>
      <div className="table-responsive">
        <table
          style={{ backgroundColor: "#fff" }}
          id="tabel-data"
          className="table table-striped  table-bordered text-center align-middle"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <thead className="header-tabel">
            <tr>
              <th colSpan={7} style={{ borderColor: "#0000" }} className="">
                <div className="d-flex justify-content-start">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa fa-print" aria-hidden="true"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li onClick={downloadPDF}>
                        <a className="dropdown-item">PDF</a>
                      </li>
                      <li onClick={createImg}>
                        <a className="dropdown-item" href="#">
                          Photo
                        </a>
                      </li>
                      <li onClick={createExcel}>
                        <a className="dropdown-item">Excel</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              {/* <th>{strings[lang].reports.time_period}</th> */}
              {/* <th style={{ borderColor: "#0000" }}></th>
            <th></th> */}
              <th colSpan={3}>{strings[lang].reports.stats}</th>
            </tr>
            {/* <tr>
            <th scope="col">#</th>
            <th scope="col">{strings[lang].reports.country}</th>
            <th scope="col">{strings[lang].reports.first_camp}</th>
            <th scope="col">{strings[lang].reports.last_camp}</th>
            <th scope="col">{strings[lang].reports.number_of_camps}</th>
            <th scope="col">{strings[lang].reports.beneficiary_cities}</th>
            <th scope="col">{strings[lang].reports.clinics}</th>
            <th scope="col">{strings[lang].reports.surgeries}</th>
            <th scope="col">{strings[lang].reports.glasses}</th>
          </tr> */}
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                <th scope="col">#</th>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}

                    <span>
                      {column.isSorted ? (
                        <i
                          className={
                            column.isSortedDesc
                              ? "fa fa-caret-down"
                              : "fa fa-caret-up"
                          }
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  <td>{i + 1}</td>
                  {row?.cells?.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* {reports.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{lang == "AR" ? item.name : item.name_EN}</td>
              <td>{item.fisrt_camp}</td>
              <td>{item.last_camp}</td>
              <td>{item.camp_count || 0}</td>
              <td>{item.cities_count || 0}</td>
              <td>{item.clinics || 0}</td>
              <td>{item.surgery || 0}</td>
              <td>{item.glasses || 0}</td>
            </tr>
          ))} */}
            {reports.length > 0 && (
              <tr className="header-tabel">
                <td colSpan={7}>{strings[lang].reports.total}</td>

                <td>
                  {reports
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue?.clinics,
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {reports
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue?.surgery,
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {reports
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue?.phaco,
                      0
                    )
                    .toLocaleString()}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
