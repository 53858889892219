import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import apiServices, { api } from "../components/api/api";

import { toast } from "react-toastify";
import strings from "../components/common/strings.json";

import logo3 from "../asset/56920.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getUserPermissions,
  setProfile,
  setToken,
  setUserPermissions,
  userToken,
} from "../store/reducers/user";
// import logo3 from "../../asset/56920.png";
export default function Login({}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector(userToken);

  if (token) {
    return <Navigate replace to={"/"} />;
  }
  return (
    <div className="row m-0 p-0">
      <div className="bg-light col-4  d-lg-flex d-none flex-column  align-items-center position-relative ">
        <img src={logo3} className="w-50 mt-5 align-self-star t m-5" alt="" />
        <p className=" fs-5 text-black-50 m-5 flex-fill">
          "Join our community of givers, log in to make a difference."
        </p>
        <p className=" align-self-start text-black-50 m-5 ">
          Copyright &copy; {new Date().getFullYear()} Albasar
        </p>
      </div>
      <div
        className="container col d-flex flex-column justify-content-center align-items-center  "
        style={{ minHeight: "100vh" }}
      >
        <img
          src={logo3}
          className="w-50 mt-1 align-self-star t m-5 d-lg-none d-block"
          alt=""
        />

        <div className=" d-flex flex-column  login-form  ">
          <span className="align-self-start mb-4 fs-3 fw-bold">
            Welcom back!
          </span>
          <p className="text-black-50">
            Please enter your credentials to sign in!
          </p>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              const token = await apiServices.login({
                username,
                password,
              });
              if (token.ok) {
                api.axiosInstance.interceptors.request.use((config) => {
                  if (token) {
                    config.headers.Authorization = `Token ${token.data.auth_token}`;
                  }
                  return config;
                });
                const user = await dispatch(getUser());
                const permissions = await dispatch(getUserPermissions());

                if (
                  user.meta.requestStatus === "fulfilled" &&
                  permissions.meta.requestStatus === "fulfilled"
                ) {
                  if (user.payload.data.isSuspended) {
                    toast.error("خطأ في الدخول");
                  } else {
                    dispatch(setToken(token.data.auth_token));
                    navigation("/", { replace: true });
                  }
                }
              } else {
                toast.error("خطأ في كلمة المرور أو أسم المستخدم");
              }

              // const result = await api.login({ username, password });
              // if (result.ok) {
              //   localStorage.setItem("token", result.data.auth_token);
              //   const { ok, data } = await api.getUser();
              //   if (ok) {
              //     if (data.isSuspended) {
              //       toast.error("خطأ في الدخول");
              //       localStorage.removeItem("token");
              //     } else {
              //       setToken(result.data.auth_token);
              //       navigation("/", { replace: true });
              //     }
              //   }
              // } else {
              //   toast.error("خطأ في كلمة المرور أو أسم المستخدم");
              // }
              setLoading(false);
            }}
          >
            <div className=" mb-3">
              <label className="mb-3" htmlFor="floatingInput">
                {strings["EN"].data.username}
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="">
              <label className="mb-3" htmlFor="floatingPassword">
                {strings["EN"].data.password}
              </label>
              <div className="  position-relative">
                <input
                  required
                  type={type}
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ paddingRight: "15%" }}
                />
                <i
                  onClick={() =>
                    setType(type == "password" ? "text" : "password")
                  }
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "90%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1000,
                    fontSize: "1.2rem",
                  }}
                  className={`fa fa-eye${type == "text" ? "-slash" : ""}`}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <button
              type="submit"
              className={`align-self-center btn btn-primary mt-4 w-100 ${
                loading ? "disabled" : ""
              }`}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {strings["EN"].data.login}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
