import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import {
  getGroupsPermissions,
  groupsPermissionsData,
} from "../store/reducers/groupsPermissions";

const useGroupsPermissionsData = () => {
  const { data, loading, error } = useSelector(groupsPermissionsData);

  const view = useHaveUserPermission("group", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getGroupsPermissions());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useGroupsPermissionsData;
