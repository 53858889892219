import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import moment from "moment";

import AutocomleteField from "../../components/common/AutoComleteField";
import TabelInputs from "../../components/stats/StatsTabelInputs";
import TotalTabel from "../../components/stats/TotalTabel";
import api from "../../components/api/api";
import DeleteModal from "../../components/common/DeleteModal";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import SearchModal from "../../components/common/SearchModal";
import NormalSearch from "../../components/common/NormalSearch";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function Stats() {
  const [planID, setPlanID] = useState();
  const [plan, setPlan] = useState();
  const [statsID, setStatsID] = useState("");
  const [campManager, setCampManager] = useState();
  const [sponsorStat, setSponsorStat] = useState("");
  const [sponsorLocal, setSponsorLocal] = useState("");
  const [surgeonManager, setSurgeonManager] = useState("");
  const [campLocation, setCampLocation] = useState("");
  const [announcement_method, setAnnouncement_method] = useState("");
  const [camp_news, setCamp_news] = useState("");
  const [vip, setVip] = useState("");
  const [Comments, setComments] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [teamManagers, setTeamManagers] = useState([]);
  const [archives, setArchives] = useState(false);
  const [inputsValue, setInputsValue] = useState({
    glasses: { male: "", female: "" },
    totalPatients: { male: "", female: "" },
    patientsSurger: { male: "", female: "" },
    phaco: { male: "", female: "" },
    iolImplant: { male: "", female: "" },
    acIol: { male: "", female: "" },
    ICCE: { male: "", female: "" },
    PTERYGIUM: { male: "", female: "" },
    CHALAZION: { male: "", female: "" },
    OTHERS: { male: "", female: "" },
    glassesChildren: { male: "", female: "" },
    totalPatientsChildren: { male: "", female: "" },
    patientsSurgerChildren: { male: "", female: "" },
    phacoChildren: { male: "", female: "" },
    iolImplantChildren: { male: "", female: "" },
    acIolChildren: { male: "", female: "" },
    ICCEChildren: { male: "", female: "" },
    PTERYGIUMChildren: { male: "", female: "" },
    CHALAZIONChildren: { male: "", female: "" },
    OTHERSChildren: { male: "", female: "" },
    vaLess: { male: "", female: "" },
    vaLessChildren: { male: "", female: "" },
    vaLarger: { male: "", female: "" },
    vaLargerChildren: { male: "", female: "" },
    icce_plan: { male: "", female: "" },
    icce_aciol: { male: "", female: "" },
    ecce: { male: "", female: "" },
    ecce_pciol: { male: "", female: "" },
    phaco_pciol: { male: "", female: "" },
    phaco_aciol: { male: "", female: "" },
    phaco_noiol: { male: "", female: "" },
  });
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [startDate, setStartDate] = useState("");
  const [sponsor, setSponsor] = useState();
  const [normalSearch, setNormalSearch] = useState(false);

  const lang = useSelector(userLang);

  const view = useHaveUserPermission("stats", "view");
  const add = useHaveUserPermission("stats", "add");
  const change = useHaveUserPermission("stats", "change");
  const deletePerm = useHaveUserPermission("stats", "delete");

  document.title = "الاحصائيات";
  const getPlan = async () => {
    const { data, ok } = await api.getPlan(planID);

    if (ok) {
      if (data.sponsor) {
        setSponsorStat(
          lang == "AR" ? data.sponsor.sponsor : data.sponsor.sponsor_EN
        );
      } else {
        setSponsorStat("");
      }
      const managers = [
        {
          EN: data.team?.manager_1_EN || "",
          AR: data.team?.manager_1 || "",
        },
        {
          EN: data.team?.manager_2_EN || "",
          AR: data.team?.manager_2 || "",
        },
        {
          EN: data.team?.manager_3_EN || "",
          AR: data.team?.manager_3 || "",
        },
      ];
      if (data.team) {
        setTeamManagers(managers.filter((i) => i.AR || i.EN));
      } else {
        setTeamManagers([]);
      }
    }
  };
  const getStats = async () => {
    if (view) {
      const result = await api.getStats(planID);

      if (result.ok) {
        const {
          glasses_male,
          glasses_female,
          glasses_children_male,
          glasses_children_female,
          totaPatients_male,
          totaPatients_female,
          totaPatients_children_male,
          totaPatients_children_female,
          patientsSurger_male,
          patientsSurger_female,
          patientsSurger_children_male,
          patientsSurger_children_female,
          phaco_male,
          phaco_female,
          phaco_children_male,
          phaco_children_female,
          iolImplant_male,
          iolImplant_female,
          iolImplant_children_male,
          iolImplant_children_female,
          acIol_male,
          acIol_female,
          acIol_children_male,
          acIol_children_female,
          icce_male,
          icce_female,
          icce_children_male,
          icce_children_female,
          pterygium_male,
          pterygium_female,
          pterygium_children_male,
          pterygium_children_female,
          chalazion_male,
          chalazion_female,
          chalazion_children_male,
          chalazion_children_female,
          others_female,
          others_male,
          others_children_female,
          others_children_male,
          camp_manager,
          camp_manager_EN,

          sponsor_local,
          surgeon_manager,
          camp_location,
          second_follow_up_date,
          first_follow_up_date,
          vaLess_female,
          vaLess_male,
          vaLarger_female,
          vaLarger_male,
          icce_plan_male,
          icce_plan_female,
          icce_aciol_male,
          icce_aciol_female,
          ecce_male,
          ecce_female,
          ecce_pciol_male,
          ecce_pciol_female,
          phaco_pciol_male,
          phaco_pciol_female,
          phaco_aciol_male,
          phaco_aciol_female,
          phaco_noiol_male,
          phaco_noiol_female,
          announcement_method,
          camp_news,
          vip,
          Comments,
          vaLess_children_female,
          vaLess_children_male,
          vaLarger_children_female,
          vaLarger_children_male,
        } = result.data;

        setCampManager({
          label: lang == "AR" ? camp_manager : camp_manager_EN,
          value: camp_manager || camp_manager_EN,
          name: { AR: camp_manager, EN: camp_manager_EN },
        });
        setSponsorLocal(sponsor_local);
        setSurgeonManager(surgeon_manager);
        setCampLocation(camp_location);
        setAnnouncement_method(announcement_method);
        setCamp_news(camp_news);
        setVip(vip);
        setComments(Comments);
        if (first_follow_up_date) setFirstDate(first_follow_up_date);
        if (second_follow_up_date) setSecondDate(second_follow_up_date);
        const statsData = {
          glasses: { male: glasses_male, female: glasses_female },
          glassesChildren: {
            male: glasses_children_male,
            female: glasses_children_female,
          },
          totalPatients: {
            male: totaPatients_male,
            female: totaPatients_female,
          },
          totalPatientsChildren: {
            male: totaPatients_children_male,
            female: totaPatients_children_female,
          },
          patientsSurger: {
            male: patientsSurger_male,
            female: patientsSurger_female,
          },
          patientsSurgerChildren: {
            male: patientsSurger_children_male,
            female: patientsSurger_children_female,
          },
          phaco: { male: phaco_male, female: phaco_female },
          phacoChildren: {
            male: phaco_children_male,
            female: phaco_children_female,
          },
          iolImplant: { male: iolImplant_male, female: iolImplant_female },
          iolImplantChildren: {
            male: iolImplant_children_male,
            female: iolImplant_children_female,
          },
          acIol: { male: acIol_male, female: acIol_female },
          acIolChildren: {
            male: acIol_children_male,
            female: acIol_children_female,
          },
          ICCE: { male: icce_male, female: icce_female },
          ICCEChildren: {
            male: icce_children_male,
            female: icce_children_female,
          },
          PTERYGIUM: { male: pterygium_male, female: pterygium_female },
          PTERYGIUMChildren: {
            male: pterygium_children_male,
            female: pterygium_children_female,
          },
          CHALAZION: { male: chalazion_male, female: chalazion_female },
          CHALAZIONChildren: {
            male: chalazion_children_male,
            female: chalazion_children_female,
          },
          OTHERS: { male: others_male, female: others_female },
          OTHERSChildren: {
            male: others_children_male,
            female: others_children_female,
          },
          vaLess: { male: vaLess_male, female: vaLess_female },
          vaLessChildren: {
            male: vaLess_children_male,
            female: vaLess_children_female,
          },

          vaLarger: { male: vaLarger_male, female: vaLarger_female },
          vaLargerChildren: {
            male: vaLarger_children_male,
            female: vaLarger_children_female,
          },

          icce_plan: { male: icce_plan_male, female: icce_plan_female },
          icce_aciol: { male: icce_aciol_male, female: icce_aciol_female },
          ecce: { male: ecce_male, female: ecce_female },
          ecce_pciol: { male: ecce_pciol_male, female: ecce_pciol_female },
          phaco_pciol: { male: phaco_pciol_male, female: phaco_pciol_female },
          phaco_aciol: { male: phaco_aciol_male, female: phaco_aciol_female },
          phaco_noiol: { male: phaco_noiol_male, female: phaco_noiol_female },
        };
        setInputsValue(statsData);
        setStatsID(planID);
      } else {
        setStatsID("");
        setInputsValue({
          glasses: { male: "", female: "" },
          totalPatients: { male: "", female: "" },
          patientsSurger: { male: "", female: "" },
          phaco: { male: "", female: "" },
          iolImplant: { male: "", female: "" },
          acIol: { male: "", female: "" },
          ICCE: { male: "", female: "" },
          PTERYGIUM: { male: "", female: "" },
          CHALAZION: { male: "", female: "" },
          OTHERS: { male: "", female: "" },
          glassesChildren: { male: "", female: "" },
          totalPatientsChildren: { male: "", female: "" },
          patientsSurgerChildren: { male: "", female: "" },
          phacoChildren: { male: "", female: "" },
          iolImplantChildren: { male: "", female: "" },
          acIolChildren: { male: "", female: "" },
          ICCEChildren: { male: "", female: "" },
          PTERYGIUMChildren: { male: "", female: "" },
          CHALAZIONChildren: { male: "", female: "" },
          OTHERSChildren: { male: "", female: "" },
          vaLess: { male: "", female: "" },
          vaLessChildren: { male: "", female: "" },
          vaLarger: { male: "", female: "" },
          vaLargerChildren: { male: "", female: "" },
          icce_plan: { male: "", female: "" },
          icce_aciol: { male: "", female: "" },
          ecce: { male: "", female: "" },
          ecce_pciol: { male: "", female: "" },
          phaco_pciol: { male: "", female: "" },
          phaco_aciol: { male: "", female: "" },
          phaco_noiol: { male: "", female: "" },
        });
        setCampManager(null);
        setSponsorLocal("");
        setSurgeonManager("");
        setCampLocation("");
        setSecondDate("");
        setFirstDate("");
        setAnnouncement_method("");
        setCamp_news("");
        setVip("");
        setComments("");
      }
    }
  };
  const createStats = async () => {
    setDisabledSave(true);
    const {
      CHALAZION,
      CHALAZIONChildren,
      ICCE,
      ICCEChildren,
      OTHERS,
      OTHERSChildren,
      PTERYGIUM,
      PTERYGIUMChildren,
      acIol,
      acIolChildren,
      glasses,
      glassesChildren,
      iolImplant,
      iolImplantChildren,
      patientsSurger,
      patientsSurgerChildren,
      phaco,
      phacoChildren,
      totalPatients,
      totalPatientsChildren,
      vaLarger,
      vaLess,
      ecce,
      ecce_pciol,
      icce_aciol,
      icce_plan,
      phaco_aciol,
      phaco_noiol,
      phaco_pciol,
      vaLargerChildren,
      vaLessChildren,
    } = inputsValue;

    const data = {
      glasses_male: glasses.male || 0,
      glasses_female: glasses.female || 0,
      glasses_children_male: glassesChildren.male || 0,
      glasses_children_female: glassesChildren.female || 0,
      totaPatients_male: totalPatients.male || 0,
      totaPatients_female: totalPatients.female || 0,
      totaPatients_children_male: totalPatientsChildren.male || 0,
      totaPatients_children_female: totalPatientsChildren.female || 0,
      patientsSurger_male: patientsSurger.male || 0,
      patientsSurger_female: patientsSurger.female || 0,
      patientsSurger_children_male: patientsSurgerChildren.male || 0,
      patientsSurger_children_female: patientsSurgerChildren.female || 0,
      phaco_male: phaco.male || 0,
      phaco_female: phaco.female || 0,
      phaco_children_male: phacoChildren.male || 0,
      phaco_children_female: phacoChildren.female || 0,
      iolImplant_male: iolImplant.male || 0,
      iolImplant_female: iolImplant.female || 0,
      iolImplant_children_male: iolImplantChildren.male || 0,
      iolImplant_children_female: iolImplantChildren.female || 0,
      acIol_male: acIol.male || 0,
      acIol_female: acIol.female || 0,
      acIol_children_male: acIolChildren.male || 0,
      acIol_children_female: acIolChildren.female || 0,
      icce_male: ICCE.male || 0,
      icce_female: ICCE.female || 0,
      icce_children_male: ICCEChildren.male || 0,
      icce_children_female: ICCEChildren.female || 0,
      pterygium_male: PTERYGIUM.male || 0,
      pterygium_female: PTERYGIUM.female || 0,
      pterygium_children_male: PTERYGIUMChildren.male || 0,
      pterygium_children_female: PTERYGIUMChildren.female || 0,
      chalazion_male: CHALAZION.male || 0,
      chalazion_female: CHALAZION.female || 0,
      chalazion_children_male: CHALAZIONChildren.male || 0,
      chalazion_children_female: CHALAZIONChildren.female || 0,
      others_female: OTHERS.female || 0,
      others_male: OTHERS.male || 0,
      others_children_female: OTHERSChildren.female || 0,
      others_children_male: OTHERSChildren.male || 0,
      vaLess_female: vaLess.female || 0,
      vaLess_male: vaLess.male || 0,
      vaLess_children_female: vaLessChildren.male || 0,
      vaLess_children_male: vaLessChildren.male || 0,
      vaLarger_female: vaLarger.female || 0,
      vaLarger_male: vaLarger.male || 0,
      vaLarger_children_female: vaLargerChildren.female || 0,
      vaLarger_children_male: vaLargerChildren.male || 0,
      icce_plan_male: icce_plan.male || 0,
      icce_plan_female: icce_plan.female || 0,
      icce_aciol_male: icce_aciol.male || 0,
      icce_aciol_female: icce_aciol.female || 0,
      ecce_male: ecce.male || 0,
      ecce_female: ecce.female || 0,
      ecce_pciol_male: ecce_pciol.male || 0,
      ecce_pciol_female: ecce_pciol.female || 0,
      phaco_pciol_male: phaco_pciol.male || 0,
      phaco_pciol_female: phaco_pciol.female || 0,
      phaco_aciol_female: phaco_aciol.female || 0,
      phaco_aciol_male: phaco_aciol.male || 0,
      phaco_noiol_male: phaco_noiol.male || 0,
      phaco_noiol_female: phaco_noiol.female || 0,

      plan: planID,
      camp_manager: campManager?.name.AR,
      camp_manager_EN: campManager?.name.EN,

      sponsor_local: sponsorLocal,
      surgeon_manager: surgeonManager,
      camp_location: campLocation,
      first_follow_up_date: firstDate || null,
      second_follow_up_date: secondDate || null,
      announcement_method,
      vip,
      camp_news,
      Comments,
    };
    const result = await api.createStats(data);

    if (result.ok) {
      toast.success("تم إنشاء الاحصائيات بنجاح");
      setStatsID(planID);
    }
    setDisabledSave(false);
  };
  const updateStats = async () => {
    setDisabledSave(true);

    const {
      CHALAZION,
      CHALAZIONChildren,
      ICCE,
      ICCEChildren,
      OTHERS,
      OTHERSChildren,
      PTERYGIUM,
      PTERYGIUMChildren,
      acIol,
      acIolChildren,
      glasses,
      glassesChildren,
      iolImplant,
      iolImplantChildren,
      patientsSurger,
      patientsSurgerChildren,
      phaco,
      phacoChildren,
      totalPatients,
      totalPatientsChildren,
      vaLarger,
      vaLess,
      ecce,
      ecce_pciol,
      icce_aciol,
      icce_plan,
      phaco_aciol,
      phaco_noiol,
      phaco_pciol,
      vaLargerChildren,
      vaLessChildren,
    } = inputsValue;
    const data = {
      glasses_male: glasses.male || 0,
      glasses_female: glasses.female || 0,
      glasses_children_male: glassesChildren.male || 0,
      glasses_children_female: glassesChildren.female || 0,
      totaPatients_male: totalPatients.male || 0,
      totaPatients_female: totalPatients.female || 0,
      totaPatients_children_male: totalPatientsChildren.male || 0,
      totaPatients_children_female: totalPatientsChildren.female || 0,
      patientsSurger_male: patientsSurger.male || 0,
      patientsSurger_female: patientsSurger.female || 0,
      patientsSurger_children_male: patientsSurgerChildren.male || 0,
      patientsSurger_children_female: patientsSurgerChildren.female || 0,
      phaco_male: phaco.male || 0,
      phaco_female: phaco.female || 0,
      phaco_children_male: phacoChildren.male || 0,
      phaco_children_female: phacoChildren.female || 0,
      iolImplant_male: iolImplant.male || 0,
      iolImplant_female: iolImplant.female || 0,
      iolImplant_children_male: iolImplantChildren.male || 0,
      iolImplant_children_female: iolImplantChildren.female || 0,
      acIol_male: acIol.male || 0,
      acIol_female: acIol.female || 0,
      acIol_children_male: acIolChildren.male || 0,
      acIol_children_female: acIolChildren.female || 0,
      icce_male: ICCE.male || 0,
      icce_female: ICCE.female || 0,
      icce_children_male: ICCEChildren.male || 0,
      icce_children_female: ICCEChildren.female || 0,
      pterygium_male: PTERYGIUM.male || 0,
      pterygium_female: PTERYGIUM.female || 0,
      pterygium_children_male: PTERYGIUMChildren.male || 0,
      pterygium_children_female: PTERYGIUMChildren.female || 0,
      chalazion_male: CHALAZION.male || 0,
      chalazion_female: CHALAZION.female || 0,
      chalazion_children_male: CHALAZIONChildren.male || 0,
      chalazion_children_female: CHALAZIONChildren.female || 0,
      others_female: OTHERS.female || 0,
      others_male: OTHERS.male || 0,
      others_children_female: OTHERSChildren.female || 0,
      others_children_male: OTHERSChildren.male || 0,
      vaLess_female: vaLess.female || 0,
      vaLess_male: vaLess.male || 0,
      vaLess_children_female: vaLessChildren.male || 0,
      vaLess_children_male: vaLessChildren.male || 0,
      vaLarger_female: vaLarger.female || 0,
      vaLarger_male: vaLarger.male || 0,
      vaLarger_children_female: vaLargerChildren.female || 0,
      vaLarger_children_male: vaLargerChildren.male || 0,
      icce_plan_male: icce_plan.male || 0,
      icce_plan_female: icce_plan.female || 0,
      icce_aciol_male: icce_aciol.male || 0,
      icce_aciol_female: icce_aciol.female || 0,
      ecce_male: ecce.male || 0,
      ecce_female: ecce.female || 0,
      ecce_pciol_male: ecce_pciol.male || 0,
      ecce_pciol_female: ecce_pciol.female || 0,
      phaco_pciol_male: phaco_pciol.male || 0,
      phaco_pciol_female: phaco_pciol.female || 0,
      phaco_aciol_female: phaco_aciol.female || 0,
      phaco_aciol_male: phaco_aciol.male || 0,
      phaco_noiol_male: phaco_noiol.male || 0,
      phaco_noiol_female: phaco_noiol.female || 0,
      plan: planID,
      camp_manager: campManager?.name.AR,
      camp_manager_EN: campManager?.name.EN,
      sponsor_local: sponsorLocal,
      surgeon_manager: surgeonManager,
      camp_location: campLocation,
      first_follow_up_date: firstDate || null,
      second_follow_up_date: secondDate || null,

      announcement_method,
      vip,
      camp_news,
      Comments,
    };
    const result = await api.updateStats(planID, data);
    if (result.ok) {
      toast.success("تم تعديل الاحصائيات بنجاح");
    }
    setDisabledSave(false);
  };
  const deleteStats = async () => {
    const result = await api.deleteStats(planID);
    if (result.ok) {
      toast.success("تم حذف الاحصائيات بنجاح");
      setStatsID("");
      setInputsValue({
        glasses: { male: "", female: "" },
        totalPatients: { male: "", female: "" },
        patientsSurger: { male: "", female: "" },
        phaco: { male: "", female: "" },
        iolImplant: { male: "", female: "" },
        acIol: { male: "", female: "" },
        ICCE: { male: "", female: "" },
        PTERYGIUM: { male: "", female: "" },
        CHALAZION: { male: "", female: "" },
        OTHERS: { male: "", female: "" },
        glassesChildren: { male: "", female: "" },
        totalPatientsChildren: { male: "", female: "" },
        patientsSurgerChildren: { male: "", female: "" },
        phacoChildren: { male: "", female: "" },
        iolImplantChildren: { male: "", female: "" },
        acIolChildren: { male: "", female: "" },
        ICCEChildren: { male: "", female: "" },
        PTERYGIUMChildren: { male: "", female: "" },
        CHALAZIONChildren: { male: "", female: "" },
        OTHERSChildren: { male: "", female: "" },
        vaLess: { male: "", female: "" },
        vaLarger: { male: "", female: "" },
        vaLessChildren: { male: "", female: "" },
        vaLargerChildren: { male: "", female: "" },
        icce_plan: { male: "", female: "" },
        icce_aciol: { male: "", female: "" },
        ecce: { male: "", female: "" },
        ecce_pciol: { male: "", female: "" },
        phaco_pciol: { male: "", female: "" },
        phaco_aciol: { male: "", female: "" },
        phaco_noiol: { male: "", female: "" },
      });
      setCampManager(null);
      setSponsorStat("");
      setSponsorLocal("");
      setSurgeonManager("");
      setCampLocation("");
      setSecondDate("");
      setFirstDate("");
      setAnnouncement_method("");
      setCamp_news("");
      setVip("");
      setComments("");
    } else {
      toast.error(strings2.error_delete[lang]);
    }
    setShowModal(false);
  };
  useEffect(() => {
    if (planID) {
      getPlan();
      getStats();
    } else {
      setStatsID("");
      setInputsValue({
        glasses: { male: "", female: "" },
        totalPatients: { male: "", female: "" },
        patientsSurger: { male: "", female: "" },
        phaco: { male: "", female: "" },
        iolImplant: { male: "", female: "" },
        acIol: { male: "", female: "" },
        ICCE: { male: "", female: "" },
        PTERYGIUM: { male: "", female: "" },
        CHALAZION: { male: "", female: "" },
        OTHERS: { male: "", female: "" },
        glassesChildren: { male: "", female: "" },
        totalPatientsChildren: { male: "", female: "" },
        patientsSurgerChildren: { male: "", female: "" },
        phacoChildren: { male: "", female: "" },
        iolImplantChildren: { male: "", female: "" },
        acIolChildren: { male: "", female: "" },
        ICCEChildren: { male: "", female: "" },
        PTERYGIUMChildren: { male: "", female: "" },
        CHALAZIONChildren: { male: "", female: "" },
        OTHERSChildren: { male: "", female: "" },
        vaLess: { male: "", female: "" },
        vaLarger: { male: "", female: "" },
        vaLessChildren: { male: "", female: "" },
        vaLargerChildren: { male: "", female: "" },
        icce_plan: { male: "", female: "" },
        icce_aciol: { male: "", female: "" },
        ecce: { male: "", female: "" },
        ecce_pciol: { male: "", female: "" },
        phaco_pciol: { male: "", female: "" },
        phaco_aciol: { male: "", female: "" },
        phaco_noiol: { male: "", female: "" },
      });
      setCampManager(null);
      setSponsorStat("");

      setSponsorLocal("");
      setSurgeonManager("");
      setCampLocation("");
      setSecondDate("");
      setFirstDate("");
      setAnnouncement_method("");
      setCamp_news("");
      setVip("");
      setComments("");
    }
  }, [planID]);

  return (
    <>
      <div
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container   mt-3 p-0 bg-light shadow pb-1  "
      >
        <div className="bg-primary d-flex p-5 ">
          <p className="fs-3 fw-bold  m-auto text-white text-center">
            {strings[lang].stats.stats}
          </p>
        </div>
        <NormalSearch
          country={country}
          setCountry={setCountry}
          city={city}
          setCity={setCity}
          sponsor={sponsor}
          setSponsor={setSponsor}
          startDate={startDate}
          setStartDate={setStartDate}
          setNormalSearch={setNormalSearch}
          setShow={setShowSearch}
        />
        {plan && (
          <div className="container px-5 mx-0">
            <div className="row  m-0 mt-3">
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control  input-bg"
                  placeholder={strings2.country[lang]}
                  value={
                    lang == "AR"
                      ? plan?.country?.country
                      : plan?.country?.country_EN
                  }
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.city[lang]}
                  value={lang == "AR" ? plan?.city?.city : plan?.city?.city_EN}
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.donor[lang]}
                  value={
                    lang == "AR"
                      ? plan.sponsor.sponsor
                      : plan.sponsor.sponsor_EN
                  }
                />
              </div>
              <div className="col-lg col-sm-12 my-2 mx-2">
                <input
                  readOnly
                  className="form-control input-bg"
                  placeholder={strings2.camp_start[lang]}
                  value={plan.camp_start_date}
                />
              </div>
            </div>
          </div>
        )}
        <div className="counatiner-fluid    ">
          <form className="row mx-0  mt-3  px-5 ">
            <div className="col-sm-12 col-lg-6  ">
              {/* <AutocomleteField
                value={plan}
                setValue={setPlan}
                getPlansOnCHangeInput
                data={plansToList()}
                title={strings[lang].stats.camp}
              /> */}
              <AutocomleteField
                value={campManager}
                setValue={setCampManager}
                data={teamManagers
                  .map((team) => {
                    if (team?.EN || team?.AR) {
                      return {
                        label: lang == "AR" ? team.AR : team.EN,
                        value: team.AR,
                        name: { AR: team.AR, EN: team.EN },
                      };
                    }
                  })
                  .filter((team) => team)}
                title={strings[lang].stats.camp_manager}
              />

              <div className="mx-2 ">
                <label className="form-label">
                  {strings[lang].stats.donor}
                </label>

                <input
                  readOnly
                  value={sponsorStat}
                  // onChange={(e) => setSponsor(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                />
              </div>
              <div className="d-lg-flex d-block w-100  ">
                <div className="w-100 my-2">
                  <label className="form-label mx-2">
                    {strings[lang].stats.first_follow_up}
                  </label>
                  <Datetime
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    onChange={(v) => {
                      if (v) {
                        setFirstDate(moment(v).format("YYYY-MM-DD"));
                      } else {
                        setFirstDate("");
                      }
                    }}
                    timeFormat={false}
                    className=" px-2 mb-0 w-100"
                    inputProps={{
                      placeholder: strings[lang].stats.choose_a_date,
                      value: firstDate,
                    }}
                  />
                </div>
                <div className="w-100 my-2">
                  <label className="form-label mx-2">
                    {strings[lang].stats.second_follow_up}
                  </label>
                  <Datetime
                    isValidDate={(current) => {
                      const yesterday = moment(firstDate).subtract(1, "day");
                      return current.isAfter(yesterday);
                    }}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    onChange={(v) => {
                      if (v) {
                        setSecondDate(moment(v).format("YYYY-MM-DD"));
                      } else {
                        setSecondDate("");
                      }
                    }}
                    className=" px-2   w-100"
                    timeFormat={false}
                    inputProps={{
                      placeholder: strings[lang].stats.choose_a_date,

                      value: secondDate,
                    }}
                  />
                </div>
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.comments}
                </label>

                <textarea
                  value={Comments}
                  onChange={(e) => setComments(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                ></textarea>
              </div>
            </div>
            <div className="col mt-0 mt-lg-2">
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.chief_surgeon}
                </label>

                <input
                  value={surgeonManager}
                  onChange={(e) => setSurgeonManager(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                />
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.camp_site}
                </label>

                <input
                  value={campLocation}
                  onChange={(e) => setCampLocation(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                />
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.local_collaborator}
                </label>

                <input
                  value={sponsorLocal}
                  onChange={(e) => setSponsorLocal(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                />
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">
                  {strings[lang].stats.advertising}
                </label>

                <textarea
                  value={announcement_method}
                  onChange={(e) => setAnnouncement_method(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                ></textarea>
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">{strings[lang].stats.news}</label>

                <textarea
                  value={camp_news}
                  onChange={(e) => setCamp_news(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                ></textarea>
              </div>
              <div className="mx-2 mb-2 ">
                <label className="form-label">{strings[lang].stats.vip}</label>

                <textarea
                  value={vip}
                  onChange={(e) => setVip(e.target.value)}
                  style={{ resize: "none" }}
                  rows={2}
                  className="form-control  p-2  "
                  placeholder={strings[lang].stats.write_here}
                ></textarea>
              </div>
            </div>
          </form>

          <div className="row mb-2 mt-3  mx-5  ">
            <div className="col-sm-12 col-lg-6  d-flex  justify-content-center">
              <button
                onClick={statsID ? updateStats : createStats}
                className={`btn btn-success ms-2 px-5 ${
                  !planID || disabledSave
                    ? "disabled"
                    : change || add
                    ? statsID && !change
                      ? "disabled"
                      : ""
                    : "disabled"
                }`}
              >
                {statsID ? strings2.change[lang] : strings2.create[lang]}
                {disabledSave && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
            <div className="col d-flex justify-content-center  mx-2">
              <button
                onClick={() => setShowModal(true)}
                className={`btn btn-danger m-lg-0 m-3   px-5 ${
                  !statsID || !deletePerm ? "disabled" : ""
                }`}
              >
                {strings[lang].stats.delete}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TabelInputs inputsValue={inputsValue} setInputsValue={setInputsValue} />
      <TotalTabel inputsValue={inputsValue} />
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        id={statsID}
        deleteMethod={deleteStats}
      />
      <SearchModal
        show={showSearch}
        setShow={setShowSearch}
        setPlanID={setPlanID}
        setPlan={setPlan}
        plan={plan}
        archives={archives}
        setArchives={setArchives}
        normalSearch={normalSearch}
        searchFields={{
          status: 2,
          stats_filter: normalSearch ? false : archives,
        }}
        dataNormalSearch={{
          country: country?.value || "",
          city: city?.value || "",
          sponsor: sponsor?.value || "",
          startDate: startDate || "",
        }}
      />
      <div className="row pb-4 mt-3  mx-5  " dir={lang == "EN" ? "ltr" : "rtl"}>
        <div className="col-sm-12 col-lg-6  d-flex  justify-content-center">
          <button
            onClick={statsID ? updateStats : createStats}
            className={`btn btn-success ms-2 px-5 ${
              !planID || disabledSave
                ? "disabled"
                : change || add
                ? statsID && !change
                  ? "disabled"
                  : ""
                : "disabled"
            }`}
          >
            {statsID ? strings2.change[lang] : strings2.create[lang]}
            {disabledSave && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
        <div className="col d-flex justify-content-center  mx-2">
          <button
            onClick={() => setShowModal(true)}
            className={`btn btn-danger m-lg-0 m-3   px-5 ${
              !statsID || !deletePerm ? "disabled" : ""
            }`}
          >
            {strings[lang].stats.delete}
          </button>
        </div>
      </div>
    </>
  );
}
