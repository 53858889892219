import React from "react";
import strings from "../../components/common/strings.json";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";

export default function MedicationFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.medicationFollowUps}
      permName={"medicationfollowup"}
      permFileName="medicationfollowupfile"
      permHistoryName={"historicalmedicationfollowup"}
      createFunc={api.createMedicationFollowUps}
      deleteFileFunc={api.deleteMedicationFollowUpsFile}
      deleteFunc={api.deleteMedicationFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.medication_follow_ups_status
          : data.medication_follow_ups_status_EN
      }
      getFilesFunc={api.getMedicationFollowUpsFile}
      getFollowUps={api.getMedicationFollowUps}
      getHistoryFunc={api.getMedicationFollowUpsHistory}
      getHistoryID={api.getMedicationFollowUpsHistoryID}
      searchPlan={{ medication: true }}
      sendFileFunc={api.sendMedicationFollowUpsFile}
      title={strings[lang].follow_up.medication_follow_up}
      updateFunc={api.updateMedicationFollowUps}
      deleteHistoryFunc={api.deleteMedicationFollowUpHistory}
    />
  );
}
