import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { writeFile, utils, write } from "xlsx";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import DeleteModal from "./DeleteModal";
import strings2 from "./strings2.json";
import api from "../api/api";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function PlanTable({
  onClickRow,
  planIdDelete,
  setPlanIdDelete,
  deleteAnnualPlan,
  plans,
  currentPage,
  pagesSize,
  deletePerm = false,
  searchData,
  sort,
  setSort,
  planId,
  search,
}) {
  const [showModal, setShowModal] = useState(false);
  const lang = useSelector(userLang);

  const deletePlan = async (id) => {
    await deleteAnnualPlan(id);

    setShowModal(false);
  };
  const downloadPDF = async () => {
    const doc = new jsPDF();
    const result = await api.searchAnnualPlan({
      ...searchData,
      ordering: sort ? `${sort.type == "Desc" ? "-" : ""}${sort.field}` : "",
    });
    if (result.ok) {
      autoTable(doc, {
        head: [
          [
            "#",
            "year",
            strings2.country["EN"],
            strings2.city["EN"],
            strings2.donor["EN"],
            strings2.camp_start["EN"],
            strings2.camp_end["EN"],
            strings2.clinics["EN"],
            strings2.surgeries["EN"],
            strings2.glasses["EN"],
          ],
        ],
        body: [
          ...result.data.map((item, index) => [
            index + 1,
            item.year,
            item?.country?.country_EN || "----",
            item?.city?.city_EN || "----",
            item?.sponsor?.sponsor_EN || "----",
            item?.camp_start_date || "----",
            item?.camp_end_date || "----",
            item.clinics || 0,
            item.surgery || 0,
            item.glasses || 0,
          ]),
        ],

        styles: { valign: "middle", halign: "center", fontStyle: "Amiri" },
        margin: { horizontal: 5, vertical: 5 },
      });
      doc.save("plans.pdf");
    }
  };
  const createExcel = async () => {
    const result = await api.searchAnnualPlan({
      ...searchData,
      ordering: sort ? `${sort.type == "Desc" ? "-" : ""}${sort.field}` : "",
    });
    if (result.ok) {
      const table = document.getElementById("tabel-data");
      const newTable = table.cloneNode(true);
      const head = newTable.childNodes[0].childNodes[0].childNodes;
      head[head.length - 1].remove();
      const row = newTable.childNodes[1].childNodes;
      row.forEach((item) => {
        const rows = item.childNodes;
        rows[rows.length - 1].remove();
      });
      if (newTable) {
        while (newTable.lastChild.firstChild) {
          newTable.lastChild.removeChild(newTable.lastChild.firstChild);
        }
      }
      result.data.forEach((item, index) => {
        const newRow = document.createElement("tr");
        const num = document.createElement("td");
        const year = document.createElement("td");
        const country = document.createElement("td");
        const city = document.createElement("td");
        const donor = document.createElement("td");
        const camp_start = document.createElement("td");
        const camp_end = document.createElement("td");
        const clinics = document.createElement("td");
        const surgeries = document.createElement("td");
        const glasses = document.createElement("td");
        num.innerText = index + 1;
        year.innerText = item.year;
        country.innerText = item?.country
          ? lang == "AR"
            ? item?.country?.country
            : item?.country?.country_EN
          : "-----";
        city.innerText = item?.city
          ? lang == "AR"
            ? item?.city?.city
            : item?.city?.city_EN
          : "-----";
        donor.innerText = item.sponsor
          ? lang == "AR"
            ? item.sponsor.sponsor
            : item.sponsor.sponsor_EN
          : "-----";
        camp_start.innerText = item.camp_start_date
          ? item.camp_start_date
          : "-----";
        camp_end.innerText = item.camp_end_date ? item.camp_end_date : "-----";
        clinics.innerText = item.clinics ? item.clinics : "-----";
        surgeries.innerText = item.surgery ? item.surgery : "-----";
        glasses.innerText = item.glasses ? item.glasses : "-----";
        newRow.appendChild(num);
        newRow.appendChild(year);
        newRow.appendChild(country);
        newRow.appendChild(city);
        newRow.appendChild(donor);
        newRow.appendChild(camp_start);
        newRow.appendChild(camp_end);
        newRow.appendChild(clinics);
        newRow.appendChild(surgeries);
        newRow.appendChild(glasses);
        newTable.lastChild.appendChild(newRow);
      });

      const wb = utils.table_to_book(newTable, { raw: true });
      writeFile(wb, "plans.xlsx");
    }
  };

  const createImg = async () => {
    const table = document.getElementById("tabel-data");
    setTimeout(async () => {
      const img = await htmlToImage.toPng(table);
      Axios.get(img, {
        responseType: "blob",
      }).then((res) => {
        fileDownload(res.data, "plans" + ".png");
      });
    }, 1500);
  };
  const tableHeader = [
    {
      Header: strings2.year_of_the_plan[lang],
      accessor: "year",
    },
    {
      Header: strings2.country[lang],
      accessor: "country",
    },
    {
      Header: strings2.city[lang],
      accessor: "city",
    },
    {
      Header: strings2.donor[lang],
      accessor: "sponsor",
    },
    {
      Header: strings2.camp_start[lang],
      accessor: "camp_start_date",
    },
    {
      Header: strings2.camp_end[lang],
      accessor: "camp_end_date",
    },
    {
      Header: strings2.clinics[lang],
      accessor: "clinics",
    },
    {
      Header: strings2.surgeries[lang],
      accessor: "surgery",
    },
    {
      Header: strings2.glasses[lang],
      accessor: "glasses",
    },
  ];
  const sortBy = async (field) => {
    if (!sort || sort.field != field) {
      setSort({ field, type: "Asce" });
    } else if (sort?.type == "Asce") {
      setSort({ ...sort, type: "Desc" });
    } else if (sort?.type == "Desc") {
      setSort("");
    }
  };
  return (
    <>
      <div
        className="table-responsive"
        style={{ backgroundColor: "#fff", position: "relative" }}
      >
        {/* <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "#000",
            zIndex: 9999999,
          }}
        /> */}
        <table
          style={{ backgroundColor: "#fff" }}
          id="tabel-data"
          className="table text-center table-hover table-striped table-bordered align-middle"
        >
          <thead className="header-tabel">
            <tr>
              <th scope="col">#</th>

              {tableHeader.map((head) => (
                <th
                  key={head.accessor}
                  onClick={() => sortBy(head.accessor)}
                  style={{ cursor: "pointer" }}
                  scope="col"
                >
                  {head.Header}
                  <span>
                    {sort && sort.field == head.accessor ? (
                      <i
                        className={
                          sort?.type == "Desc"
                            ? "fa fa-caret-down"
                            : "fa fa-caret-up"
                        }
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
              {!search && (
                <th scope="col">
                  <div className="dropdown">
                    <i
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="fa fa-print dropdown-toggle"
                      aria-hidden="true"
                    ></i>
                    <ul className="dropdown-menu">
                      <li onClick={downloadPDF}>
                        <a className="dropdown-item">PDF</a>
                      </li>
                      <li onClick={createImg}>
                        <a className="dropdown-item">Photo</a>
                      </li>
                      <li onClick={createExcel}>
                        <a className="dropdown-item">Excel</a>
                      </li>
                    </ul>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {plans.map((item, index) => (
              <tr
                // onClick={() => onClick(item)}
                key={index}
                className={planId == item.id ? "row-selected" : ""}
                style={{
                  cursor: "pointer",
                }}
              >
                <th onClick={() => onClickRow(item)} scope="row">
                  {index + 1 + (currentPage - 1) * pagesSize}
                </th>
                <td onClick={() => onClickRow(item)}>{item.year}</td>
                <td onClick={() => onClickRow(item)}>
                  {item?.country
                    ? lang == "AR"
                      ? item?.country?.country
                      : item?.country?.country_EN
                    : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item?.city
                    ? lang == "AR"
                      ? item?.city?.city
                      : item?.city?.city_EN
                    : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.sponsor
                    ? lang == "AR"
                      ? item.sponsor.sponsor
                      : item.sponsor.sponsor_EN
                    : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.camp_start_date ? item.camp_start_date : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.camp_end_date ? item.camp_end_date : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.clinics ? item.clinics : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.surgery ? item.surgery : "-----"}
                </td>
                <td onClick={() => onClickRow(item)}>
                  {item.glasses ? item.glasses : "-----"}
                </td>
                {!search && (
                  <td>
                    <p
                      className={`mb-0  btn
                    ${planId == item.id ? "btn-danger" : "btn-outline-danger"}
                     ${!deletePerm ? "disabled" : ""}`}
                      onClick={() => {
                        setPlanIdDelete(item.id);
                        setShowModal(true);
                      }}
                    >
                      {strings2.delete[lang]}
                    </p>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!search && (
        <DeleteModal
          show={showModal}
          setShow={setShowModal}
          id={planIdDelete}
          deleteMethod={deletePlan}
        />
      )}
    </>
  );
}
