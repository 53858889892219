import React from "react";
import ReactPaginate from "react-paginate";

export default function PaginationBar({ pageCount, setPage, page }) {
  return (
    <ReactPaginate
      forcePage={page ? page - 1 : 0}
      breakLabel="⋯"
      breakClassName="page-item disabled"
      breakLinkClassName="page-link"
      nextLabel={<span aria-hidden="true">&raquo;</span>}
      previousLabel={<span aria-hidden="true">&laquo;</span>}
      onPageChange={(p) => {
        setPage(p.selected + 1);
      }}
      nextClassName="page-item"
      nextLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      containerClassName="pagination mb-0"
      pageClassName="page-item"
      activeClassName="active"
      pageLinkClassName="page-link"
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
    />
  );
}
