import React from "react";
import strings from "../../components/common/strings.json";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";

export default function SponsorsFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.sponsorsFollowUps}
      permName={"sponsorsfollowup"}
      permFileName="sponsorsfollowupfile"
      permHistoryName={"historicalsponsorsfollowup"}
      createFunc={api.createSponsorsFollowUps}
      deleteFileFunc={api.deleteSponsorsFollowUpsFile}
      deleteFunc={api.deleteSponsorsFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.supporters_follow_ups_status
          : data.supporters_follow_ups_status_EN
      }
      getFilesFunc={api.getSponsorsFollowUpsFile}
      getFollowUps={api.getSponsorsFollowUps}
      getHistoryFunc={api.getSponsorsFollowUpsHistory}
      getHistoryID={api.getSponsorsFollowUpsHistoryID}
      searchPlan={{ sponsors: true }}
      sendFileFunc={api.sendSponsorsFollowUpsFile}
      title={strings[lang].follow_up.sponsors_follow_up}
      updateFunc={api.updateSponsorsFollowUps}
      deleteHistoryFunc={api.deleteSponsorsFollowUpHistory}
    />
  );
}
