import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import AutoComleteField from "../components/common/AutoComleteField";
import api from "../components/api/api";
import UsersTabel from "../components/users/UsersTabel";
import DeleteModal from "../components/common/DeleteModal";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useHospitalsCountriesData from "../hooks/useHospitalsCountriesData";
import useTeamsData from "../hooks/useTeamsData";
import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import useGroupsPermissionsData from "../hooks/useGroupsPermissionsData";
export default function UsersManagement() {
  const [groups, setGroups] = useState([]);
  const [countries, setCountries] = useState([]);

  const [hospitalsSelected, setHospitalsSelected] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [teams, setTeams] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [deleteID, setDeleteID] = useState("");
  const [userID, setUserID] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [disableCreate, setDisableCreate] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [disableBlock, setDisableBlock] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("password");
  const [users, setUsers] = useState();

  const { data: teamList } = useTeamsData();
  const { data: groupsPermissions } = useGroupsPermissionsData();
  const { data: hosiptalsCountries } = useHospitalsCountriesData();

  const lang = useSelector(userLang);

  const view = useHaveUserPermission("users", "view");
  const add = useHaveUserPermission("users", "add");
  const change = useHaveUserPermission("users", "change");
  const deletePerm = useHaveUserPermission("users", "delete");
  // const getUsersGroupsPermissionsList = async (id) => {
  //   const { data, ok } = await api.getUsersGroupsPermissions(id);
  //   if (ok) {
  //     setGroups(
  //       data.map((item) => ({
  //         value: item.id,
  //         label: `${item.name}`,
  //       }))
  //     );
  //   }
  // };
  const getUsers = async () => {
    if (view) {
      const { data, ok } = await api.getUsers();
      if (ok) {
        setUsers(data);
      }
    }
  };
  const deleteUser = async (id) => {
    const { ok, data } = await api.deleteUser(deleteID);
    if (ok) {
      const newUsers = users.filter((user) => user.id != id);
      setUsers(newUsers);
      setShow(false);
      setFirstName("");
      setLastName("");
      setUsername("");
      setEmail("");
      setUserID("");
      setPassword("");
      setTeams([]);
      setIsActive(false);
      setGroups([]);
    }
  };
  const createUser = async () => {
    setDisableCreate(true);

    const userInfo = {
      email,
      username,

      first_name: firstName || "",
      last_name: lastName || "",
      is_active: isActive,
      password,
      teams: teams?.map((item) => item.value),
      groups: groups.length ? groups.map((i) => i.value) : [],
      hospitals: [],
      // hospitals_cities: [],
      hospitals_countries: [],
    };
    const { ok, data } = await api.createUsers(userInfo);

    if (ok) {
      toast.success("تم إنشاء المستخدم بنجاح");
      if (groups.length || teams.length) {
        await api.updateUser(data.id, userInfo);
      }

      // if (groups.length ) {
      //   await api.updateUserGroups(data.id, {
      //     groups: groups.map((i) => i.value),
      //   });
      // }
      // if (teams.length) {
      //   const res = await api.updateUserTeams(data.id, {
      //     teams: teams?.map((item) => item.value),
      //   });
      // }
      getUsers();
    } else {
      for (let err in data) {
        data[err].forEach((error) => {
          toast.error(error);
        });
      }
    }
    setDisableCreate(false);
  };
  const updateUser = async () => {
    setDisableUpdate(true);
    const userInfo = {
      email,
      username,

      first_name: firstName || "",
      last_name: lastName || "",
      is_active: isActive,
      password,
      teams: teams?.map((item) => item.value),
      groups: groups.length ? groups.map((i) => i.value) : [],
      hospitals: hospitalsSelected.length
        ? hospitalsSelected.map((i) => i.value)
        : [],
      // hospitals_cities: citiesSelected.length
      //   ? citiesSelected.map((i) => i.value)
      //   : [],
      hospitals_countries: countries.length
        ? countries.map((i) => i.value)
        : [],
    };
    const { ok, data, status } = await api.updateUser(userID, userInfo);
    if (ok) {
      getUsers();
      toast.success("تم تعديل المستخدم بنجاح");
    }
    // if (groups.length) {
    //   const { ok, data } = await api.usersGroupsPermissions(userID, {
    //     groups: groups.map((i) => i.value),
    //   });
    // } else {
    //   const { ok, data } = await api.usersGroupsPermissions(userID, {
    //     groups: [],
    //   });
    // }
    setDisableUpdate(false);
  };

  useEffect(() => {
    document.title = "المستخدمين";
    // getUsersGroupsPermissionsList();
    getUsers();
  }, []);
  const onClickRow = (item) => {
    setDisableBlock(item.is_superuser ? true : false);
    setFirstName(item.first_name);
    setLastName(item.last_name);
    setUsername(item.username);
    setEmail(item.email);
    setUserID(item.id);
    setIsActive(item.is_active);
    setCountriesAndGetCities(
      item.hospitals_countries.map((i) => ({
        value: i.id,
        label: lang == "AR" ? i.country : i.country_EN,
      }))
    );
    setHospitalsSelected(
      item.hospitals.map((i) => ({
        value: i.id,
        label: lang == "AR" ? i.hospital : i.hospital_EN,
      }))
    );
    setPassword("");
    setGroups(
      item.groups.map((i) => ({
        value: i.id,
        label: lang == "AR" ? i.name : i.name_EN,
      }))
    );
    setTeams(
      item.teams.map((i) => ({
        value: i.id,
        label: lang == "AR" ? i.team : i.team_EN,
      }))
    );
  };

  const setCountriesAndGetCities = async (values) => {
    setCountries(values);
    if (values.length) {
      const { ok, data } = await api.getHospitals(values.map((i) => i.value));
      if (ok) {
        setHospitals(data);
      }
    } else {
      setHospitals([]);
    }
  };
  const deleteFields = () => {
    setShow(false);
    setFirstName("");
    setLastName("");
    setUsername("");
    setEmail("");
    setUserID("");
    setPassword("");
    setTeams([]);
    setIsActive(false);
    setGroups([]);
    setHospitalsSelected([]);
    setHospitalsSelected([]);
    setCountries([]);
    setHospitals([]);
  };
  const isSuperUser = users?.filter((user) => user.id == userID)[0]
    ?.is_superuser;
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-1  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.users}
        </p>
      </div>
      <div className="counatiner-fluid    ">
        <form className="row mx-0 mt-3  px-5 ">
          <div className="col-sm-12 col-lg-6  ">
            <div className="d-flex w-100">
              <div className="mx-2 mb-2 w-100">
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].data.first_name}
                />
              </div>
              <div className="mx-2 mb-2 w-100">
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-control  p-2  "
                  placeholder={strings[lang].data.last_name}
                />
              </div>
            </div>

            <div className="mx-2 mb-2 ">
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings[lang].data.username}
              />
            </div>
            <AutoComleteField
              closeMenuOnSelect={false}
              value={groups}
              setValue={setGroups}
              isMulti
              data={groupsPermissions.map((item) => ({
                label: lang == "AR" ? item.name : item.name_EN,
                value: item.value,
              }))}
              placeholder={strings[lang].data.groups}
            />
          </div>
          <div className="col ">
            <div className="mx-2 mb-2 ">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings[lang].data.email}
              />
            </div>
            <div className="mx-2 mb-2  position-relative">
              <input
                autoComplete="new-password"
                list="autocompleteOff"
                id="passwords"
                type={type}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings[lang].data.password}
              />
              <i
                onClick={() =>
                  setType(type == "password" ? "text" : "password")
                }
                style={{
                  position: "absolute",
                  top: "50%",
                  left: lang == "AR" ? 20 : "93%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  zIndex: 1000,
                  fontSize: "1.2rem",
                }}
                className={`fa fa-eye${type == "text" ? "-slash" : ""}`}
                aria-hidden="true"
              ></i>
            </div>

            <div
              className="form-check mx-2 mb-2  d-flex align-items-center "
              style={{ height: 50 }}
            >
              <input
                disabled={disableBlock}
                onChange={(e) => setIsActive(e.target.checked)}
                className="form-check-input float-none ms-0 "
                type="checkbox"
                checked={isActive}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label mx-2"
                htmlFor="flexCheckDefault"
              >
                {strings[lang].data.active}
              </label>
            </div>
          </div>
        </form>
        <div className="d-flex justify-content-center align-items-center px-5">
          <hr className="w-100" />
          <span className="fs-5  mx-3">{strings2.camps[lang]}</span>{" "}
          <hr className="w-100" />
        </div>
        <div className="  row mx-0 my-3  px-5">
          <div className="col-lg-6 col-12">
            <AutoComleteField
              closeMenuOnSelect={false}
              value={teams}
              setValue={setTeams}
              isMulti
              data={teamList.map((item) => ({
                label: lang == "AR" ? item.team : item.team_EN,
                value: item.id,
              }))}
              placeholder={strings[lang].data.teams}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center px-5">
          <hr className="w-100" />
          <span className="fs-5 mx-3">{strings2.Hospitals[lang]}</span>
          <hr className="w-100" />
        </div>
        <div className="  row mx-0 mt-3  px-5">
          <div className="col-lg-6 col-12">
            <AutoComleteField
              closeMenuOnSelect={false}
              value={countries}
              setValue={setCountriesAndGetCities}
              isMulti
              data={hosiptalsCountries.map((item) => ({
                label: lang == "AR" ? item.country : item.country_EN,
                value: item.id,
              }))}
              placeholder={"الدول"}
            />
          </div>
          <div className="col-lg-6 col-12">
            <AutoComleteField
              closeMenuOnSelect={false}
              value={hospitalsSelected}
              setValue={setHospitalsSelected}
              isMulti
              data={hospitals.map((item) => ({
                label: lang == "AR" ? item.hospital : item.hospital_EN,
                value: item.id,
              }))}
              placeholder={"المستشفيات"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center my-5">
        <button
          onClick={createUser}
          className={`btn btn-success ${
            !username || !password || !email || !add ? "disabled" : ""
          }`}
        >
          {strings[lang].data.create}
          {disableCreate && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <button
          onClick={updateUser}
          className={`btn btn-success mx-2 ${
            disableUpdate || !userID || !change || isSuperUser ? "disabled" : ""
          }`}
        >
          {strings[lang].data.save}
          {disableUpdate && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <button onClick={deleteFields} className={`btn btn-success mx-2 `}>
          {strings[lang].common.Delete_fields}
        </button>
      </div>
      <UsersTabel
        setDeleteID={setDeleteID}
        setShow={setShow}
        onClickRow={onClickRow}
        users={users}
        deletePerm={deletePerm}
        strings={strings}
        lang={lang}
        userID={userID}
      />
      <DeleteModal
        setShow={setShow}
        show={show}
        deleteMethod={deleteUser}
        id={deleteID}
      />
    </div>
  );
}
