import React, { useEffect, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";

import PlanTable from "../components/common/PlanTable";
import {
  hideLoading,
  showLoading,
} from "../components/common/loadingFunctions";

import HistoriesModal from "../components/plan/HistoriesModal";
import strings2 from "../components/common/strings2.json";

import PlanFields from "../components/common/PlanFields";
import UpdateModal from "../components/common/UpdateModal";
import ConfirmModal from "../components/common/ConfirmModal";
import useHaveUserPermission from "../hooks/useHaveUserPermission";

import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import {
  useCreatePlanApiMutation,
  useDeletePlanApiMutation,
  useGetAnnualPlanHistoriesQuery,
  useGetPlansQuery,
  useUpdatePlanApiMutation,
} from "../store/services/plan";

import PaginationBar from "../components/common/PaginationBar";
import { useForm } from "react-hook-form";

export default function Plan() {
  const [showHistoriesModal, setShowHistoriesModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreatemModal, setShowCreateModal] = useState(false);
  const [planId, setPlanId] = useState();
  const [planIdDelete, setPlanIdDelete] = useState();

  const [page, setPage] = useState(1);
  const [pagesSize, setPagesSize] = useState(20);
  const [sort, setSort] = useState("");
  const [searchData, setSearchData] = useState({});

  const lang = useSelector(userLang);
  const formData = useForm({
    defaultValues: {
      city: null,
      country: null,
      year: "",
      team: null,
      status: null,
      responsible: null,
      remark: "",
      sponsor: null,
      sponsorship: null,
      reference_number: "",
      project_number: "",
      dateFrom: "",
      dateTo: "",
      visitChecked: false,
      financialChecked: false,
      financialsectorChecked: false,
      sponsorChecked: false,
      mediaChecked: false,
      preparingtheteamChecked: false,
      medicationChecked: false,
    },
  });

  const planData = () => {
    const {
      city,
      country,
      year,
      team,
      status,
      responsible,
      remark,
      sponsor,
      sponsorship,
      visitChecked,
      financialChecked,
      financialsectorChecked,
      sponsorChecked,
      mediaChecked,
      preparingtheteamChecked,
      medicationChecked,
      reference_number,
      project_number,
      dateFrom,
      dateTo,
    } = formData.getValues();

    return {
      year: year,
      country: country?.value,
      city: city?.value,
      team: team?.value ? team?.value : "",
      status: status?.value,
      responsible: responsible?.value ? responsible?.value : "",
      camp_start_date: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
      camp_end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : null,
      remark: remark ? remark : "",
      sponsor: sponsor?.value ? sponsor?.value : "",
      sponsorship_new: sponsorship
        ? sponsorship.map((value) => value.value)
        : [],
      visit: visitChecked,
      financial: financialChecked,
      financialsector: financialsectorChecked,
      sponsors: sponsorChecked,
      media: mediaChecked,
      preparingtheteam: preparingtheteamChecked,

      medication: medicationChecked,
      reference_number: reference_number || "",
      project_number: project_number || "",
    };
  };

  const { data: plans, isFetching: isFetchingPlans } = useGetPlansQuery({
    ...searchData,
    page,
    page_size: pagesSize,
    ordering: sort ? `${sort.type == "Desc" ? "-" : ""}${sort.field}` : "",
  });
  const { data: historiesData } = useGetAnnualPlanHistoriesQuery(planId, {
    skip: !planId || !showHistoriesModal,
  });
  const [deletePlan] = useDeletePlanApiMutation();

  const [createPlan, { isLoading: isLoadingCreatePlan }] =
    useCreatePlanApiMutation();

  const [updatePlanApi, { isLoading: isLoadingUpdatePlan }] =
    useUpdatePlanApiMutation();

  const deletePerm = useHaveUserPermission("annualplan", "delete");

  const onClickRow = async (item) => {
    showLoading();
    const { reset } = formData;
    reset({
      year: item.year,
      dateFrom: item.camp_start_date ? item.camp_start_date : "",
      dateTo: item.camp_end_date ? item.camp_end_date : "",
      remark: item.remark ? item.remark : "",
      country: item?.country
        ? {
            label:
              lang == "AR" ? item.country.country : item.country.country_EN,
            value: item.country.id,
          }
        : null,
      sponsor: item?.sponsor
        ? {
            label:
              lang == "AR" ? item.sponsor.sponsor : item.sponsor.sponsor_EN,
            value: item.sponsor.id,
          }
        : null,
      responsible: item.responsible
        ? {
            value: item.responsible.id,
            label:
              lang == "AR"
                ? item.responsible.responsible
                : item.responsible.responsible_EN,
          }
        : null,
      team: item?.team
        ? {
            label: lang == "AR" ? item.team.team : item.team.team_EN,
            value: item.team.id,
          }
        : null,
      status: item.status
        ? {
            value: item.status.id,
            label: lang == "AR" ? item.status.status : item.status.status_EN,
          }
        : null,
      reference_number: item.reference_number || "",
      project_number: item.project_number || "",
      city: item?.city
        ? {
            value: item.city.id,
            label: lang == "AR" ? item.city.city : item.city.city_EN,
          }
        : null,
      sponsorship: item?.sponsorship_new
        ? item?.sponsorship_new.map((value) => ({
            value: value.id,
            label: lang == "AR" ? value.sponsorship : value.sponsorship_EN,
          }))
        : null,
      financialChecked: item?.financial,
      visitChecked: item?.visit,
      financialsectorChecked: item?.financialsector,
      medicationChecked: item?.medication,
      sponsorChecked: item?.sponsors,
      mediaChecked: item?.media,
      preparingtheteamChecked: item?.preparingtheteam,
    });

    setPlanId(item.id);

    hideLoading();
  };

  const deleteFields = () => {
    formData.reset({
      city: null,
      country: null,
      year: "",
      team: null,
      status: null,
      responsible: null,
      remark: "",
      sponsor: null,
      sponsorship: null,
      reference_number: "",
      project_number: "",
      dateFrom: "",
      dateTo: "",
      visitChecked: false,
      financialChecked: false,
      financialsectorChecked: false,
      sponsorChecked: false,
      mediaChecked: false,
      preparingtheteamChecked: false,
      medicationChecked: false,
    });
    setPlanId("");
  };
  const createAnnualPlan = async () => {
    setPage(1);
    createPlan(planData());
    toast.success("تم أضافة الخطة بنجاح");
  };
  const updatePlan = async (modificationReason) => {
    updatePlanApi({
      id: planId,
      data: { ...planData(), modification_reason: modificationReason },
    });
    toast.success("تم التعديل بنجاح");
  };

  const deleteAnnualPlan = async (id) => {
    deletePlan(id);
    toast.success("تم حذف الخطة بنجاح");
    deleteFields();
  };

  useEffect(() => {
    document.title = strings2.annual_plan[lang];
  }, []);

  const selectPageSize = async (e) => {
    setPagesSize(e.target.value || 20);
    setPage(1);
  };
  return (
    <>
      <div
        dir={lang == "EN" ? "lrt" : "rtl"}
        className="container   mt-3 p-0 bg-light shadow pb-1  "
      >
        <div className="bg-success d-flex p-5 ">
          <p className="fs-3 fw-bold  m-auto text-white text-center">
            {strings2.annual_plan[lang]}
          </p>
        </div>

        <PlanFields
          formDate={formData}
          planId={planId}
          isFetchingPlans={isFetchingPlans}
          setShowUpdateModal={setShowUpdateModal}
          setSearchData={() => {
            setPage(1);
            setSearchData(planData());
          }}
          deleteFields={deleteFields}
          setShowCreateModal={setShowCreateModal}
          setShowHistoriesModal={setShowHistoriesModal}
          isLoadingCreatePlan={isLoadingCreatePlan}
          isLoadingUpdatePlan={isLoadingUpdatePlan}
        />

        <div
          style={{
            minHeight: 50,
          }}
          className="d-flex align-items-center flex-column flex-lg-row justify-content-between mx-3 mb-2 position-relative"
        >
          {!!plans?.count && plans?.count > pagesSize && (
            <>
              <div dir={lang == "EN" ? "lrt" : "rtl"}>
                <PaginationBar
                  pageCount={Math.ceil(plans?.count / pagesSize)}
                  setPage={setPage}
                  page={page}
                />
              </div>
              <select
                style={{ height: 37, maxWidth: 75 }}
                className=" form-select form-select-sm"
                aria-label="Default select example"
                onChange={async (e) => {
                  selectPageSize(e);
                }}
                defaultValue={""}
              >
                {/* <option value="">{strings2.number_of_items[lang]}</option> */}
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </>
          )}
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
              fontSize: 18,
            }}
            className="mb-0"
          >
            <span className="ms-2">{strings2.number_of_camps[lang]}</span>
            <span className="badge bg-primary mb-0">{plans?.count}</span>
          </p>
        </div>
        <PlanTable
          planId={planId}
          onClickRow={onClickRow}
          planIdDelete={planIdDelete}
          setPlanIdDelete={setPlanIdDelete}
          deleteAnnualPlan={deleteAnnualPlan}
          plans={plans?.results ?? []}
          pagesSize={pagesSize}
          currentPage={page}
          deletePerm={deletePerm}
          sort={sort}
          setSort={setSort}
          searchData={searchData}
        />
        {!!plans?.count && plans?.count > pagesSize && (
          <div dir={lang == "EN" ? "lrt" : "rtl"}>
            <PaginationBar
              pageCount={Math.ceil(plans?.count / pagesSize)}
              setPage={setPage}
              page={page}
            />
          </div>
        )}
      </div>
      <HistoriesModal
        histoies={historiesData ?? []}
        setShow={setShowHistoriesModal}
        show={showHistoriesModal}
      />
      <UpdateModal
        updateMethod={updatePlan}
        show={showUpdateModal}
        setShow={setShowUpdateModal}
      />
      <ConfirmModal
        setShow={setShowCreateModal}
        show={showCreatemModal}
        confirmMethod={createAnnualPlan}
        title={strings2.comfirm_msg_camps[lang]}
      />
    </>
  );
}
