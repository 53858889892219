import React from "react";
import moment from "moment";
import queryString from "query-string";

import strings2 from "../common/strings2.json";
import strings from "../common/strings.json";
import data from "../common/data.json";

import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import api from "../api/api";
import { useEffect } from "react";
import PaginationBar from "../common/PaginationBar";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function FollowUpHistoriesModal({ show, setShow, followUpID }) {
  const [histories, setHistories] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const lang = useSelector(userLang);

  const findLabel = (data, value) => {
    return data.find((item) => item.value == value)?.label;
  };

  const statusMapper = {
    medication_follow_up:
      lang == "AR"
        ? data.medication_follow_ups_status
        : data.medication_follow_ups_status_EN,
    financial_follow_up:
      lang == "AR"
        ? data.financial_follow_ups_status
        : data.financial_follow_ups_status_EN,
    sponsors_follow_up:
      lang == "AR"
        ? data.supporters_follow_ups_status
        : data.supporters_follow_ups_status_EN,
    media_follow_up:
      lang == "AR"
        ? data.media_follow_ups_status
        : data.media_follow_ups_status_EN,
    preparatory_visit:
      lang == "AR"
        ? data.Preparatory_visit_follow_ups_status
        : data.Preparatory_visit_follow_ups_status_EN,
    financial_sector_follow_up:
      lang == "AR"
        ? data.financial_sector_follow_ups_status
        : data.financial_sector_follow_ups_status_EN,
    preparing_the_team_follow_up:
      lang == "AR" ? data.preparing_the_team : data.preparing_the_team_EN,
  };

  const findStatusText = (data, value) => {
    return data.find((status) => status.value === value).label;
  };

  const getAnnualPlanHistories = async () => {
    const { data, ok } = await api.getFolowUpHistory(followUpID, page);
    if (ok) setHistories(data.results);

    setCount(data.count);
  };

  useEffect(() => {
    if (show) {
      getAnnualPlanHistories();
    } else {
      setHistories([]);
    }
  }, [show, page]);

  return (
    <Modal
      fullscreen
      scrollable
      dir={lang == "AR" ? "rtl" : "ltr"}
      size="xl"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {count > 20 && <PaginationBar setPage={setPage} pageCount={20} />}
        <div className="table-responsive">
          <table className="table text-center table-hover table-striped table-bordered align-middle">
            <thead>
              <tr>
                <th scope="col">{strings2.followUp[lang]}</th>
                <th scope="col">{strings[lang].follow_up.stage}</th>
                <th scope="col">{strings[lang].follow_up.stage_end_date}</th>
                <th scope="col">{strings2.finished[lang]}</th>
                <th scope="col">{strings[lang].follow_up.remark}</th>
                <th scope="col">{strings2.user[lang]}</th>
                <th scope="col">{strings2.modification_date[lang]}</th>
                <th scope="col">{strings2.modificationType[lang]}</th>
              </tr>
            </thead>
            <tbody>
              {histories.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{strings[lang].follow_up[item.followUp]}</td>
                    <td>
                      {findStatusText(statusMapper[item.followUp], item.stage)}
                    </td>
                    <td>{item.end_date}</td>
                    <td>
                      <i
                        className={`fa ${
                          item.finished
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>{item.remark}</td>
                    <td>{item.history_user}</td>
                    <td>
                      {moment(item.history_date).format("YYYY-MM-DD hh:mm a")}
                    </td>
                    <td>{strings2[item.history_type][lang]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {count > 20 && <PaginationBar setPage={setPage} pageCount={20} />}
      </Modal.Body>
    </Modal>
  );
}
