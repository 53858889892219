import React from "react";

export default function UsersTabel({
  setDeleteID,
  onClickRow,
  setShow,
  users = [],
  deletePerm,
  strings,
  lang,
  userID,
}) {
  return (
    <div className="table-responsive">
      <table className="table text-center table-hover table-striped table-bordered align-middle">
        <thead className="header-tabel">
          <tr>
            <th scope="col">#</th>
            <th scope="col">{strings[lang].data.first_name}</th>
            <th scope="col">{strings[lang].data.last_name}</th>
            <th scope="col">{strings[lang].data.username}</th>
            <th scope="col">{strings[lang].data.email}</th>
            <th scope="col">{strings[lang].data.groups}</th>

            <th scope="col">{strings[lang].data.active}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, index) => (
            <tr
              onClick={() => onClickRow(item)}
              key={index}
              style={{ cursor: "pointer" }}
              className={userID == item.id ? "row-selected" : ""}
            >
              <th scope="row">{index + 1}</th>
              <td>{item.first_name || "-----"}</td>
              <td>{item.last_name || "-----"}</td>
              <td>{item.username}</td>
              <td>{item.email}</td>
              <td>
                {item.groups.map((item) => (
                  <p key={item.id}>{item.name}</p>
                ))}
              </td>
              <td>
                <i
                  className={`fa ${
                    item.is_active
                      ? "fa-check-circle text-success"
                      : "fa-times-circle text-danger"
                  } `}
                  aria-hidden="true"
                ></i>
              </td>
              {!item.is_superuser && (
                <td>
                  <p
                    className={`mb-0  btn btn-outline-danger ${
                      !deletePerm ? "disabled" : ""
                    }`}
                    onClick={() => {
                      setDeleteID(item.id);
                      setShow(true);
                    }}
                  >
                    {strings[lang].data.delete}
                  </p>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
