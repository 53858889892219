import React, { useEffect, useState } from "react";
import strings from "../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function FollowUpTabel({
  setInputsValue,
  inputsValue,
  fields = [],
}) {
  const [total, setTotal] = useState(0);
  const lang = useSelector(userLang);

  useEffect(() => {
    let totalValue = 0;
    fields.map((item) => {
      totalValue +=
        Number(inputsValue[item.valueAdults]?.female) +
        Number(inputsValue[item.valueAdults]?.male);
    });
    setTotal(totalValue);
  }, [inputsValue]);
  return (
    <div className="container table-responsive p-0" dir="rtl">
      <table
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container table table-hover table-striped  table-bordered text-center align-middle"
      >
        <thead className="header-tabel">
          <tr>
            <th scope="col">{strings[lang].stats.procedure}</th>
            <th scope="col">{strings[lang].stats.male}</th>

            <th scope="col">{strings[lang].stats.female}</th>
            <th scope="col">{strings[lang].stats.total}</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const value = { ...inputsValue };
            return (
              <tr key={index}>
                {/* <th scope="row">{index + 1}</th> */}
                <td>
                  <p>{item.action_AR}</p>
                  <p>{item.action_EN}</p>
                </td>
                <td>
                  {/* ----------------male Adults-------------- */}
                  <input
                    style={{ minWidth: 75 }}
                    value={value[item.valueAdults]?.male}
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                      value[item.valueAdults].male = Number(e.target.value);
                      setInputsValue(value);
                    }}
                    className="form-control  p-2 text-center "
                    placeholder={strings[lang].stats.number}
                    type={"number"}
                    min={0}
                  />
                </td>
                <td>
                  {/* -----------female Adults------------ */}
                  <input
                    style={{ minWidth: 75 }}
                    value={value[item.valueAdults]?.female}
                    onWheel={(event) => event.currentTarget.blur()}
                    onChange={(e) => {
                      value[item.valueAdults].female = Number(e.target.value);
                      setInputsValue(value);
                    }}
                    className="form-control  p-2 text-center "
                    placeholder={strings[lang].stats.number}
                    type={"number"}
                    min={0}
                  />
                </td>
                <td>
                  {Number(inputsValue[item.valueAdults]?.female) +
                    Number(inputsValue[item.valueAdults]?.male) || 0}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4}>
              <textarea
                value={inputsValue.followUpRemark}
                onChange={(e) =>
                  setInputsValue((value) => ({
                    ...value,
                    followUpRemark: e.target.value,
                  }))
                }
                style={{ resize: "none" }}
                rows={2}
                className="form-control  p-2  "
                placeholder={strings[lang].common.remark}
              ></textarea>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <span>{strings[lang].stats.total}</span>
              <span className="badge text-bg-primary mx-2 fs-6">
                {Number(total) || 0}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
