import React from "react";
import strings from "../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function ColorTabel() {
  const lang = useSelector(userLang);

  return (
    <div className="mx-2 mt-2">
      <table
        className="table table-striped  table-bordered text-center align-middle "
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <tbody>
          <tr>
            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-success`}
              ></div>
            </td>
            <td>{strings[lang].reports.finished}</td>
          </tr>
          <tr>
            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-primary`}
              ></div>
            </td>
            <td>{strings[lang].reports.going_normally}</td>
          </tr>
          <tr>
            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-warning`}
              ></div>
            </td>
            <td>{strings[lang].reports.expired}</td>
          </tr>

          <tr>
            <td>
              <div
                style={{ padding: 2, width: 30 }}
                className={` bg-danger`}
              ></div>
            </td>
            <td>{strings[lang].reports.expired_48}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
