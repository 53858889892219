import React from "react";
import moment from "moment";
import Datetime from "react-datetime";

import strings2 from "./strings2.json";
import { useController } from "react-hook-form";
import Autocomlete from "./AutoComlete";
import { useGetCitiesQuery } from "../../store/services/planCities";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useGetSponsorshipsQuery } from "../../store/services/sponsorship";
import useCountriesData from "../../hooks/useCountriesData";
import useTeamsData from "../../hooks/useTeamsData";
import useSponsorsData from "../../hooks/useSponsorsData";
import useResponsibleData from "../../hooks/useResponsibleData";
import useStatusData from "../../hooks/useStatusData";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function SearchModalFields({
  formData: { register, watch, control, handleSubmit, setValue },
}) {
  const citiesView = useHaveUserPermission("cities", "view");
  const sponsorshipView = useHaveUserPermission("sponsorship", "view");

  const lang = useSelector(userLang);

  const { field: yearField } = useController({
    name: "year",
    control,
  });
  const { field: dateFrom } = useController({
    name: "dateFrom",
    control,
  });
  const { field: dateTo } = useController({
    name: "dateTo",
    control,
  });

  const { data: cities } = useGetCitiesQuery(watch("country")?.value, {
    skip: !watch("country") && citiesView,
    selectFromResult: (data) => {
      return watch("country")?.value ? data : [];
    },
  });

  const { data: sponsorships } = useGetSponsorshipsQuery(
    watch("sponsor")?.value,
    {
      skip: !watch("sponsor") && sponsorshipView,
      selectFromResult: (data) => {
        return watch("sponsor")?.value ? data : [];
      },
    }
  );
  const { data: countries } = useCountriesData();
  const { data: teamList } = useTeamsData();
  const { data: sponsors } = useSponsorsData();
  const { data: responsibles } = useResponsibleData();
  const { data: statusData } = useStatusData();

  return (
    <div className="counatiner-fluid    ">
      <form className="row mx-0 mt-3  ">
        <div className="col-sm-12 col-lg-6  ">
          <div className="d-lg-flex d-block w-100 ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <label className="form-label">
                {strings2.year_of_the_plan[lang]}
              </label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY"
                timeFormat={false}
                onChange={(v) =>
                  yearField.onChange(v ? moment(v).format("YYYY") : "")
                }
                inputProps={{
                  value: yearField.value,
                  placeholder: strings2.select[lang],
                  ref: yearField.ref,
                  onBlur: yearField.onBlur,
                  name: yearField.name,
                }}
              />
            </div>
            <div className="col">
              <Autocomlete
                data={responsibles.map((item) => ({
                  label: lang == "AR" ? item.responsible : item.responsible_EN,
                  value: item.id,
                }))}
                title={strings2.responsible[lang]}
                control={control}
                name={"responsible"}
              />
            </div>
          </div>
          <Autocomlete
            data={countries.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
            title={strings2.country[lang]}
            control={control}
            name={"country"}
            onChangeData={() => setValue("city", null)}
          />
          <Autocomlete
            data={teamList.map((item) => ({
              label: lang == "AR" ? item.team : item.team_EN,
              value: item.id,
            }))}
            title={strings2.team[lang]}
            control={control}
            name={"team"}
          />
          <Autocomlete
            data={statusData.map((item) => ({
              label: lang == "AR" ? item.status : item.status_EN,
              value: item.id,
            }))}
            title={strings2.camp_condition[lang]}
            control={control}
            name={"status"}
          />
        </div>
        <div className="col ">
          <div className="d-lg-flex d-block w-100  ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <div className=" ">
                <label className="form-label">
                  {strings2.reference_number[lang]}
                </label>

                <input
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                  {...register("reference_number")}
                ></input>
              </div>
            </div>
            <div className=" p-2   w-100">
              <div className=" ">
                <label className="form-label">
                  {strings2.project_number[lang]}
                </label>

                <input
                  className="form-control  p-2  "
                  placeholder={strings2.write_here[lang]}
                  {...register("project_number")}
                ></input>
              </div>
            </div>
          </div>
          <Autocomlete
            data={
              cities
                ? cities.map((item) => ({
                    label: lang == "AR" ? item.city : item.city_EN,
                    value: item.id,
                  }))
                : []
            }
            title={strings2.city[lang]}
            control={control}
            name={"city"}
          />

          <div className="d-lg-flex d-block w-100  ">
            <Autocomlete
              data={sponsors.map((item) => ({
                label: lang == "AR" ? item.sponsor : item.sponsor_EN,
                value: item.id,
              }))}
              title={strings2.donor[lang]}
              control={control}
              name={"sponsor"}
              onChangeData={() => setValue("sponsorship", null)}
            />
            <Autocomlete
              isMulti
              closeMenuOnSelect={false}
              data={
                sponsorships
                  ? sponsorships.map((item) => ({
                      label:
                        lang == "AR" ? item.sponsorship : item.sponsorship_EN,
                      value: item.id,
                    }))
                  : []
              }
              title={strings2.sub_donor[lang]}
              control={control}
              name={"sponsorship"}
            />
          </div>
          <div className="d-lg-flex d-block w-100  ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <label className="form-label">{strings2.camp_start[lang]}</label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(v) =>
                  dateFrom.onChange(moment(v).format("YYYY-MM-DD"))
                }
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  ref: dateFrom.ref,
                  onBlur: dateFrom.onBlur,
                  name: dateFrom.name,
                  value: dateFrom.value,
                }}
              />
            </div>
            <div className=" p-2  mb-2 w-100">
              <label className="form-label">{strings2.camp_end[lang]}</label>

              <Datetime
                isValidDate={(current) => {
                  const yesterday = moment(watch("dateFrom")).subtract(
                    1,
                    "day"
                  );
                  return current.isAfter(yesterday);
                }}
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(v) =>
                  dateTo.onChange(moment(v).format("YYYY-MM-DD"))
                }
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  ref: dateTo.ref,
                  onBlur: dateTo.onBlur,
                  name: dateTo.name,
                  value: dateTo.value,
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mx-0 d-flex flex-column-reverse flex-lg-row  ">
          <div className="col-12 col-lg-6 form-check ps-2 m-2">
            <input
              className="form-check-input float-none ms-0"
              type="checkbox"
              id="archives"
              {...register("archives")}
            />
            <label className="form-check-label mx-2" htmlFor="archives">
              {strings2.archives[lang]}
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}
