import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async () => {
    return await apiServices.getCountries();
  }
);
export const createCountry = createAsyncThunk(
  "countries/createCountry",
  async (data) => {
    return await apiServices.createCountry(data);
  }
);
export const updateCountries = createAsyncThunk(
  "countries/updateCountries",
  async ({ id, data }) => {
    return await apiServices.updateCountries(id, data);
  }
);

export const deleteCountry = createAsyncThunk(
  "countries/deleteCountry",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteCountries(id);
    if (ok) return id;

    return rejectWithValue();
  }
);

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getCountries.rejected, (state) => {
        state.error = true;
      })
      .addCase(createCountry.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
      })
      .addCase(updateCountries.fulfilled, (state, action) => {
        const countryIndex = state.data.findIndex(
          (c) => c.id === action.payload.data.id
        );
        state.data[countryIndex] = action.payload.data;
      })
      .addCase(deleteCountry.fulfilled, (state, action) => {
        state.data = state.data.filter((c) => c.id !== action.payload);
      });
  },
});

export const {} = countriesSlice.actions;

export const countriesData = (state) => state.countries;

export default countriesSlice.reducer;
