import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import Modal from "react-bootstrap/Modal";
import ContactPerson from "../components/systemAdministration/ContactPerson";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useSponsorsData from "../hooks/useSponsorsData";
import { userLang } from "../store/reducers/user";
import { useDispatch, useSelector } from "react-redux";
import {
  createSponsorService,
  deleteSponsorService,
  updateSponsorService,
} from "../store/reducers/sponsors";

export default function Sponsors() {
  const [sponsor, setSponsor] = useState("");
  const [sponsor_EN, setSponsor_EN] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [office_tel, setOffice_tel] = useState("");
  const [office_email, setOffice_email] = useState("");
  const [Categories, setCategories] = useState("");
  const [Notes, setNotes] = useState("");
  const [sponsorId, setSponsorId] = useState();
  const [sponsorIdDelete, setSponsorIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const { data: sponsors } = useSponsorsData();
  const lang = useSelector(userLang);
  const dispatch = useDispatch();

  const add = useHaveUserPermission("sponsor", "add");
  const change = useHaveUserPermission("sponsor", "change");
  const deletePerm = useHaveUserPermission("sponsor", "delete");
  const viewContact = useHaveUserPermission("contactperson", "view");

  const createSponsor = async () => {
    setDisableBtnCreate(true);
    const resultAction = await dispatch(
      createSponsorService({
        sponsor,
        sponsor_EN,
        active,
        Categories,
        address,
        location,
        office_email,
        office_tel,
        Notes,
      })
    );
    if (createSponsorService.fulfilled.match(resultAction)) {
      toast.success("تم إضافة الراعي بنجاح");
    }
    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setActive(item.active);
    setSponsor(item.sponsor);
    setSponsor_EN(item.sponsor_EN);
    setSponsorId(item.id);
    setCategories(item.Categories);
    setNotes(item.Notes);
    setAddress(item.address);
    setLocation(item.location);
    setOffice_email(item.office_email);
    setOffice_tel(item.office_tel);
  };
  const deleteSponsor = async (id) => {
    const resultAction = await dispatch(deleteSponsorService(id));
    if (deleteSponsorService.rejected.match(resultAction)) {
      toast.error(strings2.error_delete[lang]);
    }
    if (deleteSponsorService.fulfilled.match(resultAction)) {
      toast.success("تم حذف الراعي بنجاح");
    }
  };
  const updateSponsor = async () => {
    setDisableBtnUpdate(true);
    const resultAction = await dispatch(
      updateSponsorService({
        id: sponsorId,
        data: {
          sponsor,
          sponsor_EN,
          active,
          Categories,
          address,
          location,
          office_email,
          office_tel,
          Notes,
        },
      })
    );
    if (updateSponsorService.fulfilled.match(resultAction)) {
      toast.success("تم تعديل الراعي بنجاح");
    }

    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "الرعاة والمتبرعين";
  }, []);
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.donors}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50">
          <input
            value={sponsor}
            onChange={(e) => setSponsor(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="الراعي"
          />
          <input
            value={sponsor_EN}
            onChange={(e) => setSponsor_EN(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="Donor"
          />
          <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.location[lang]}
          />
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.address[lang]}
          />
          <input
            value={office_tel}
            onChange={(e) => setOffice_tel(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.office_tel[lang]}
          />
          <input
            value={office_email}
            onChange={(e) => setOffice_email(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.office_email[lang]}
          />
          <input
            value={Categories}
            onChange={(e) => setCategories(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.Categories[lang]}
          />
          <input
            value={Notes}
            onChange={(e) => setNotes(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder={strings2.remark[lang]}
          />

          <div className="form-check ">
            <input
              onChange={(e) => setActive(e.target.checked)}
              className="form-check-input float-none ms-0"
              type="checkbox"
              checked={active}
              id="flexCheckDefault"
            />
            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
              {strings[lang].data.active}
            </label>
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={updateSponsor}
            className={`btn btn-success  mx-4 ${
              disableBtnUpdate || !sponsorId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          {sponsorId && viewContact && (
            <button
              onClick={() => setShowContactModal(true)}
              className={`btn btn-primary mx-4 `}
            >
              {strings2.contact[lang]}
            </button>
          )}
          <button
            onClick={createSponsor}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !sponsor || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
      </div>
      {sponsors.length > 0 && (
        <div className="table-responsive">
          <table className="table  table-hover mt-4  table-bordered text-center align-middle container ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">الراعي</th>
                <th scope="col">Donor</th>

                <th scope="col">{strings2.location[lang]}</th>
                <th scope="col">{strings2.address[lang]}</th>
                <th scope="col">{strings2.office_tel[lang]}</th>
                <th scope="col">{strings2.office_email[lang]}</th>
                <th scope="col">{strings2.Categories[lang]}</th>
                <th scope="col">{strings[lang].data.date_of_registration}</th>
                <th scope="col">{strings[lang].data.active}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {sponsors.map((item, index) => (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <th scope="row" onClick={() => onClick(item)}>
                    {sponsors.length - index}
                  </th>
                  <td onClick={() => onClick(item)}>{item.sponsor}</td>
                  <td onClick={() => onClick(item)}>{item.sponsor_EN}</td>
                  <td onClick={() => onClick(item)}>{item.location}</td>
                  <td onClick={() => onClick(item)}>{item.address}</td>
                  <td onClick={() => onClick(item)}>{item.office_tel}</td>
                  <td onClick={() => onClick(item)}>{item.office_email}</td>
                  <td onClick={() => onClick(item)}>{item.Categories}</td>

                  <td onClick={() => onClick(item)} dir="ltr">
                    <span>{moment(item.date).format("YYYY-M-D")}</span>
                    <span> {moment(item.date).format("LT")}</span>
                  </td>
                  <td onClick={() => onClick(item)}>
                    <i
                      className={`fa ${
                        item.active
                          ? "fa-check-circle text-success"
                          : "fa-times-circle text-danger"
                      } `}
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <p
                      className={`mb-0 btn btn-outline-danger  ${
                        !deletePerm ? "disabled" : ""
                      }`}
                      onClick={() => {
                        setSponsorIdDelete(item.id);
                        setShowModal(true);
                      }}
                    >
                      {strings[lang].data.delete}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={sponsorIdDelete}
        deleteMethod={deleteSponsor}
      />
      <Modal
        dir={lang == "EN" ? "ltr" : "rtl"}
        show={showContactModal}
        onHide={() => {
          setShowContactModal(false);
        }}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ContactPerson sponsorId={sponsorId} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
