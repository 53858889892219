import React from "react";
import Select from "react-select";

import strings from "./strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function AutocomleteField({
  placeholder,
  data = [],
  value,
  setValue,
  history = {},
  isLoading = false,
  error = false,
  getPlansOnCHangeInput = false,
  searchData = {},
  title,
  ...props
}) {
  const lang = useSelector(userLang);

  return (
    <div className=" position-relative p-2  mb-0 w-100">
      {title ? <label className="form-label mx-2">{title}</label> : null}
      <Select
        {...props}
        isRtl={lang == "EN" ? false : true}
        styles={{
          control: (provided, { isFocused }) => ({
            ...provided,
            borderColor: error && !isFocused ? "red" : "#ced4da",
          }),
        }}
        isLoading={isLoading}
        noOptionsMessage={() => strings[lang].common.no_result}
        placeholder={placeholder ? placeholder : strings[lang].common.select}
        isClearable
        value={value}
        onChange={(v) => setValue(v)}
        options={data}
      />
    </div>
  );
}
