import React, { useEffect, useState } from "react";
import strings from "../../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../../store/reducers/user";

export default function HospitalDataTabel({
  setInputsValue,
  inputsValue,
  title,
  fields = [],
  OPDCount,
  setOPDCOunt,
}) {
  const [total, setTotal] = useState(0);
  const lang = useSelector(userLang);

  const filter = [
    "Working_Days_OPD",
    "Average_Day_OPD",
    "Total_Days_of_Operations_Surgeries",
    "Average_Per_Day_Surgeries",
    "Percentage_of_Surg_from_OPD_Surgeries",
  ];
  useEffect(() => {
    let totalValue = 0;
    fields.map((item) => {
      if (!filter.includes(item.value)) {
        if (typeof inputsValue[item.value] == "object") {
          totalValue +=
            Number(inputsValue[item.value]?.female) +
            Number(inputsValue[item.value]?.male);
        } else {
          totalValue += Number(inputsValue[item.value]);
        }
      }
    });
    setTotal(totalValue || 0);
    if (title == "OPD") {
      setOPDCOunt(totalValue || 0);
    }
  }, [inputsValue]);

  return (
    <div className="container table-responsive p-0" dir="rtl">
      <table
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container table table-hover table-striped  table-bordered text-center align-middle"
      >
        <thead className="header-tabel">
          <tr>
            <th scope="col" colSpan={title == "OPD" ? 5 : 4}>
              {title}
            </th>
          </tr>
          {title == "OPD" && (
            <tr>
              <th scope="col">#</th>
              <th scope="col">{strings[lang].stats.procedure}</th>
              <th scope="col">{strings[lang].stats.male}</th>

              <th scope="col">{strings[lang].stats.female}</th>
              <th scope="col">{strings[lang].stats.total}</th>
            </tr>
          )}
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const value = { ...inputsValue };
            const inputValue = value[item.value];
            if (typeof value[item.value] == "object") {
              // return OPD Inputs only
              return (
                <React.Fragment key={index}>
                  <tr>
                    {/* <th scope="row">{index + 1}</th> */}
                    <td>
                      <p className="m-0">{index + 1}</p>
                    </td>
                    <td>
                      <p className="m-0">{item.action}</p>
                    </td>
                    <td>
                      {/* ----------------male Adults-------------- */}
                      <input
                        style={{ minWidth: 75 }}
                        value={value[item.value]?.male}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => {
                          value[item.value].male = Number(e.target.value);
                          setInputsValue(value);
                        }}
                        className="form-control  p-2 text-center "
                        placeholder={strings[lang].stats.number}
                        type={"number"}
                        min={0}
                      />
                    </td>
                    <td>
                      {/* -----------female Adults------------ */}
                      <input
                        style={{ minWidth: 75 }}
                        value={value[item.value]?.female}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => {
                          value[item.value].female = Number(e.target.value);
                          setInputsValue(value);
                        }}
                        className="form-control  p-2 text-center "
                        placeholder={strings[lang].stats.number}
                        type={"number"}
                        min={0}
                      />
                    </td>
                    <td>
                      {Number(inputsValue[item.value]?.female) +
                        Number(inputsValue[item.value]?.male) || 0}
                    </td>
                  </tr>
                  {item.value == "old" && (
                    <tr>
                      <td colSpan={5}>
                        <span>{strings[lang].stats.total}</span>
                        <span className="badge text-bg-primary mx-2 fs-6">
                          {Number(total) || 0}
                        </span>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            }
            let finalValue = 0;
            if (
              item.value == "Average_Day_OPD" ||
              item.value == "Average_Per_Day_Surgeries"
            ) {
              const day =
                item.value == "Average_Day_OPD"
                  ? value["Working_Days_OPD"]
                  : value["Total_Days_of_Operations_Surgeries"];
              // Put the value by dividing the total number by days

              finalValue = day ? (total / day).toFixed(2) : "";
            } else if (item.value == "Percentage_of_Surg_from_OPD_Surgeries") {
              if (total || OPDCount)
                finalValue = ((total / OPDCount) * 100).toFixed(2) + "%";
            } else {
              finalValue = value[item.value];
            }

            return (
              <React.Fragment key={index}>
                <tr key={index}>
                  {/* <th scope="row">{index + 1}</th> */}
                  <td>
                    <p className="m-0">
                      {!filter.includes(item.value) ? index + 1 : ""}
                    </p>
                  </td>
                  <td>
                    <p>{item.action}</p>
                  </td>

                  <td colSpan={3}>
                    <div className="d-flex justify-content-center">
                      <input
                        readOnly={filter
                          .filter((i) => {
                            return (
                              i == "Average_Day_OPD" ||
                              i == "Average_Per_Day_Surgeries" ||
                              i == "Percentage_of_Surg_from_OPD_Surgeries"
                            );
                          })
                          .includes(item.value)}
                        style={{ minWidth: 75, maxWidth: "50%" }}
                        value={finalValue}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={(e) => {
                          value[item.value] = Number(e.target.value);
                          setInputsValue(value);
                        }}
                        className="form-control  p-2 text-center "
                        placeholder={strings[lang].stats.number}
                        type={
                          item.value == "Percentage_of_Surg_from_OPD_Surgeries"
                            ? "text"
                            : "number"
                        }
                        min={0}
                      />
                    </div>
                  </td>
                </tr>
                {(item.value == "Minor" ||
                  item.value == "Others" ||
                  item.value == "Inj_Avastin_IVTA") && (
                  <tr>
                    <td colSpan={5}>
                      <span>{strings[lang].stats.total}</span>
                      <span className="badge text-bg-primary mx-2 fs-6">
                        {Number(total) || 0}
                      </span>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
