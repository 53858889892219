import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import { countriesData, getCountries } from "../store/reducers/countries";

const useCountriesData = () => {
  const { data, loading, error } = useSelector(countriesData);

  const view = useHaveUserPermission("countries", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.length && !error && view) {
      dispatch(getCountries());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useCountriesData;
