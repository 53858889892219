import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useTeamsData from "../hooks/useTeamsData";
import { useDispatch, useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import {
  createTeamsService,
  deleteTeamsService,
  updateTeamsService,
} from "../store/reducers/teams";
export default function Teams() {
  const [team, setTeam] = useState("");
  const [team_EN, setTeam_EN] = useState("");
  const [manager1, setManager1] = useState("");
  const [manager1_EN, setManager1_EN] = useState("");
  const [manager2, setManager2] = useState("");
  const [manager2_EN, setManager2_EN] = useState("");
  const [manager3, setManager3] = useState("");
  const [manager3_EN, setManager3_EN] = useState("");
  const [teamId, setTeamId] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [teamIdDelete, setTeamIdDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);
  const { data: teamList } = useTeamsData();
  const lang = useSelector(userLang);

  const dispatch = useDispatch();

  const add = useHaveUserPermission("team", "add");
  const change = useHaveUserPermission("team", "change");
  const deletePerm = useHaveUserPermission("team", "delete");
  const createTeam = async () => {
    setDisableBtnCreate(true);
    dispatch(
      createTeamsService({
        team,
        team_EN,
        active,
        manager_1: manager1,
        manager_2: manager2,
        manager_3: manager3,
        manager_1_EN: manager1_EN,
        manager_2_EN: manager2_EN,
        manager_3_EN: manager3_EN,
      })
    );
    toast.success("تم إضافة الفريق بنجاح");

    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setActive(item.active);
    setTeam(item.team);
    setTeam_EN(item.team_EN);
    setTeamId(item.id);
    setManager1(item.manager_1);
    setManager2(item.manager_2);
    setManager3(item.manager_3);
    setManager1_EN(item.manager_1_EN);
    setManager2_EN(item.manager_2_EN);
    setManager3_EN(item.manager_3_EN);
  };
  const deleteTeam = async (id) => {
    const resultAction = await dispatch(deleteTeamsService(id));
    if (deleteTeamsService.rejected.match(resultAction)) {
      toast.error(strings2.error_delete[lang]);
    }
    if (deleteTeamsService.fulfilled.match(resultAction)) {
      toast.success("تم حذف الفريق بنجاح");
    }
  };
  const updateTeam = async () => {
    setDisableBtnUpdate(true);
    dispatch(
      updateTeamsService({
        id: teamId,
        data: {
          team,
          team_EN,
          active,
          manager_1: manager1,
          manager_2: manager2,
          manager_3: manager3,
          manager_1_EN: manager1_EN,
          manager_2_EN: manager2_EN,
          manager_3_EN: manager3_EN,
        },
      })
    );
    toast.success("تم تعديل الفريق بنجاح");

    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "الفرق";
  }, []);

  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.teams}
        </p>
      </div>
      <div className=" d-flex me-2  flex-column ">
        <div className="row w-100">
          <div className="col">
            <input
              value={team}
              onChange={(e) => setTeam(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="الفريق"
            />
            <input
              value={manager1}
              onChange={(e) => setManager1(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="المدير الأول"
            />
            <input
              value={manager2}
              onChange={(e) => setManager2(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="المدير الثاني"
            />
            <input
              value={manager3}
              onChange={(e) => setManager3(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="المدير الثالث"
            />
            <div className="form-check ">
              <input
                onChange={(e) => setActive(e.target.checked)}
                className="form-check-input float-none ms-0"
                type="checkbox"
                checked={active}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label mx-2"
                htmlFor="flexCheckDefault"
              >
                {strings[lang].data.active}
              </label>
            </div>
          </div>
          <div className="col">
            <input
              value={team_EN}
              onChange={(e) => setTeam_EN(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="team"
            />
            <input
              value={manager1_EN}
              onChange={(e) => setManager1_EN(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="first manager"
            />
            <input
              value={manager2_EN}
              onChange={(e) => setManager2_EN(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="second manager"
            />
            <input
              value={manager3_EN}
              onChange={(e) => setManager3_EN(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder="third manager"
            />
          </div>
        </div>
        <div className="d-flex align-self-center">
          <button
            onClick={updateTeam}
            className={`btn btn-success  ${
              disableBtnUpdate || !teamId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createTeam}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !team || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {teamList.length > 0 && (
          <div className="table-responsive">
            <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">الفريق</th>
                  <th scope="col">Team</th>
                  <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col">{strings[lang].data.active}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {teamList.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row" onClick={() => onClick(item)}>
                      {teamList.length - index}
                    </th>
                    <td onClick={() => onClick(item)}>{item.team}</td>
                    <td onClick={() => onClick(item)}>{item.team_EN}</td>
                    <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>
                    <td onClick={() => onClick(item)}>
                      <i
                        className={`fa ${
                          item.active
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <p
                        className={`mb-0 btn btn-outline-danger  ${
                          !deletePerm ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setTeamIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={teamIdDelete}
        deleteMethod={deleteTeam}
      />
    </div>
  );
}
