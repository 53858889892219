import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import { getTeams, teamsData } from "../store/reducers/teams";

const useTeamsData = () => {
  const { data, loading, error } = useSelector(teamsData);

  const view = useHaveUserPermission("team", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getTeams());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useTeamsData;
