import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import { getPermissions, permissionsData } from "../store/reducers/permissions";

const usePermissionsData = () => {
  const { data, loading, error } = useSelector(permissionsData);

  const view = useHaveUserPermission("permission", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getPermissions());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default usePermissionsData;
