import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../components/api/api";
import { toast } from "react-toastify";
import strings2 from "../../components/common/strings2.json";
import { userLang } from "../reducers/user";

export const planCitiesServices = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "planCitiesServices",
  tagTypes: ["cities"],
  endpoints: (builder) => ({
    getCities: builder.query({
      query: (countryId) => ({
        url: `data/cities/?country__in=${countryId}`,
      }),
    }),
    createCity: builder.mutation({
      query: (data) => ({
        url: "data/cities/",
        method: "POST",
        data,
      }),
      async onQueryStarted({ country }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          planCitiesServices.util.updateQueryData(
            "getCities",
            country,
            (draft) => {
              draft.unshift(data);
            }
          )
        );
        toast.success("تم إضافة المدينة بنجاح");
      },
    }),
    updateCity: builder.mutation({
      query: ({ cityId, data }) => ({
        url: `data/cities/${cityId}/`,
        method: "PUT",
        data,
      }),
      async onQueryStarted(
        { cityId, data: { country } },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          planCitiesServices.util.updateQueryData(
            "getCities",
            country,
            (draft) => {
              const index = draft.findIndex((c) => c.id === cityId);
              draft[index] = data;
            }
          )
        );
        toast.success("تم تعديل المدينة بنجاح");
      },
    }),
    deleteCity: builder.mutation({
      query: ({ cityId }) => ({
        url: `data/cities/${cityId}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { countryId, cityId },
        { dispatch, queryFulfilled, getState }
      ) {
        const lang = userLang(getState());
        try {
          const data = await queryFulfilled;
          dispatch(
            planCitiesServices.util.updateQueryData(
              "getCities",
              countryId,
              (draft) => {
                return draft.filter((cities) => cities.id !== cityId);
              }
            )
          );
          toast.success("تم حذف المدينة بنجاح");
        } catch (error) {
          toast.error(strings2.error_delete[lang]);
        }
      },
    }),
  }),
});

export const {
  useLazyGetCitiesQuery,
  useCreateCityMutation,
  useDeleteCityMutation,
  useUpdateCityMutation,
  useGetCitiesQuery,
} = planCitiesServices;
