import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

import strings from "./strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function Autocomlete({
  placeholder,
  data = [],

  title,
  control,
  name,
  rules = {},
  onChangeData,
  ...props
}) {
  const lang = useSelector(userLang);

  return (
    <div className=" position-relative p-2  mb-0 w-100">
      {title ? <label className="form-label mx-2">{title}</label> : null}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            isRtl={lang == "EN" ? false : true}
            noOptionsMessage={() => strings[lang].common.no_result}
            placeholder={
              placeholder ? placeholder : strings[lang].common.select
            }
            isClearable
            value={value}
            onChange={(v) => {
              if (onChangeData) onChangeData();
              onChange(v);
            }}
            // components={{
            //   Input: {
            //     ref,
            //   },
            // }}
            ref={ref}
            options={data}
            onBlur={onBlur}
            {...props}
          />
        )}
      />
    </div>
  );
}
