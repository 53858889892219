import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../components/api/api";

export const planApi = createApi({
  reducerPath: "planApiServices",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["plans", "AnnualPlanHistories"],
  endpoints: (builder) => ({
    getAnnualPlanHistories: builder.query({
      query: (id) => {
        return {
          url: `data/AnnualPlanHistories/${id}`,
        };
      },
      providesTags: ["AnnualPlanHistories"],
    }),
    getPlans: builder.query({
      query: (data) => {
        const params = new URLSearchParams();
        params.append("ordering", data?.ordering || "-camp_start_date");
        params.append("page", data?.page || 1);
        if (data?.followUp) params.append("followUp", data?.followUp);
        if (typeof data?.first_followup == "boolean")
          params.append("first_followup", data?.first_followup);
        if (typeof data?.stats_filter == "boolean")
          params.append("stats_filter", data?.stats_filter);
        if (typeof data?.second_followup == "boolean")
          params.append("second_followup", data?.second_followup);
        if (typeof data?.followUpArchive == "boolean")
          params.append("followUpArchive", data?.followUpArchive);

        if (typeof data?.followUp == "boolean")
          params.append("followUp", data?.followUp);
        if (data?.page_size) params.append("page_size", data?.page_size);
        if (data?.year) params.append("year", data?.year);
        if (data?.country) params.append("country", data?.country);
        if (data?.city) params.append("city", data?.city);
        if (data?.status) params.append("status", data?.status);
        if (data?.team) params.append("team", data?.team);
        if (data?.sponsor) params.append("sponsor", data?.sponsor);
        if (data?.responsible) params.append("responsible", data?.responsible);
        if (data?.camp_start_date)
          params.append("camp_start_date_after", data?.camp_start_date);
        if (data?.camp_end_date)
          params.append("camp_end_date_before", data?.camp_end_date);
        if (data?.remark) params.append("remark", data?.remark);
        if (data?.sponsorship) params.append("sponsorship", data?.sponsorship);
        if (data?.search) params.append("search", data?.search);

        if (data?.visit) params.append("visit", data?.visit);
        if (data?.financial) params.append("financial", data?.financial);
        if (data?.sponsors) params.append("sponsors", data?.sponsors);
        if (data?.media) params.append("media", data?.media);
        if (data?.medication) params.append("medication", data?.medication);
        if (data?.financialsector)
          params.append("financialsector", data?.financialsector);
        if (data?.preparingtheteam)
          params.append("preparingtheteam", data?.preparingtheteam);
        if (data?.reference_number)
          params.append("reference_number", data?.reference_number);
        if (data?.project_number)
          params.append("project_number", data?.project_number);
        return {
          url: `data/annualPlan?${params.toString()}`,
        };
      },
      providesTags: ["plans"],
    }),
    createPlanApi: builder.mutation({
      query: (data) => ({
        url: "data/annualPlan/",
        method: "POST",
        data,
      }),
      invalidatesTags: ["plans"],
    }),
    updatePlanApi: builder.mutation({
      query: ({ id, data }) => ({
        url: `data/annualPlan/${id}/`,
        method: "PUT",
        data,
      }),

      invalidatesTags: ["plans"],
    }),
    deletePlanApi: builder.mutation({
      query: (id) => ({
        url: `data/annualPlan/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["plans"],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useLazyGetPlansQuery,
  useDeletePlanApiMutation,
  useCreatePlanApiMutation,
  useUpdatePlanApiMutation,
  useGetAnnualPlanHistoriesQuery,
} = planApi;
