import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import queryString from "query-string";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import Spinner from "react-bootstrap/Spinner";
import Datetime from "react-datetime";
import moment from "moment";

import AutoComleteField from "../../components/common/AutoComleteField";
import api from "../../components/api/api";
import data from "../../components/common/data.json";
import ColorTabel from "../../components/reports/ColorTabel";
import PaginationBar from "../../components/common/PaginationBar";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import useCountriesData from "../../hooks/useCountriesData";
import useTeamsData from "../../hooks/useTeamsData";
import useSponsorsData from "../../hooks/useSponsorsData";
import useStatusData from "../../hooks/useStatusData";
import { useLazyGetCitiesQuery } from "../../store/services/planCities";
export default function Reports() {
  const [reports, setReports] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState();
  const [followUpStatus, setFollowUpStatus] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [team, setTeam] = useState();
  const [sponsor, setSponsor] = useState();
  const [status, setStatus] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [refreshPDF, setRefreshPDF] = useState(false);
  const [archives, setArchives] = useState(false);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchData, setSearchData] = useState({
    country: "",
    city: "",
    year: "",
    team: "",
    status: "",
    sponsor: "",
    followUpArchive: false,
    startDate: "",
    endDate: "",

    page: 1,
  });

  const { data: countries } = useCountriesData();
  const { data: teamList } = useTeamsData();
  const { data: sponsors } = useSponsorsData();
  const { data: statusData } = useStatusData();

  const [triggerCities, { data: cities }] = useLazyGetCitiesQuery({
    selectFromResult: (data) => {
      return country?.value ? data : [];
    },
  });
  const lang = useSelector(userLang);

  const view = useHaveUserPermission("reports", "view");
  const pagesSize = 10;

  const getReports = async (data) => {
    setRefreshPDF(true);
    const result = await api.getReportsFollowUps(
      data ? data : { ...searchData, page, followUpArchive: archives }
    );
    if (result.ok) {
      setReports(result.data.results);

      setCount(result.data.count);
    }
    setRefreshPDF(false);
  };
  const findLabel = (data, value) => {
    return data.find((item) => item.value == value)?.label;
  };
  const findName = (data, value) => {
    return data.find((item) => item.id == value);
  };
  const getYearsList = () => {
    const yearNow = new Date().getFullYear();
    const years = [];
    for (var y = yearNow + 1; y >= 1989; y--) {
      years.push({ value: y, label: y });
    }
    setYearList([...years]);
  };

  const fileName = () => {
    const { city, country, status, team, year } = searchData;
    const name =
      (year ? year + "-" : "") +
      (findName(countries, country)?.country
        ? findName(countries, country)?.country + "-"
        : "") +
      (findName(cities, city)?.city ? findName(cities, city)?.city + "-" : "") +
      (findName(teamList, team)?.team
        ? findName(teamList, team)?.team + "-"
        : "") +
      (status || "");
    return name;
  };
  const tableToImg = async () => {
    const tabel = document.getElementById("tabel-data");
    return await htmlToImage.toPng(tabel);
  };
  const downloadPhoto = async () => {
    const img = await tableToImg();
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  const createPDF = async () => {
    const imgURL = await tableToImg();
    const doc = new jsPDF("p", "mm", "a4");
    var width = doc.internal.pageSize.getWidth();

    doc.addImage(imgURL, "JPEG", 0, 0, width, 0);
    doc.save("reports.pdf");
  };
  useEffect(() => {
    document.title = "التقارير";

    getYearsList();
  }, []);
  useEffect(() => {
    getReports();
  }, [page]);
  useEffect(() => {
    if (country?.value) {
      triggerCities(country?.value, !!cities);
    }
  }, [country]);
  const search = async () => {
    const data = {
      country: country?.value || "",
      city: city?.value || "",
      year: year?.value || "",
      team: team?.value || "",
      status: status?.value || "",
      page: 1,
      follow_up_status: followUpStatus?.value || "",
      followUpArchive: archives,
      sponsor: sponsor?.value || "",
      startDate,
      endDate,
    };

    setPage(1);
    setCurrentPage(1);
    setSearchData(data);
    setDisableBtn(true);
    setRefreshPDF(true);
    await getReports(data);
    setRefreshPDF(false);
    setDisableBtn(false);
  };
  const deleteFields = () => {
    setCity(null);
    setCountry(null);
    setEndDate("");
    setFollowUpStatus(null);
    setTeam(null);
    setYear(null);
    setStartDate("");
    setStatus(null);
    setSponsor(null);
    setArchives(false);
  };
  return (
    <div className="container-fluid mt-2">
      <div className="row flex-row-reverse">
        <div
          className="col-12 col-lg-3 d-flex flex-column"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <AutoComleteField
            value={year}
            setValue={setYear}
            data={yearList}
            placeholder={strings[lang].reports.year_of_the_plan}
          />
          <AutoComleteField
            value={country}
            setValue={setCountry}
            data={countries.map((item) => ({
              label: item.country,
              value: item.id,
            }))}
            placeholder={strings[lang].reports.country}
          />
          <AutoComleteField
            value={city}
            setValue={setCity}
            data={cities?.map((item) => ({
              label: item.city,
              value: item.id,
            }))}
            placeholder={strings[lang].reports.city}
          />
          <AutoComleteField
            value={team}
            setValue={setTeam}
            data={teamList.map((item) => ({
              label: item.team,
              value: item.id,
            }))}
            placeholder={strings[lang].reports.team}
          />
          <AutoComleteField
            value={status}
            setValue={setStatus}
            data={statusData.map((item) => ({
              label: lang == "AR" ? item.status : item.status_EN,
              value: item.id,
            }))}
            // data={data.camp_status}
            placeholder={strings[lang].reports.camp_condition}
          />
          <AutoComleteField
            value={sponsor}
            setValue={setSponsor}
            data={sponsors.map((item) => ({
              label: lang == "AR" ? item.sponsor : item.sponsor_EN,
              value: item.id,
            }))}
            placeholder={strings2.donor[lang]}
          />

          <AutoComleteField
            value={followUpStatus}
            setValue={setFollowUpStatus}
            data={[
              { label: strings[lang].reports.finished, value: "success" },
              { label: strings[lang].reports.going_normally, value: "primary" },
              { label: strings[lang].reports.expired, value: "warning" },
              { label: strings[lang].reports.expired_48, value: "danger" },
            ]}
            placeholder={strings[lang].reports.follow_up_status}
          />
          <div className="d-lg-flex d-block w-100  ">
            <div className=" p-2 mr-2  mb-0  col-lg-6 col-12 ">
              <label className="form-label">{strings2.from[lang]}</label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                onChange={(v) => {
                  if (v) {
                    setStartDate(moment(v).format("YYYY-MM-DD"));
                  } else {
                    setStartDate("");
                  }
                }}
                timeFormat={false}
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  value: startDate,
                }}
              />
            </div>
            <div className=" p-2  mb-2 w-100">
              <label className="form-label">{strings2.to[lang]}</label>

              <Datetime
                isValidDate={(current) => {
                  const yesterday = moment(startDate).subtract(1, "day");
                  return current.isAfter(yesterday);
                }}
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                onChange={(v) => {
                  if (v) {
                    setEndDate(moment(v).format("YYYY-MM-DD"));
                  } else {
                    setEndDate("");
                  }
                }}
                timeFormat={false}
                inputProps={{
                  placeholder: strings2.choose_a_date[lang],
                  value: endDate,
                }}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 form-check ps-2 m-2">
            <input
              className="form-check-input float-none ms-0"
              type="checkbox"
              checked={archives}
              id="sponsorChecked"
              onChange={(e) => {
                setArchives(e.target.checked);
              }}
            />
            <label className="form-check-label mx-2" htmlFor="sponsorChecked">
              {strings2.archives[lang]}
            </label>
          </div>
          <div className="d-flex  justify-content-around">
            <button
              onClick={search}
              style={{ width: "25%" }}
              className={`btn btn-primary  ${
                disableBtn || !view ? "disabled" : ""
              }`}
            >
              {strings[lang].reports.search}
              {disableBtn && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
              )}
            </button>
            <button
              onClick={deleteFields}
              style={{ width: "35%" }}
              className={`btn btn-success `}
            >
              {strings2.Delete_fields[lang]}
            </button>
            <div style={{ width: "30%" }} className="dropdown-center ">
              <button
                style={{ width: "100%" }}
                type="button"
                className={`btn btn-warning dropdown-toggle ${
                  !view ? "disabled" : ""
                }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {strings[lang].reports.download}
              </button>
              <ul className="dropdown-menu">
                <li style={{ cursor: "pointer" }} onClick={downloadPhoto}>
                  <span className="dropdown-item text-end">
                    {strings[lang].reports.photo}
                  </span>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li onClick={createPDF} style={{ cursor: "pointer" }}>
                  <span className="dropdown-item text-end">PDF</span>
                </li>
              </ul>
            </div>
          </div>
          <ColorTabel />
        </div>
        <div className="col  pt-2">
          <div className="table-responsive">
            <table
              style={{ backgroundColor: "#fff" }}
              id="tabel-data"
              className="table table-striped  table-bordered text-center align-middle"
              dir={lang == "EN" ? "ltr" : "rtl"}
            >
              <thead>
                <tr>
                  <th
                    //  style={{ fontFamily: "Amiri, serif" }}
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    // style={{ fontFamily: "Amiri, serif" }}
                    scope="col"
                  >
                    {strings[lang].reports.country}
                  </th>
                  <th scope="col">{strings[lang].reports.city}</th>
                  <th scope="col">{strings[lang].reports.camp_start}</th>
                  <th scope="col">{strings[lang].reports.camp_condition}</th>
                  <th scope="col">{strings[lang].reports.team}</th>
                  <th scope="col">
                    {strings[lang].reports.financial_follow_up}
                  </th>
                  <th scope="col">
                    {strings[lang].reports.financial_sector_follow_up}
                  </th>
                  <th scope="col">
                    {strings[lang].reports.sponsors_follow_up}
                  </th>
                  <th scope="col">{strings[lang].reports.media_follow_up}</th>
                  <th scope="col">
                    {strings[lang].reports.medication_follow_up}
                  </th>
                  <th scope="col">{strings[lang].reports.preparatory_visit}</th>
                  <th scope="col">{strings2.preparingTheTeam[lang]}</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row">{index + 1 + (page - 1) * pagesSize}</th>
                    <td>{item.country ? item.country[lang] : "-----"}</td>
                    <td>{item.city ? item.city[lang] : "-----"}</td>
                    <td className="text-nowrap">
                      {item.camp_start_date || "-----"}
                    </td>
                    <td>
                      {lang == "AR"
                        ? item?.status?.status
                        : item?.status?.status_EN || "-----"}
                    </td>
                    <td>{item.team ? item.team[lang] : "-----"}</td>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.financial_follow_up?.stage ? "d-none" : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.financial_follow_ups_status
                              : data.financial_follow_ups_status_EN,
                            item.financial_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.financial_follow_up?.stage
                            ? item.financial_follow_up?.stage
                            : item.financial
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.financial_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.financial_sector_follow_up?.stage
                              ? "d-none"
                              : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.financial_sector_follow_ups_status
                              : data.financial_sector_follow_ups_status_EN,
                            item.financial_sector_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.financial_sector_follow_up?.stage
                            ? item.financial_sector_follow_up?.stage
                            : item.financialsector
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.financial_sector_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.sponsors_follow_up?.stage ? "d-none" : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.supporters_follow_ups_status
                              : data.supporters_follow_ups_status_EN,
                            item.sponsors_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.sponsors_follow_up?.stage
                            ? item.sponsors_follow_up?.stage
                            : item.sponsors
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.sponsors_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.media_follow_up?.stage ? "d-none" : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.media_follow_ups_status
                              : data.media_follow_ups_status_EN,
                            item.media_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.media_follow_up?.stage
                            ? item.media_follow_up?.stage
                            : item.media
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.media_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.medication_follow_up?.stage ? "d-none" : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.medication_follow_ups_status
                              : data.medication_follow_ups_status_EN,
                            item.medication_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.medication_follow_up?.stage
                            ? item.medication_follow_up?.stage
                            : item.medication
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.medication_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.preparatory_visit_follow_up?.stage
                              ? "d-none"
                              : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.Preparatory_visit_follow_ups_status
                              : data.Preparatory_visit_follow_ups_status_EN,
                            item.preparatory_visit_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.preparatory_visit_follow_up?.stage
                            ? item.preparatory_visit_follow_up?.stage
                            : item.visit
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.preparatory_visit_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          className={
                            !item.preparing_the_team_follow_up?.stage
                              ? "d-none"
                              : ""
                          }
                          id={`tooltip-top`}
                        >
                          {findLabel(
                            lang == "AR"
                              ? data.preparing_the_team
                              : data.preparing_the_team_EN,
                            item.preparing_the_team_follow_up?.stage
                          )}
                        </Tooltip>
                      }
                    >
                      <td>
                        <span>
                          {item.preparing_the_team_follow_up?.stage
                            ? item.preparing_the_team_follow_up?.stage
                            : item.preparingtheteam
                            ? "-----"
                            : strings2.noFollowUp[lang]}
                        </span>
                        <div
                          style={{ padding: 2 }}
                          className={` bg-${item.preparing_the_team_follow_up?.color}`}
                        ></div>
                      </td>
                    </OverlayTrigger>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {count > pagesSize && (
            <PaginationBar
              pageCount={Math.ceil(count / pagesSize)}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
