import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import fileDownload from "js-file-download";
import DeleteModal from "./DeleteModal";
import strings from "../common/strings.json";

import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function Attachments({
  id,
  getFiles,
  viewFile,
  deletePerm,
  deleteFunc,
}) {
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [idDelete, setIdDelete] = useState();

  const lang = useSelector(userLang);

  const filesAttach = async () => {
    if (viewFile) {
      const result = await getFiles(id);
      if (result.ok) {
        setFiles(result.data);
      }
    }
  };
  useEffect(() => {
    filesAttach();
  }, []);
  const deleteFile = async (id) => {
    const { ok } = await deleteFunc(id);
    if (ok) {
      const newFiles = [...files];
      setFiles(newFiles.filter((file) => file.id != id));
    }
  };

  const handleDownload = (url) => {
    const filename = url.split("/media/")[1];
    const fileName = url.split("/").reverse()[0];

    fetch(`https://api.dosmartdeal.com/data/download/?filepath=${filename}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = decodeURIComponent(fileName);
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const downloadFile = async (file) => {
    const fileName = file.split("/");

    Axios.get(file, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, decodeURIComponent(fileName[fileName.length - 1]));
    });
  };
  return (
    <>
      {files.length > 0 && (
        <p onClick={() => setShow(true)} className="link-primary  btn mb-0">
          {strings[lang].common.click}
        </p>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table   table-bordered text-center align-middle">
              <thead className="">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{strings[lang].common.file}</th>
                  <th scope="col">{strings[lang].common.remark}</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {files.map((item, index) => {
                  const fileName = item.file.split("/");
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <a
                          href={item.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {fileName[fileName.length - 1].length > 20
                            ? "..."
                            : ""}
                          {decodeURIComponent(
                            fileName[fileName.length - 1]
                          ).slice(0, 20)}
                        </a>
                      </td>
                      <td>{item.note}</td>
                      <td>
                        <p
                          className={`mb-0  btn btn-outline-danger fa fa-trash ${
                            !deletePerm ? "disabled" : ""
                          }`}
                          onClick={() => {
                            setIdDelete(item.id);
                            setShowDelete(true);
                          }}
                        >
                          {/* {strings[lang].common.delete} */}
                        </p>
                      </td>
                      <td>
                        <button
                          onClick={async () => {
                            handleDownload(item.file);
                          }}
                          type="button"
                          className="btn btn-outline-success fa fa-download "
                          style={{ textOverflow: "ellipsis" }}
                        >
                          {/* {strings[lang].common.download} */}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteModal
        setShow={setShowDelete}
        show={showDelete}
        deleteMethod={deleteFile}
        id={idDelete}
      />
    </>
  );
}
