import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import strings from "./strings.json";
import strings2 from "./strings2.json";
import { userLang } from "../../store/reducers/user";
import { useSelector } from "react-redux";
export default function ConfirmModal({ show, setShow, confirmMethod, title }) {
  const [disabled, setDisabled] = useState(false);
  const lang = useSelector(userLang);

  const handleConfirm = async () => {
    setDisabled(true);
    await confirmMethod();
    setDisabled(false);
    setShow(false);
  };
  return (
    <Modal
      dir={lang == "EN" ? "ltr" : "rtl"}
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          {strings[lang].common.close}
        </Button>
        <Button disabled={disabled} onClick={handleConfirm} variant="primary">
          {strings2.comfirm[lang]}
          {disabled && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
