import React from "react";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import strings from "../../components/common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";
export default function FinancialSectorFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.financialSectorFollowUps}
      permName={"financialsectorfollowup"}
      permFileName="financialsectorfollowupfile"
      permHistoryName={"historicalfinancialsectorfollowup"}
      createFunc={api.createFinancialSectorFollowUps}
      deleteFileFunc={api.deleteFinancialSectorFollowUpsFile}
      deleteFunc={api.deleteFinancialSectorFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.financial_sector_follow_ups_status
          : data.financial_sector_follow_ups_status_EN
      }
      getFilesFunc={api.getFinancialSectorFollowUpsFile}
      getFollowUps={api.getFinancialSectorFollowUps}
      getHistoryFunc={api.getFinancialSectorFollowUpsHistory}
      getHistoryID={api.getFinancialSectorFollowUpsHistoryID}
      searchPlan={{ financialsector: true }}
      sendFileFunc={api.sendFinancialSectorFollowUpsFile}
      title={strings[lang].follow_up.financial_sector_follow_up}
      updateFunc={api.updateFinancialSectorFollowUps}
      deleteHistoryFunc={api.deleteFinancialSectorFollowUpHistory}
    />
  );
}
