import React, { useEffect, useState } from "react";
import api from "../components/api/api";
import Table from "../components/home/Table";
import strings2 from "../components/common/strings2.json";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
export default function HomeCamps({ camps }) {
  const [yearsReports, setYearsReports] = useState({ loading: true });

  const [citiesReports, setCitiesReports] = useState({ loading: true });
  const [sponorReports, setSponorReports] = useState({ loading: true });
  const [teamReports, setTeamReports] = useState({ loading: true });
  const [countriesReports, setCountriesReports] = useState({ loading: true });
  const [statusReports, setStatusReports] = useState({ loading: true });

  const getYearsReports = async () => {
    const { data, ok } = await api.getYearsReport();
    if (ok) {
      setYearsReports({
        count: data.length,

        data,
      });
    } else {
      setYearsReports({ loading: false });
    }
  };

  const getCitiesReports = async () => {
    const { data, ok } = await api.getCitiesReport();
    if (ok) {
      setCitiesReports({
        count: data.length,

        data,
      });
    } else {
      setCitiesReports({ loading: false });
    }
  };
  const getSponorReports = async () => {
    const { data, ok } = await api.getSponsorReport();
    if (ok) {
      setSponorReports({
        count: data.length,

        data,
      });
    } else {
      setSponorReports({ loading: false });
    }
  };
  const getTeamsReports = async () => {
    const { data, ok } = await api.getTeamReport();
    if (ok) {
      setTeamReports({
        count: data.length,

        data,
      });
    } else {
      setTeamReports({ loading: false });
    }
  };
  const getCountriesReports = async () => {
    const { data, ok } = await api.getCountriesReport();
    if (ok) {
      setCountriesReports({
        count: data.length,

        data,
      });
    } else {
      setCountriesReports({ loading: false });
    }
  };
  const getStatusReports = async () => {
    const { data, ok } = await api.getStatusReport();
    if (ok) {
      setStatusReports({ data });
    } else {
      setStatusReports({ loading: false });
    }
  };
  useEffect(() => {
    getStatusReports();
    getCitiesReports();
    getCountriesReports();
    getSponorReports();
    getTeamsReports();
    getYearsReports();
  }, []);
  return (
    <div className="container flex-fill">
      <div className="row  p-0 my-2 ">
        <Table
          label={strings2.city}
          title={strings2.table_cities_title}
          data={citiesReports}
          camps
        />
        <Table
          label={strings2.year}
          title={strings2.years_home}
          data={yearsReports}
          camps
        />
        {/* </div>
          <div className="row p-0 my-2 "> */}
        <Table
          label={strings2.sponsors}
          title={strings2.partners}
          data={sponorReports}
          camps
        />
        <Table
          label={strings2.country}
          title={strings2.countries}
          data={countriesReports}
          camps
        />
        {/* </div>
          <div className="row p-0 my-2"> */}
        <Table
          label={strings2.team}
          title={strings2.medical_teams}
          data={teamReports}
          camps
        />
        <Table
          label={strings2.situation}
          title={strings2.camp_situation}
          data={statusReports}
          camps
        />
      </div>
    </div>
  );
}
