import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getSponsors = createAsyncThunk(
  "sponsors/getSponsors",
  async () => {
    return await apiServices.getSponsors();
  }
);
export const createSponsorService = createAsyncThunk(
  "sponsors/createSponsorService",
  async (sponsorData, { rejectWithValue }) => {
    const { ok, data } = await apiServices.createSponsors(sponsorData);
    if (ok) return data;
    return rejectWithValue();
  }
);

export const deleteSponsorService = createAsyncThunk(
  "sponsors/deleteSponsorService",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.getSponsors(id);
    if (ok) return id;
    return rejectWithValue();
  }
);
export const updateSponsorService = createAsyncThunk(
  "sponsors/updateSponsorService",
  async ({ id, data }) => {
    return await apiServices.updateSponsor(id, data);
  }
);
const sponsorsSlice = createSlice({
  name: "sponsors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSponsors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSponsors.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getSponsors.rejected, (state) => {
        state.error = true;
      })
      .addCase(createSponsorService.fulfilled, (state, action) => {
        state.data.unshift(action.payload);
      })
      .addCase(deleteSponsorService.fulfilled, (state, action) => {
        state.data = state.data.filter((r) => r.id !== action.payload);
      })
      .addCase(updateSponsorService.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (r) => r.id === action.payload.data.id
        );
        state.data[index] = action.payload.data;
      });
  },
});

export const {} = sponsorsSlice.actions;

export const sponsorsData = (state) => state.sponsors;

export default sponsorsSlice.reducer;
