import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getTeams = createAsyncThunk("teams/getTeams", async () => {
  return await apiServices.getTeams();
});

export const createTeamsService = createAsyncThunk(
  "teams/createTeamsService",
  async (data) => {
    return await apiServices.createTeams(data);
  }
);
export const deleteTeamsService = createAsyncThunk(
  "teams/deleteTeamsService",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteTeams(id);
    if (ok) return id;
    return rejectWithValue();
  }
);
export const updateTeamsService = createAsyncThunk(
  "teams/updateTeamsService",
  async ({ id, data }) => {
    return await apiServices.updateTeams(id, data);
  }
);
const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getTeams.rejected, (state) => {
        state.error = true;
      })
      .addCase(createTeamsService.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
      })
      .addCase(deleteTeamsService.fulfilled, (state, action) => {
        state.data = state.data.filter((r) => r.id !== action.payload);
      })
      .addCase(updateTeamsService.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (t) => t.id === action.payload.data.id
        );
        state.data[index] = action.payload.data;
      });
  },
});

export const {} = teamsSlice.actions;

export const teamsData = (state) => state.teams;

export default teamsSlice.reducer;
