import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import { getSponsors, sponsorsData } from "../store/reducers/sponsors";

const useSponsorsData = () => {
  const { data, loading, error } = useSelector(sponsorsData);

  const view = useHaveUserPermission("sponsor", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getSponsors());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useSponsorsData;
