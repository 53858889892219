import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserPermissions, userToken } from "../store/reducers/user";
import { api } from "./api/api";
import { signout } from "../utils/signout";

const AuthenticatedRoutes = () => {
  const token = useSelector(userToken);

  api.axiosInstance.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  });

  api.axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        signout();
      }
      return Promise.reject(error);
    }
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoutes;
