import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import { getStatus, statusData } from "../store/reducers/status";

const useStatusData = () => {
  const { data, loading, error } = useSelector(statusData);

  const view = useHaveUserPermission("planstatus", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getStatus());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useStatusData;
