import React from "react";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import strings from "../../components/common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";
export default function PreparingTheTeamFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.preparingTheTeamFollowUps}
      permName={"preparingtheteamfollowup"}
      permFileName="preparingtheteamfollowupfile"
      permHistoryName={"historicalpreparingtheteamfollowup"}
      createFunc={api.createPreparingTheTeamFollowUps}
      deleteFileFunc={api.deletePreparingTheTeamFollowUpsFile}
      deleteFunc={api.deletePreparingTheTeamFollowUps}
      follow_ups_status={
        lang == "AR" ? data.preparing_the_team : data.preparing_the_team_EN
      }
      getFilesFunc={api.getPreparingTheTeamFollowUpsFile}
      getFollowUps={api.getPreparingTheTeamFollowUps}
      getHistoryFunc={api.getPreparingTheTeamFollowUpsHistory}
      getHistoryID={api.getPreparingTheTeamFollowUpsHistoryID}
      // searchPlan={{ financialsector: true }}
      sendFileFunc={api.sendPreparingTheTeamFollowUpsFile}
      title={strings[lang].follow_up.preparing_the_team_follow_up}
      updateFunc={api.updatePreparingTheTeamFollowUps}
      deleteHistoryFunc={api.deletepreparingtheteamHistory}
    />
  );
}
