import React from "react";
import strings from "../common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function TotalTabel({ inputsValue }) {
  const lang = useSelector(userLang);

  const total =
    Number(inputsValue.phaco.female) +
    Number(inputsValue.phaco.male) +
    Number(inputsValue.phacoChildren.female) +
    Number(inputsValue.phacoChildren.male) +
    // inputsValue.patientsSurger.female +
    // inputsValue.patientsSurger.male +
    Number(inputsValue.iolImplant.female) +
    Number(inputsValue.iolImplant.male) +
    Number(inputsValue.iolImplantChildren.female) +
    Number(inputsValue.iolImplantChildren.male) +
    Number(inputsValue.acIol.female) +
    Number(inputsValue.acIol.male) +
    Number(inputsValue.acIolChildren.female) +
    Number(inputsValue.acIolChildren.male) +
    Number(inputsValue.ICCE.female) +
    Number(inputsValue.ICCE.male) +
    Number(inputsValue.ICCEChildren.female) +
    Number(inputsValue.ICCEChildren.male) +
    Number(inputsValue.PTERYGIUM.female) +
    Number(inputsValue.PTERYGIUM.male) +
    Number(inputsValue.PTERYGIUMChildren.female) +
    Number(inputsValue.PTERYGIUMChildren.male) +
    Number(inputsValue.CHALAZION.female) +
    Number(inputsValue.CHALAZION.male) +
    Number(inputsValue.CHALAZIONChildren.female) +
    Number(inputsValue.CHALAZIONChildren.male) +
    Number(inputsValue.OTHERS.female) +
    Number(inputsValue.OTHERS.male) +
    Number(inputsValue.OTHERSChildren.female) +
    Number(inputsValue.OTHERSChildren.male);
  return (
    <div style={{ margin: "0 20%" }}>
      <table className="table table-hover   table-bordered text-center align-middle container ">
        <tbody>
          <tr>
            <td>
              {inputsValue.totalPatients.female +
                inputsValue.totalPatients.male +
                inputsValue.totalPatientsChildren.female +
                inputsValue.totalPatientsChildren.male || 0}
            </td>
            <td>{strings[lang].stats.total_patients}</td>
          </tr>
          <tr>
            <td>
              {inputsValue.iolImplant.female +
                inputsValue.iolImplant.male +
                inputsValue.iolImplantChildren.female +
                inputsValue.iolImplantChildren.male || 0}
            </td>
            <td>{strings[lang].stats.cataract}</td>
          </tr>
          <tr>
            <td>
              {inputsValue.phaco.female +
                inputsValue.phaco.male +
                inputsValue.phacoChildren.female +
                inputsValue.phacoChildren.male || 0}
            </td>
            <td>{strings[lang].stats.phaco}</td>
          </tr>
          <tr>
            <td>{total || 0}</td>
            <td>{strings[lang].stats.surgeries}</td>
          </tr>
          <tr>
            <td>
              {inputsValue.glasses.female +
                inputsValue.glasses.male +
                inputsValue.glassesChildren.female +
                inputsValue.glassesChildren.male || 0}
            </td>
            <td>{strings[lang].stats.glasses}</td>
          </tr>
          {/* <tr>
            <td>
              {inputsValue.vaLarger.female +
                inputsValue.vaLarger.male +
                inputsValue.vaLargerChildren.female +
                inputsValue.vaLargerChildren.male || 0}
            </td>
            <td>VA ≥ 6/60</td>
          </tr>
          <tr>
            <td>
              {inputsValue.vaLess.female +
                inputsValue.vaLess.male +
                inputsValue.vaLessChildren.female +
                inputsValue.vaLessChildren.male || 0}
            </td>
            <td>VA &lt; 6/60</td>
          </tr> */}

          <tr>
            <td>
              {inputsValue.OTHERS.female +
                inputsValue.OTHERS.male +
                inputsValue.OTHERSChildren.female +
                inputsValue.OTHERSChildren.male || 0}
            </td>
            <td>{strings[lang].stats.other}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
