import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import useHaveUserPermission from "./useHaveUserPermission";
import {
  getResponsibles,
  responsiblesData,
} from "../store/reducers/responsibles";

const useResponsibleData = () => {
  const { data, loading, error } = useSelector(responsiblesData);

  const view = useHaveUserPermission("responsible", "view");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data && !error && view) {
      dispatch(getResponsibles());
    }
  }, []);

  return { data: data ?? [], loading, error };
};

export default useResponsibleData;
