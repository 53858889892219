import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import AutocomleteField from "../components/common/AutoComleteField";
import HospitalDataTabel from "../components/stats/Hospital/HospitalDataTabel";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import Dropzone from "../components/common/Dropzone";

import {
  OPD_FIELDS,
  Surgeries_FIELDS,
  Injection_FIELDS,
  Lasers_FIELDS,
} from "../components/stats/Hospital/fields";
import Attachments from "../components/common/Attachments";
import HospitalFiles from "../components/stats/Hospital/HospitalFiles";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
export default function HospitalStats() {
  const [hospital, setHospital] = useState();
  const [statsID, setStatsID] = useState("");
  // const [statsID, setStatsID] = useState("");
  const [files, setFiles] = useState([]);
  const [statFiles, setStatFiles] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [statsHistories, setStatsHistories] = useState([]);
  const [date, setDate] = useState("");
  const [OPDCount, setOPDCOunt] = useState("");

  const [inputsValue, setInputsValue] = useState({
    // OPD
    new: { male: "", female: "" },
    old: { male: "", female: "" },
    // OPD_new_female: "",
    // OPD_new_male: "",
    // OPD_old_male: "",
    // OPD_old_female: "",
    Working_Days_OPD: "",
    Average_Day_OPD: "",
    // Surgeries
    Phaco_IOL: "",
    ECCE_PCIOL: "",
    Phaco_IOL_Avastin: "",
    Evisearation: "",
    Enucleation: "",
    Phaco_Trabe: "",
    AMG: "",
    Secondary_PC_AC_IOL: "",
    SICS_PC_IOL: "",
    ECCE_ICCE: "",
    Trabeculectomy_MMC: "",
    DCR_Laser: "",
    Squint_Surgery: "",
    Entropion: "",
    E_U_A: "",
    Exentration: "",
    Artificial_Eye: "",
    Lensectomy_Foldable_IOL: "",
    Blepheroplasty_Orbital_reconstruction_skin_graft: "",
    Surgical_Capsuloctomy: "",
    Ptosis_Correction: "",
    Cyclocryopoxy: "",
    Syringing_Probing: "",
    Corneal_Repair_Scleral_Rep: "",
    Corneal_Repair_PCIOL: "",
    Corneal_Cross_Linking: "",
    PKP_PCIOL_Scleralfix: "",
    PKP: "",
    Dermiod_Cyst_Excision: "",
    Tarsoraphy: "",
    Removed_of_Silicon_OIL_IOL: "",
    Removed_of_Silicon_OIL: "",
    Removal_of_IOL_Exchange_IOL: "",
    PHACO_PPV: "",
    DALK_DSAEK: "",
    PPV: "",
    Silicon_Oil_Inject: "",
    PPV_PPL_PC_AC_IOL: "",
    Orbital_Implant: "",
    Anterior_VIT_A_C_wash: "",
    Resuturing_Globe_Rupture: "",
    Reposting_of_IOL_Ant_Vit: "",
    Tumor_Excision: "",
    Biopcy: "",
    Ahmed_Valve_Implant: "",
    Keratoprothesis: "",
    Scleral_Graft: "",
    Lid_repair: "",
    PI_Iridectomy: "",
    Preposition_of_Iris_Prolapse_Pupilo_Plasty: "",
    AC_Wash: "",
    Minor: "",
    Total_Days_of_Operations_Surgeries: "",
    Average_Per_Day_Surgeries: "",
    Percentage_of_Surg_from_OPD_Surgeries: "",
    // Lasers
    Yag_Laser_Capsulotomy: "",
    Yag_Laser_P_I: "",
    Argon_Laser_PRP: "",
    F_Photo: "",
    FFA: "",
    Perimetery: "",
    OCT: "",
    B_Scan: "",
    Others: "",
    // Injection
    Avastin_Injection: "",
    IVTA_subtenon: "",
    Kinacort_Injection: "",
    Vancomycine_Injectin: "",
    Inj_Avastin_IVTA: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const lang = useSelector(userLang);

  const view = useHaveUserPermission("hospitalstats", "view");
  const add = useHaveUserPermission("hospitalstats", "add");
  const change = useHaveUserPermission("hospitalstats", "change");
  const deletePerm = useHaveUserPermission("hospitalstats", "delete");
  const viewFile = useHaveUserPermission("hospitalsstatsfile", "view");
  const addFile = useHaveUserPermission("hospitalsstatsfile", "add");
  const changeFile = useHaveUserPermission("hospitalsstatsfile", "change");
  const deletePermFile = useHaveUserPermission("hospitalsstatsfile", "delete");

  const updateStats = async () => {
    setDisabledSave(true);

    const data = {
      // OPD
      // new: { male: "", female: "" },
      // old: { male: "", female: "" },
      OPD_new_female: inputsValue.new.female || 0,
      OPD_new_male: inputsValue.new.male || 0,
      OPD_old_male: inputsValue.old.male || 0,
      OPD_old_female: inputsValue.old.female || 0,
      Working_Days_OPD: inputsValue.Working_Days_OPD || 0,
      Average_Day_OPD: inputsValue.Average_Day_OPD || 0,
      // Surgeries
      Phaco_IOL: inputsValue.Phaco_IOL || 0,
      ECCE_PCIOL: inputsValue.ECCE_PCIOL || 0,
      Phaco_IOL_Avastin: inputsValue.Phaco_IOL_Avastin || 0,
      Evisearation: inputsValue.Evisearation || 0,
      Enucleation: inputsValue.Enucleation || 0,
      Phaco_Trabe: inputsValue.Phaco_Trabe || 0,
      AMG: inputsValue.AMG || 0,
      Secondary_PC_AC_IOL: inputsValue.Secondary_PC_AC_IOL || 0,
      SICS_PC_IOL: inputsValue.SICS_PC_IOL || 0,
      ECCE_ICCE: inputsValue.ECCE_ICCE || 0,
      Trabeculectomy_MMC: inputsValue.Trabeculectomy_MMC || 0,
      DCR_Laser: inputsValue.DCR_Laser || 0,
      Squint_Surgery: inputsValue.Squint_Surgery || 0,
      Entropion: inputsValue.Entropion || 0,
      E_U_A: inputsValue.E_U_A || 0,
      Exentration: inputsValue.Exentration || 0,
      Artificial_Eye: inputsValue.Artificial_Eye || 0,
      Lensectomy_Foldable_IOL: inputsValue.Lensectomy_Foldable_IOL || 0,
      Blepheroplasty_Orbital_reconstruction_skin_graft:
        inputsValue.Blepheroplasty_Orbital_reconstruction_skin_graft || 0,
      Surgical_Capsuloctomy: inputsValue.Surgical_Capsuloctomy || 0,
      Ptosis_Correction: inputsValue.Ptosis_Correction || 0,
      Cyclocryopoxy: inputsValue.Cyclocryopoxy || 0,
      Syringing_Probing: inputsValue.Syringing_Probing || 0,
      Corneal_Repair_Scleral_Rep: inputsValue.Corneal_Repair_Scleral_Rep || 0,
      Corneal_Repair_PCIOL: inputsValue.Corneal_Repair_PCIOL || 0,
      Corneal_Cross_Linking: inputsValue.Corneal_Cross_Linking || 0,
      PKP_PCIOL_Scleralfix: inputsValue.PKP_PCIOL_Scleralfix || 0,
      PKP: inputsValue.PKP || 0,
      Dermiod_Cyst_Excision: inputsValue.Dermiod_Cyst_Excision || 0,
      Tarsoraphy: inputsValue.Tarsoraphy || 0,
      Removed_of_Silicon_OIL_IOL: inputsValue.Removed_of_Silicon_OIL_IOL || 0,
      Removed_of_Silicon_OIL: inputsValue.Removed_of_Silicon_OIL || 0,
      Removal_of_IOL_Exchange_IOL: inputsValue.Removal_of_IOL_Exchange_IOL || 0,
      PHACO_PPV: inputsValue.PHACO_PPV || 0,
      DALK_DSAEK: inputsValue.DALK_DSAEK || 0,
      PPV: inputsValue.PPV || 0,
      Silicon_Oil_Inject: inputsValue.Silicon_Oil_Inject || 0,
      PPV_PPL_PC_AC_IOL: inputsValue.PPV_PPL_PC_AC_IOL || 0,
      Orbital_Implant: inputsValue.Orbital_Implant || 0,
      Anterior_VIT_A_C_wash: inputsValue.Anterior_VIT_A_C_wash || 0,
      Resuturing_Globe_Rupture: inputsValue.Resuturing_Globe_Rupture || 0,
      Reposting_of_IOL_Ant_Vit: inputsValue.Reposting_of_IOL_Ant_Vit || 0,
      Tumor_Excision: inputsValue.Tumor_Excision || 0,
      Biopcy: inputsValue.Biopcy || 0,
      Ahmed_Valve_Implant: inputsValue.Ahmed_Valve_Implant || 0,
      Keratoprothesis: inputsValue.Keratoprothesis || 0,
      Scleral_Graft: inputsValue.Scleral_Graft || 0,
      Lid_repair: inputsValue.Lid_repair || 0,
      PI_Iridectomy: inputsValue.PI_Iridectomy || 0,
      Preposition_of_Iris_Prolapse_Pupilo_Plasty:
        inputsValue.Preposition_of_Iris_Prolapse_Pupilo_Plasty || 0,
      AC_Wash: inputsValue.AC_Wash || 0,
      Minor: inputsValue.Minor || 0,
      Total_Days_of_Operations_Surgeries:
        inputsValue.Total_Days_of_Operations_Surgeries || 0,
      Average_Per_Day_Surgeries: inputsValue.Average_Per_Day_Surgeries || 0,
      Percentage_of_Surg_from_OPD_Surgeries:
        inputsValue.Percentage_of_Surg_from_OPD_Surgeries || 0,
      // Lasers
      Yag_Laser_Capsulotomy: inputsValue.Yag_Laser_Capsulotomy || 0,
      Yag_Laser_P_I: inputsValue.Yag_Laser_P_I || 0,
      Argon_Laser_PRP: inputsValue.Argon_Laser_PRP || 0,
      F_Photo: inputsValue.F_Photo || 0,
      FFA: inputsValue.FFA || 0,
      Perimetery: inputsValue.Perimetery || 0,
      OCT: inputsValue.OCT || 0,
      B_Scan: inputsValue.B_Scan || 0,
      Others: inputsValue.Others || 0,
      // Injection
      Avastin_Injection: inputsValue.Avastin_Injection || 0,
      IVTA_subtenon: inputsValue.IVTA_subtenon || 0,
      Kinacort_Injection: inputsValue.Kinacort_Injection || 0,
      Vancomycine_Injectin: inputsValue.Vancomycine_Injectin || 0,
      Inj_Avastin_IVTA: inputsValue.Inj_Avastin_IVTA || 0,
      hospital: hospital?.value,
      // year: moment(date).format("YYYY"),
      // month: moment(date).format("MM"),
      created_for_date: moment(date).format("YYYY-MM-01"),
    };
    const result = await api.updateHospitalStats(statsID, data);
    if (result.ok) {
      if (files.length) {
        await Promise.all(
          files.map(async (file) => {
            const dataFile = new FormData();
            dataFile.append("note", file.note);
            dataFile.append("file", file.file);
            dataFile.append("hospitalStats", result.data.id);
            await api.createHospitalStatsFiles(dataFile);
          })
        );

        getHospitalStats();
        toast.success("تم تعديل الاحصائيات بنجاح");
      } else {
        toast.success("تم تعديل الاحصائيات بنجاح");
      }
    }
    setDisabledSave(false);
    setStatsID(statsID);
  };
  const createStats = async () => {
    setDisabledSave(true);

    const data = {
      // OPD
      // new: { male: "", female: "" },
      // old: { male: "", female: "" },
      OPD_new_female: inputsValue.new.female || 0,
      OPD_new_male: inputsValue.new.male || 0,
      OPD_old_male: inputsValue.old.male || 0,
      OPD_old_female: inputsValue.old.female || 0,
      Working_Days_OPD: inputsValue.Working_Days_OPD || 0,
      Average_Day_OPD: inputsValue.Average_Day_OPD || 0,
      // Surgeries
      Phaco_IOL: inputsValue.Phaco_IOL || 0,
      ECCE_PCIOL: inputsValue.ECCE_PCIOL || 0,
      Phaco_IOL_Avastin: inputsValue.Phaco_IOL_Avastin || 0,
      Evisearation: inputsValue.Evisearation || 0,
      Enucleation: inputsValue.Enucleation || 0,
      Phaco_Trabe: inputsValue.Phaco_Trabe || 0,
      AMG: inputsValue.AMG || 0,
      Secondary_PC_AC_IOL: inputsValue.Secondary_PC_AC_IOL || 0,
      SICS_PC_IOL: inputsValue.SICS_PC_IOL || 0,
      ECCE_ICCE: inputsValue.ECCE_ICCE || 0,
      Trabeculectomy_MMC: inputsValue.Trabeculectomy_MMC || 0,
      DCR_Laser: inputsValue.DCR_Laser || 0,
      Squint_Surgery: inputsValue.Squint_Surgery || 0,
      Entropion: inputsValue.Entropion || 0,
      E_U_A: inputsValue.E_U_A || 0,
      Exentration: inputsValue.Exentration || 0,
      Artificial_Eye: inputsValue.Artificial_Eye || 0,
      Lensectomy_Foldable_IOL: inputsValue.Lensectomy_Foldable_IOL || 0,
      Blepheroplasty_Orbital_reconstruction_skin_graft:
        inputsValue.Blepheroplasty_Orbital_reconstruction_skin_graft || 0,
      Surgical_Capsuloctomy: inputsValue.Surgical_Capsuloctomy || 0,
      Ptosis_Correction: inputsValue.Ptosis_Correction || 0,
      Cyclocryopoxy: inputsValue.Cyclocryopoxy || 0,
      Syringing_Probing: inputsValue.Syringing_Probing || 0,
      Corneal_Repair_Scleral_Rep: inputsValue.Corneal_Repair_Scleral_Rep || 0,
      Corneal_Repair_PCIOL: inputsValue.Corneal_Repair_PCIOL || 0,
      Corneal_Cross_Linking: inputsValue.Corneal_Cross_Linking || 0,
      PKP_PCIOL_Scleralfix: inputsValue.PKP_PCIOL_Scleralfix || 0,
      PKP: inputsValue.PKP || 0,
      Dermiod_Cyst_Excision: inputsValue.Dermiod_Cyst_Excision || 0,
      Tarsoraphy: inputsValue.Tarsoraphy || 0,
      Removed_of_Silicon_OIL_IOL: inputsValue.Removed_of_Silicon_OIL_IOL || 0,
      Removed_of_Silicon_OIL: inputsValue.Removed_of_Silicon_OIL || 0,
      Removal_of_IOL_Exchange_IOL: inputsValue.Removal_of_IOL_Exchange_IOL || 0,
      PHACO_PPV: inputsValue.PHACO_PPV || 0,
      DALK_DSAEK: inputsValue.DALK_DSAEK || 0,
      PPV: inputsValue.PPV || 0,
      Silicon_Oil_Inject: inputsValue.Silicon_Oil_Inject || 0,
      PPV_PPL_PC_AC_IOL: inputsValue.PPV_PPL_PC_AC_IOL || 0,
      Orbital_Implant: inputsValue.Orbital_Implant || 0,
      Anterior_VIT_A_C_wash: inputsValue.Anterior_VIT_A_C_wash || 0,
      Resuturing_Globe_Rupture: inputsValue.Resuturing_Globe_Rupture || 0,
      Reposting_of_IOL_Ant_Vit: inputsValue.Reposting_of_IOL_Ant_Vit || 0,
      Tumor_Excision: inputsValue.Tumor_Excision || 0,
      Biopcy: inputsValue.Biopcy || 0,
      Ahmed_Valve_Implant: inputsValue.Ahmed_Valve_Implant || 0,
      Keratoprothesis: inputsValue.Keratoprothesis || 0,
      Scleral_Graft: inputsValue.Scleral_Graft || 0,
      Lid_repair: inputsValue.Lid_repair || 0,
      PI_Iridectomy: inputsValue.PI_Iridectomy || 0,
      Preposition_of_Iris_Prolapse_Pupilo_Plasty:
        inputsValue.Preposition_of_Iris_Prolapse_Pupilo_Plasty || 0,
      AC_Wash: inputsValue.AC_Wash || 0,
      Minor: inputsValue.Minor || 0,
      Total_Days_of_Operations_Surgeries:
        inputsValue.Total_Days_of_Operations_Surgeries || 0,
      Average_Per_Day_Surgeries: inputsValue.Average_Per_Day_Surgeries || 0,
      Percentage_of_Surg_from_OPD_Surgeries:
        inputsValue.Percentage_of_Surg_from_OPD_Surgeries || 0,
      // Lasers
      Yag_Laser_Capsulotomy: inputsValue.Yag_Laser_Capsulotomy || 0,
      Yag_Laser_P_I: inputsValue.Yag_Laser_P_I || 0,
      Argon_Laser_PRP: inputsValue.Argon_Laser_PRP || 0,
      F_Photo: inputsValue.F_Photo || 0,
      FFA: inputsValue.FFA || 0,
      Perimetery: inputsValue.Perimetery || 0,
      OCT: inputsValue.OCT || 0,
      B_Scan: inputsValue.B_Scan || 0,
      Others: inputsValue.Others || 0,
      // Injection
      Avastin_Injection: inputsValue.Avastin_Injection || 0,
      IVTA_subtenon: inputsValue.IVTA_subtenon || 0,
      Kinacort_Injection: inputsValue.Kinacort_Injection || 0,
      Vancomycine_Injectin: inputsValue.Vancomycine_Injectin || 0,
      Inj_Avastin_IVTA: inputsValue.Inj_Avastin_IVTA || 0,
      hospital: hospital?.value,
      // year: moment(date).format("YYYY"),
      // month: moment(date).format("MM"),
      created_for_date: moment(date).format("YYYY-MM-01"),
    };
    const result = await api.createHospitalStats(data);
    if (result.ok) {
      toast.success("تم إنشاء الاحصائيات بنجاح");
      if (files.length) {
        await Promise.all(
          files.map(async (file) => {
            const dataFile = new FormData();
            dataFile.append("note", file.note);
            dataFile.append("file", file.file);
            dataFile.append("hospitalStats", result.data.id);
            await api.createHospitalStatsFiles(dataFile);
          })
        );
        getHospitalStats();
      }
      setStatsID(result.data.id);
    }
    setDisabledSave(false);
  };
  const getHospital = async () => {
    const { ok, data } = await api.getHospitals("");
    if (ok) {
      setHospitals(data);
    }
  };

  const getHospitalStats = async () => {
    if (view) {
      // const result = await api.getHospitalStats(hospital?.value);
      // if (result.ok) {
      // const {} = result.data;
      // setStatsID(hospital?.value);

      const { ok, data } = await api.filterHospitalStats(
        null,
        null,
        moment(date).format("YYYY-MM-01"),
        hospital?.value
      );

      if (ok && data.length) {
        setStatsID(data[0].id);
        const statsdata = {
          // OPD
          new: {
            male: data[0].OPD_new_male || "",
            female: data[0].OPD_new_female || "",
          },
          old: {
            male: data[0].OPD_old_male || "",
            female: data[0].OPD_old_female || "",
          },

          Working_Days_OPD: data[0].Working_Days_OPD || "",
          Average_Day_OPD: data[0].Average_Day_OPD || "",
          // Surgeries
          Phaco_IOL: data[0].Phaco_IOL || "",
          ECCE_PCIOL: data[0].ECCE_PCIOL || "",
          Phaco_IOL_Avastin: data[0].Phaco_IOL_Avastin || "",
          Evisearation: data[0].Evisearation || "",
          Enucleation: data[0].Enucleation || "",
          Phaco_Trabe: data[0].Phaco_Trabe || "",
          AMG: data[0].AMG || "",
          Secondary_PC_AC_IOL: data[0].Secondary_PC_AC_IOL || "",
          SICS_PC_IOL: data[0].SICS_PC_IOL || "",
          ECCE_ICCE: data[0].ECCE_ICCE || "",
          Trabeculectomy_MMC: data[0].Trabeculectomy_MMC || "",
          DCR_Laser: data[0].DCR_Laser || "",
          Squint_Surgery: data[0].Squint_Surgery || "",
          Entropion: data[0].Entropion || "",
          E_U_A: data[0].E_U_A || "",
          Exentration: data[0].Exentration || "",
          Artificial_Eye: data[0].Artificial_Eye || "",
          Lensectomy_Foldable_IOL: data[0].Lensectomy_Foldable_IOL || "",
          Blepheroplasty_Orbital_reconstruction_skin_graft:
            data[0].Blepheroplasty_Orbital_reconstruction_skin_graft || "",
          Surgical_Capsuloctomy: data[0].Surgical_Capsuloctomy || "",
          Ptosis_Correction: data[0].Ptosis_Correction || "",
          Cyclocryopoxy: data[0].Cyclocryopoxy || "",
          Syringing_Probing: data[0].Syringing_Probing || "",
          Corneal_Repair_Scleral_Rep: data[0].Corneal_Repair_Scleral_Rep || "",
          Corneal_Repair_PCIOL: data[0].Corneal_Repair_PCIOL || "",
          Corneal_Cross_Linking: data[0].Corneal_Cross_Linking || "",
          PKP_PCIOL_Scleralfix: data[0].PKP_PCIOL_Scleralfix || "",
          PKP: data[0].PKP || "",
          Dermiod_Cyst_Excision: data[0].Dermiod_Cyst_Excision || "",
          Tarsoraphy: data[0].Tarsoraphy || "",
          Removed_of_Silicon_OIL_IOL: data[0].Removed_of_Silicon_OIL_IOL || "",
          Removed_of_Silicon_OIL: data[0].Removed_of_Silicon_OIL || "",
          Removal_of_IOL_Exchange_IOL:
            data[0].Removal_of_IOL_Exchange_IOL || "",
          PHACO_PPV: data[0].PHACO_PPV || "",
          DALK_DSAEK: data[0].DALK_DSAEK || "",
          PPV: data[0].PPV || "",
          Silicon_Oil_Inject: data[0].Silicon_Oil_Inject || "",
          PPV_PPL_PC_AC_IOL: data[0].PPV_PPL_PC_AC_IOL || "",
          Orbital_Implant: data[0].Orbital_Implant || "",
          Anterior_VIT_A_C_wash: data[0].Anterior_VIT_A_C_wash || "",
          Resuturing_Globe_Rupture: data[0].Resuturing_Globe_Rupture || "",
          Reposting_of_IOL_Ant_Vit: data[0].Reposting_of_IOL_Ant_Vit || "",
          Tumor_Excision: data[0].Tumor_Excision || "",
          Biopcy: data[0].Biopcy || "",
          Ahmed_Valve_Implant: data[0].Ahmed_Valve_Implant || "",
          Keratoprothesis: data[0].Keratoprothesis || "",
          Scleral_Graft: data[0].Scleral_Graft || "",
          Lid_repair: data[0].Lid_repair || "",
          PI_Iridectomy: data[0].PI_Iridectomy || "",
          Preposition_of_Iris_Prolapse_Pupilo_Plasty:
            data[0].Preposition_of_Iris_Prolapse_Pupilo_Plasty || "",
          AC_Wash: data[0].AC_Wash || "",
          Minor: data[0].Minor || "",
          Total_Days_of_Operations_Surgeries:
            data[0].Total_Days_of_Operations_Surgeries || "",
          Average_Per_Day_Surgeries: data[0].Average_Per_Day_Surgeries || "",
          Percentage_of_Surg_from_OPD_Surgeries:
            data[0].Percentage_of_Surg_from_OPD_Surgeries || "",
          // Lasers
          Yag_Laser_Capsulotomy: data[0].Yag_Laser_Capsulotomy || "",
          Yag_Laser_P_I: data[0].Yag_Laser_P_I || "",
          Argon_Laser_PRP: data[0].Argon_Laser_PRP || "",
          F_Photo: data[0].F_Photo || "",
          FFA: data[0].FFA || "",
          Perimetery: data[0].Perimetery || "",
          OCT: data[0].OCT || "",
          B_Scan: data[0].B_Scan || "",
          Others: data[0].Others || "",
          // Injection
          Avastin_Injection: data[0].Avastin_Injection || "",
          IVTA_subtenon: data[0].IVTA_subtenon || "",
          Kinacort_Injection: data[0].Kinacort_Injection || "",
          Vancomycine_Injectin: data[0].Vancomycine_Injectin || "",
          Inj_Avastin_IVTA: data[0].Inj_Avastin_IVTA || "",
        };
        setInputsValue(statsdata);
        const filesStats = await api.getHospitalStatsFiles(data[0].id);
        if (filesStats.ok) {
          setStatFiles(filesStats.data);
        }
        const histories = await api.getHospitalStatsHistories(data[0].id);
        if (histories.ok) {
          setStatsHistories(histories.data);
        }
      } else {
        setStatsID("");
        setStatsHistories([]);

        setInputsValue({
          // OPD
          new: { male: "", female: "" },
          old: { male: "", female: "" },
          // OPD_new_female: "",
          // OPD_new_male: "",
          // OPD_old_male: "",
          // OPD_old_female: "",
          Working_Days_OPD: "",
          Average_Day_OPD: "",
          // Surgeries
          Phaco_IOL: "",
          ECCE_PCIOL: "",
          Phaco_IOL_Avastin: "",
          Evisearation: "",
          Enucleation: "",
          Phaco_Trabe: "",
          AMG: "",
          Secondary_PC_AC_IOL: "",
          SICS_PC_IOL: "",
          ECCE_ICCE: "",
          Trabeculectomy_MMC: "",
          DCR_Laser: "",
          Squint_Surgery: "",
          Entropion: "",
          E_U_A: "",
          Exentration: "",
          Artificial_Eye: "",
          Lensectomy_Foldable_IOL: "",
          Blepheroplasty_Orbital_reconstruction_skin_graft: "",
          Surgical_Capsuloctomy: "",
          Ptosis_Correction: "",
          Cyclocryopoxy: "",
          Syringing_Probing: "",
          Corneal_Repair_Scleral_Rep: "",
          Corneal_Repair_PCIOL: "",
          Corneal_Cross_Linking: "",
          PKP_PCIOL_Scleralfix: "",
          PKP: "",
          Dermiod_Cyst_Excision: "",
          Tarsoraphy: "",
          Removed_of_Silicon_OIL_IOL: "",
          Removed_of_Silicon_OIL: "",
          Removal_of_IOL_Exchange_IOL: "",
          PHACO_PPV: "",
          DALK_DSAEK: "",
          PPV: "",
          Silicon_Oil_Inject: "",
          PPV_PPL_PC_AC_IOL: "",
          Orbital_Implant: "",
          Anterior_VIT_A_C_wash: "",
          Resuturing_Globe_Rupture: "",
          Reposting_of_IOL_Ant_Vit: "",
          Tumor_Excision: "",
          Biopcy: "",
          Ahmed_Valve_Implant: "",
          Keratoprothesis: "",
          Scleral_Graft: "",
          Lid_repair: "",
          PI_Iridectomy: "",
          Preposition_of_Iris_Prolapse_Pupilo_Plasty: "",
          AC_Wash: "",
          Minor: "",
          Total_Days_of_Operations_Surgeries: "",
          Average_Per_Day_Surgeries: "",
          Percentage_of_Surg_from_OPD_Surgeries: "",
          // Lasers
          Yag_Laser_Capsulotomy: "",
          Yag_Laser_P_I: "",
          Argon_Laser_PRP: "",
          F_Photo: "",
          FFA: "",
          Perimetery: "",
          OCT: "",
          B_Scan: "",
          Others: "",
          // Injection
          Avastin_Injection: "",
          IVTA_subtenon: "",
          Kinacort_Injection: "",
          Vancomycine_Injectin: "",
          Inj_Avastin_IVTA: "",
        });
      }
    }
  };
  const deleteStats = async () => {
    const result = await api.deleteHospitalStats(statsID);
    if (result.ok) {
      toast.success("تم حذف الاحصائيات بنجاح");
      setStatsID("");
      setInputsValue({
        // OPD
        new: { male: "", female: "" },
        old: { male: "", female: "" },
        // OPD_new_female: "",
        // OPD_new_male: "",
        // OPD_old_male: "",
        // OPD_old_female: "",
        Working_Days_OPD: "",
        Average_Day_OPD: "",
        // Surgeries
        Phaco_IOL: "",
        ECCE_PCIOL: "",
        Phaco_IOL_Avastin: "",
        Evisearation: "",
        Enucleation: "",
        Phaco_Trabe: "",
        AMG: "",
        Secondary_PC_AC_IOL: "",
        SICS_PC_IOL: "",
        ECCE_ICCE: "",
        Trabeculectomy_MMC: "",
        DCR_Laser: "",
        Squint_Surgery: "",
        Entropion: "",
        E_U_A: "",
        Exentration: "",
        Artificial_Eye: "",
        Lensectomy_Foldable_IOL: "",
        Blepheroplasty_Orbital_reconstruction_skin_graft: "",
        Surgical_Capsuloctomy: "",
        Ptosis_Correction: "",
        Cyclocryopoxy: "",
        Syringing_Probing: "",
        Corneal_Repair_Scleral_Rep: "",
        Corneal_Repair_PCIOL: "",
        Corneal_Cross_Linking: "",
        PKP_PCIOL_Scleralfix: "",
        PKP: "",
        Dermiod_Cyst_Excision: "",
        Tarsoraphy: "",
        Removed_of_Silicon_OIL_IOL: "",
        Removed_of_Silicon_OIL: "",
        Removal_of_IOL_Exchange_IOL: "",
        PHACO_PPV: "",
        DALK_DSAEK: "",
        PPV: "",
        Silicon_Oil_Inject: "",
        PPV_PPL_PC_AC_IOL: "",
        Orbital_Implant: "",
        Anterior_VIT_A_C_wash: "",
        Resuturing_Globe_Rupture: "",
        Reposting_of_IOL_Ant_Vit: "",
        Tumor_Excision: "",
        Biopcy: "",
        Ahmed_Valve_Implant: "",
        Keratoprothesis: "",
        Scleral_Graft: "",
        Lid_repair: "",
        PI_Iridectomy: "",
        Preposition_of_Iris_Prolapse_Pupilo_Plasty: "",
        AC_Wash: "",
        Minor: "",
        Total_Days_of_Operations_Surgeries: "",
        Average_Per_Day_Surgeries: "",
        Percentage_of_Surg_from_OPD_Surgeries: "",
        // Lasers
        Yag_Laser_Capsulotomy: "",
        Yag_Laser_P_I: "",
        Argon_Laser_PRP: "",
        F_Photo: "",
        FFA: "",
        Perimetery: "",
        OCT: "",
        B_Scan: "",
        Others: "",
        // Injection
        Avastin_Injection: "",
        IVTA_subtenon: "",
        Kinacort_Injection: "",
        Vancomycine_Injectin: "",
        Inj_Avastin_IVTA: "",
      });
      setStatsID("");
    } else {
      toast.error(strings2.error_delete[lang]);
    }
    setShowModal(false);
  };
  useEffect(() => {
    if (hospital?.value && date) {
      getHospitalStats();
    } else {
      setStatsID("");

      // setInputsValue({
      //   va_6_18_or_better: { male: "", female: "" },
      //   va_6_18: { male: "", female: "" },
      //   va_6_60: { male: "", female: "" },
      //   Absent: { male: "", female: "" },
      // });
    }
  }, [hospital, date]);
  useEffect(() => {
    document.title = "احصائيات المستشفيات";

    getHospital();
  }, []);
  return (
    <>
      <div
        dir={lang == "EN" ? "ltr" : "rtl"}
        className="container   mt-3 p-0 bg-light shadow pb-1  "
      >
        <div className="bg-primary d-flex p-5 ">
          <p className="fs-3 fw-bold  m-auto text-white text-center">
            {strings[lang].stats.hospital_stats}
          </p>
        </div>
        <div className="counatiner-fluid    ">
          <div className="row mx-0  mt-3  px-5 align-items-center justify-content-center">
            <div className=" col-lg-6 col-sm-12 ">
              <AutocomleteField
                value={hospital}
                setValue={setHospital}
                data={hospitals.map((h) => ({
                  value: h.id,
                  label:
                    lang == "AR"
                      ? `${h.country.country} / ${h.city.city} / ${h.hospital}`
                      : `${h.country.country_EN} / ${h.city.city_EN} / ${h.hospital_EN}`,
                }))}
                title={strings[lang].stats.hospital}
              />
              <div className=" p-2  mb-0 w-100">
                <label className="form-label">{strings[lang].stats.date}</label>
                <Datetime
                  closeOnSelect
                  dateFormat="YYYY-MM"
                  onChange={(v) => {
                    if (v) {
                      setDate(moment(v).format("YYYY-MM"));
                    } else {
                      setDate("");
                    }
                  }}
                  timeFormat={false}
                  inputProps={{
                    placeholder: strings[lang].common.select,
                    value: date,
                  }}
                />
              </div>
              {statsHistories.length > 0 && (
                <>
                  <div className=" p-2  mb-0 w-100">
                    <label className="form-label">
                      {strings[lang].stats.user}
                    </label>
                    <input
                      value={statsHistories[0].history_user}
                      readOnly
                      className="form-control  p-2  "
                    />
                  </div>
                  <div className=" p-2  mb-0 w-100">
                    <label className="form-label">
                      {strings[lang].stats.Modification_date}
                    </label>
                    <input
                      value={moment(statsHistories[0].history_date).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                      readOnly
                      className="form-control  p-2 text-end "
                      dir="ltr"
                    />
                  </div>
                </>
              )}

              <Dropzone files={files} setFiles={setFiles} />
            </div>
          </div>

          <div
            className="row mb-2 mt-3  mx-5   pb-5 "
            dir={lang == "EN" ? "ltr" : "rtl"}
          >
            <div className="col-sm-12 col-lg-4  d-flex  justify-content-center">
              <button
                onClick={statsID ? updateStats : createStats}
                className={`btn btn-success ms-2 px-5 m-lg-0 m-3 ${
                  disabledSave || !hospital || !date || (!change && !add)
                    ? "disabled"
                    : ""
                }`}
              >
                {statsID ? strings2.change[lang] : strings2.create[lang]}
                {disabledSave && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
            <div className="col-sm-12 col-lg-4  d-flex  justify-content-center">
              <HospitalFiles
                deleteFunc={api.deleteHospitalStatsFiles}
                deletePerm={deletePermFile}
                viewFile={viewFile}
                getFiles={api.getHospitalStatsFiles}
                id={statsID}
              />
            </div>
            <div className="col d-flex justify-content-center  mx-2">
              <button
                onClick={() => setShowModal(true)}
                className={`btn btn-danger m-lg-0 m-3   px-5 ${
                  !statsID || !deletePerm ? "disabled" : ""
                }`}
              >
                {strings[lang].stats.delete}
              </button>
            </div>
          </div>
        </div>
      </div>
      <HospitalDataTabel
        title={"OPD"}
        inputsValue={inputsValue}
        setInputsValue={setInputsValue}
        fields={OPD_FIELDS}
        OPDCount={OPDCount}
        setOPDCOunt={setOPDCOunt}
      />
      <HospitalDataTabel
        title={"Surgeries"}
        inputsValue={inputsValue}
        setInputsValue={setInputsValue}
        fields={Surgeries_FIELDS}
        OPDCount={OPDCount}
      />
      <HospitalDataTabel
        title={"Lasers"}
        inputsValue={inputsValue}
        setInputsValue={setInputsValue}
        fields={Lasers_FIELDS}
      />
      <HospitalDataTabel
        title={"Injection"}
        inputsValue={inputsValue}
        setInputsValue={setInputsValue}
        fields={Injection_FIELDS}
      />
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        id={statsID}
        deleteMethod={deleteStats}
      />
      <div
        className="row mb-2 mt-3  mx-5   pb-5 "
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <div className="col-sm-12 col-lg-4  d-flex  justify-content-center">
          <button
            onClick={statsID ? updateStats : createStats}
            className={`btn btn-success ms-2 px-5 m-lg-0 m-3 ${
              disabledSave || !hospital || !date || (!change && !add)
                ? "disabled"
                : ""
            }`}
          >
            {statsID ? strings2.change[lang] : strings2.create[lang]}
            {disabledSave && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
        <div className="col-sm-12 col-lg-4  d-flex  justify-content-center">
          <HospitalFiles
            deleteFunc={api.deleteHospitalStatsFiles}
            deletePerm={deletePermFile}
            viewFile={viewFile}
            getFiles={api.getHospitalStatsFiles}
            id={statsID}
          />
        </div>
        <div className="col d-flex justify-content-center  mx-2">
          <button
            onClick={() => setShowModal(true)}
            className={`btn btn-danger m-lg-0 m-3   px-5 ${
              !statsID || !deletePerm ? "disabled" : ""
            }`}
          >
            {strings[lang].stats.delete}
          </button>
        </div>
      </div>
    </>
  );
}
