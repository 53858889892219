import React from "react";
import strings from "../../components/common/strings.json";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";

export default function FinancialFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.financialFollowUps}
      permName={"financialfollowup"}
      permFileName="financialfollowupfile"
      permHistoryName={"historicalfinancialfollowup"}
      createFunc={api.createFinancialFollowUps}
      deleteFileFunc={api.deleteFinancialFollowUpsFile}
      deleteFunc={api.deleteFinancialFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.financial_follow_ups_status
          : data.financial_follow_ups_status_EN
      }
      getFilesFunc={api.getFinancialFollowUpsFile}
      getFollowUps={api.getFinancialFollowUps}
      getHistoryFunc={api.getFinancialFollowUpsHistory}
      getHistoryID={api.getFinancialFollowUpsHistoryID}
      searchPlan={{ financial: true }}
      sendFileFunc={api.sendFinancialFollowUpsFile}
      title={strings[lang].follow_up.financial_follow_up}
      updateFunc={api.updateFinancialFollowUps}
      deleteHistoryFunc={api.deleteFinancialFollowUpHistory}
    />
  );
}
