import React from "react";
import strings from "../../components/common/strings.json";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";

export default function PreparatoryVisitFollowUp() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.preparatoryVisitFollowUps}
      permName={"preparatoryvisitfollowup"}
      permFileName="preparatoryvisitfollowupfile"
      permHistoryName={"historicalpreparatoryvisitfollowup"}
      createFunc={api.createPreparatoryVisitFollowUps}
      deleteFileFunc={api.deletePreparatoryVisitFollowUpsFile}
      deleteFunc={api.deletePreparatoryVisitFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.Preparatory_visit_follow_ups_status
          : data.Preparatory_visit_follow_ups_status_EN
      }
      getFilesFunc={api.getPreparatoryVisitFollowUpsFile}
      getFollowUps={api.getPreparatoryVisitFollowUps}
      getHistoryFunc={api.getPreparatoryVisitFollowUpsHistory}
      getHistoryID={api.getPreparatoryVisitFollowUpsHistoryID}
      searchPlan={{ visit: true }}
      sendFileFunc={api.sendPreparatoryVisitFollowUpsFile}
      title={strings[lang].follow_up.preparatory_visit}
      updateFunc={api.updatePreparatoryVisitFollowUps}
      deleteHistoryFunc={api.deletePreparatoryVisitFollowUpHistory}
    />
  );
}
