import { create } from "apisauce";

export const api = create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASEURL
      : process.env.REACT_APP_BASEURL_DEV,
});

export const axiosBaseQuery =
  () =>
  async ({ url, method = "GET", data, params, headers }) => {
    try {
      const result = await api.axiosInstance({
        url: url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const getAnnualPlan = async () => {
  return api.get("data/annualPlan/?page_size=20&ordering=-camp_start_date");
};
const getPlan = async (id) => {
  return api.get(`data/annualPlan/${id}/`);
};
const searchAnnualPlan = async (data) => {
  return api.get(
    `data/annualPlan/?followUp=${data.followUp}&first_followup=${
      typeof data.first_followup == "boolean" ? data.first_followup : ""
    }&stats_filter=${
      typeof data.stats_filter == "boolean" ? data.stats_filter : ""
    }&second_followup=${
      typeof data.second_followup == "boolean" ? data.second_followup : ""
    }&ordering=${data.ordering || "-camp_start_date"}&page_size=${
      data.page_size || ""
    }&year=${data.year || ""}&country=${data.country || ""}&city=${
      data.city || ""
    }&status=${data.status || ""}&team=${data.team || ""}&sponsor=${
      data.sponsor || ""
    }&camp_start_date_after=${data.startDate || ""}&camp_end_date_before=${
      data.endDate || ""
    }&remark=${data.remark || ""}&sponsorship=${data.sponsorship || ""}&page=${
      data.page || 1
    }&search=${data.search || ""}&visit=${data.visit || ""}&financial=${
      data.financial || ""
    }&sponsors=${data.sponsors || ""}&media=${data.media || ""}&medication=${
      data.medication || ""
    }&financialsector=${data.financialsector || ""}&preparingtheteam=${
      data.preparingtheteam || ""
    }&reference_number=${data.reference_number || ""}&project_number=${
      data.project_number || ""
    }&followUpArchive=${
      typeof data.followUpArchive == "boolean" ? data.followUpArchive : ""
    }`
  );
};
const createAnnualPlan = async (data) => {
  return api.post("data/annualPlan/", data);
};

const getAnnualPlanHistories = async (id) => {
  return api.get(`data/AnnualPlanHistories/${id}`);
};
const deleteAnnualPlan = async (id) => {
  return api.delete(`data/annualPlan/${id}`);
};
const updateAnnualPlan = async (id, data) => {
  return api.put(`data/annualPlan/${id}/`, data);
};
const getCamps = async () => {
  return api.get("data/camps/");
};
const getCountries = async () => {
  return api.get("data/countries/");
};

const deleteCountries = async (id) => {
  return api.delete("data/countries/" + id);
};
const updateCountries = async (id, data) => {
  return api.put(`data/countries/${id}/`, data);
};
const createCountry = async (data) => {
  return api.post("data/countries/", data);
};
const getHospitalsCountries = async () => {
  return api.get("data/HospitalsCountries/");
};

const deleteHospitalsCountries = async (id) => {
  return api.delete("data/HospitalsCountries/" + id);
};
const updateHospitalsCountries = async (id, data) => {
  return api.put(`data/HospitalsCountries/${id}/`, data);
};
const createHospitalsCountry = async (data) => {
  return api.post("data/HospitalsCountries/", data);
};
const getCities = async (countryId) => {
  return api.get(`data/cities/?country__in=${countryId}`);
};
const getCity = async (id) => {
  return api.get(`data/cities/${id}/`);
};
const createCities = async (data) => {
  return api.post("data/cities/", data);
};
const deleteCities = async (id) => {
  return api.delete("data/cities/" + id);
};
const updateHospitalsCity = async (id, data) => {
  return api.put(`data/HospitalsCities/${id}/`, data);
};
const getHospitalsCities = async (countryId) => {
  return api.get(`data/HospitalsCities/?country__in=${countryId}`);
};
const getHospitalsCity = async (id) => {
  return api.get(`data/HospitalsCities/${id}/`);
};
const createHospitalsCities = async (data) => {
  return api.post("data/HospitalsCities/", data);
};
const deleteHospitalsCities = async (id) => {
  return api.delete("data/HospitalsCities/" + id);
};
const hospitalApiData = {
  deleteHospitalsCities,
  createHospitalsCities,
  getHospitalsCity,
  getHospitalsCities,
  updateHospitalsCity,
  createHospitalsCountry,
  updateHospitalsCountries,
  deleteHospitalsCountries,
  getHospitalsCountries,
};
const updateCity = async (id, data) => {
  return api.put(`data/cities/${id}/`, data);
};
const getTeams = async () => {
  return api.get("data/teams/");
};
const createTeams = async (data) => {
  return api.post("data/teams/", data);
};
const updateTeams = async (id, data) => {
  return api.put(`data/teams/${id}/`, data);
};
const deleteTeams = async (id) => {
  return api.delete(`data/teams/${id}/`);
};
const getStatus = async () => {
  return api.get("data/PlanStatus/");
};
const createStatus = async (data) => {
  return api.post("data/PlanStatus/", data);
};
const updateStatus = async (id, data) => {
  return api.put(`data/PlanStatus/${id}/`, data);
};
const deleteStatus = async (id) => {
  return api.delete(`data/PlanStatus/${id}/`);
};
const getResponsible = async () => {
  return api.get("data/Responsible/");
};
const createResponsible = async (data) => {
  return api.post("data/Responsible/", data);
};
const updateResponsible = async (id, data) => {
  return api.put(`data/Responsible/${id}/`, data);
};
const deleteResponsible = async (id) => {
  return api.delete(`data/Responsible/${id}/`);
};
// ------------users-------------
const login = async (data) => {
  return api.post("auth/token/login/", data);
};
const logout = async (data) => {
  return api.post("auth/token/logout/", data);
};

// ---------------financial------------------
const getFinancialFollowUps = async (planId) => {
  return api.get(`follow_ups/financial/?plan=${planId}`);
};

const deleteFinancialFollowUps = async (id) => {
  return api.delete(`follow_ups/financial/${id}/`);
};

const createFinancialFollowUps = async (data) => {
  return api.post(`follow_ups/financial/`, data);
};

const sendFinancialFollowUpsFile = async (data) => {
  return api.post(`follow_ups/financialFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getFinancialFollowUpsFile = async (id) => {
  return api.get(`follow_ups/financialFiles/?related_history_id=${id}`);
};
const deleteFinancialFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/financialFiles/${id}/`);
};

const updateFinancialFollowUps = async (id, data) => {
  return api.put(`follow_ups/financial/${id}/`, data);
};
const getFinancialFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/financialHistory/${id}`);
};
const getFinancialFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/financialHistoryID/${planId}`);
};
// ----------------sponsors-----------------
const getSponsors = async () => {
  return api.get("data/sponsor/");
};
const createSponsors = async (data) => {
  return api.post("data/sponsor/", data);
};
const deleteSponsor = async (id) => {
  return api.delete(`data/sponsor/${id}/`);
};
const updateSponsor = async (id, data) => {
  return api.put(`data/sponsor/${id}/`, data);
};

const getSponsorship = async (id) => {
  return api.get(`data/sponsorship/${id}/`);
};
const updateSponsorships = async (id, data) => {
  return api.put(`data/sponsorship/${id}/`, data);
};
const getSponsorships = async (sponsorId) => {
  return api.get(`data/sponsorship/?sponsor=${sponsorId}`);
};
const createSponsorships = async (data) => {
  return api.post("data/sponsorship/", data);
};
const deleteSponsorships = async (id) => {
  return api.delete(`data/sponsorship/${id}/`);
};

const getSponsorsFollowUps = async (planId) => {
  return api.get(`follow_ups/sponsors/?plan=${planId}`);
};
const deleteSponsorsFollowUps = async (id) => {
  return api.delete(`follow_ups/sponsors/${id}/`);
};
const createSponsorsFollowUps = async (data) => {
  return api.post(`follow_ups/sponsors/`, data);
};
const sendSponsorsFollowUpsFile = async (data) => {
  return api.post(`follow_ups/sponsorsFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getSponsorsFollowUpsFile = async (id) => {
  return api.get(`follow_ups/sponsorsFiles/?related_history_id=${id}`);
};
const deleteSponsorsFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/sponsorsFiles/${id}/`);
};
const updateSponsorsFollowUps = async (id, data) => {
  return api.put(`follow_ups/sponsors/${id}/`, data);
};
const getSponsorsFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/sponsorsHistory/${id}`);
};
const getSponsorsFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/sponsorsHistoryID/${planId}`);
};
// -----------media--------------
const getMediaFollowUps = async (planId) => {
  return api.get(`follow_ups/media/?plan=${planId}`);
};
const deleteMediaFollowUps = async (id) => {
  return api.delete(`follow_ups/media/${id}/`);
};
const createMediaFollowUps = async (data) => {
  return api.post(`follow_ups/media/`, data);
};
const sendMediaFollowUpsFile = async (data) => {
  return api.post(`follow_ups/mediaFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getMediaFollowUpsFile = async (id) => {
  return api.get(`follow_ups/mediaFiles/?related_history_id=${id}`);
};
const deleteMediaFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/mediaFiles/${id}/`);
};
const updateMediaFollowUps = async (id, data) => {
  return api.put(`follow_ups/media/${id}/`, data);
};
const getMediaFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/mediaHistory/${id}`);
};
const getMediaFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/mediaHistoryID/${planId}`);
};
// --------Medication-------------
const getMedicationFollowUps = async (planId) => {
  return api.get(`follow_ups/medication/?plan=${planId}`);
};
const deleteMedicationFollowUps = async (id) => {
  return api.delete(`follow_ups/medication/${id}/`);
};
const createMedicationFollowUps = async (data) => {
  return api.post(`follow_ups/medication/`, data);
};
const sendMedicationFollowUpsFile = async (data) => {
  return api.post(`follow_ups/medicationFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getMedicationFollowUpsFile = async (id) => {
  return api.get(`follow_ups/medicationFiles/?related_history_id=${id}`);
};
const deleteMedicationFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/medicationFiles/${id}/`);
};
const updateMedicationFollowUps = async (id, data) => {
  return api.put(`follow_ups/medication/${id}/`, data);
};
const getMedicationFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/medicationHistory/${id}`);
};
const getMedicationFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/medicationHistoryID/${planId}`);
};
// --------preparatory visit-------------
const getPreparatoryVisitFollowUps = async (planId) => {
  return api.get(`follow_ups/preparatoryvisit/?plan=${planId}`);
};
const deletePreparatoryVisitFollowUps = async (id) => {
  return api.delete(`follow_ups/preparatoryvisit/${id}/`);
};
const createPreparatoryVisitFollowUps = async (data) => {
  return api.post(`follow_ups/preparatoryvisit/`, data);
};
const sendPreparatoryVisitFollowUpsFile = async (data) => {
  return api.post(`follow_ups/preparatoryvisitFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getPreparatoryVisitFollowUpsFile = async (id) => {
  return api.get(`follow_ups/preparatoryvisitFiles/?related_history_id=${id}`);
};
const deletePreparatoryVisitFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/preparatoryvisitFiles/${id}/`);
};
const updatePreparatoryVisitFollowUps = async (id, data) => {
  return api.put(`follow_ups/preparatoryvisit/${id}/`, data);
};
const getPreparatoryVisitFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/preparatoryvisitHistory/${id}`);
};
const getPreparatoryVisitFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/preparatoryvisitHistoryID/${planId}`);
};
const preparatoryVisit = {
  getPreparatoryVisitFollowUps,
  deletePreparatoryVisitFollowUps,
  createPreparatoryVisitFollowUps,
  sendPreparatoryVisitFollowUpsFile,
  getPreparatoryVisitFollowUpsFile,
  updatePreparatoryVisitFollowUps,
  getPreparatoryVisitFollowUpsHistory,
  getPreparatoryVisitFollowUpsHistoryID,
  deletePreparatoryVisitFollowUpsFile,
};
// --------Financial Sector-------------
const getFinancialSectorFollowUps = async (planId) => {
  return api.get(`follow_ups/financialsector/?plan=${planId}`);
};
const deleteFinancialSectorFollowUps = async (id) => {
  return api.delete(`follow_ups/financialsector/${id}/`);
};
const createFinancialSectorFollowUps = async (data) => {
  return api.post(`follow_ups/financialsector/`, data);
};
const sendFinancialSectorFollowUpsFile = async (data) => {
  return api.post(`follow_ups/financialsectorFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getFinancialSectorFollowUpsFile = async (id) => {
  return api.get(`follow_ups/financialsectorFiles/?related_history_id=${id}`);
};
const deleteFinancialSectorFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/financialsectorFiles/${id}/`);
};
const updateFinancialSectorFollowUps = async (id, data) => {
  return api.put(`follow_ups/financialsector/${id}/`, data);
};
const getFinancialSectorFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/financialsectorHistory/${id}`);
};
const getFinancialSectorFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/financialsectorHistoryID/${planId}`);
};

const financialSector = {
  getFinancialSectorFollowUps,
  deleteFinancialSectorFollowUps,
  createFinancialSectorFollowUps,
  sendFinancialSectorFollowUpsFile,
  getFinancialSectorFollowUpsFile,
  updateFinancialSectorFollowUps,
  getFinancialSectorFollowUpsHistory,
  getFinancialSectorFollowUpsHistoryID,
  deleteFinancialSectorFollowUpsFile,
};
// --------preparing the team-------------
const getPreparingTheTeamFollowUps = async (planId) => {
  return api.get(`follow_ups/preparingtheteam/?plan=${planId}`);
};
const deletePreparingTheTeamFollowUps = async (id) => {
  return api.delete(`follow_ups/preparingtheteam/${id}/`);
};
const createPreparingTheTeamFollowUps = async (data) => {
  return api.post(`follow_ups/preparingtheteam/`, data);
};
const sendPreparingTheTeamFollowUpsFile = async (data) => {
  return api.post(`follow_ups/preparingtheteamFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getPreparingTheTeamFollowUpsFile = async (id) => {
  return api.get(`follow_ups/preparingtheteamFiles/?related_history_id=${id}`);
};
const deletePreparingTheTeamFollowUpsFile = async (id) => {
  return api.delete(`follow_ups/preparingtheteamFiles/${id}/`);
};
const updatePreparingTheTeamFollowUps = async (id, data) => {
  return api.put(`follow_ups/preparingtheteam/${id}/`, data);
};
const getPreparingTheTeamFollowUpsHistory = async (id) => {
  return api.get(`follow_ups/preparingtheteamHistory/${id}`);
};
const getPreparingTheTeamFollowUpsHistoryID = async (planId) => {
  return api.get(`follow_ups/preparingtheteamHistoryID/${planId}`);
};
const PreparingTheTeam = {
  getPreparingTheTeamFollowUps,
  deletePreparingTheTeamFollowUps,
  createPreparingTheTeamFollowUps,
  sendPreparingTheTeamFollowUpsFile,
  getPreparingTheTeamFollowUpsFile,
  deletePreparingTheTeamFollowUpsFile,
  updatePreparingTheTeamFollowUps,
  getPreparingTheTeamFollowUpsHistory,
  getPreparingTheTeamFollowUpsHistoryID,
};
// ----------reports--------
const getReportsFollowUps = async (data) => {
  return api.get(
    `data/reports/?sponsor=${data.sponsor || ""}&country=${data.country}&city=${
      data.city
    }&year=${data.year}&team=${data.team}&status=${data.status}&page=${
      data.page
    }&follow_up_status=${data.follow_up_status}&followUpArchive=${
      data.followUpArchive
    }&camp_start_date_after=${data.startDate || ""}&camp_start_date_before=${
      data.endDate || ""
    }`
  );
};
const getCollectiveReport = async (data) => {
  const params = new URLSearchParams();
  if (data?.sponsor) {
    params.append(
      "plan__sponsor__in",
      data.sponsor.map((d) => d.value)
    );
    params.append(
      "sponsor__in",
      data.sponsor.map((d) => d.value)
    );
  }
  if (data?.dateFrom) {
    params.append("plan__camp_start_date", data.dateFrom + "-01");
    params.append("camp_start_date", data.dateFrom + "-01");
  }
  if (data?.dateTo) {
    params.append("plan__camp_end_date", data.dateTo + "-01");
    params.append("camp_end_date", data.dateTo + "-01");
  }

  return api.get(`data/CollectiveReport?${params.toString()}`);
};
const getHospitalsCollectiveReport = async (data) => {
  const params = new URLSearchParams();
  if (data?.country)
    params.append(
      "country__in",
      data.country.map((d) => d.value)
    );
  if (data?.dateFrom)
    params.append("created_for_date_after", data.dateFrom + "-01");
  if (data?.dateTo)
    params.append("created_for_date_before", data.dateTo + "-01");
  return api.get(`data/HospitalsCollectiveReport?${params.toString()}`);
};
const getYearsReport = async () => {
  return api.get("data/YearsReport");
};
const getHospitalsYearsReports = async () => {
  return api.get("data/HospitalsYearsReport");
};
const getHospitalsCitiesReports = async () => {
  return api.get("data/HospitalsCitiesReport");
};
const HospitalsCountriesYearsReport = async () => {
  return api.get("data/HospitalsCountriesYearsReport");
};
const getCitiesReport = async () => {
  return api.get("data/CitiesReport");
};
const getCountriesReport = async () => {
  return api.get("data/CountriesReport");
};
const getSponsorReport = async () => {
  return api.get("data/SponsorReport");
};
const getTeamReport = async () => {
  return api.get("data/TeamReport");
};
const getStatusReport = async () => {
  return api.get("data/StatusReport");
};
const getChronologicalReport = async (year = 2022) => {
  return api.get(`data/TeamChronologicalReport/${year}`);
};
const getHospitalMonthlyReport = async (hospital, date) => {
  return api.get(`data/HospitalsAnalyticalReport/${hospital}/${date}`);
};
const reports = {
  getReportsFollowUps,
  getHospitalsYearsReports,
  getHospitalsCitiesReports,
  HospitalsCountriesYearsReport,
  getYearsReport,
  getCitiesReport,
  getSponsorReport,
  getTeamReport,
  getStatusReport,
  getCollectiveReport,
  getChronologicalReport,
  getCountriesReport,
  getHospitalsCollectiveReport,
  getHospitalMonthlyReport,
};
// ----------stats----------
const searchStats = async (id) => {
  return api.get(
    `data/stats/?camp_location=&surgeon_manager=&sponsor_local=&sponsor=&camp_manager=`
  );
};
const getStats = async (id) => {
  return api.get(`data/stats/${id}/`);
};
const createStats = async (data) => {
  return api.post(`data/stats/`, data);
};
const updateStats = async (id, data) => {
  return api.put(`data/stats/${id}/`, data);
};
const deleteStats = async (id) => {
  return api.delete(`data/stats/${id}/`);
};
// ----------stats First Follow up----------

const getFirstFollowUp = async (id) => {
  return api.get(`data/firstFollowUp/${id}/`);
};
const createFirstFollowUp = async (data) => {
  return api.post(`data/firstFollowUp/`, data);
};
const updateFirstFollowUp = async (id, data) => {
  return api.put(`data/firstFollowUp/${id}/`, data);
};
const deleteFirstFollowUp = async (id) => {
  return api.delete(`data/firstFollowUp/${id}/`);
};
const FirstFollowUp = {
  getFirstFollowUp,
  createFirstFollowUp,
  updateFirstFollowUp,
  deleteFirstFollowUp,
};
// ----------stats second Follow up----------

const getSecondFollowUp = async (id) => {
  return api.get(`data/secondFollow/${id}/`);
};
const createSecondFollowUp = async (data) => {
  return api.post(`data/secondFollow/`, data);
};
const updateSecondFollowUp = async (id, data) => {
  return api.put(`data/secondFollow/${id}/`, data);
};
const deleteSecondFollowUp = async (id) => {
  return api.delete(`data/secondFollow/${id}/`);
};
const SecondFollowUp = {
  getSecondFollowUp,
  createSecondFollowUp,
  updateSecondFollowUp,
  deleteSecondFollowUp,
};
// --------------permissions--------------
const getUserPermissions = async () => {
  return await api.get("core/permissionsUser/");
};
const getPermissions = async () => {
  return await api.get("core/permissions/");
};
const getGroupsPermissions = async () => {
  return await api.get("core/groups/");
};
const crateGroupsPermissions = async (data) => {
  return await api.post("core/groups/", data);
};
const updateGroupsPermissions = async (id, data) => {
  return await api.put(`core/groups/${id}/`, data);
};
const deleteGroupsPermissions = async (id) => {
  return await api.delete(`core/groups/${id}/`);
};
const getUsersGroupsPermissions = async (id) => {
  return await api.get("core/groupsUser/" + id);
};
// ------------users----------
const getUser = async () => {
  return api.get("auth/users/me/");
};
const getUsers = async () => {
  return api.get("core/usersList/");
};
const createUsers = async (data) => {
  return api.post("auth/users/", data);
};
const deleteUser = async (id) => {
  return await api.delete(`core/users/${id}`);
};
const updateUser = async (id, data) => {
  return await api.put("core/users/" + id, data);
};
const updateUserGroups = async (id, data) => {
  return await api.put("core/updateUserGroups/" + id, data);
};
const updateUserTeams = async (id, data) => {
  return await api.put("core/updateUserTeams/" + id, data);
};

const resetPassword = async (data) => {
  return await api.post(`auth/users/set_password/`, data);
};
// ----------Hospitals----------
const getHospitals = async (country) => {
  return await api.get(`data/hospital/?country__in=${country}`);
};
const createHospitals = async (data) => {
  return await api.post("data/hospital/", data);
};
const updateHospitals = async (id, data) => {
  return await api.put("data/hospital/" + id + "/", data);
};
const deleteHospitals = async (id) => {
  return await api.delete("data/hospital/" + id + "/");
};
const hospitalApi = {
  getHospitals,
  createHospitals,
  updateHospitals,
  deleteHospitals,
};
// ---------------- Hospital Stats ------------
const getHospitalStats = async (id) => {
  return await api.get(`data/hospitalStats/${id}`);
};
const getHospitalsDateReport = async (year) => {
  return await api.get(`data/HospitalsDateReport/${year}`);
};
const filterHospitalStats = async (country, city, date, hospital) => {
  return await api.get(
    `data/hospitalStats/?hospital__country=${country || ""}&hospital__city=${
      city || ""
    }&created_for_date=${date || ""}&hospital=${hospital || ""}`
  );
};
const hospitalStatsReports = async (country, city, date, hospital) => {
  return await api.get(
    `data/HospitalStatsReports/?hospital__country=${
      country || ""
    }&hospital__city=${city || ""}&created_for_date=${date || ""}&hospital=${
      hospital || ""
    }`
  );
};
const createHospitalStats = async (data) => {
  return await api.post(`data/hospitalStats/`, data);
};
const updateHospitalStats = async (id, data) => {
  return await api.put(`data/hospitalStats/${id}/`, data);
};
const deleteHospitalStats = async (id) => {
  return await api.delete(`data/hospitalStats/${id}/`);
};
const getHospitalStatsFiles = async (id) => {
  return await api.get(`data/hospitalStatsFiles/?hospitalStats=${id}`);
};
const getHospitalStatsHistories = async (id) => {
  return await api.get(`data/HosiptalStatsHistory/${id}`);
};
const createHospitalStatsFiles = async (data) => {
  return await api.post(`data/hospitalStatsFiles/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const updateHospitalStatsFiles = async (id, data) => {
  return await api.put(`data/hospitalStatsFiles/${id}/`, data);
};
const deleteHospitalStatsFiles = async (id) => {
  return await api.delete(`data/hospitalStatsFiles/${id}`);
};
const hospitalStatsApi = {
  getHospitalStats,
  hospitalStatsReports,
  createHospitalStats,
  updateHospitalStats,
  deleteHospitalStats,
  getHospitalStatsFiles,
  createHospitalStatsFiles,
  updateHospitalStatsFiles,
  deleteHospitalStatsFiles,
  filterHospitalStats,
  getHospitalStatsHistories,
  getHospitalsDateReport,
};

const updateContact = async (id, data) => {
  return api.put(`data/ContactPerson/${id}/`, data);
};
const getContact = async (sponsorId) => {
  return api.get(`data/ContactPerson/?sponsor=${sponsorId}`);
};
const createContact = async (data) => {
  return api.post("data/ContactPerson/", data);
};
const deleteContact = async (id) => {
  return api.delete(`data/ContactPerson/${id}/`);
};

// ----------------Folow up history---------------
const getFolowUpHistory = async (followUpID, page = 1, pageSize = 20) => {
  return await api.get(
    `follow_ups/followUpHistory/${followUpID}?page=${page}&page_size=${pageSize}`
  );
};
const deletepreparingtheteamHistory = async (id) => {
  return await api.delete(`follow_ups/deletepreparingtheteamHistory/${id}`);
};
const deleteMedicationFollowUpHistory = async (id) => {
  return await api.delete(`follow_ups/deleteMedicationFollowUpHistory/${id}`);
};
const deleteFinancialFollowUpHistory = async (id) => {
  return await api.delete(`follow_ups/deleteFinancialFollowUpHistory/${id}`);
};
const deleteSponsorsFollowUpHistory = async (id) => {
  return await api.delete(`follow_ups/deleteSponsorsFollowUpHistory/${id}`);
};
const deleteMediaFollowUpHistory = async (id) => {
  return await api.delete(`follow_ups/deleteMediaFollowUpHistory/${id}`);
};
const deleteFinancialSectorFollowUpHistory = async (id) => {
  return await api.delete(
    `follow_ups/deleteFinancialSectorFollowUpHistory/${id}`
  );
};
const deletePreparatoryVisitFollowUpHistory = async (id) => {
  return await api.delete(
    `follow_ups/deletePreparatoryVisitFollowUpHistory/${id}`
  );
};

export default {
  getAnnualPlan,
  deletepreparingtheteamHistory,
  deleteMedicationFollowUpHistory,
  deleteMedicationFollowUpHistory,
  deleteFinancialFollowUpHistory,
  deleteSponsorsFollowUpHistory,
  deleteMediaFollowUpHistory,
  deleteFinancialSectorFollowUpHistory,
  deletePreparatoryVisitFollowUpHistory,
  getFolowUpHistory,
  updateContact,
  getContact,
  createContact,
  deleteContact,
  ...hospitalApi,
  ...hospitalStatsApi,
  getStatus,
  createStatus,
  updateStatus,
  deleteStatus,
  getResponsible,
  createResponsible,
  updateResponsible,
  deleteResponsible,
  getCamps,
  getCountries,
  deleteAnnualPlan,
  createAnnualPlan,
  getCities,
  updateAnnualPlan,
  searchAnnualPlan,
  login,
  getFinancialFollowUps,
  createCountry,
  deleteCountries,
  updateCountries,
  createCities,
  deleteCities,
  updateCity,
  getTeams,
  createTeams,
  deleteTeams,
  updateTeams,
  getSponsors,
  createSponsors,
  updateSponsor,
  deleteSponsor,
  getSponsorships,
  createSponsorships,
  deleteSponsorships,
  updateSponsorships,
  getCity,
  getSponsorship,
  getFinancialFollowUpsHistory,
  getFinancialFollowUpsHistoryID,
  createFinancialFollowUps,
  updateFinancialFollowUps,
  sendFinancialFollowUpsFile,
  getFinancialFollowUpsFile,
  getUser,
  updateUserTeams,
  logout,
  deleteFinancialFollowUps,
  getSponsorsFollowUps,
  updateSponsorsFollowUps,
  createSponsorsFollowUps,
  deleteSponsorsFollowUps,
  sendSponsorsFollowUpsFile,
  getSponsorsFollowUpsHistory,
  getSponsorsFollowUpsHistoryID,
  getSponsorsFollowUpsFile,
  getMediaFollowUps,
  deleteMediaFollowUps,
  createMediaFollowUps,
  sendMediaFollowUpsFile,
  getMediaFollowUpsFile,
  updateMediaFollowUps,
  getMediaFollowUpsHistory,
  getMediaFollowUpsHistoryID,
  getMedicationFollowUps,
  deleteMedicationFollowUps,
  createMedicationFollowUps,
  sendMedicationFollowUpsFile,
  getMedicationFollowUpsFile,
  updateMedicationFollowUps,
  getMedicationFollowUpsHistory,
  getMedicationFollowUpsHistoryID,
  getStats,
  createStats,
  updateStats,
  deleteStats,
  getUserPermissions,
  getPermissions,
  getGroupsPermissions,
  getUsersGroupsPermissions,
  getUsers,
  deleteUser,
  updateUser,
  createUsers,
  updateUserGroups,
  crateGroupsPermissions,
  updateGroupsPermissions,
  deleteGroupsPermissions,
  resetPassword,
  getAnnualPlanHistories,
  ...preparatoryVisit,
  ...financialSector,
  ...FirstFollowUp,
  ...SecondFollowUp,
  ...reports,
  deleteFinancialFollowUpsFile,
  deleteMediaFollowUpsFile,
  deleteSponsorsFollowUpsFile,
  deleteMedicationFollowUpsFile,
  getPlan,
  ...hospitalApiData,
  ...PreparingTheTeam,
};
