import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useHospitalsCountriesData from "../hooks/useHospitalsCountriesData";
import { useDispatch, useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import {
  createCountry,
  deleteCountry,
  updateCountries,
} from "../store/reducers/hospitalsCountries";
export default function HospitalsCountries() {
  const [country, setCountry] = useState("");
  const [country_EN, setCountry_EN] = useState("");
  const [countryId, setCountryId] = useState();
  const [countryIdDelete, setCountryIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const { data: hosiptalsCountries } = useHospitalsCountriesData();
  const lang = useSelector(userLang);

  const dispatch = useDispatch();

  const location = useLocation();

  const addHosiptal = useHaveUserPermission("hospitalscountries", "add");
  const changeHosiptal = useHaveUserPermission("hospitalscountries", "change");
  const deletePermHosiptal = useHaveUserPermission(
    "hospitalscountries",
    "delete"
  );

  const createNewCountry = async () => {
    setDisableBtnCreate(true);
    dispatch(createCountry({ country, active, country_EN }));
    toast.success("تم إضافة الدولة بنجاح");

    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setActive(item.active);
    setCountry(item.country);
    setCountry_EN(item.country_EN);
    setCountryId(item.id);
  };
  const hamdleDeleteCountry = async (id) => {
    const resultAction = await dispatch(deleteCountry(id));
    if (deleteCountry.rejected.match(resultAction)) {
      toast.error(strings2.error_delete[lang]);
    }
    if (deleteCountry.fulfilled.match(resultAction)) {
      toast.success("تم حذف الدولة بنجاح");
    }
  };
  const handleUpdateCountry = async () => {
    dispatch(
      updateCountries({ id: countryId, data: { country, active, country_EN } })
    );
    toast.success("تم تعديل الدولة بنجاح");
  };
  useEffect(() => {
    document.title = "الدول";
  }, []);

  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.countries}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50">
          <input
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="الدولة"
          />
          <input
            value={country_EN}
            onChange={(e) => setCountry_EN(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="country"
          />
          <div className="form-check ">
            <input
              onChange={(e) => setActive(e.target.checked)}
              className="form-check-input float-none ms-0"
              type="checkbox"
              checked={active}
              id="flexCheckDefault"
            />
            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
              {strings[lang].data.active}
            </label>
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={handleUpdateCountry}
            className={`btn btn-success  ${
              disableBtnUpdate || !countryId || !changeHosiptal
                ? "disabled"
                : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createNewCountry}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !country || !addHosiptal ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {hosiptalsCountries.length > 0 && (
          <div className="table-responsive" style={{ margin: "0 20%" }}>
            <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">الدولة</th>
                  <th scope="col">country</th>
                  <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col">{strings[lang].data.active}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {hosiptalsCountries.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row" onClick={() => onClick(item)}>
                      {(location.pathname.includes("camps")
                        ? hosiptalsCountries.length
                        : hosiptalsCountries.length) - index}
                    </th>
                    <td onClick={() => onClick(item)}>{item.country}</td>
                    <td onClick={() => onClick(item)}>{item.country_EN}</td>
                    <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>
                    <td onClick={() => onClick(item)}>
                      <i
                        className={`fa ${
                          item.active
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <p
                        className={`mb-0 btn btn-outline-danger  ${
                          !deletePermHosiptal ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setCountryIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={countryIdDelete}
        deleteMethod={hamdleDeleteCountry}
      />
    </div>
  );
}
