import React, { useEffect, useState } from "react";
import api from "../api/api";
import DeleteModal from "../common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import strings from "../common/strings.json";
import strings2 from "../common/strings2.json";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import useSponsorsData from "../../hooks/useSponsorsData";
export default function ContactPerson({ sponsorId }) {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [pobox, setPobox] = useState("");
  // const [sponsorId, setSponsor] = useState();
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [contacts, setContacts] = useState([]);
  const [phone, setPhone] = useState("");
  const [Language, setLanguage] = useState("");
  const [phone2, setPhone2] = useState("");
  const [contactId, setContactId] = useState();
  const [contactIdDelete, setContactIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { data: sponsors } = useSponsorsData();
  const lang = useSelector(userLang);

  const view = useHaveUserPermission("contactperson", "view");
  const add = useHaveUserPermission("contactperson", "add");
  const change = useHaveUserPermission("contactperson", "change");
  const deletePerm = useHaveUserPermission("contactperson", "delete");
  const getContacts = async () => {
    if (view) {
      const result = await api.getContact(sponsorId);
      if (result.ok) {
        setContacts(result.data.reverse());
      }
    }
  };
  const createContacts = async () => {
    setDisableBtnCreate(true);
    const result = await api.createContact({
      name,
      location,
      phone,
      email,
      email2,
      phone2,
      Language,
      pobox,
      sponsor: sponsorId,
    });
    if (result.ok) {
      const newContacts = [...contacts];
      newContacts.unshift({
        sponsor: result.data.sponsor,
        name: result.data.name,
        location: result.data.location,
        id: result.data.id,
        email: result.data.email,
        email2: result.data.email2,
        phone: result.data.phone,
        phone2: result.data.phone2,
        Language: result.data.Language,
        pobox: result.data.pobox,
      });
      setContacts(newContacts);
      setContactId();
      setEmail("");
      setEmail2("");
      setPhone("");
      setPhone2("");
      setLanguage("");
      setName("");
      setLocation("");
      setPobox("");

      toast.success("تم إضافة جهة الاتصال بنجاح");
    }
    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setEmail(item.email ? item.email : "");
    setEmail2(item.email2 ? item.email2 : "");
    setPhone(item.phone ? item.phone : "");
    setPhone2(item.phone2 ? item.phone2 : "");
    setName(item.name);
    setPobox(item.pobox);
    setLanguage(item.Language);
    setLocation(item.location);
    setContactId(item.id);
  };
  const deleteContacts = async (id) => {
    const result = await api.deleteContact(id);
    if (result.ok) {
      const newSponsor = contacts.filter((i) => i.id != id);
      setContacts(newSponsor);
      setContactId();

      setShowModal(false);
      toast.success("تم حذف جهة الاتصال بنجاح");
    }
  };
  const updateSponsor = async () => {
    setDisableBtnUpdate(true);

    const result = await api.updateContact(contactId, {
      name,
      location,
      phone,
      email,
      email2,
      phone2,
      Language,
      pobox,
      sponsor: sponsorId,
    });
    if (result.ok) {
      const newContacts = [...contacts];
      const index = newContacts.findIndex((i) => i.id == contactId);
      newContacts[index].name = name;
      newContacts[index].location = location;
      newContacts[index].phone = phone;
      newContacts[index].email = email;
      newContacts[index].email2 = email2;
      newContacts[index].phone2 = phone2;
      newContacts[index].pobox = pobox;
      newContacts[index].Language = Language;
      setContacts(newContacts);
      setContactId();
      setName("");
      setLocation("");
      setEmail("");
      setEmail2("");
      setPhone("");
      setPhone2("");
      setPobox("");
      setLanguage("");
      toast.success("تم تعديل جهة الاتصال بنجاح");
    }
    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = strings2.contact[lang];

    if (sponsorId) {
      getContacts();
      setContactId();
      setName("");
      setLocation("");
      setEmail("");
      setEmail2("");
      setPhone("");
      setPhone2("");
      setPobox("");
      setLanguage("");
    }
  }, [sponsorId]);

  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings2.contact[lang]}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50 mt-3">
          {/* <AutocomleteField
            placeholder="الراعي"
            value={sponsorId}
            setValue={setSponsor}
            data={sponsorList.map((i) => ({
              label: lang == "AR" ? i.sponsor : i.sponsor_EN,
              value: i.id,
            }))}
          /> */}
          <div className="mx-2">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control  p-2  mb-3 mt-2"
              placeholder={strings2.name[lang]}
            />
            <input
              value={Language}
              onChange={(e) => setLanguage(e.target.value)}
              className="form-control  p-2  mb-3 mt-2"
              placeholder={strings2.lang[lang]}
            />
            <input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="form-control  p-2  mb-3 mt-2"
              placeholder={strings2.location[lang]}
            />
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={strings[lang].data.email}
            />
            <input
              type={"email"}
              value={email2}
              onChange={(e) => setEmail2(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={strings2.secound_email[lang]}
            />
            {/* <input
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={strings2.country[lang]}
            />
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={strings2.city[lang]}
            /> */}
            <input
              value={pobox}
              onChange={(e) => setPobox(e.target.value)}
              className="form-control  p-2  my-3"
              placeholder={"PO box"}
            />

            <input
              onWheel={(event) => event.currentTarget.blur()}
              type={"number"}
              value={phone}
              onChange={(e) => setPhone(e.target.value.toString())}
              className="form-control  p-2  my-3"
              placeholder={strings[lang].data.phone}
            />
            <input
              onWheel={(event) => event.currentTarget.blur()}
              type={"number"}
              value={phone2}
              onChange={(e) => setPhone2(e.target.value.toString())}
              className="form-control  p-2  my-3"
              placeholder={strings2.secound_phone[lang]}
            />
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={updateSponsor}
            className={`btn btn-success  ${
              disableBtnUpdate || !contactId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createContacts}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !name || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
      </div>
      {contacts.length > 0 && (
        <div className="table-responsive">
          <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{strings[lang].data.donors}</th>
                <th scope="col">{strings2.name[lang]}</th>
                <th scope="col">{strings2.lang[lang]}</th>
                <th scope="col">{strings2.location[lang]}</th>
                <th scope="col">{strings[lang].data.email}</th>
                <th scope="col">{strings2.secound_email[lang]}</th>

                <th scope="col">Pobox</th>
                <th scope="col">{strings[lang].data.phone}</th>
                <th scope="col">{strings2.secound_phone[lang]}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((item, index) => (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <th scope="row" onClick={() => onClick(item)}>
                    {contacts.length - index}
                  </th>
                  <td onClick={() => onClick(item)}>
                    {lang == "AR"
                      ? sponsors.find((i) => i.id == item.sponsor).sponsor
                      : sponsors.find((i) => i.id == item.sponsor).sponsor_EN}
                  </td>
                  <td onClick={() => onClick(item)}>{item.name}</td>
                  <td onClick={() => onClick(item)}>{item.Language}</td>
                  <td onClick={() => onClick(item)}>{item.location}</td>
                  <td onClick={() => onClick(item)}>{item.email}</td>
                  <td onClick={() => onClick(item)}>{item.email2}</td>
                  <td onClick={() => onClick(item)}>{item.pobox}</td>
                  <td onClick={() => onClick(item)}>{item.phone}</td>
                  <td onClick={() => onClick(item)}>{item.phone2}</td>

                  <td>
                    <p
                      className={`mb-0 btn btn-outline-danger  ${
                        !deletePerm ? "disabled" : ""
                      }`}
                      onClick={() => {
                        setContactIdDelete(item.id);
                        setShowModal(true);
                      }}
                    >
                      {strings[lang].data.delete}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={contactIdDelete}
        deleteMethod={deleteContacts}
      />
    </div>
  );
}
