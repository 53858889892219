import React, { useState, useRef } from "react";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import api from "../../components/api/api";
import AutocomleteField from "../../components/common/AutoComleteField";
import Datetime from "react-datetime";
import moment from "moment";
import {
  Injection_FIELDS,
  Lasers_FIELDS,
  OPD_FIELDS,
  Surgeries_FIELDS,
} from "../../components/stats/Hospital/fields";
import { writeFile, utils, write } from "xlsx";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import useHospitalsCountriesData from "../../hooks/useHospitalsCountriesData";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function HosptalMonthlyReport() {
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [date, setDate] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [stats, setStats] = useState([]);

  const { data: hosiptalsCountries } = useHospitalsCountriesData();

  const lang = useSelector(userLang);

  const setCountryAndGetCities = async (c) => {
    setCountry(c);
    if (c) {
      const result = await api.getHospitalsCities(c.value);
      if (result.ok) {
        setCitiesList(result.data.reverse());
      } else {
        setCitiesList([]);
      }
    }
  };
  const getStats = async () => {
    const { data, ok } = await api.hospitalStatsReports(
      country?.value ? country?.value : "",
      city?.value ? city?.value : "",
      date ? date : ""
    );
    if (ok) {
      setStats(data);
    }
  };
  const pickDataOPD = [
    { value: "OPD_new_female", action: "female (new)" },
    { value: "OPD_new_male", action: "male (new)" },
    {},
    { value: "OPD_old_female", action: "female (old)" },
    { value: "OPD_old_male", action: "male (old)" },
    {},
  ];
  const createExcel = () => {
    const table = document.getElementById("hospitals-reports");
    // const newTable = table.cloneNode(true);

    // newTable.childNodes[0].childNodes[0].childNodes[0].childNodes[0].remove();

    const wb = utils.table_to_book(table, {
      raw: true,
    });
    writeFile(wb, "reports.xlsx");
  };
  const tableToImg = async () => {
    const tabel = document.getElementById("hospitals-reports");
    return await htmlToImage.toPng(tabel);
  };
  const downloadPhoto = async () => {
    const img = await tableToImg();
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  const table_ref = useRef();
  const createPDF = async () => {
    const tabel = document.getElementById("hospitals-reports");
    const tabel_pdf = document.getElementById("tabel-pdf");
    while (tabel_pdf.firstChild) {
      tabel_pdf.removeChild(tabel_pdf.firstChild);
    }
    const tabel_pdf_clone = tabel.cloneNode(true);
    tabel_pdf_clone.id = "tabel-clone";
    tabel_pdf_clone.removeChild(tabel_pdf_clone.firstChild);
    const thead = document.createElement("thead");
    const tr = document.createElement("tr");
    const hospitalName = document.createElement("th");
    tr.appendChild(hospitalName);
    hospitalName.innerText = "Hospital Name	";
    stats.map((s) => {
      const name = document.createElement("th");
      name.innerText = s.hospital.hospital_EN;
      tr.appendChild(name);
    });
    const total = document.createElement("th");
    total.innerText = "Total";
    tr.appendChild(total);
    thead.appendChild(tr);
    tabel_pdf_clone.insertBefore(thead, tabel_pdf_clone.firstChild);

    tabel_pdf.appendChild(tabel_pdf_clone);
    const doc = new jsPDF("p", "mm", "a4");
    autoTable(doc, {
      html: "#tabel-clone",
    });

    // // var width = doc.internal.pageSize.getWidth();

    // // // doc.addImage(imgURL, "JPEG", 0, 0, width, 0);
    doc.save("reports.pdf");
  };
  function CloneTabel(props) {
    return (
      <div>
        {React.cloneElement(props.children, {
          className: "cloned",
          children: React.Children.map(props.children.props.children, (child) =>
            React.cloneElement(child, { className: "cloned" })
          ),
        })}
      </div>
    );
  }
  const deleteFields = () => {
    setCity(null);
    setCountry(null);
    setDate("");
  };
  return (
    <div className="flex flex-column">
      <div
        className="col-md-6 col-12 mx-auto d-flex flex-md-row flex-column justify-content-center align-items-center"
        dir={lang == "AR" ? "rtl" : "ltr"}
      >
        <AutocomleteField
          value={country}
          setValue={setCountryAndGetCities}
          placeholder={strings[lang].data.country}
          data={hosiptalsCountries.map((item) => ({
            label: lang == "AR" ? item.country : item.country_EN,
            value: item.id,
          }))}
        />
        <AutocomleteField
          value={city}
          setValue={setCity}
          placeholder={strings[lang].data.city}
          data={citiesList.map((item) => ({
            label: lang == "AR" ? item.city : item.city_EN,
            value: item.id,
          }))}
        />
        <div className="d-flex">
          <Datetime
            className="p-2 w-40"
            closeOnSelect
            dateFormat="YYYY-MM"
            onChange={(v) => {
              if (v) {
                setDate(moment(v).format("YYYY-MM-01"));
              } else {
                setDate("");
              }
            }}
            timeFormat={false}
            inputProps={{
              placeholder: strings2.choose_a_date[lang],
              value: date ? moment(date).format("YYYY-MM") : "",
            }}
          />
        </div>
      </div>
      <div className="row justify-content-around mb-3 w-sm-100 col-sm-5 col-md-6 col-12 mx-auto">
        <button
          onClick={getStats}
          className={`btn btn-primary col-4 ${!date ? "disabled" : ""}`}
        >
          {strings[lang].reports.search}
        </button>
        <button
          onClick={deleteFields}
          style={{ width: "40%" }}
          className={`btn btn-success `}
        >
          {strings2.Delete_fields[lang]}
        </button>
        <div
          style={{ width: "35%", marginTop: 10 }}
          className="dropdown-center "
        >
          <button
            style={{ width: "100%" }}
            type="button"
            className={`btn btn-warning dropdown-toggle `}
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {strings[lang].reports.download}
          </button>
          <ul className="dropdown-menu">
            <li style={{ cursor: "pointer" }} onClick={downloadPhoto}>
              <span className="dropdown-item text-end">
                {strings[lang].reports.photo}
              </span>
            </li>
            <li style={{ cursor: "pointer" }} onClick={createExcel}>
              <span className="dropdown-item text-end">{"Excel"}</span>
            </li>

            <li>
              <hr className="dropdown-divider" />
            </li>
            <li onClick={createPDF} style={{ cursor: "pointer" }}>
              <span className="dropdown-item text-end">PDF</span>
            </li>
          </ul>
        </div>
        {/* <button onClick={createExcel} className="btn btn-warning col-4">
              {strings[lang].reports.download}
            </button> */}
      </div>
      {stats.length > 0 && (
        <div
          className="table-responsive "
          style={{
            maxHeight: "67vh",
            overflow: "auto",
          }}
        >
          <table
            ref={table_ref}
            style={{ backgroundColor: "#fff" }}
            id="hospitals-reports"
            className="table table-striped  table-bordered text-center align-middle"
            dir={lang == "EN" ? "ltr" : "rtl"}
          >
            <thead
              className="header-tabel"
              style={{ position: "sticky", top: 0 }}
            >
              <tr>
                <th scope="col">Hospital Name</th>
                {stats.map((s) => (
                  <th scope="col" key={s.hospital.id} className="text-nowrap">
                    {lang == "AR"
                      ? s.hospital.hospital
                      : s.hospital.hospital_EN}
                  </th>
                ))}
                <th scope="col">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {/* --------------OPD---------------- */}

              <tr className="bg-secondary">
                <td colSpan={stats.length + 2} className="text-white">
                  OPD
                </td>
              </tr>
              {pickDataOPD.map((item, index) => {
                if (item.action) {
                  return (
                    <tr key={index}>
                      <td>{item.action}</td>
                      {stats.map((s, i) => (
                        <td key={i}>{s[item.value].toLocaleString()}</td>
                      ))}
                      <td>
                        {stats
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue[item.value],
                            0
                          )
                          .toLocaleString()}
                      </td>
                    </tr>
                  );
                }
                if (index == 2) {
                  return (
                    <tr key={index} className="header-tabel ">
                      <td className="text-white">Total</td>
                      {stats.map((s, i) => (
                        <td key={i} className="text-white">
                          {(
                            s["OPD_new_female"] + s["OPD_new_male"]
                          ).toLocaleString()}
                        </td>
                      ))}
                      <td className="text-white">
                        {stats
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator +
                              currentValue["OPD_new_female"] +
                              currentValue["OPD_new_male"],
                            0
                          )
                          .toLocaleString()}
                      </td>
                    </tr>
                  );
                }
                if (index == 5) {
                  return (
                    <tr key={index} className="header-tabel ">
                      <td className="text-white">Total</td>
                      {stats.map((s, i) => (
                        <td key={i} className="text-white">
                          {(
                            s["OPD_old_female"] + s["OPD_old_male"]
                          ).toLocaleString()}
                        </td>
                      ))}

                      <td className="text-white">
                        {stats
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator +
                              currentValue["OPD_old_female"] +
                              currentValue["OPD_old_male"],
                            0
                          )
                          .toLocaleString()}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr className="">
                <td className="">New + Old</td>
                {stats.map((s, i) => {
                  return (
                    <td key={i} className="">
                      {(
                        s["OPD_old_female"] +
                        s["OPD_old_male"] +
                        s["OPD_new_male"] +
                        s["OPD_new_female"]
                      ).toLocaleString()}
                    </td>
                  );
                })}
                <td>
                  {stats
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator +
                        currentValue["OPD_old_female"] +
                        currentValue["OPD_old_male"] +
                        currentValue["OPD_new_male"] +
                        currentValue["OPD_new_female"],
                      0
                    )
                    .toLocaleString()}
                </td>
              </tr>

              {/* return  Working_Days_OPD and Average_Day_OPD fields */}
              {OPD_FIELDS.map((item, index) => {
                const key = item.value;
                if (key !== "old" && key !== "new") {
                  return (
                    <tr key={index}>
                      <td>{item.action}</td>
                      {stats.map((s, i) => {
                        // Calculate the sum of all fields and divide it by the number of days
                        const total =
                          s["OPD_old_female"] +
                          s["OPD_old_male"] +
                          s["OPD_new_female"] +
                          s["OPD_new_male"];
                        const days = s["Working_Days_OPD"];
                        return (
                          <td key={i}>
                            {key == "Average_Day_OPD"
                              ? days
                                ? (total / days).toFixed(2).toLocaleString()
                                : ""
                              : s[key]}
                          </td>
                        );
                      })}
                      <td></td>
                    </tr>
                  );
                }
              })}

              {/* --------------Surgeries---------------- */}

              <tr className="bg-secondary">
                <td colSpan={stats.length + 2} className="text-white">
                  Surgeries
                </td>
              </tr>
              {Surgeries_FIELDS.filter((i) => {
                return (
                  i.value !== "Total_Days_of_Operations_Surgeries" &&
                  i.value != "Average_Per_Day_Surgeries" &&
                  i.value != "Percentage_of_Surg_from_OPD_Surgeries"
                );
              }).map((item, index) => {
                const key = item.value;

                return (
                  <tr key={index}>
                    <td className="d-flex justify-content-between">
                      <span className="fw-bold">{index + 1 + "    "}</span>
                      <span>{item.action}</span>
                    </td>
                    {stats.map((s, i) => (
                      <td key={i}>{s[key].toLocaleString()}</td>
                    ))}
                    <td>
                      {stats
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue[key],
                          0
                        )
                        .toLocaleString()}
                    </td>
                  </tr>
                );
              })}
              <tr className="header-tabel ">
                <td className="text-white">Total</td>
                {stats.map((s, i) => (
                  <td key={i} className="text-white">
                    {Surgeries_FIELDS.filter((i) => {
                      return (
                        i.value !== "Total_Days_of_Operations_Surgeries" &&
                        i.value != "Average_Per_Day_Surgeries" &&
                        i.value != "Percentage_of_Surg_from_OPD_Surgeries"
                      );
                    })
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + s[currentValue.value],
                        0
                      )
                      .toLocaleString()}
                  </td>
                ))}
                <td className="text-white">
                  {stats
                    .map((s, i) => {
                      return Surgeries_FIELDS.filter((i) => {
                        return (
                          i.value !== "Total_Days_of_Operations_Surgeries" &&
                          i.value != "Average_Per_Day_Surgeries" &&
                          i.value != "Percentage_of_Surg_from_OPD_Surgeries"
                        );
                      }).reduce(
                        (accumulator, currentValue) =>
                          accumulator + s[currentValue.value],
                        0
                      );
                    })
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )
                    .toLocaleString()}
                </td>
              </tr>
              {/* return only Total_Days_of_Operations_Surgeries Average_Per_Day_Surgeries Percentage_of_Surg_from_OPD_Surgeries fields */}
              {Surgeries_FIELDS.filter((i) => {
                return (
                  i.value == "Total_Days_of_Operations_Surgeries" ||
                  i.value == "Average_Per_Day_Surgeries" ||
                  i.value == "Percentage_of_Surg_from_OPD_Surgeries"
                );
              }).map((item, index) => {
                const key = item.value;
                return (
                  <tr key={index}>
                    <td>{item.action}</td>
                    {stats.map((s, i) => {
                      // Calculate the sum of all fields and divide it by the number of days
                      const total_Surgeries = Surgeries_FIELDS.filter((i) => {
                        return (
                          i.value !== "Total_Days_of_Operations_Surgeries" &&
                          i.value != "Average_Per_Day_Surgeries" &&
                          i.value != "Percentage_of_Surg_from_OPD_Surgeries"
                        );
                      }).reduce(
                        (accumulator, currentValue) =>
                          accumulator + s[currentValue.value],
                        0
                      );

                      const days = s["Total_Days_of_Operations_Surgeries"];
                      const Average_Per_Day_filed_value = days
                        ? Math.ceil(total_Surgeries / days)
                        : "";
                      const total_OPD =
                        s["OPD_old_female"] +
                        s["OPD_old_male"] +
                        s["OPD_new_female"] +
                        s["OPD_new_male"];

                      let final_field_value;
                      if (key == "Average_Per_Day_Surgeries") {
                        final_field_value = Average_Per_Day_filed_value;
                      } else if (
                        key == "Percentage_of_Surg_from_OPD_Surgeries"
                      ) {
                        final_field_value =
                          ((total_Surgeries / total_OPD) * 100).toFixed(2) +
                          "%";
                      } else {
                        final_field_value = s[key];
                      }
                      return (
                        <td key={i}>{final_field_value.toLocaleString()}</td>
                      );
                    })}
                    <td></td>
                  </tr>
                );
              })}
              {/* --------------Lasers---------------- */}

              <tr className="bg-secondary">
                <td colSpan={stats.length + 2} className="text-white">
                  Lasers
                </td>
              </tr>
              {Lasers_FIELDS.map((item, index) => {
                const key = item.value;

                return (
                  <tr key={index}>
                    <td>{item.action}</td>
                    {stats.map((s, i) => (
                      <td key={i}>{s[key].toLocaleString()}</td>
                    ))}
                    <td>
                      {stats
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue[key],
                          0
                        )
                        .toLocaleString()}
                    </td>
                  </tr>
                );
              })}
              <tr className="header-tabel ">
                <td className="text-white">Total</td>
                {stats.map((s, i) => (
                  <td key={i} className="text-white">
                    {Lasers_FIELDS.reduce(
                      (accumulator, currentValue) =>
                        accumulator + s[currentValue.value],
                      0
                    ).toLocaleString()}
                  </td>
                ))}
                <td className="text-white">
                  {stats
                    .map((s, i) => {
                      return Lasers_FIELDS.reduce(
                        (accumulator, currentValue) =>
                          accumulator + s[currentValue.value],
                        0
                      );
                    })
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )
                    .toLocaleString()}
                </td>
              </tr>
              {/* --------------Injection---------------- */}
              <tr className="bg-secondary">
                <td colSpan={stats.length + 2} className="text-white">
                  Injection
                </td>
              </tr>
              {Injection_FIELDS.map((item, index) => {
                const key = item.value;

                return (
                  <tr key={index}>
                    <td>{item.action}</td>
                    {stats.map((s, i) => (
                      <td key={i}>{s[key].toLocaleString()}</td>
                    ))}
                    <td>
                      {stats
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue[key],
                          0
                        )
                        .toLocaleString()}
                    </td>
                  </tr>
                );
              })}
              <tr className="header-tabel ">
                <td className="text-white">Total</td>
                {stats.map((s, i) => (
                  <td key={i} className="text-white">
                    {Injection_FIELDS.reduce(
                      (accumulator, currentValue) =>
                        accumulator + s[currentValue.value],
                      0
                    ).toLocaleString()}
                  </td>
                ))}
                <td className="text-white">
                  {stats
                    .map((s, i) => {
                      return Injection_FIELDS.reduce(
                        (accumulator, currentValue) =>
                          accumulator + s[currentValue.value],
                        0
                      );
                    })
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    )
                    .toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div id="tabel-pdf" className=" d-none"></div>
    </div>
  );
}
