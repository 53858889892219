import React from "react";
import Modal from "react-bootstrap/Modal";

import { Chart } from "react-google-charts";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function ChartReport({ show, setShow, data = [] }) {
  const lang = useSelector(userLang);

  const chartToImg = async () => {
    const chart = document.getElementById("chart");
    return await htmlToImage.toPng(chart);
  };
  const downloadPhoto = async () => {
    const img = await chartToImg();
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  return (
    <Modal
      // dir={lang == "EN" ? "ltr" : "rtl"}
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <button className="btn" onClick={downloadPhoto}>
            <i className="fa fa-download fs-3" aria-hidden="true"></i>
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="chart">
        <Chart chartType="ColumnChart" width="100%" height="100%" data={data} />
      </Modal.Body>
    </Modal>
  );
}
