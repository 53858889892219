import React from "react";
import userImg from "../../asset/user.svg";
import { hideLoading, showLoading } from "../common/loadingFunctions";
import strings2 from "../common/strings2.json";
import {
  useLocation,
  NavLink,
  Link,
  useNavigate,
  redirect,
} from "react-router-dom";
import api from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import { setLang, userLang, userProfile } from "../../store/reducers/user";
import { signout } from "../../utils/signout";

export default function Menu({ menuData, viewUsers, viewGroup }) {
  const lang = useSelector(userLang);

  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(userProfile);
  const userMenu = [
    { label: strings2.users[lang], to: "/users", perm: viewUsers },
    { label: strings2.groups[lang], to: "/groups", perm: viewGroup },
    {
      label: strings2.reset_pass[lang],
      to: "/resetPassword",
      perm: true,
    },
    { label: "" },
    { label: strings2.logout[lang], to: "/login", perm: true },
  ];

  return (
    <>
      {(lang == "AR" || window.innerWidth <= 990
        ? menuData.reverse()
        : menuData
      )
        .filter((i) => i.perm)
        .map((item, index) => {
          if (!item.dropmenu) {
            //user menu
            if (item.label == "user") {
              return (
                <li key={index} className="nav-item dropdown  mx-lg-5">
                  <a
                    className={`nav-link dropdown-toggle fs-5  ${
                      lang == "AR" ? "text-end" : ""
                    } `}
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={userImg}
                      className="!inline-block"
                      style={{
                        height: 40,
                        width: 40,
                        backgroundColor: "#fff",

                        borderRadius: 25,
                        marginRight: 10,
                      }}
                    />
                    <span>{user?.username} </span>
                  </a>
                  <ul className="dropdown-menu">
                    {userMenu
                      .filter((i) => i.perm)
                      .map((item, index) => (
                        <li key={index}>
                          {item.label ? (
                            item.label == strings2.logout[lang] ? (
                              <button
                                className={`dropdown-item ${
                                  lang == "AR" ? "text-end" : ""
                                }`}
                                onClick={async () => {
                                  signout();
                                }}
                              >
                                {item.label}
                              </button>
                            ) : (
                              <NavLink
                                className={(isActive) =>
                                  `dropdown-item ${
                                    lang == "AR" ? "text-end" : ""
                                  }`
                                }
                                to={item.to}
                              >
                                {item.label}
                              </NavLink>
                            )
                          ) : (
                            <hr className="dropdown-divider" />
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
              );
            }
            //language menu
            if (item.label == strings2.lang[lang]) {
              return (
                <li key={index} className="nav-item dropdown ">
                  <a
                    className={`nav-link fs-5 dropdown-toggle  ${
                      lang == "AR" ? "text-end" : ""
                    }`}
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-globe me-2" aria-hidden="true"></i>

                    {/* <span>{strings[lang].navbar.lang}</span> */}
                  </a>
                  <ul
                    className={`dropdown-menu ${
                      lang == "AR" ? "text-end" : ""
                    } `}
                  >
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item "
                      onClick={() => {
                        localStorage.setItem("lang", "AR");
                        dispatch(setLang("AR"));
                      }}
                    >
                      عربي
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => {
                        localStorage.setItem("lang", "EN");

                        dispatch(setLang("EN"));
                      }}
                    >
                      EN
                    </li>
                  </ul>
                </li>
              );
            }
            if (item.perm) {
              const active =
                (location.pathname.includes("followUp") &&
                  item.label == strings2.follow_ups[lang]) ||
                location.pathname == item.to;
              return (
                <li
                  key={index}
                  className={`nav-item  ${lang == "AR" ? "text-end" : ""} `}
                >
                  <NavLink
                    className={({ isActive }) => {
                      return `nav-link  fs-5  ${
                        active ? "fw-bold active" : ""
                      } `;
                    }}
                    to={item.to}
                  >
                    {item.label}
                  </NavLink>
                </li>
              );
            }
          }
          //dropdown menu
          return (
            <li key={index} className="nav-item dropdown ">
              <a
                className={`nav-link fs-5 dropdown-toggle ${
                  lang == "AR" ? "text-end" : ""
                }`}
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  className={` ${
                    item.dropmenu.findIndex((i) => i.to == location.pathname) !=
                      -1 && item.label != strings2.system[lang]
                      ? "fw-bold active text-dark"
                      : ""
                  }`}
                >
                  {item.label}
                </span>
              </a>
              <ul className={`dropdown-menu ${lang == "AR" ? "text-end" : ""}`}>
                {item.dropmenu
                  .filter((i) => i.perm)
                  .map((item, index) => {
                    if (item.label) {
                      return (
                        <li key={index}>
                          <Link
                            className={`dropdown-item ${
                              location.pathname == item.to ? "active" : ""
                            } `}
                            to={item.to}
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    } else {
                      return <hr key={index} className="dropdown-divider" />;
                    }
                  })}
              </ul>
            </li>
          );
        })}
    </>
  );
}
