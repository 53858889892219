import React, { useState, useRef } from "react";
import strings from "../../components/common/strings.json";
import strings2 from "../../components/common/strings2.json";
import api from "../../components/api/api";
import AutocomleteField from "../../components/common/AutoComleteField";
import Datetime from "react-datetime";
import moment from "moment";

import { writeFile, utils, write } from "xlsx";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import useHospitalsCountriesData from "../../hooks/useHospitalsCountriesData";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function HosptalAnalyticalReport() {
  const [country, setCountry] = useState();
  const [hospital, setHospital] = useState();
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [hospitalsList, setHospitalsList] = useState([]);
  const [stats, setStats] = useState({});
  const { data: hosiptalsCountries } = useHospitalsCountriesData();

  const lang = useSelector(userLang);

  const setCountryAndGetHospitals = async (c) => {
    setCountry(c);
    if (c) {
      const result = await api.getHospitals([c.value]);
      if (result.ok) {
        setHospitalsList(result.data.reverse());
      } else {
        setHospitalsList([]);
      }
    }
  };
  const getStats = async () => {
    const { data, ok } = await api.getHospitalMonthlyReport(
      hospital?.value,
      `${year}-${month}-01`
    );
    if (ok) {
      setStats(data);
    }
  };
  const pickDataOPD = [
    { value: "OPD_new_female", action: "female (new)" },
    { value: "OPD_new_male", action: "male (new)" },
    {},
    { value: "OPD_old_female", action: "female (old)" },
    { value: "OPD_old_male", action: "male (old)" },
    {},
  ];
  const createExcel = () => {
    const table = document.getElementById("hospitals-reports");
    // const newTable = table.cloneNode(true);

    // newTable.childNodes[0].childNodes[0].childNodes[0].childNodes[0].remove();

    const wb = utils.table_to_book(table, {
      raw: true,
    });
    writeFile(wb, "reports.xlsx");
  };
  const tableToImg = async () => {
    const tabel = document.getElementById("hospitals-reports");
    return await htmlToImage.toPng(tabel);
  };
  const downloadPhoto = async () => {
    const img = await tableToImg();
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  const table_ref = useRef();
  const createPDF = async () => {
    const doc = new jsPDF("p", "mm", "a4");
    autoTable(doc, {
      html: "#tabel-clone",
    });

    // // var width = doc.internal.pageSize.getWidth();

    // // // doc.addImage(imgURL, "JPEG", 0, 0, width, 0);
    doc.save("reports.pdf");
  };
  const deleteFields = () => {
    setCountry(null);
    setHospital(null);
    setYear("");
    setMonth("");
  };
  return (
    <>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <div className="col-lg-4 col-12 " dir={lang == "AR" ? "rtl" : "ltr"}>
          <AutocomleteField
            value={country}
            setValue={setCountryAndGetHospitals}
            placeholder={strings[lang].data.country}
            data={hosiptalsCountries?.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
          />
          <AutocomleteField
            value={hospital}
            setValue={setHospital}
            placeholder={strings2.hospital[lang]}
            data={hospitalsList.map((item) => ({
              label: lang == "AR" ? item.hospital : item.hospital_EN,
              value: item.id,
            }))}
          />
          <div className="d-flex">
            <Datetime
              className="p-2"
              closeOnSelect
              dateFormat="YYYY"
              onChange={(v) => {
                if (v) {
                  setYear(moment(v).format("YYYY"));
                } else {
                  setYear("");
                }
              }}
              timeFormat={false}
              inputProps={{
                placeholder: strings2.year[lang],
                value: year,
              }}
            />
            <Datetime
              className="p-2"
              closeOnSelect
              dateFormat="MM"
              onChange={(v) => {
                if (v) {
                  setMonth(moment(v).format("MM"));
                } else {
                  setMonth("");
                }
              }}
              timeFormat={false}
              inputProps={{
                placeholder: strings2.month[lang],
                value: month,
              }}
            />
          </div>
          <div className="row justify-content-around mb-3">
            <button
              onClick={getStats}
              className={`btn btn-primary col-4 ${!year || !month || !country || !hospital ? "disabled" : ""
                }`}
            >
              {strings[lang].reports.search}
            </button>
            <button onClick={deleteFields} className={`btn btn-success col-4`}>
              {strings2.Delete_fields[lang]}
            </button>
            <div
              style={{ width: "35%", marginTop: 10 }}
              className="dropdown-center "
            >
              <button
                style={{ width: "100%" }}
                type="button"
                className={`btn btn-warning dropdown-toggle `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {strings[lang].reports.download}
              </button>
              <ul className="dropdown-menu">
                <li style={{ cursor: "pointer" }} onClick={downloadPhoto}>
                  <span className="dropdown-item text-end">
                    {strings[lang].reports.photo}
                  </span>
                </li>
                <li style={{ cursor: "pointer" }} onClick={createExcel}>
                  <span className="dropdown-item text-end">{"Excel"}</span>
                </li>

                {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}
                <li onClick={createPDF} style={{ cursor: "pointer" }}>
                  <span className="dropdown-item text-end">PDF</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive ">
        <table
          ref={table_ref}
          style={{ backgroundColor: "#fff" }}
          id="hospitals-reports"
          className="table   table-bordered text-center align-middle"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <tbody>
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Percentage_of_new_patients_to_old_patients[lang]}
              </td>
            </tr>
            <tr className="">
              <td colSpan={2}>{strings2.old_OPD[lang]}</td>
              <td>{strings2.new_OPD[lang]}</td>
              <td>{strings2.Percentage[lang]}</td>
            </tr>
            <tr>
              <td colSpan={2}>{stats.OPD_old}</td>
              <td>{stats.OPD_new}</td>
              <td>{stats.percentage_of_OPD_new_from_old}</td>
            </tr>
            {/* ------------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Percentage_of_surgeries_from_OPD[lang]}
              </td>
            </tr>
            <tr className="">
              <td colSpan={2}>{strings2.number_of_patients[lang]}</td>
              <td>{strings2.number_of_surgeries[lang]}</td>
              <td>{strings2.Percentage[lang]}</td>
            </tr>
            <tr>
              <td colSpan={2}>{stats.OPD_date}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_from_OPD}</td>
            </tr>
            {/* ---------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Patient_analysis_table_for_clinics[lang]}
              </td>
            </tr>
            <tr className="">
              <td>{strings2.details[lang]}</td>
              <td>{strings2.past_months[lang]}</td>
              <td>{strings2.current_month[lang]}</td>
              <td>{strings2.Percentage[lang]}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_month[lang]}</td>
              <td>{stats.OPD_last_month}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_last_month}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_year[lang]}</td>
              <td>{stats.OPD_last_year}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_last_year}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_three_years_ago[lang]}</td>
              <td>{stats.OPD_three_years_ago}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_three_years_ago}</td>
            </tr>
            {/* ---------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Operations_data_analysis_table[lang]}
              </td>
            </tr>
            <tr className="">
              <td>{strings2.details[lang]}</td>
              <td>{strings2.past_months[lang]}</td>
              <td>{strings2.current_month[lang]}</td>
              <td>{strings2.Percentage[lang]}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_month[lang]}</td>
              <td>{stats.surgeries_last_month}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_last_month}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_year[lang]}</td>
              <td>{stats.surgeries_last_year}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_last_year}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_three_years_ago[lang]}</td>
              <td>{stats.surgeries_three_years_ago}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_three_years_ago}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="tabel-pdf" className=" d-none">
        <table
          ref={table_ref}
          style={{ backgroundColor: "#fff" }}
          id="tabel-clone"
          className="table   table-bordered text-center align-middle"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <tbody>
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Percentage_of_new_patients_to_old_patients["EN"]}
              </td>
            </tr>
            <tr className="">
              <td colSpan={2}>{strings2.old_OPD["EN"]}</td>
              <td>{strings2.new_OPD["EN"]}</td>
              <td>{strings2.Percentage["EN"]}</td>
            </tr>
            <tr>
              <td colSpan={2}>{stats.OPD_old}</td>
              <td>{stats.OPD_new}</td>
              <td>{stats.percentage_of_OPD_new_from_old}</td>
            </tr>
            {/* ------------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Percentage_of_surgeries_from_OPD["EN"]}
              </td>
            </tr>
            <tr className="">
              <td colSpan={2}>{strings2.number_of_patients["EN"]}</td>
              <td>{strings2.number_of_surgeries["EN"]}</td>
              <td>{strings2.Percentage["EN"]}</td>
            </tr>
            <tr>
              <td colSpan={2}>{stats.OPD_date}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_from_OPD}</td>
            </tr>
            {/* ---------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Patient_analysis_table_for_clinics["EN"]}
              </td>
            </tr>
            <tr className="">
              <td>{strings2.details["EN"]}</td>
              <td>{strings2.past_months["EN"]}</td>
              <td>{strings2.current_month["EN"]}</td>
              <td>{strings2.Percentage["EN"]}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_month["EN"]}</td>
              <td>{stats.OPD_last_month}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_last_month}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_year["EN"]}</td>
              <td>{stats.OPD_last_year}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_last_year}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_three_years_ago["EN"]}</td>
              <td>{stats.OPD_three_years_ago}</td>
              <td>{stats.OPD_date}</td>
              <td>{stats.percentag_OPD_three_years_ago}</td>
            </tr>
            {/* ---------------------- */}
            <tr className="header-tabel">
              <td colSpan={4}>
                {strings2.Operations_data_analysis_table["EN"]}
              </td>
            </tr>
            <tr className="">
              <td>{strings2.details["EN"]}</td>
              <td>{strings2.past_months["EN"]}</td>
              <td>{strings2.current_month["EN"]}</td>
              <td>{strings2.Percentage["EN"]}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_month["EN"]}</td>
              <td>{stats.surgeries_last_month}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_last_month}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_last_year["EN"]}</td>
              <td>{stats.surgeries_last_year}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_last_year}</td>
            </tr>
            <tr>
              <td>{strings2.Total_compared_to_three_years_ago["EN"]}</td>
              <td>{stats.surgeries_three_years_ago}</td>
              <td>{stats.surgeries_date}</td>
              <td>{stats.percentag_surgeries_three_years_ago}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
