import React, { useEffect, useState } from "react";
import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import { useDispatch, useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
import useResponsibleData from "../hooks/useResponsibleData";
import {
  createResponsibleService,
  deleteResponsibleService,
  updateResponsibleService,
} from "../store/reducers/responsibles";
export default function Responsible() {
  const [responsible, setResponsible] = useState("");
  const [responsible_EN, setResponsible_EN] = useState("");
  const [responsibleId, setResponsibleId] = useState();
  const [statusIdDelete, setstatusIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);

  const { data: responsibles } = useResponsibleData();

  const lang = useSelector(userLang);
  const dispatch = useDispatch();

  const add = useHaveUserPermission("responsible", "add");
  const change = useHaveUserPermission("responsible", "change");
  const deletePerm = useHaveUserPermission("responsible", "delete");
  const createResponsible = async () => {
    setDisableBtnCreate(true);
    dispatch(
      createResponsibleService({
        responsible: responsible,
        responsible_EN: responsible_EN,
      })
    );

    toast.success("تم إضافة المسؤول بنجاح");
    setDisableBtnCreate(false);
  };

  const onClick = (item) => {
    setResponsible(item.responsible);
    setResponsible_EN(item.responsible_EN);
    setResponsibleId(item.id);
  };
  const deleteResponsible = async (id) => {
    const resultAction = await dispatch(deleteResponsibleService(id));
    if (deleteResponsibleService.rejected.match(resultAction)) {
      toast.error(strings2.error_delete[lang]);
    }
    if (deleteResponsibleService.fulfilled.match(resultAction)) {
      toast.success("تم حذف المسؤول بنجاح");
    }
  };
  const updateStatus = async () => {
    setDisableBtnUpdate(true);
    dispatch(
      updateResponsibleService({
        id: responsibleId,
        data: {
          responsible: responsible,
          responsible_EN: responsible_EN,
        },
      })
    );

    toast.success("تم تعديل المسؤول بنجاح");
    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "المسؤول";
  }, []);
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.Responsible}
        </p>
      </div>
      <div className=" d-flex justify-content-center align-items-center  flex-column ">
        <div className="w-50">
          <input
            value={responsible}
            onChange={(e) => setResponsible(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="المسؤول"
          />
          <input
            value={responsible_EN}
            onChange={(e) => setResponsible_EN(e.target.value)}
            className="form-control  p-2  my-3"
            placeholder="Responsible"
          />
        </div>
        <div className="d-flex">
          <button
            onClick={updateStatus}
            className={`btn btn-success  ${
              disableBtnUpdate || !responsibleId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createResponsible}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !responsible || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {responsibles.length > 0 && (
          <div className="table-responsive" style={{ margin: "0 20%" }}>
            <table className="table table-hover mt-4  table-bordered text-center align-middle container ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">المسؤول</th>
                  <th scope="col">Responsible</th>
                  {/* <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col">{strings[lang].data.active}</th> */}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {responsibles.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row" onClick={() => onClick(item)}>
                      {responsibles.length - index}
                    </th>
                    <td onClick={() => onClick(item)}>{item.responsible}</td>
                    <td onClick={() => onClick(item)}>{item.responsible_EN}</td>
                    {/* <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>
                    <td onClick={() => onClick(item)}>
                      <i
                        className={`fa ${
                          item.active
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td> */}
                    <td>
                      <p
                        className={`mb-0 btn btn-outline-danger  ${
                          !deletePerm ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setstatusIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={statusIdDelete}
        deleteMethod={deleteResponsible}
      />
    </div>
  );
}
