import React from "react";
import strings from "../../components/common/strings.json";

import data from "../../components/common/data.json";
import api from "../../components/api/api";
import FollowUp from "../../components/followUp/FollowUp";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import { useOutletContext } from "react-router-dom";

export default function MediaFollowUps() {
  const lang = useSelector(userLang);
  const { followUpsData } = useOutletContext();

  return (
    <FollowUp
      followUpData={followUpsData.mediaFollowUps}
      permName={"mediafollowup"}
      permFileName="mediafollowupfile"
      permHistoryName={"historicalmediafollowup"}
      createFunc={api.createMediaFollowUps}
      deleteFileFunc={api.deleteMediaFollowUpsFile}
      deleteFunc={api.deleteMediaFollowUps}
      follow_ups_status={
        lang == "AR"
          ? data.media_follow_ups_status
          : data.media_follow_ups_status_EN
      }
      getFilesFunc={api.getMediaFollowUpsFile}
      getFollowUps={api.getMediaFollowUps}
      getHistoryFunc={api.getMediaFollowUpsHistory}
      getHistoryID={api.getMediaFollowUpsHistoryID}
      searchPlan={{ media: true }}
      sendFileFunc={api.sendMediaFollowUpsFile}
      title={strings[lang].follow_up.media_follow_up}
      updateFunc={api.updateMediaFollowUps}
      deleteHistoryFunc={api.deleteMediaFollowUpHistory}
    />
  );
}
