import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import AutocomleteField from "../common/AutoComleteField";
import Dropzone from "../common/Dropzone";
import api from "../api/api";
import Attachments from "../common/Attachments";
import DeleteModal from "../common/DeleteModal";
import strings from "../common/strings.json";
import strings2 from "../common/strings2.json";
import FollowUpHistoriesModal from "./FollowUpHistoriesModal";
import useHaveUserPermission from "../../hooks/useHaveUserPermission";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function FollowUp({
  permName,
  permFileName,
  permHistoryName,
  searchPlan,
  follow_ups_status,
  deleteFunc,
  createFunc,
  getHistoryID,
  updateFunc,
  getHistoryFunc,
  sendFileFunc,
  title,
  deleteFileFunc,
  deleteHistoryFunc,
  getFilesFunc,
  followUpData,
}) {
  const [status, setStatus] = useState();
  const [endDate, setEndDate] = useState("");
  const [remark, setRemark] = useState();
  const [followUpID, setFollowUpID] = useState();
  const [historyID, setHistoryID] = useState();
  const [followUpHistory, setFollowUpHistory] = useState([]);
  const [files, setFiles] = useState([]);
  const [finish, setFinish] = useState(false);
  const [disableCreate, setDisableCreate] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [showHistortyModal, setShowHistortyModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [dropbox, setDropbox] = useState("");

  const lang = useSelector(userLang);

  const { planID, plan } = useOutletContext();

  const view = useHaveUserPermission(permName, "view");
  const add = useHaveUserPermission(permName, "add");
  const change = useHaveUserPermission(permName, "change");
  const deletePerm = useHaveUserPermission(permName, "delete");
  const deleteFilePerm = useHaveUserPermission(permFileName, "delete");
  const viewHistory = useHaveUserPermission(permHistoryName, "view");
  const deleteHistory = useHaveUserPermission(permHistoryName, "delete");
  const addFile = useHaveUserPermission(permFileName, "add");
  const viewFile = useHaveUserPermission(permFileName, "view");
  const findLabel = (data, value) => {
    return data.find((item) => item.value == value).label;
  };

  const getFollowUp = async () => {
    if (view) {
      if (planID && followUpData) {
        setStatus({
          label: findLabel(follow_ups_status, followUpData.stage),
          value: followUpData.stage,
        });
        // setRemark(followUpData.remark);
        setEndDate(followUpData.end_date);
        setFinish(followUpData.finished);
        setFollowUpID(planID);
        getHistory(planID);
      } else {
        setStatus(null);
        setRemark("");
        setEndDate("");
        setFinish(false);
        setFollowUpID("");
        setFollowUpHistory([]);
      }
    }
  };
  const deleteFollowUp = async (id) => {
    setDisableDelete(true);
    const result = await deleteFunc(id);
    if (result.ok) {
      setStatus(null);
      setRemark("");
      setEndDate("");
      setFinish(false);
      setFollowUpID("");
      setFollowUpHistory([]);
      setShowFollowUpModal(false);
    }
    setDisableDelete(false);
  };
  const handleDeleteHistory = async (id) => {
    // setDisableDelete(true);
    const result = await deleteHistoryFunc(id);
    if (result.ok) {
      setFollowUpHistory(followUpHistory.filter((h) => h.history_id !== id));
      setShowHistortyModal(false);
    }
    // setDisableDelete(false);
  };
  const createFollowUps = async () => {
    setDisableCreate(true);

    const result = await createFunc({
      plan: planID,
      stage: status?.value,
      remark: remark,
      end_date: moment(endDate).format("YYYY-MM-DD"),

      finished: finish,
      dropbox,
    });
    if (result.ok) {
      setFollowUpID(planID);
      if (files.length) {
        const historyID = await getHistoryID(planID);
        if (historyID.ok) {
          const id = historyID.data.id;

          await Promise.all(
            files.map(async (file, index) => {
              return sendFiles(file, planID, id);
            })
          );
          setFiles([]);
        }
      } else {
        setDisableCreate(false);
      }
      getHistory(planID);
    }
  };
  const updateFollowUps = async () => {
    setDisableCreate(true);

    const result = await updateFunc(planID, {
      plan: planID,
      stage: status?.value,
      remark: remark,
      end_date: moment(endDate).format("YYYY-MM-DD"),

      finished: finish,
      dropbox,
    });

    if (result.ok) {
      setFollowUpID(planID);
      if (files.length) {
        const historyID = await getHistoryID(planID);
        if (historyID.ok) {
          const id = historyID.data.id;

          await Promise.all(
            files.map(async (file, index) => {
              return sendFiles(file, planID, id);
            })
          );
          setFiles([]);
        }
      } else {
        setDisableCreate(false);
      }
      getHistory(planID);
    }
  };
  const getHistory = async (id) => {
    if (viewHistory) {
      const history = await getHistoryFunc(id);
      if (history.ok) {
        setFollowUpHistory(history.data);
      }
      setDisableCreate(false);
    }
  };
  const sendFiles = async (file, followUpID, historyId) => {
    const data = new FormData();
    data.append("note", file.note);
    data.append("file", file.file);
    data.append("FollowUp", followUpID);
    data.append("related_history_id", historyId);
    const result = await sendFileFunc(data);
  };
  useEffect(() => {
    if (planID) {
      getFollowUp();
    } else {
      setStatus(null);
      setEndDate("");
      setRemark("");
      setFinish(false);
      setDropbox("");
      setFiles([]);
      setFollowUpHistory([]);
    }
  }, [planID, followUpData]);
  // useEffect(() => {
  //   getPlans();
  // }, []);

  const onChange = (value) => {
    setStatus(value);
    setEndDate("");
    setRemark("");
  };
  const deleteFields = () => {
    setStatus(null);
    setEndDate("");
    setRemark("");
    setDropbox("");
    setFinish(false);
  };

  return (
    <div className="container shadow p-0 " dir={lang == "AR" ? "rtl" : "ltr"}>
      <div className="row ">
        <div className="col-12 col-lg-6 mx-0">
          <div className="bg-success mx-0 d-flex">
            <p className="m-auto py-5 fs-3 fw-bold text-white">{title}</p>
          </div>

          {plan && (
            <div className="row m-2">
              <input
                readOnly
                className="form-control col-lg col-sm-12 my-2 mx-1 input-bg"
                placeholder={strings2.country[lang]}
                value={
                  lang == "AR"
                    ? plan.country?.country
                    : plan.country?.country_EN
                }
              />
              <input
                readOnly
                className="form-control col-lg col-sm-12 my-2 mx-1 input-bg"
                placeholder={strings2.city[lang]}
                value={lang == "AR" ? plan.city?.city : plan.city?.city_EN}
              />
              <input
                readOnly
                className="form-control col-lg col-sm-12 my-2 mx-1 input-bg"
                placeholder={strings2.donor[lang]}
                value={
                  lang == "AR"
                    ? plan.sponsor?.sponsor
                    : plan.sponsor?.sponsor_EN
                }
              />
              <input
                readOnly
                className="form-control col-lg col-sm-12 mx-1 my-2 input-bg"
                placeholder={strings2.camp_start[lang]}
                value={plan.camp_start_date}
              />
            </div>
          )}
          <AutocomleteField
            value={status}
            setValue={onChange}
            title={strings[lang].follow_up.stage}
            data={follow_ups_status}
          />
          <label className="form-label mx-3">
            {strings[lang].follow_up.stage_end_date}
          </label>

          <Datetime
            dateFormat="YYYY-MM-DD"
            onChange={(v) => {
              if (v) {
                setEndDate(moment(v).format("YYYY-MM-DD"));
              } else {
                setEndDate("");
              }
            }}
            // value={endDate}
            closeOnClickOutside
            closeOnSelect
            className="p-2 "
            timeFormat={false}
            inputProps={{
              placeholder: strings[lang].follow_up.choose_a_date,
              value: endDate,
            }}
          />
          {title == strings[lang].follow_up.media_follow_up && (
            <div className="mx-2 my-2">
              <label className="form-label mx-2">
                {strings[lang].follow_up.dropbox}
              </label>

              <input
                onChange={(e) => setDropbox(e.target.value)}
                value={dropbox}
                className="form-control  p-2  "
                placeholder={strings[lang].follow_up.write_here}
              />
            </div>
          )}
          <div className="mx-2 my-2">
            <label className="form-label mx-2 ">
              {strings[lang].follow_up.remark}
            </label>

            <textarea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              style={{ resize: "none" }}
              rows={2}
              className="form-control  p-2  "
              placeholder={strings[lang].follow_up.write_here}
            ></textarea>
          </div>
          <div className="form-check px-2">
            <input
              onChange={(e) => setFinish(e.target.checked)}
              className="form-check-input float-none ms-0"
              type="checkbox"
              checked={finish}
              id="flexCheckDefault"
            />
            <label className="form-check-label mx-2" htmlFor="flexCheckDefault">
              {strings2.finished[lang]}
            </label>
          </div>
          <div className="d-flex justify-content-around my-4">
            <button
              onClick={followUpID ? updateFollowUps : createFollowUps}
              className={`btn btn-success px-4  ${
                !planID || disableCreate || !status?.value || !endDate
                  ? "disabled"
                  : change || add
                  ? followUpID && !change
                    ? "disabled"
                    : ""
                  : "disabled"
              }`}
            >
              {followUpID ? strings2.change[lang] : strings2.create[lang]}
              {disableCreate && (
                <span
                  className={`spinner-border spinner-border-sm me-2 `}
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
            <button
              onClick={() => {
                deleteFields();
              }}
              className={`btn btn-success mx-lg-2 my-2 my-lg-0   px-2  col-lg-3`}
            >
              {strings2.Delete_fields[lang]}
            </button>
            <button
              onClick={() => setShowFollowUpModal(true)}
              className={`btn btn-danger px-4 ${
                !followUpID || !planID || disableDelete || !deletePerm
                  ? "disabled"
                  : ""
              }`}
            >
              {strings[lang].follow_up.delete}
              {disableDelete && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </div>
        <div className="col">
          <div className="w-100 justify-content-center align-items-center d-flex mt-2">
            <button
              disabled={!followUpID}
              onClick={() => setShowHistoryModal(true)}
              className={`btn btn-success px-4 `}
            >
              {strings2.history[lang]}
            </button>
          </div>
          <Dropzone setFiles={setFiles} files={files} disabled={!addFile} />
        </div>
      </div>
      {followUpHistory.length > 0 && (
        <div
          className=" table-responsive p-0"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <table className="table table-hover table-striped  table-bordered text-center align-middle">
            <thead className="">
              <tr>
                <th scope="col">#</th>
                {/* <th scope="col">{strings[lang].follow_up.start}</th> */}
                <th scope="col">{strings[lang].follow_up.stage}</th>
                <th scope="col">{strings[lang].follow_up.end}</th>
                <th scope="col">{strings[lang].follow_up.remark}</th>
                <th scope="col">{strings[lang].follow_up.finished}</th>
                <th scope="col">{strings[lang].follow_up.user}</th>
                <th scope="col">{strings[lang].follow_up.modification_date}</th>
                <th scope="col">{strings[lang].follow_up.attachments}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {followUpHistory.map((item, index) => (
                <tr key={item.history_id}>
                  <th scope="row"> {followUpHistory.length - index}</th>
                  {/* <td>{item.start_date}</td> */}
                  <td>{findLabel(follow_ups_status, item.stage)}</td>

                  <td>{item.end_date}</td>
                  <td>{item.remark}</td>
                  <td>
                    <i
                      className={`fa ${
                        item.finished
                          ? "fa-check-circle text-success"
                          : "fa-times-circle text-danger"
                      } `}
                      aria-hidden="true"
                    ></i>
                  </td>

                  <td>{item.user}</td>
                  <td dir="ltr">
                    {moment(item.history_date).format("YYYY-MM-DD hh:mm a")}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    <Attachments
                      viewFile={viewFile}
                      id={item.history_id}
                      getFiles={getFilesFunc}
                      deletePerm={deleteFilePerm}
                      deleteFunc={deleteFileFunc}
                    />
                  </td>
                  <td>
                    <p
                      className={`mb-0  btn btn-danger
                     ${!deleteHistory ? "disabled" : ""}`}
                      onClick={() => {
                        setHistoryID(item.history_id);
                        setShowHistortyModal(true);
                      }}
                    >
                      {strings2.delete[lang]}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <FollowUpHistoriesModal
        followUpID={followUpID}
        setShow={setShowHistoryModal}
        show={showHistoryModal}
      />
      <DeleteModal
        deleteMethod={deleteFollowUp}
        id={followUpID}
        setShow={setShowFollowUpModal}
        show={showFollowUpModal}
      />
      <DeleteModal
        deleteMethod={handleDeleteHistory}
        id={historyID}
        setShow={setShowHistortyModal}
        show={showHistortyModal}
      />
    </div>
  );
}
