import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
import AutoComleteField from "../../components/common/AutoComleteField";
import dateDiff from "../../components/common/dateDiff";
import api from "../../components/api/api";
import * as htmlToImage from "html-to-image";
import Axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import strings from "../../components/common/strings.json";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";

import { userLang } from "../../store/reducers/user";
import useStatusData from "../../hooks/useStatusData";
import strings2 from "../../components/common/strings2.json";

export function ChronologicalReport() {
  const [reports, setReports] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [value, setValue] = useState(new Date().getFullYear());
  const [yearList, setYearList] = useState([]);

  const { data: statusData } = useStatusData();
  const lang = useSelector(userLang);

  const getReprts = async () => {
    const { data, ok } = await api.getChronologicalReport(
      year || new Date().getFullYear()
    );
    if (ok) {
      setValue(year || new Date().getFullYear());

      setReports([
        [
          { type: "string", id: "Position" },
          { type: "string", id: "Name" },
          { type: "string", role: "tooltip" },
          { type: "string", id: "style", role: "style" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ],
        ...data.map((report) => {
          const start_date = moment(report.start_date);
          const end_date = moment(report.end_date);

          return [
            lang == "AR" ? report.team : report.team_EN,
            ` ${lang == "AR" ? report.country : report.country_EN} ${
              lang == "AR" ? report.city : report.city_EN
            }`,
            `<div class='m-2'  >
                <p class=' text-center fw-bold text-nowrap'>${
                  lang == "AR" ? report.country : report.country_EN
                } ${lang == "AR" ? report.city : report.city_EN}</p>
                <hr>
                <p class='text-center fw-bold text-nowrap'>${`${start_date.format(
                  "MMM DD"
                )}, ${end_date.format("MMM DD")}`}</p>
                <hr>
                <p class='text-center fw-bold text-nowrap'>${dateDiff(
                  new Date(
                    start_date.get("y"),
                    start_date.get("M"),
                    start_date.get("D")
                  ),
                  new Date(
                    end_date.get("y"),
                    end_date.get("M"),
                    end_date.get("D")
                  )
                )}</p>
            </div>
    `,
            report.status?.color ?? "#C4C4C4",
            new Date(
              start_date.get("y"),
              start_date.get("M"),
              start_date.get("D")
            ),
            new Date(end_date.get("y"), end_date.get("M"), end_date.get("D")),
          ];
        }),
      ]);
    }
  };
  const getYearsList = () => {
    const yearNow = new Date().getFullYear();
    const years = [];
    for (var y = yearNow; y >= 1989; y--) {
      years.push({ value: y, label: y });
    }
    setYearList([...years]);
  };
  useEffect(() => {
    getReprts();
    getYearsList();
  }, [year]);
  const createImg = async () => {
    const chart = document.getElementById("chart-data");

    const img = await htmlToImage.toPng(chart);
    Axios.get(img, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "reports" + ".JPEG");
    });
  };
  const createPDF = async () => {
    const chart = document.getElementById("chart-data");
    const imgURL = await htmlToImage.toPng(chart);
    const doc = new jsPDF("p", "mm", "a4");
    var width = doc.internal.pageSize.getWidth();

    doc.addImage(imgURL, "JPEG", 0, 0, width, 0);
    doc.save("reports.pdf");
  };

  return (
    <div className="flex-fill d-flex align-items-center  flex-column">
      <div className="container  " dir={lang == "AR" ? "rlt" : "ltr"}>
        <div className="d-flex justify-content-center align-items-center w-100 mb-2 mt-2">
          <div className="year-select me-3">
            <Datetime
              closeOnSelect
              dateFormat="YYYY"
              onChange={(v) => {
                if (v) {
                  setYear(moment(v).format("YYYY"));
                } else {
                  setYear("");
                }
              }}
              timeFormat={false}
              inputProps={{
                placeholder: strings2.select[lang],
                value: year,
                style: {},
              }}
            />
          </div>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-print me-1" aria-hidden="true"></i>
            </button>
            <ul className="dropdown-menu">
              <li onClick={createPDF}>
                <a className="dropdown-item">PDF</a>
              </li>
              <li onClick={createImg}>
                <a className="dropdown-item" href="#">
                  {strings[lang].reports.photo}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <table
          className="table table-striped  table-bordered text-center align-middle"
          dir={lang == "EN" ? "ltr" : "rtl"}
        >
          <tbody>
            <tr>
              {statusData.map((item, index) => (
                <td key={index}>
                  {lang === "AR" ? item.status : item.status_EN}
                </td>
              ))}
              <td>{strings2.noStatus[lang]}</td>
            </tr>
            <tr>
              {statusData.map((item, index) => (
                <td key={index}>
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div
                      style={{
                        height: 15,
                        width: 15,
                        borderRadius: "50%",
                        backgroundColor: item.color,
                      }}
                    ></div>
                  </div>
                </td>
              ))}
              <td>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div
                    style={{
                      height: 15,
                      width: 15,
                      borderRadius: "50%",
                      backgroundColor: "#C4C4C4",
                    }}
                  ></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {reports.length > 1 ? (
        // <div id="chart-data h-100 " style={{ backgroundColor: "#fff" }}>
        <Chart
          graphID="chart-data"
          options={{
            allowHtml: true,
            // colors: ["#cbb69d", "#603913", "#c69c6e"],
            hAxis: {
              maxValue: new Date(value, 12, 31),
              minValue: new Date(value, 1, 1),
            },
            timeline: {
              rowLabelStyle: {
                fontSize: 20,
              },
              barLabelStyle: { fontSize: 15 },
            },
          }}
          chartType="Timeline"
          data={reports}
          width="98%"
          height="100%"
          formatters={[
            {
              type: "DateFormat",
              options: { formatType: "long" },
              column: 2,
            },
          ]}
        />
      ) : (
        // </div>
        <>
          {reports.length > 0 ? (
            <div className="d-flex justify-content-center ">
              <p className="mt-5 fs-5 fw-bold">
                {strings[lang].reports.no_result}
              </p>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <div
                style={{ width: "3rem", height: "3rem" }}
                className="spinner-border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
