import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import strings2 from "./strings2.json";
import PlanTable from "./PlanTable";
import PaginationBar from "./PaginationBar";

import { userLang } from "../../store/reducers/user";
import { useSelector } from "react-redux";
import { useGetPlansQuery } from "../../store/services/plan";

import SearchModalFields from "./SearchModalFields";
import { useForm } from "react-hook-form";
import moment from "moment";
export default function SearchModal({
  show,
  setShow,
  setPlanID,
  followUp = false,
  searchFields = {},
  archives,
  setArchives,
  plan,
  setPlan,
  normalSearch,
  dataNormalSearch = {},
}) {
  const [disabled, setDisabled] = useState(false);
  const [back, setBack] = useState(true);
  const [search, setSearch] = useState(false);

  const [page, setPage] = useState(1);
  const [pagesSize, setPagesSize] = useState(20);
  const [sort, setSort] = useState("");

  const [planId, setPlanId] = useState();

  const [searchData, setSearchData] = useState({});

  const formData = useForm({
    defaultValues: {
      city: null,
      country: null,
      year: "",
      team: null,
      status: null,
      responsible: null,
      remark: "",
      sponsor: null,
      sponsorship: null,
      reference_number: "",
      project_number: "",
      dateFrom: "",
      dateTo: "",
      archives,
    },
  });

  const fieldsData = normalSearch ? dataNormalSearch : searchData;
  const { data: plans, isFetching: isFetchingPlans } = useGetPlansQuery(
    {
      ...fieldsData,
      page,
      page_size: pagesSize,
      ordering: sort ? `${sort.type == "Desc" ? "-" : ""}${sort.field}` : "",
      followUp,
      ...searchFields,
    },
    {
      skip: !search,
    }
  );

  // const [triggerPlans, { data: plans, isFetching: isFetchingPlans }] =
  //   useLazyGetPlansQuery();

  const lang = useSelector(userLang);

  const searchPlans = async () => {
    const {
      city,
      country,
      year,
      team,
      status,
      responsible,
      sponsor,
      sponsorship,

      reference_number,
      project_number,
      dateFrom,
      dateTo,
      archives: archivesForm,
    } = formData.getValues();
    const planData = {
      year: year,
      country: country?.value,
      city: city?.value,
      team: team?.value ? team?.value : "",
      status: status?.value,
      responsible: responsible?.value ? responsible?.value : "",
      camp_start_date: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
      camp_end_date: dateTo ? moment(dateTo).format("YYYY-MM-DD") : null,
      sponsor: sponsor?.value ? sponsor?.value : "",
      sponsorship_new: sponsorship
        ? sponsorship.map((value) => value.value)
        : [],

      reference_number: reference_number || "",
      project_number: project_number || "",
    };
    setSearch(true);
    setPage(1);
    setArchives(archivesForm);
    setSearchData(planData);
    setDisabled(true);

    setDisabled(false);
    setBack(false);
  };

  const onClickRow = async (item) => {
    if (typeof setPlan == "function") setPlan(item);
    setPlanId(item.id);
    setPlanID(item.id);
    // setTimeout(() => {
    //   setBack(false);
    // }, 500);
    setShow(false);
  };

  const selectPageSize = async (e) => {
    setPagesSize(e.target.value || 20);

    setPage(1);
  };

  const handleBack = () => {
    if (!back && !normalSearch) {
      setBack(true);
      setSearch(false);
    } else {
      setShow(false);
      // setTimeout(() => {
      //   setBack(false);
      // }, 500);
    }
  };

  useEffect(() => {
    if (normalSearch && show) {
      setBack(true);
      setSearch(true);

      setPage(1);
    }
  }, [normalSearch, show]);

  return (
    <Modal
      dir={lang == "EN" ? "ltr" : "rtl"}
      show={show}
      onHide={() => {
        // setTimeout(() => {
        //   setBack(true);
        // }, 500);
        // setBack(true);
        setShow(false);
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!normalSearch && back ? (
          <SearchModalFields
            formData={formData}
            archives={archives}
            setArchives={setArchives}
          />
        ) : (
          <>
            {!!plans?.count && plans?.count > pagesSize && (
              <div className="d-flex align-items-center flex-column flex-lg-row justify-content-between mx-3">
                <div dir={lang == "EN" ? "lrt" : "rtl"}>
                  <PaginationBar
                    pageCount={Math.ceil(plans?.count / pagesSize)}
                    setPage={setPage}
                    page={page}
                  />
                </div>
                <div>
                  <select
                    // style={{ height: 40, maxWidth: 150 }}
                    className=" form-select form-select-sm"
                    aria-label="Default select example"
                    onChange={async (e) => {
                      selectPageSize(e);
                    }}
                    defaultValue={""}
                  >
                    {/* <option value="">{strings2.number_of_items[lang]}</option> */}
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            )}
            <PlanTable
              search
              planId={planId}
              onClickRow={onClickRow}
              plans={plans?.results ?? []}
              pagesSize={pagesSize}
              currentPage={page}
              sort={sort}
              setSort={setSort}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Button
          variant="secondary"
          onClick={() => {
            handleBack();
          }}
        >
          {normalSearch || back ? strings2.close[lang] : strings2.back[lang]}
        </Button>
        {!normalSearch && back && (
          <Button
            disabled={disabled}
            variant="success"
            onClick={() => searchPlans()}
          >
            {strings2.search[lang]}
            {disabled && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
