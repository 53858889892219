import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import api from "../components/api/api";
import DeleteModal from "../components/common/DeleteModal";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import AutoComleteField from "../components/common/AutoComleteField";
import strings from "../components/common/strings.json";
import strings2 from "../components/common/strings2.json";
import useHaveUserPermission from "../hooks/useHaveUserPermission";
import useCountriesData from "../hooks/useCountriesData";
import useHospitalsCountriesData from "../hooks/useHospitalsCountriesData";
import { userLang } from "../store/reducers/user";
import { useSelector } from "react-redux";
import {
  useCreateCityMutation,
  useDeleteCityMutation,
  useLazyGetCitiesQuery,
  useUpdateCityMutation,
} from "../store/services/planCities";
export default function Cities() {
  const [country, setCountry] = useState();
  const [city, setCity] = useState("");
  const [city_EN, setCity_EN] = useState("");
  // const [citiesList, setCitiesList] = useState([]);
  const [cityId, setCityId] = useState();
  const [cityIdDelete, setCityIdDelete] = useState();
  const [disableBtnCreate, setDisableBtnCreate] = useState(false);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();

  const { data: countries } = useCountriesData();
  const lang = useSelector(userLang);

  const view = useHaveUserPermission("cities", "view");
  const add = useHaveUserPermission("cities", "add");
  const change = useHaveUserPermission("cities", "change");
  const deletePerm = useHaveUserPermission("cities", "delete");

  const [createCityService] = useCreateCityMutation();
  const [updateCityService] = useUpdateCityMutation();

  const [triggerGetCity, { data: citiesList }] = useLazyGetCitiesQuery({
    selectFromResult: (data) => {
      return country?.value ? data : [];
    },
  });

  const [triggerDeleteCity, {}] = useDeleteCityMutation();

  const createCity = async () => {
    setDisableBtnCreate(true);
    createCityService({
      country: country.value,
      city,
      city_EN,
      active,
    });

    setDisableBtnCreate(false);
  };
  const getCities = async () => {
    const viewPerm = view;

    if (viewPerm) {
      triggerGetCity(country.value, !!citiesList);
    }
  };
  const onClick = (item) => {
    setActive(item.active);
    setCity(item.city);
    setCity_EN(item.city_EN);

    setCityId(item.id);
  };
  const deleteCity = async (id) => {
    triggerDeleteCity({
      countryId: country.value,
      cityId: id,
    });
  };
  const updateCity = async () => {
    setDisableBtnUpdate(true);
    updateCityService({
      data: {
        country: country.value,
        active,
        city,
        city_EN,
      },
      cityId,
    });

    setDisableBtnUpdate(false);
  };
  useEffect(() => {
    document.title = "المدن";
    if (country) {
      getCities();
      setCityId();
      setCity("");
      setActive(false);
    }
  }, [country]);
  const data = countries;

  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-4  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.cities}
        </p>
      </div>
      <div className=" d-flex    flex-column ">
        <div className="w-50 mt-4 align-self-center">
          <AutoComleteField
            value={country}
            setValue={setCountry}
            placeholder={strings[lang].data.country}
            data={data.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
          />
          <div className="mx-2">
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="form-control  p-2  my-3 "
              placeholder="المدينة"
            />
            <input
              value={city_EN}
              onChange={(e) => setCity_EN(e.target.value)}
              className="form-control  p-2  my-3 "
              placeholder="city"
            />
            <div className="form-check ">
              <input
                onChange={(e) => setActive(e.target.checked)}
                className="form-check-input float-none ms-0"
                type="checkbox"
                checked={active}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label mx-2"
                htmlFor="flexCheckDefault"
              >
                {strings[lang].data.active}
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex align-self-center">
          <button
            onClick={updateCity}
            className={`btn btn-success  ${
              disableBtnUpdate || !cityId || !change ? "disabled" : ""
            } `}
          >
            {strings[lang].data.save}
            {disableBtnUpdate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
          <button
            onClick={createCity}
            className={`btn btn-success mx-4 ${
              disableBtnCreate || !city || !add ? "disabled" : ""
            } `}
          >
            {strings[lang].data.create}
            {disableBtnCreate && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            )}
          </button>
        </div>
        {citiesList?.length > 0 && (
          <div style={{}} className="table-responsive">
            <table className="table table-hover mt-4  table-bordered text-center align-middle  ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{strings[lang].data.country}</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">city</th>
                  <th scope="col">{strings[lang].data.date_of_registration}</th>
                  <th scope="col">{strings[lang].data.active}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {citiesList.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row" onClick={() => onClick(item)}>
                      {citiesList.length - index}
                    </th>
                    <td onClick={() => onClick(item)}>
                      {lang == "AR"
                        ? data.find((i) => i.id == item.country).country
                        : data.find((i) => i.id == item.country).country_EN}
                    </td>
                    <td onClick={() => onClick(item)}>{item.city}</td>
                    <td onClick={() => onClick(item)}>{item.city_EN}</td>
                    <td onClick={() => onClick(item)} dir="ltr">
                      <span>{moment(item.date).format("YYYY-M-D")}</span>
                      <span> {moment(item.date).format("LT")}</span>
                    </td>
                    <td onClick={() => onClick(item)}>
                      <i
                        className={`fa ${
                          item.active
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <p
                        className={`mb-0 btn btn-outline-danger  ${
                          !deletePerm ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setCityIdDelete(item.id);
                          setShowModal(true);
                        }}
                      >
                        {strings[lang].data.delete}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        setShow={setShowModal}
        show={showModal}
        id={cityIdDelete}
        deleteMethod={deleteCity}
      />
    </div>
  );
}
