import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../components/api/api";
import strings from "../components/common/strings.json";
import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
export default function ResetPassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [typeCurrPass, setTypeCurrPass] = useState("password");
  const [typeNewPass, setTypeNewPass] = useState("password");
  const [disableUpdate, setDisableUpdate] = useState(false);
  const lang = useSelector(userLang);

  const setPassword = async () => {
    setDisableUpdate(true);
    const passData = {
      new_password: newPassword,
      current_password: currentPassword,
    };
    const { ok, data } = await api.resetPassword(passData);
    if (ok) {
      toast.success("تم تغيير كلمة المرور بنجاح");
    } else {
      const current_password = data?.current_password
        ? data?.current_password.toString()
        : "";
      const new_password = data?.new_password
        ? data?.new_password.toString()
        : "";
      toast.error(current_password || new_password);
    }
    setDisableUpdate(false);
  };
  useEffect(() => {
    document.title = "تغيير كلمة المرور";
  }, []);
  return (
    <div
      dir={lang == "AR" ? "rtl" : "ltr"}
      className="container   mt-3 p-0 bg-light shadow pb-1  "
    >
      <div className="bg-success d-flex p-5 ">
        <p className="fs-3 fw-bold  m-auto text-white text-center">
          {strings[lang].data.reset_pass}
        </p>
      </div>
      <div className="counatiner-fluid   mt-3 ">
        <form className="d-flex justify-content-center align-items-center  flex-column  ">
          <div className="col-sm-12 col-lg-6  ">
            <div className="mx-2 mb-2  position-relative">
              <input
                autoComplete="new-password"
                list="autocompleteOff"
                type={typeCurrPass}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings[lang].data.old_pass}
              />
              <i
                onClick={() =>
                  setTypeCurrPass(
                    typeCurrPass == "password" ? "text" : "password"
                  )
                }
                style={{
                  position: "absolute",
                  top: "50%",
                  left: lang == "AR" ? 20 : "92%",

                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  zIndex: 1000,
                  fontSize: "1.2rem",
                }}
                className={`fa fa-eye${typeCurrPass == "text" ? "-slash" : ""}`}
                aria-hidden="true"
              ></i>
            </div>
            <div className="mx-2 mb-2  position-relative">
              <input
                autoComplete="new-password"
                list="autocompleteOff"
                type={typeNewPass}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="form-control  p-2  "
                placeholder={strings[lang].data.new_pass}
              />
              <i
                onClick={() =>
                  setTypeNewPass(
                    typeNewPass == "password" ? "text" : "password"
                  )
                }
                style={{
                  position: "absolute",
                  top: "50%",
                  left: lang == "AR" ? 20 : "92%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  zIndex: 1000,
                  fontSize: "1.2rem",
                }}
                className={`fa fa-eye${typeNewPass == "text" ? "-slash" : ""}`}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </form>
      </div>
      <div className="d-flex align-items-center justify-content-center my-2">
        <button
          onClick={setPassword}
          className={`btn btn-success mx-2 ${
            disableUpdate || !currentPassword || !newPassword ? "disabled" : ""
          }`}
        >
          {strings[lang].data.save}
          {disableUpdate && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
      </div>
    </div>
  );
}
