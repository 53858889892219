import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../components/api/api";

const initialState = {
  data: null,
  loading: false,
  error: false,
};

export const getStatus = createAsyncThunk("status/getStatus", async () => {
  return await apiServices.getStatus();
});
export const createStatusService = createAsyncThunk(
  "status/createStatusService",
  async (data) => {
    return await apiServices.createStatus(data);
  }
);
export const deleteStatusService = createAsyncThunk(
  "status/deleteStatusService",
  async (id, { rejectWithValue }) => {
    const { ok } = await apiServices.deleteStatus(id);
    if (ok) return id;
    return rejectWithValue();
  }
);
export const updateStatusService = createAsyncThunk(
  "status/updateStatusService",
  async ({ id, data }) => {
    return await apiServices.updateStatus(id, data);
  }
);

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.data = action.payload.data.reverse();
      })
      .addCase(getStatus.rejected, (state) => {
        state.error = true;
      })
      .addCase(createStatusService.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
      })
      .addCase(deleteStatusService.fulfilled, (state, action) => {
        state.data = state.data.filter((s) => s.id !== action.payload);
      })
      .addCase(updateStatusService.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (r) => r.id === action.payload.data.id
        );
        state.data[index] = action.payload.data;
      });
  },
});

export const {} = statusSlice.actions;

export const statusData = (state) => state.status;

export default statusSlice.reducer;
