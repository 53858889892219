import React, { useEffect, useState } from "react";
import api from "../components/api/api";
import Table from "../components/home/Table";
import strings2 from "../components/common/strings2.json";
import { useSelector } from "react-redux";
import { userLang } from "../store/reducers/user";
export default function HomeHospitals({ camps }) {
  const [hospitalsCountriesReports, setHospitalsCountriesReports] = useState();

  const HospitalsCountriesYearsReport = async () => {
    const { data, ok } = await api.HospitalsCountriesYearsReport();
    if (ok) {
      setHospitalsCountriesReports(data);
    } else {
      setHospitalsCountriesReports([]);
    }
  };

  useEffect(() => {
    HospitalsCountriesYearsReport();
  }, []);
  return (
    <div className="container flex-fill">
      <div className="flex-fill">
        {hospitalsCountriesReports ? (
          <div className="row p-0 my-2 w-100">
            {hospitalsCountriesReports.map((i, index) => (
              <Table
                key={index}
                label={strings2.hospital}
                title={{
                  AR: i.country_name + " {}",
                  EN: i.country_name_EN + " {}",
                }}
                data={i.hospitals}
                hospitals
              />
            ))}
          </div>
        ) : (
          <div className="position-absolute top-50 start-50 translate-middle">
            <div
              style={{ width: "3rem", height: "3rem" }}
              className="spinner-border spinner-border-sm m-auto "
              role="status"
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
