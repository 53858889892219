/* eslint-disable react/no-danger-with-children */
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { writeFile, utils, write } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import strings2 from "../common/strings2.json";
import sysData from "../common/data.json";
import ChartReport from "./ChartReport";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";

export default function Table({ data, title, label, hospitals }) {
  const lang = useSelector(userLang);

  const location = useLocation();
  const [show, setShow] = useState(false);

  const numberFormatter = Intl.NumberFormat("en-US");
  const createExcel = () => {
    const table = document.getElementById(title[lang].replaceAll(" ", "-"));

    const wb = utils.table_to_book(table, { raw: true });
    writeFile(wb, "reports.xlsx");
  };
  const downloadPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [
        hospitals
          ? ["#", label["EN"], strings2.clinics["EN"], strings2.surgeries["EN"]]
          : [
              "#",
              label["EN"],
              strings2.number_of_camps["EN"],
              strings2.clinics["EN"],
              strings2.surgeries["EN"],
              strings2.glasses["EN"],
            ],
      ],
      body: hospitals
        ? [
            ...data.map((item, index) => [
              index + 1,
              item.name_EN,
              item.clinics || 0,
              item.surgery || 0,
            ]),
          ]
        : [
            ...data.data.map((item, index) => [
              index + 1,
              item.name_EN,
              item.camp_count || 0,
              item.clinics || 0,
              item.surgery || 0,
              item.glasses || 0,
            ]),
          ],

      styles: { valign: "middle", halign: "center", fontStyle: "Amiri" },
      margin: { horizontal: 5, vertical: 5 },
    });

    doc.save("table.pdf");
  };
  function Table2({ columns, data2 }) {
    const formateColumnField = ["glasses", "surgery", "clinics", "camp_count"];

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data: data2,
        },
        useSortBy
      );
    // Render the UI for your table
    return (
      <table
        id={title[lang].replaceAll(" ", "-")}
        dir={lang == "AR" ? "rtl" : "ltr"}
        className="container table table-hover table-striped  table-bordered text-center align-middle my-2"
        {...getTableProps()}
      >
        <thead className="header-tabel" style={{ position: "sticky", top: 0 }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  <span>
                    {column.isSorted ? (
                      <i
                        className={
                          column.isSortedDesc
                            ? "fa fa-caret-down"
                            : "fa fa-caret-up"
                        }
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row?.cells?.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {formateColumnField.includes(
                        cell.render("Cell").props.column.id
                      )
                        ? numberFormatter.format(
                            cell.render("Cell").props.value
                          )
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const findLabel = (data, value) => {
    return data.find((item) => item.value == value)?.label;
  };
  const columns = React.useMemo(() => {
    let dataT = [
      {
        Header: label[lang],
        accessor: lang == "AR" ? "name" : "name_EN",
      },
      {
        Header: strings2.clinics[lang],
        accessor: "clinics",
      },
      {
        Header: strings2.surgeries[lang],
        accessor: "surgery",
      },
    ];
    if (location.pathname.includes("camps")) {
      dataT.splice(1, 0, {
        Header: strings2.number_of_camps[lang],
        accessor: "camp_count",
      });
      dataT.splice(4, 0, {
        Header: strings2.glasses[lang],
        accessor: "glasses",
      });
    }
    if (title[lang] == strings2.camp_situation[lang]) {
      dataT = dataT.slice(0, 2);
    }
    return dataT;
  }, [lang, location]);

  // const rowData = React.useMemo(() => [], [data])
  const rowData = React.useMemo(() => (hospitals ? data : data.data), [data]);
  const chartData = React.useMemo(() => {
    const d = data?.data?.map((i) => {
      return [(lang == "AR" ? i.name : i.name_EN).toString(), i.camp_count];
    });
    if (d) {
      d.unshift([label[lang], strings2.number_of_camps[lang]]);
    }
    return d;
  }, [data]);
  return (
    <>
      <div className="col-lg-6 px-2 col-12 p-0 shadow position-relative">
        <div className="w-100 my-2 d-flex align-items-center justify-content-center">
          {!hospitals && (
            <div style={{ right: 30 }} className="dropdown  position-absolute ">
              <button
                className="btn  "
                type="button"
                aria-expanded="false"
                onClick={() => setShow(true)}
              >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
              </button>
            </div>
          )}
          <span
            className="fs-5 mx-2"
            dangerouslySetInnerHTML={{
              __html: title[lang].replaceAll(
                "{}",
                `<span class="badge text-bg-primary fs-5">
          ${hospitals ? data.length : data?.data?.length || 0}
        </span>`
              ),
            }}
          ></span>

          <div style={{ left: 15 }} className="dropdown  position-absolute ">
            <button
              className="btn  dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-print" aria-hidden="true"></i>
            </button>
            <ul className="dropdown-menu">
              <li onClick={downloadPDF}>
                <a className="dropdown-item">PDF</a>
              </li>

              <li onClick={createExcel}>
                <a className="dropdown-item">Excel</a>
              </li>
            </ul>
          </div>
          {/* <span className="fs-5 mx-2">وصلت فرقنا إلى</span> */}
        </div>
        <div
          className="position-relative"
          style={{
            height: "50vh",
            overflowY: "auto",
          }}
        >
          {(data?.data?.length > 0 || hospitals) && (
            <Table2 columns={columns} data2={rowData} />
          )}

          {!hospitals && data?.loading && (
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                style={{ width: "3rem", height: "3rem" }}
                className="spinner-border spinner-border-sm m-auto "
                role="status"
              ></div>
            </div>
          )}
        </div>
      </div>
      {location.pathname.includes("camps") && (
        <ChartReport
          title={title[lang]}
          label={label[lang]}
          show={show}
          setShow={setShow}
          data={chartData}
        />
      )}
    </>
  );
}
