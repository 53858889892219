import React, { useState } from "react";
import api from "../api/api";
import strings2 from "./strings2.json";
import AutocomleteField from "./AutoComleteField";
import Datetime from "react-datetime";
import moment from "moment";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
import useCountriesData from "../../hooks/useCountriesData";
import useSponsorsData from "../../hooks/useSponsorsData";

export default function NormalSearch({
  country,
  setCountry,
  city,
  setCity,
  sponsor,
  setSponsor,
  setStartDate,
  startDate,
  setNormalSearch,
  setShow,
}) {
  const [citiesList, setCitiesList] = useState([]);

  const lang = useSelector(userLang);
  const { data: countries } = useCountriesData();
  const { data: sponsors } = useSponsorsData();

  const setCountryAndGetCities = async (data) => {
    setCountry(data);
    if (data) {
      const result = await api.getCities(data.value);
      if (result.ok) {
        setCitiesList(result.data);
      }
    } else {
      setCitiesList([]);
    }
  };
  return (
    <div className="container">
      <div
        className="row  align-items-center mt-2  justify-content-center "
        dir={lang == "EN" ? "ltr" : "rtl"}
      >
        <div className="  mt-2 col-lg-2 col-12">
          <AutocomleteField
            value={country}
            setValue={setCountryAndGetCities}
            data={countries.map((item) => ({
              label: lang == "AR" ? item.country : item.country_EN,
              value: item.id,
            }))}
            placeholder={strings2.country[lang]}
          />
        </div>
        <div className=" mt-2 col-lg-2 col-12">
          <AutocomleteField
            value={city}
            setValue={setCity}
            data={citiesList.map((item) => ({
              label: lang == "AR" ? item.city : item.city_EN,
              value: item.id,
            }))}
            placeholder={strings2.city[lang]}
          />
        </div>
        <div className=" mt-2 col-lg-3 col-12">
          <AutocomleteField
            value={sponsor}
            setValue={setSponsor}
            data={sponsors.map((item) => ({
              label: lang == "AR" ? item.sponsor : item.sponsor_EN,
              value: item.id,
            }))}
            placeholder={strings2.donor[lang]}
          />
        </div>
        <div className=" mt-2 col-lg-2 col-12 " dir="ltr">
          <Datetime
            closeOnSelect
            className="text-end"
            dateFormat="YYYY-MM-DD"
            onChange={(v) => {
              if (v) {
                setStartDate(moment(v).format("YYYY-MM-DD"));
              } else {
                setStartDate("");
              }
            }}
            timeFormat={false}
            inputProps={{
              placeholder: strings2.camp_start[lang],
              value: startDate,
              className: "text-end form-control",
            }}
          />
        </div>
        <div className="col-lg-2 col d-flex justify-content-center ">
          <button
            className="btn btn-success  mt-2 mx-1"
            onClick={() => {
              setNormalSearch(true);
              setShow(true);
            }}
          >
            {strings2.search[lang]}
          </button>
          <button
            className="btn btn-success  mt-2 mx-1"
            onClick={() => {
              setNormalSearch(false);
              setShow(true);
            }}
          >
            {strings2.advances_search[lang]}
          </button>
        </div>
      </div>
    </div>
  );
}
