import React from "react";
import moment from "moment";
import strings2 from "../common/strings2.json";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { userLang } from "../../store/reducers/user";
export default function HistoriesModal({ show, setShow, histoies }) {
  const lang = useSelector(userLang);

  return (
    <Modal
      fullscreen
      scrollable
      dir={lang == "AR" ? "rtl" : "ltr"}
      size="xl"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table text-center table-hover table-striped table-bordered align-middle">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{strings2.year[lang]}</th>
                <th scope="col">{strings2.country[lang]}</th>
                <th scope="col">{strings2.city[lang]}</th>
                <th scope="col">{strings2.donor[lang]}</th>
                <th scope="col">{strings2.sub_donor[lang]}</th>
                <th scope="col">{strings2.team[lang]}</th>
                <th scope="col">{strings2.camp_condition[lang]}</th>
                <th scope="col">{strings2.camp_start[lang]}</th>
                <th scope="col">{strings2.camp_end[lang]}</th>
                <th scope="col">{strings2.responsible[lang]}</th>
                <th scope="col">{strings2.reference_number[lang]}</th>
                <th scope="col">{strings2.project_number[lang]}</th>
                <th scope="col">{strings2.preparatory_visit[lang]}</th>
                <th scope="col">{strings2.financial_follow_up[lang]}</th>
                <th scope="col">{strings2.financial_sector_follow_up[lang]}</th>
                <th scope="col">{strings2.medication_follow_up[lang]}</th>
                <th scope="col">{strings2.sponsors_follow_up[lang]}</th>
                <th scope="col">{strings2.media_follow_up[lang]}</th>
                <th scope="col">{strings2.user[lang]}</th>
                <th scope="col">{strings2.modification_date[lang]}</th>
                <th scope="col">{strings2.remark[lang]}</th>
              </tr>
            </thead>
            <tbody>
              {histoies.map((item, index) => {
                const nextIndex =
                  index + 1 > histoies.length - 1 ? histoies.length : index + 1;
                const year =
                  item.year == histoies[nextIndex]?.year ||
                  index == histoies.length - 1;
                const country =
                  item.country?.AR == histoies[nextIndex]?.country?.AR ||
                  index == histoies.length - 1;
                const city =
                  item.city?.AR == histoies[nextIndex]?.city?.AR ||
                  index == histoies.length - 1;
                const sponsor =
                  item.sponsor?.AR == histoies[nextIndex]?.sponsor?.AR ||
                  index == histoies.length - 1;
                const sponsorship =
                  item.sponsorship?.AR ==
                    histoies[nextIndex]?.sponsorship?.AR ||
                  index == histoies.length - 1;
                const team =
                  item.team?.AR == histoies[nextIndex]?.team?.AR ||
                  index == histoies.length - 1;
                const status =
                  item.status?.AR == histoies[nextIndex]?.status?.AR ||
                  index == histoies.length - 1;
                const camp_start_date =
                  item.camp_start_date ==
                    histoies[nextIndex]?.camp_start_date ||
                  index == histoies.length - 1;
                const camp_end_date =
                  item.camp_end_date == histoies[nextIndex]?.camp_end_date ||
                  index == histoies.length - 1;
                const responsible =
                  item.responsible?.AR ==
                    histoies[nextIndex]?.responsible?.AR ||
                  index == histoies.length - 1;
                const reference_number =
                  item.reference_number ==
                    histoies[nextIndex]?.reference_number ||
                  !!item.reference_number ==
                    !!histoies[nextIndex]?.reference_number ||
                  index == histoies.length - 1;
                const project_number =
                  item.project_number == histoies[nextIndex]?.project_number ||
                  index == histoies.length - 1;

                const visit =
                  item.visit == histoies[nextIndex]?.visit ||
                  index == histoies.length - 1;
                const financial =
                  item.financial == histoies[nextIndex]?.financial ||
                  index == histoies.length - 1;
                const financialsector =
                  item.financialsector ==
                    histoies[nextIndex]?.financialsector ||
                  index == histoies.length - 1;
                const medication =
                  item.medication == histoies[nextIndex]?.medication ||
                  index == histoies.length - 1;
                const sponsors =
                  item.sponsors == histoies[nextIndex]?.sponsors ||
                  index == histoies.length - 1;
                const media =
                  item.media == histoies[nextIndex]?.media ||
                  index == histoies.length - 1;
                return (
                  <tr key={item.history_id}>
                    <td className="fw-bold"> {histoies.length - index}</td>
                    <td className={year ? "" : "bg-primary text-white"}>
                      {item.year}
                    </td>
                    <td className={country ? "" : "bg-primary text-white"}>
                      {item.country ? item.country[lang] : ""}
                    </td>
                    <td className={city ? "" : "bg-primary text-white"}>
                      {item.city ? item.city[lang] : ""}
                    </td>
                    <td className={sponsor ? "" : "bg-primary text-white"}>
                      {item.sponsor ? item.sponsor[lang] : ""}
                    </td>
                    <td className={sponsorship ? "" : "bg-primary text-white"}>
                      {item.sponsorship ? item.sponsorship[lang] : ""}
                    </td>
                    <td className={team ? "" : "bg-primary text-white"}>
                      {item.team ? item.team[lang] : ""}
                    </td>
                    <td className={status ? "" : "bg-primary text-white"}>
                      {item.status ? item.status[lang] : ""}
                    </td>
                    <td
                      className={camp_start_date ? "" : "bg-primary text-white"}
                    >
                      {item.camp_start_date}
                    </td>
                    <td
                      className={camp_end_date ? "" : "bg-primary text-white"}
                    >
                      {item.camp_end_date}
                    </td>
                    <td className={responsible ? "" : "bg-primary text-white"}>
                      {item.responsible ? item.responsible[lang] : ""}
                    </td>
                    <td
                      className={
                        reference_number ? "" : "bg-primary text-white"
                      }
                    >
                      {item.reference_number ? item.reference_number : ""}
                    </td>
                    <td
                      className={project_number ? "" : "bg-primary text-white"}
                    >
                      {item.project_number ? item.project_number : ""}
                    </td>

                    <td className={visit ? "" : "bg-primary text-white"}>
                      <i
                        className={`fa ${
                          item.visit
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td className={financial ? "" : "bg-primary text-white"}>
                      <i
                        className={`fa ${
                          item.financial
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td
                      className={financialsector ? "" : "bg-primary text-white"}
                    >
                      <i
                        className={`fa ${
                          item.financialsector
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td className={medication ? "" : "bg-primary text-white"}>
                      <i
                        className={`fa ${
                          item.medication
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td className={sponsors ? "" : "bg-primary text-white"}>
                      <i
                        className={`fa ${
                          item.sponsors
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td className={media ? "" : "bg-primary text-white"}>
                      <i
                        className={`fa ${
                          item.media
                            ? "fa-check-circle text-success"
                            : "fa-times-circle text-danger"
                        } `}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>{item.history_user}</td>
                    <td dir="ltr">
                      {moment(item.history_date).format("YYYY-MM-DD hh:mm a")}
                    </td>
                    <td>{item.modification_reason}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
